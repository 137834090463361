import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import {Button, Form} from 'react-bootstrap';
import {notificationService} from '../../../shared/services/notification-service';
import {useAuth} from '../../../shared/authentications/auth-context';
import {useNavigate, useParams} from 'react-router-dom';
import {LeaveRequestTypeOptions, TotalLeaveTime} from "../../../shared/utils/constants";
import InputSelect from "../../../shared/components/atoms/input-select";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import DateInput from "../../../shared/components/atoms/date-input";
import LeaveFileForm from "../components/file-form";
import {convertDateToString} from "../../../shared/utils/functions-date-time";
import {fileToBase64} from "../../../shared/utils/function-attachment";
import {LeaveRequestService} from "../../../shared/services/leave-request";
import {LeaveRequest, LeaveRequestQueryParams} from "../../../shared/models/leave-request.model";
import {ApiResponse} from "../../../shared/models/common.model";
import RejectDialog from "../components/reject-dialog";
import LeaveHistoryDialog from "../components/leave-history-dialog";
import {apiDropdownService} from "../../../shared/services/dropdown-service";

interface File {
  file: string | null;
  file_name: string;
  new_file?: boolean;
}

export type formType = {
  status: string,
  leave_request_details:
    {
      leave_type: string,
      total_leave_time: string,
      start_date: string,
      end_date: string,
      total_hours: number,
      total_hours_to_days: number,
      reason: string | null,
      attachment: string | null,
    }[]
  created_by_user: {
    code: string,
    fullname_th: string,
    employee: {
      photo: string,
      job_positions: {
        position_name: string,
        is_currently: boolean
      }[]
    },
  }

}

const ApproverRequestDetailPage: FC = () => {
  let {id} = useParams();
  const [submitted, setSubmitted] = useState(false);
  const {startLoading, stopLoading} = useSpinnerLoader();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<LeaveRequestQueryParams>({
    request_id: '',
    status: '',
    page: 1,
    page_size: 10,
    leave_limit_by_type_id: '',
  });

  const onLeaveHistoryButtonClick = async () => {
    setDialogOpen(prev => {
      return {
        ...prev,
        leaveHistory: true
      }
    })
  };

  const onRejectButtonClick = async () => {
    setDialogOpen(prev => {
      return {
        ...prev,
        reject: true
      }
    })
    setStatusForm((prev: any) => {
      return {
        ...prev,
        status: "rejected"
      }
    })
  };

  const [totalLeaveTime, setTotalLeaveTime] = useState<any>("");
  const [leaveType, setLeaveType] = useState<any>("");

  const [file, setFile] = useState<File>({
    file: null,
    file_name: '',
    new_file: false
  });

  const [form, setForm] = useState<formType>({
    status: "waiting_for_approver",
    leave_request_details: [
      {
        leave_type: "",
        total_leave_time: '',
        start_date: "",
        end_date: "",
        total_hours: 0,
        total_hours_to_days: 0,
        reason: "",
        attachment: "",
      }
    ],
    created_by_user: {
      code: '',
      fullname_th: '',
      employee: {
        photo: '',
        job_positions: [{
          position_name: '',
          is_currently: false
        }],
      },
    }
  })

  const [leaveHistoryTypeTotal, setLeaveHistoryTypeTotal] = useState([]) as any
  const [leaveTypeOptions, setLeaveTypeOptions] = useState<any>([]);

  const [statusForm, setStatusForm] = useState<{
    leave_request_id: string,
    status: string,
    reason: string
  }>({
    leave_request_id: "",
    status: "",
    reason: ""
  })


  const handleLeaveTimeTypeChange = (event: any) => {
    setLeaveType(event.value.value);
  }

  const handleLeaveTimeTotalChange = (event: any) => {
    setTotalLeaveTime(event.target.value);
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      fileToBase64(fileList[0]).then(base64String => {
        setFile({
          file: base64String as string,
          file_name: fileList[0].name,
          new_file: true
        });
      });
    }
  };

  const handleConfirmDelete = async () => {
    setFile(
      {
        file: null,
        file_name: '',
        new_file: true
      }
    )
  };

  const fetchData = async () => {
    setSubmitted(true);
    startLoading();
    try {
      const response = await LeaveRequestService.getApproverLeaveRequestList(queryParams) as ApiResponse<LeaveRequest>;
      const data = {
        user_id: Number(response?.rows[0]?.created_by_user?.id)
      }
      const leaveHistoryTypeTotal = await LeaveRequestService.getLeaveHistoryTypeTotal(data)
      if (response.rows.length !== 0) {
        setForm(prev => {
          return {
            ...prev,
            status: response?.rows[0]?.status,
            leave_request_details: [
              {
                leave_type: response?.rows[0]?.leave_request_details[0]?.leave_limit_by_type_id,
                total_leave_time: response?.rows[0]?.leave_request_details[0]?.total_leave_time,
                start_date: response?.rows[0]?.leave_request_details[0]?.start_date,
                end_date: response?.rows[0]?.leave_request_details[0]?.end_date,
                total_hours: response?.rows[0]?.leave_request_details[0]?.total_hours,
                total_hours_to_days: response?.rows[0]?.leave_request_details[0]?.total_hours_to_days,
                reason: response?.rows[0]?.leave_request_details[0]?.reason,
                attachment: response?.rows[0]?.leave_request_details[0]?.attachment,
              }
            ],
            created_by_user: {
              code: response?.rows[0]?.created_by_user?.employee?.code,
              fullname_th: response?.rows[0]?.created_by_user?.employee?.fullname_th,
              employee: {
                photo: response?.rows[0]?.created_by_user?.employee?.photo,
                job_positions: response?.rows[0]?.created_by_user?.employee?.job_positions
              }
            }
          }
        })
        setFile({
          file: response?.rows[0]?.leave_request_details[0]?.attachment,
          file_name: response?.rows[0]?.leave_request_details[0]?.attachment.split('/').pop() || '',
          new_file: false
        })
      }
      if (leaveHistoryTypeTotal.length !== 0) {
        setLeaveHistoryTypeTotal(leaveHistoryTypeTotal)
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  const [dialogOpen, setDialogOpen] = useState({
    reject: false,
    leaveHistory: false
  });

  const onRejectDialogValueChange = (e: any) => {
    setStatusForm((prev: any) => {
      return {
        ...prev,
        reject_reason: e.target.value
      }
    })
  }

  const handleRejectSubmitClick = async () => {
    try {
      startLoading();
      const response = await LeaveRequestService.sendLeaveRequestStatus(statusForm)
      if (response) {
        notificationService.successNotification('ยืนยันการไม่อนุมัติเเล้ว', '');
        setDialogOpen(prev => {
          return {
            ...prev,
            reject: false
          }
        })
        navigate("/employee-leaves/approver-request-list")
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  }

  const handleApprovedButtonClick = async () => {
    const data = {
      ...statusForm,
      status: "approved"
    }
    try {
      startLoading();
      const response = await LeaveRequestService.sendLeaveRequestStatus(data)
      if (response) {
        notificationService.successNotification('ยืนยันการอนุมัติเเล้ว', '');
        navigate("/employee-leaves/approver-request-list")
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  }

  const leaveRequestDropdown = async () => {
    const leaveRequestTypeOptions = await apiDropdownService.getLeaveRequestDropdown({});
    if (leaveRequestTypeOptions) {
      setLeaveTypeOptions(leaveRequestTypeOptions);
    }
  }

  const getTotalDayText = Number(form.leave_request_details[0]?.total_hours_to_days) ? Number(form.leave_request_details[0]?.total_hours_to_days) <= 0.5 ? "ครึ่งวัน" : form.leave_request_details[0]?.total_hours_to_days : ""

  useEffect(() => {
    leaveRequestDropdown();
  }, []);


  useEffect(() => {
    if (id) {
      setQueryParams({
        ...queryParams,
        request_id: parseInt(id, 10)
      })
      setStatusForm((prev: any) => {
        return {
          ...prev,
          leave_request_id: id ? parseInt(id) : ''
        }
      })
    }
    if (queryParams.request_id !== '') {
      fetchData();
    }
  }, [id, queryParams.request_id])


  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">
        อนุมัติการลาของผู้ใต้บังคับบัญชา
      </h2>
      <div className="" style={{marginRight: "24px"}}>
        <Button type="button"
                variant="info"
                onClick={onLeaveHistoryButtonClick}
                className="d-block btn-action"
        >
          ประวัติการลา
        </Button>
      </div>
    </div>
    <RejectDialog open={dialogOpen.reject} onRejectDialogValueChange={onRejectDialogValueChange}
                  handleRejectSubmitClick={handleRejectSubmitClick} handleClose={() => {
      setDialogOpen(prev => {
        return {
          ...prev,
          reject: false
        }
      })
    }}/>
    <LeaveHistoryDialog userData={form} userLeaveTypeTotalData={leaveHistoryTypeTotal}
                        open={dialogOpen.leaveHistory}
                        handleClose={() => {
                          setDialogOpen(prev => {
                            return {
                              ...prev,
                              leaveHistory: false
                            }
                          })
                        }}/>
    <Form noValidate validated={submitted} className="">
      <div className="row mt-3">
        <div className={`col-sm-12 col-md-4 pb-3 }`}>
          <label className="form-label required">รหัสพนักงาน</label>
          <input type="text" className="form-control" name="code" disabled={true}
                 placeholder="รหัสพนักงาน" value={form.created_by_user?.code} required={true}/>
          <div className="invalid-feedback">
            กรุณากรอกรหัสพนักงาน
          </div>
        </div>
        <div className={`col-sm-12 col-md-4 pb-3 }`}>
          <label className="form-label required">ชื่อ-นามสกุลพนักงาน</label>
          <input type="text" className="form-control" name="code" disabled={true}
                 placeholder="รหัสพนักงาน" value={form.created_by_user?.fullname_th} required={true}/>
          <div className="invalid-feedback">
            กรุณากรอกรหัสพนักงาน
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ประเภทการลา</label>
          <InputSelect options={leaveTypeOptions} placeholder="" name="is_active"
                       onChange={handleLeaveTimeTypeChange} isDisable
                       value={form.leave_request_details[0]?.leave_type}/>
        </div>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          className="col-sm-12 col-md-4 flex-row align-content-end column-gap-3 mt-sm-2"
          value={form.leave_request_details[0]?.total_leave_time}
          onChange={handleLeaveTimeTotalChange}
        >
          {TotalLeaveTime.map((data, index) => {
            return <FormControlLabel key={index} value={data.value}
                                     control={<Radio color={"success"}/>} label={data.label} disabled
                                     disableTypography={true}/>
          })}
        </RadioGroup>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <div className={``}>
            <label className="form-label">วันเริ่ม</label>
            <DateInput
              date={form.leave_request_details[0]?.start_date && new Date(form.leave_request_details[0]?.start_date) || null}
              onChange={(e) => {
              }}
              required={true}
              minDate={new Date()}
              disable/>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <div className={``}>
            <label className="form-label">จนถึง</label>
            <DateInput
              date={form.leave_request_details[0]?.end_date && new Date(form.leave_request_details[0]?.end_date) || null}
              onChange={(e) => {
              }}
              required={true}
              minDate={new Date()}
              disable/>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 mt-sm-3 mt-md-0">
          <div className={``}>
            <label className="form-label">จำนวนวัน</label>
            <input type="text" className="form-control" name="code" disabled
                   placeholder="" value={getTotalDayText} required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">

            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 mt-sm-3 mt-md-0 mt-xl-3">
          <div className={``}>
            <label className="form-label">เหตุผลการลา</label>
            <TextField
              id="outlined-multiline-static"
              className="form-control"
              multiline
              rows={4}
              defaultValue={form.leave_request_details[0]?.reason}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 mt-3">
          {!file.file ? null :
            <LeaveFileForm handleFileChange={handleFileChange} handleConfirmDelete={handleConfirmDelete}
                           file={file}
                           viewPage={true}/>}
        </div>
      </div>
      <div className="row mt-4 mb-5">
        <div className="col-3 col-xs-1 col-md-0 col-sm-0 col-xl-6"></div>
        <div
          className="d-flex flex-row col-6 col-xs-12 col-sm-12 col-md-12 col-xl-6 justify-content-evenly column-gap-3">
          <div className="" style={{marginLeft: "164px"}}>
            <Button variant="secondary" type="button"
                    onClick={() => {
                      navigate(-1)
                    }}
                    className="d-block btn-action"
            >
              ย้อนกลับ
            </Button>
          </div>
          {form.status === "waiting_for_approver" ? <>
            <div className="">
              <Button type="button"
                      onClick={onRejectButtonClick}
                      className="d-block btn-action"
                      variant='danger'
                      style={{color:"white"}}
              >
                ไม่อนุมัติ
              </Button>
            </div>
            <div className="" style={{marginRight: "164px"}}>
              <Button type="button"
                      onClick={handleApprovedButtonClick}
                      className="d-block btn-action"
                      variant={'success'}
                      style={{color:"white"}}
              >
                อนุมัติ
              </Button>
            </div>
          </> : null}
        </div>
      </div>
    </Form>

  </>
}

export default ApproverRequestDetailPage;
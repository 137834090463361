import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import {
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateOrganizationalStructurePage
} from '../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import DeputyDirectorForm from '../components/forms/deputy-director-form';
import SaveDraft from '../functions/save-draft';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';

const DeputyDirectorPage: FC = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const [showSkipModal, setShowSkipModal] = useState(false);

  useEffect(() => {
    updateOrganizationalStructurePage('deputy-director', dispatch);
  }, []);

  const getPreviousLink = () => {
    if (id) {
      return `/organizational-structures/${id}/director`;
    }
    return '/organizational-structures/create/director';
  };

  const getNextLink = () => {
    if (id) {
      navigate(`/organizational-structures/${id}/office-department`);
    } else {
      navigate('/organizational-structures/create/office-department');
    }
  };

  const validateForm = () => {
    if (organizationalStructureAction.status === 'view') {
      getNextLink();
      return;
    }
    setSubmitted(true);
    const inValid = organizationalStructure.deputy_directors.some(data => {
      const isUserNotSelect = !data.is_empty && !data.user_id;
      return isUserNotSelect || !data.pay_account_number || !data.level;
    });
    if (inValid) {
      setShowSkipModal(true);
      return;
    }
    getNextLink();
  };

  const handleCloseSkipModal = () => {
    setShowSkipModal(false);
  }

  const handleConfirmSkipModal = () => {
    getNextLink();
  }

  return (
    <Form noValidate validated={organizationalStructureAction.isSubmitted || submitted}
          className="custom-no-validation">
      <ConfirmModal
        show={showSkipModal}
        title={'กรุณากรอกข้อมูลให้ครบถ้วน'}
        description={'ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่'}
        confirmLabel={'ใช่'}
        onHide={handleCloseSkipModal}
        onConfirm={handleConfirmSkipModal}
      />
      <div className="h-100 content-background-color rounded-4 px-4 py-4">
        {
          organizationalStructure.deputy_directors.map((data, index) => {
            return <DeputyDirectorForm key={index}
                                       index={index}
                                       lastButton={index === organizationalStructure.deputy_directors.length - 1}
                                       hideDelete={index < 3}
                                       {...data}/>;
          })
        }
      </div>

      <div className="d-flex justify-content-end my-4">
        <Link to={getPreviousLink()}>
          <Button variant="secondary" type="button" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        <SaveDraft/>
        <Button variant="primary" type="button" className="btn-action me-2" onClick={validateForm}>
          ถัดไป
          <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
        </Button>
      </div>
    </Form>
  );
};

export default DeputyDirectorPage;
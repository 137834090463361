import React from 'react';
import {Button, Form} from "react-bootstrap";
import {Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import DateInput from "../../../shared/components/atoms/date-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import HolidayDetailSection from "../pages/holiday-details-section";
import {HolidayType} from "../types/type";
import {convertDateToString} from "../../../shared/utils/functions-date-time";
import {notificationService} from "../../../shared/services/notification-service";

type PageProps = {
  open: boolean;
  handleClose: () => void;
  onHolidayDetailDeleteClick: (index: number) => () => void;
  handleSubmitClick: (value: HolidayType[]) => void;
};
const AddHolidayDetail = ({
                            open,
                            handleClose,
                            handleSubmitClick,
                            onHolidayDetailDeleteClick
                          }: PageProps) => {
  const [submitted, setSubmitted] = React.useState(false);
  const [formValue, setFormValue] = React.useState<HolidayType>({
    url: '',
    holiday_date: new Date(),
    holiday_name: ''
  });
  const [currentHolidayValue, setCurrentHolidayValue] = React.useState<HolidayType[]>([]);
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            height: "auto",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" sx={{fontWeight: "Medium", color: "#056310"}}
                      fontFamily={`"Anuphan", sans-serif`}>เพิ่มวันหยุดประจำปี</Typography>
        </DialogTitle>
        <Form noValidate validated={submitted} className="">
        <DialogContent>
          {/*<div className="col-sm-12 col-md-12 mt-sm-3 mt-md-0 mt-xl-3" style={{padding: "0 32px 0 32px"}}>*/}
          {/*  <div className={`col-sm-12 col-md-12 pb-3`}>*/}
          {/*    <label className="form-label">URL</label>*/}
          {/*    <input type="text" className="form-control" name="url" disabled={false}*/}
          {/*           placeholder="URL" value={formValue.url}*/}
          {/*           onChange={(e) => {*/}
          {/*             setFormValue({*/}
          {/*               ...formValue,*/}
          {/*               url: e.target.value*/}
          {/*             })*/}
          {/*           }}/>*/}
          {/*    <div className="invalid-feedback">*/}
          {/*      กรุณากรอก URL*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="row" style={{padding: "0 32px 0 32px"}}>
            <div
              className={`col-sm-12 col-md-6 pb-3}`}>
              <label className="form-label required">วันที่</label>
              <DateInput date={formValue.holiday_date}
                         onChange={(e) => {
                           setFormValue({
                             ...formValue,
                             holiday_date: e ? new Date(e) : new Date()
                           })
                         }}
                         required={true}
                         disable={false}/>
              <input type="date" className="form-control" name="holiday_date"
                     placeholder="" disabled={false} hidden={true}
                     value={formValue.holiday_date && formValue.holiday_date.toISOString().slice(0, 10) || ''}
                     required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณาเลือกวันที่
              </div>
            </div>
            <div className={`col-sm-12 col-md-6 pb-3`}>
              <label className="form-label required">ชื่อวันหยุด</label>
              <input type="text" className="form-control" name="description" disabled={false}
                     placeholder="รายละเอียด" value={formValue.holiday_name} required={true}
                     onChange={(e) => {
                       setFormValue({
                         ...formValue,
                         holiday_name: e.target.value
                       })
                     }}
              />
              <div className="invalid-feedback">
                กรุณากรอกรายละเอียด
              </div>
            </div>
          </div>
          <div className="row mt-3" style={{padding: "0 32px 0 32px"}}>
            <div className="d-flex flex-row justify-content-end">
              <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                      style={{width: "128px"}}
                      onClick={() => {
                        setSubmitted(true);
                        // Check if holiday_date is provided
                        if (!formValue.holiday_date) {
                          return;
                        }

                        const dateExists = currentHolidayValue.some(holiday =>
                          convertDateToString(holiday?.holiday_date) === convertDateToString(formValue.holiday_date)
                        );

                        if (dateExists) {
                          notificationService.warningNotification('วันหยุดซ้ำ', 'วันหยุดนี้ถูกเพิ่มแล้ว');
                          setSubmitted(false);
                          return;
                        }

                        setCurrentHolidayValue([...currentHolidayValue, formValue]);
                        setFormValue({
                          url: '',
                          holiday_date: new Date(),
                          holiday_name: ''
                        });
                        setSubmitted(false);
                      }}>
                <FontAwesomeIcon icon={faPlus} className="me-2"/>
                เพิ่ม
              </Button>
            </div>
          </div>
          <div className="col" style={{padding: "0 32px 0 32px"}}>
            {
              currentHolidayValue.map((holiday, index) => {
                return (
                  <HolidayDetailSection key={index}
                                        componentWidth={100}
                                        date={convertDateToString(holiday?.holiday_date) || ''}
                                        description={holiday.holiday_name}
                                        onDeleteClick={() => {
                                          onHolidayDetailDeleteClick(index)
                                          setCurrentHolidayValue(currentHolidayValue.filter((_, i) => i !== index))
                                        }}
                                        isDeleteButtonVisible={true}
                  />
                )
              })
            }
          </div>
        </DialogContent>
        </Form>
        <div
          className="d-flex flex-row col-12 justify-content-center column-gap-3 mb-4">
          <div className="">
            <Button variant="secondary" type="button"
                    onClick={handleClose}
                    className="d-block btn-action"
            >
              ย้อนกลับ
            </Button>
          </div>
          <div className="">
            <Button type="button"
                    variant="primary"
                    onClick={() => {
                      handleSubmitClick(currentHolidayValue)
                      setCurrentHolidayValue([])
                    }}
                    className="d-block btn-action"
                    style={{background: "background: #007BFF"}}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </Dialog>
  );
};

export default AddHolidayDetail;


import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import { faDownload, faEye, faFile, faLink, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import {AxiosError} from 'axios/index';
import {notificationService} from '../../../shared/services/notification-service';
import {apiEmployeeAttachmentService} from '../../../shared/services/employee-attachment-service';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../shared/services/employee-service';
import LoaderSpinner from '../../../shared/components/molecules/commons/loader-spinner';
import FilePreviewer from '../../../shared/components/molecules/previews/file-previewer';
import DeleteConfirmationModal from '../../../shared/components/molecules/modals/delete-confirmation-modal';

interface LeaveFileFormProps {
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  file: File;
  handleConfirmDelete: () => void;
  viewPage?: boolean;
  disabled?: boolean;
}

interface File {
  file: string | File | null;
  file_name: string;
  new_file?: boolean;
}

const LeaveFileForm = ({handleFileChange, file, handleConfirmDelete, viewPage,disabled=false}: LeaveFileFormProps) => {
  const employeeAction = useSelector(getEmployeeAction);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loaderComponent = isLoading ? <LoaderSpinner/> : null;
  const [showModal, setShowModal] = useState(false);

  const handleButtonClickUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setShowModal(false);
  };

  const handleShowPreviewModel = () => {
    setShowPreviewModal(true);
  };

  const handleClosePreviewModel = () => {
    setShowPreviewModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDownload = () => {
    setIsLoading(true);
    try {
      apiEmployeeAttachmentService.downloadFile(file.file as string, file.file_name);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = () => {
    // Show the confirmation modal when the delete button is clicked
    setShowModal(true);
  };

  const uploadButton = (disabled?:boolean) => {
    if (file.file === null) {
      return <>
        <div className="flex-column">
          <div>
            <Button className="btn" type="button" disabled={disabled} variant="success"
                    onClick={handleButtonClickUploadFile}>
              <FontAwesomeIcon icon={faLink}
                               style={{color: "white", cursor: "pointer", marginRight: 6, marginLeft: 6}}/>
              <label className="p-1" style={{color: "white", cursor: "pointer"}}>เเนบไฟล์</label>
            </Button>
          </div>
          <label className="mt-2" style={{fontSize: "12px", color: "#707070"}}>เรับรองไฟล์ทุกขนาดไฟล์ต้องไม่เกิน 5
            mb</label>
        </div>
      </>;
    } else {
      return <>
        <div className="row pb-5 gap-3">
          <div className={"col-sm-12 col-md-4"} style={{width: "30px", marginTop: "6px"}}>
            <FontAwesomeIcon icon={faFile}/>
          </div>
          <div className={"col-sm-12 col-md-4"}>
            <input type="text" className="form-control" disabled={true} style={{maxWidth: "400px"}}
                   value={file.file_name}/>
          </div>
          <div className={"col-sm-12 col-md-4"}>
            <Button variant="outline-success" type="button" className="btn-mof-primary" onClick={handleShowPreviewModel}>
              <FontAwesomeIcon icon={faEye}/>
            </Button>
            <Button variant="outline-info" className="ms-2" type="button" onClick={handleDownload}
                    hidden={file.new_file === true}>
              <FontAwesomeIcon icon={faDownload}/>
            </Button>
            <Button variant="danger" className="ms-2" onClick={handleDeleteFile} disabled={viewPage}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </div>
        </div>


        <FilePreviewer
          fileUrl={file.file.toString()}
          fileName={file.file_name}
          show={showPreviewModal}
          base64String={file.new_file === true}
          onHide={handleClosePreviewModel}/>
        <DeleteConfirmationModal
          show={showModal}
          title={'ยืนยันการลบไฟล์'}
          description={'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้'}
          onHide={handleCloseModal}
          onConfirm={() => {
            handleConfirmDelete();
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
          }}
        />
      </>;
    }

  };

  return (
    <div>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{display: 'none'}}/>
      {loaderComponent}
      {uploadButton(disabled)}
    </div>
  );
};

export default LeaveFileForm;
import { Outlet, useParams } from 'react-router-dom';
import EmployeeCard from './components/employee-card';
import EmployeeTabMenu from './components/employee-tab-menu';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    apiEmployeeService,
    employeeActionAfterSave,
    getEmployeeAction,
    updateEmployeeActionStatus,
    updateEmployeeActionSubmitted,
    updateEmployeeActionType,
    updateEmployeeState,
} from '../../shared/services/employee-service';
import LoaderSpinner from '../../shared/components/molecules/commons/loader-spinner';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import { useAuth } from '../../shared/authentications/auth-context';

const EmployeeDetailPage: FC = () => {
    let {id} = useParams();
    const {authJwt , logout} = useAuth();
    const dispatch = useDispatch();
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const employeeAction = useSelector(getEmployeeAction);

    const fetchData = async () => {
        startLoading();
        try {
            const employee = await apiEmployeeService.getEmployeeDetail(Number(getEmployeeId()));

			if (employee.user_id === null) {
				return logout();
			}

            updateEmployeeState(employee, dispatch);
            updateEmployeeActionType('view', dispatch);
            updateEmployeeActionStatus('view', dispatch);
            employeeActionAfterSave(false, dispatch);
            updateEmployeeActionSubmitted(false, dispatch);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (['edit', 'job_position', 'job_position_edit'].includes(employeeAction.type) && employeeAction.status === 'after_process') {
            // หลังจากเซฟตำแหน่งเสร็จ
            updateEmployeeActionType('view', dispatch);
            updateEmployeeActionStatus('view', dispatch);
            updateEmployeeActionSubmitted(false, dispatch);
            fetchData();
        }
    }, [employeeAction]);

    const onChangeTab = (tabMenu: number) => {

    };

    const getEmployeeId = () => {
        return id ? id : authJwt?.employee_id.toString();
    }

    return (
        <div className="pb-5">
            {loaderComponent}
            <div className="pt-4 pb-2">
                <EmployeeCard/>
            </div>
            <hr/>
            <EmployeeTabMenu isView={true} employeeId={getEmployeeId()!} onSelectTap={onChangeTab}/>
            <Outlet/>
        </div>
    );
};

export default EmployeeDetailPage;
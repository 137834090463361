export const organizationLevelOptions = [
  { label: 'ระดับ 1 (ผู้อำนวยการ)', value: 1 },
  { label: 'ระดับ 2 (รองผู้อำนวยการ)', value: 2 },
  { label: 'ระดับ 3 (สำนัก / ฝ่าย)', value: 3 },
  { label: 'ระดับ 4 (กอง / กลุ่ม / สำนักงาน)', value: 4 },
  { label: 'ระดับ 5 (แผนก)', value: 5 },
  { label: 'ระดับ 6 (บุคลากรทั่วไป)', value: 6 },
]

export const employeeTypeOptions = [
  { label: 'พนักงาน', value: 'พนักงาน' },
  { label: 'ลูกจ้างประจำ', value: 'ลูกจ้างประจำ' },
  { label: 'ลูกจ้างชั่วคราว', value: 'ลูกจ้างชั่วคราว' },
]

export const workPeriodOptions = [
  { label: 'ไม่ครบ 1 ปี', value: 'ไม่ครบ 1 ปี' },
  { label: '1 ปี แต่ไม่เกิน 3 ปี', value: '1 ปี แต่ไม่เกิน 3 ปี' },
  { label: '3 ปี แต่ไม่เกิน 10 ปี', value: '3 ปี แต่ไม่เกิน 10 ปี' },
  { label: '10 ปี เป็นต้นไป', value: '10 ปี เป็นต้นไป' },
]

export const minimumLeaveOptions = [
  { label: 'ครึ่งวัน', value: 'ครึ่งวัน' },
  { label: 'เต็มวัน', value: 'เต็มวัน' },
]

export const allowToUseLeaveOptions = [
  { label: 'เริ่มใช้ได้เลย', value: 'เริ่มใช้ได้เลย' },
  { label: 'หลังจากผ่านการทดลองงาน', value: 'หลังจากผ่านการทดลองงาน' },
]

export const carryOverOptions = [
  { label: 'ยกวันลาคงเหลือได้', value: 'ยกวันลาคงเหลือได้' },
  { label: 'ยกวันลาคงเหลือไม่ได้', value: 'ยกวันลาคงเหลือไม่ได้' },
]

export const genderOptions = [
  { label: 'ทุกเพศ', value: 'ทุกเพศ' },
  { label: 'ชาย', value: 'ชาย' },
  { label: 'หญิง', value: 'หญิง' },
]
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { PermissionRequest, PermissionRequestQueryParams } from '../../shared/models/permission.model';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import { PermissionService } from '../../shared/services/permission-service';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import PermissionApproveModal from "../permissions/components/permission-approve-modal";
import {hasPermission} from "../../shared/utils/roles";
import {Link, useNavigate} from "react-router-dom";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";
import { ShiftRequestType} from "../../shared/models/employee-shifts.model";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";

interface EmployeeShiftRowProps {
  data: any,
  onAction: (status: 'approve' | 'reject', id: any) => void;
}

const EmployeeShiftPersonelListPage: FC = () => {
  const [queryParams, setQueryParams] = useState<PermissionRequestQueryParams>({
    search: '',
    ordering_field: '',
    ordering: '',
    page: 1,
    page_size: 10,
  });
  const [data, setData] = useState<ApiResponse<ShiftRequestType>>(initialResponseData);
  const [transformedData, setTransformedData] = useState([]);
  const {authJwt} = useAuth();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [idTarget, setIdTarget] = useState<any>(null);
  const [paginationCount, setPaginationCount] = useState(0);

  const headers: Header[] = [
    {
      label: 'รหัสพนักงาน',
      field: 'code',
      order: null,
      isOrder: true,
    },
    {
      label: 'ชื่อ - นามสกุล',
      field: 'full_name_th',
      order: null,
      isOrder: true,
    },
    {
      label: 'ชื่อตารางกะ',
      field: 'shift_name',
      order: null,
      isOrder: true,
    },
    {
      label: 'เลขบัญชีที่ถือจ่าย',
      field: '',
      order: null,
      isOrder: true,
    },
    {
      label: 'ตำแหน่ง',
      field: 'role',
      order: null,
      isOrder: true,
    },
    {
      label: 'แผนก',
      field: 'department',
      order: null,
      isOrder: true,
    },
    {
      label: 'กอง / กลุ่ม / สำนักงาน',
      field: '',
      order: null,
      isOrder: true,
    },
    {
      label: 'สถานะ',
      field: '',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await EmployeeShiftService.getEmployeeShiftPersonelList(queryParams);
      setData(result);
      // console.log("transformedData", transformedData);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (data) {
      const transformedData = data.rows.reduce((acc, row):any => {
        // Extract each employee detail and include the status from the main object
        const employeeDetails = row.shift_employee_assignment_details.map((detail:any) => ({
          shift_request_id: row.id,
          shift_name: row.shift_name,
          status: row.status,
          ...detail.user.employee
        }));
        return [...acc, ...employeeDetails]; // Correctly spreading the accumulator and adding the new details
      }, []);
      setTransformedData(transformedData);
      setPaginationCount(transformedData.length);
    }
  }, [data]);

  let paginatedData = transformedData.slice((queryParams.page - 1) * queryParams.page_size, queryParams.page * queryParams.page_size);

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };
  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prevState => {
      return {...prevState, search: e.target.value};
    });
  };

  const onChangeOrder = (orderData: OrderData) => {
    setQueryParams({
      ...queryParams,
      ordering_field: orderData.field,
      ordering: orderData.order,
    });
  };

  const onAction = (status: 'approve' | 'reject', id: any) => {
    setIdTarget(id);
    if (status === 'approve') {
      setConfirmModal(true);
    } else {
      setRejectModal(true);
    }
  };

  const onReject = async (reason: any) => {
    handleCloseModal();
    startLoading();
    const data = {
      reason: reason
    };
    try {
      await PermissionService.onPermissionRequestReject(idTarget, data);
      notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setRejectModal(false);
  };

  const handleOnAction = () => {
    handleCloseModal();
    fetchData();
  };

  const displayModal = () => {
    if (showConfirmModal) {
      return <PermissionApproveModal
        permissionRequestId={idTarget}
        show={showConfirmModal}
        edited={authJwt?.role === 'ADMIN'}
        onHide={handleCloseModal}
        onAction={handleOnAction}
      />;
    }
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onReject}
      />;
    }
    return;
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">กำหนดตารางกะรายบุคคล</h2>
      {
        // hasPermission(permission, ['organizational_structures'], 'create') ?
        <Link to="/employee-shift/create">
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                  onClick={()=>{}}>
            <FontAwesomeIcon icon={faPlus} className="me-2"/>
            สร้างตารางกะ
          </Button>
        </Link>
        // : null
      }
    </div>
    {loaderComponent}
    {displayModal()}
    <div className="row mt-2">
      <div className="col-sm-12 col-md-3">
        <input className="form-control" type="text" value={queryParams.search}
               onChange={onChangeSearch} autoComplete="off"
               placeholder="ค้นหา..." name="search"/>
      </div>
      <div className="col-sm-12 col-md-2">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
        </thead>
        <tbody>
        {
          paginatedData?.map((data:any, index) => {
            return <ShiftRow key={data.id} data={data} onAction={onAction}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={paginationCount} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
};

const ShiftRow: FC<EmployeeShiftRowProps> = ({
                                               data,
                                               onAction,
                                             }) => {
  const {authJwt} = useAuth();

  const getApproverStatus = () => {
    if (data?.status === 'waiting_for_approver')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (data?.status === 'approver_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (!!data?.approver_action_at)
      return <span className="badge bg-success">อนุมัติ</span>;
    return '-';
  };

  const getAdminStatus = () => {
    if (data?.status === 'waiting_for_admin')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (data?.status === 'admin_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (data?.status === 'admin_approve')
      return <span className="badge bg-success">อนุมัติ</span>;
    return '';
  };

  const getStatusName = (status: string) => {
    switch (status) {
      case 'waiting_for_approver':
        return 'รออนุมัติ';
      case 'approved':
        return 'อนุมัติ';
      case 'rejected':
        return 'ไม่อนุมัติ';
      default:
        return '-';
    }
  }

  function convertToThaiDate(gregorianDateString: Date): string {
    // Parse the Gregorian date string
    const gregorianDate = new Date(gregorianDateString);

    // Add 543 to convert to Thai year
    const thaiYear = gregorianDate.getFullYear() + 543;

    // Thai month abbreviations
    const thaiMonths = [
      'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
      'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    // Format the date in Thai date format
    const day = gregorianDate.getDate();
    const month = thaiMonths[gregorianDate.getMonth()];
    const thaiDateStr = `${day} ${month} ${thaiYear}`;

    return thaiDateStr;
  }

  const handleOnApprove = () => {
    onAction('approve', data?.id);
  };

  const handleOnReject = () => {
    onAction('reject', data?.id);
  };

  const navigate = useNavigate();

  const displayButton = () => {
    if (authJwt?.role === 'ADMIN') {
      return <>
        <Button variant="warning" type="button" className="me-2 px-4" onClick={()=>{}}
                disabled={false}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>
      </>;
    }
    return <>
    </>;
  };

  return (
    <tr style={{cursor:"pointer"}} onClick={()=>{navigate(`/employee-shift/employee-shift-personel?isEdit=true&id=${data?.user_id}`)}}>
      <td>{data?.code || '-' }</td>
      <td>{data?.prefix_name_th + " " + data?.firstname_th + " " + data?.lastname_th || '-' }</td>
      <td>{data?.shift_name || '-' }</td>
      <td>{data?.current_job_position?.pay_account_number || '-'}</td>
      <td>{data?.current_job_position?.position_name || '-'}</td>
      <td>{data?.current_job_position?.structure_departments_name || '-'}</td>
      <td>{data?.current_job_position?.division_group_office_name || '-'}</td>
      <td>{getStatusName(data?.status) || '-'}</td>
      {/*<td>{work_time_start} - {work_time_end}</td>*/}
      {/*/!*<td>{Moment(start_date).format('DD/MM/YYYY')} - {Moment(end_date).format('DD/MM/YYYY')}</td>*!/*/}
      {/*<td>{`${convertToThaiDate(start_date)} - ${convertToThaiDate(end_date)}`}</td>*/}
      {/*<td>{getStatusName(status)}</td>*/}
      {authJwt?.role === 'ADMIN' ? <td>
        {
          displayButton()
        }
      </td> : <td></td>}
    </tr>
  )
    ;
};

export default EmployeeShiftPersonelListPage;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ChildModel,
    EducationHistoryModel,
    EmployeeCertifierModel,
    EmployeeModel,
    EmployeeSectionKey,
    InsigniaHistoryModel,
    JobPosition,
    NameChangeHistoryModel,
    OutstandingPerformanceAwardModel,
    PunishmentHistoryModel,
    ResignationRetirementDeathHistoryModel,
    TrainingHistoryModel,
    UserModel
} from '../models/employee.model';

export const initialStateEmployee: EmployeeModel = {
    id: null,
    prefix_name_en: null,
    firstname_en: null,
    lastname_en: null,
    prefix_name_th: null,
    firstname_th: null,
    lastname_th: null,
    user_id: null,
    address_contactable: null,
    address_official: null,
    birthday: null,
    blood_type: null,
    code: null,
    contact_father_firstname: null,
    contact_father_lastname: null,
    contact_father_prefix_name: null,
    contact_father_status: null,
    contact_mother_firstname: null,
    contact_mother_lastname: null,
    contact_mother_prefix_name: null,
    contact_mother_status: null,
    contact_spouse_prefix_name: null,
    contact_spouse_firstname: null,
    contact_spouse_lastname: null,
    contact_spouse_status: null,
    contactable_amphure_id: null,
    contactable_province_id: null,
    contactable_tambon_id: null,
    emergency_contact_address: null,
    emergency_contact_amphure_id: null,
    emergency_contact_full_name: null,
    emergency_contact_postcode: null,
    emergency_contact_province_id: null,
    emergency_contact_relationship: null,
    emergency_contact_tambon_id: null,
    emergency_contact_tel: null,
    ethnicity: null,
    gender: null,
    id_card: null,
    id_card_date_expire: null,
    id_card_date_generate: null,
    nationality: null,
    phone: null,
    photo: null,
    postcode_contactable: null,
    postcode_official: null,
    official_province_id: null,
    official_amphure_id: null,
    official_tambon_id: null,
    current_job_position_id: null,
    religion: null,
    salary: 0,
    user: {
        id: null,
        email: null,
        username: null,
    },
    current_job_position: null,
	new_email: null,
    job_positions: [],
    education_histories: [],
    employee_certifiers: [],
    insignia_histories: [],
    name_change_histories: [],
    outstanding_performance_awards: [],
    punishment_histories: [],
    resignation_retirement_death_histories: [],
    training_histories: [],
    children: [],
    work_histories: []
};

interface UpdateFieldAction {
    field: keyof EmployeeModel;
    value: string | number | null | boolean;
}

interface UpdateUserFieldAction {
    field: keyof UserModel;
    value: string | number | null | boolean;
}

interface UpdateDataInSectionAction {
    type: EmployeeSectionKey;
    index: number;
    field: string;
    value: string | number | null | boolean;
}

interface AddNewDataSectionAction {
    type: EmployeeSectionKey;
    data: any;
}

interface RemoveDataFromSectionAction {
    type: EmployeeSectionKey;
    index: number;
}

export const employeeReducer = createSlice({
    name: 'employee',
    initialState: initialStateEmployee,
    reducers: {
        setEmployeeId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        setEmployee: (state, action: PayloadAction<EmployeeModel>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the states because it uses the Immer library,
            // which detects changes to a "draft states" and produces a brand new
            // immutable states based off those changes
            Object.assign(state, action.payload);
        },
        updateField: (state, action: PayloadAction<UpdateFieldAction>) => {
            const {field, value} = action.payload;
            state[field as keyof EmployeeModel] = value as never;
        },
        updateUserField: (state, action: PayloadAction<UpdateUserFieldAction>) => {
            const {field, value} = action.payload;
            state.user[field as keyof UserModel] = value as never;
        },
        updateDataInSection: (state, action: PayloadAction<UpdateDataInSectionAction>) => {
            const {type, index, field, value} = action.payload;
            const field_name = field as keyof (JobPosition |
                EducationHistoryModel |
                EmployeeCertifierModel |
                InsigniaHistoryModel |
                NameChangeHistoryModel |
                OutstandingPerformanceAwardModel |
                PunishmentHistoryModel |
                ResignationRetirementDeathHistoryModel |
                TrainingHistoryModel |
                ChildModel);
            state[type as EmployeeSectionKey]![index][field_name] = value as never;
        },
        addNewDataToSection: (state, action: PayloadAction<AddNewDataSectionAction>) => {
            const {type, data} = action.payload;
            state[type as EmployeeSectionKey]!.push(data);
        },
        removeDataFromSection: (state, action: PayloadAction<RemoveDataFromSectionAction>) => {
            const {type, index} = action.payload;
            const array = state[type as EmployeeSectionKey] as [];
            state[type as EmployeeSectionKey] = array.filter((item, i) => i !== index);
        },
    },
});

// Export the action creators
export const {
    setEmployeeId,
    setEmployee,
    updateField,
    updateUserField,
    updateDataInSection,
    addNewDataToSection,
    removeDataFromSection
} = employeeReducer.actions;

// Export the reducer
export default employeeReducer.reducer;

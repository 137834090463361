import apiService from './api-service';

const PERMISSION_BASE_API = process.env.REACT_APP_API_URI + '/permissions';

export class PermissionService {
  static async getMyPermission() {
    const response = await apiService().get(`${PERMISSION_BASE_API}/me`, {});
    return response.data;
  }

  static async createPermissionRequest(data: object) {
    return await apiService().post(`${PERMISSION_BASE_API}/`, data);
  }

  static async getPermissionRequest(params: any) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/`, {params});
    return response.data;
  }

  static async getPermissionRequestDetail(id: any) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/requests/${id}/`, {});
    return response.data;
  }

  static async onPermissionRequestApprove(id: any, data: any) {
    const response = await apiService().post(`${PERMISSION_BASE_API}/requests/${id}/approve/`, data);
    return response.data;
  }

  static async onPermissionRequestReject(id: any, data: any) {
    const response = await apiService().post(`${PERMISSION_BASE_API}/requests/${id}/reject/`, data);
    return response.data;
  }
}


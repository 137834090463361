import React, { FC } from 'react';

export interface VacationCardModel {
  month: string;
  vacations: VacationModel[]
}

export interface VacationModel {
  day_name: string;
  day_number: string;
  description: string;
}

const VacationCard: FC<VacationCardModel> = ({month, vacations}) => {
  return <div className="border-success card border-2 my-1 px-2 py-2 rounded-4">
    <div className="row">
      <div className="col-2 text-center">
        {month}
      </div>
      <div className="col-10">
        {
          vacations.map((data, index) => {
            return <div key={index} className={`row ${index !== vacations.length - 1 ? 'mb-2' : ''}`}>
              <div className="col-sm-12 col-md-2 text-center">
                {data?.day_name}
              </div>
              <div className="col-sm-12 col-md-2 text-success text-center">
                <b>{data?.day_number}</b>
              </div>
              <div className="col-sm-12 col-md-8 text-left">
                {data?.description}
              </div>
            </div>
          })
        }
      </div>
    </div>
  </div>
}

export default VacationCard;
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { PermissionRequest, PermissionRequestQueryParams } from '../../shared/models/permission.model';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import { PermissionService } from '../../shared/services/permission-service';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import PermissionApproveModal from "../permissions/components/permission-approve-modal";
import {hasPermission} from "../../shared/utils/roles";
import {Link, useNavigate} from "react-router-dom";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";
import { ShiftRequestType} from "../../shared/models/employee-shifts.model";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import RejectDialog from "../employee-leaves/components/reject-dialog";

interface EmployeeShiftRowProps extends ShiftRequestType {
  onAction: (status: 'approve' | 'reject', id: any) => void;
  isNoAssignedEmployee: boolean;
}

const EmployeeShiftListPage: FC = () => {
  const [queryParams, setQueryParams] = useState<PermissionRequestQueryParams>({
    search: '',
    ordering_field: '',
    ordering: '',
    page: 1,
    page_size: 10,
  });
  const [data, setData] = useState<ApiResponse<ShiftRequestType>>(initialResponseData);
  const {authJwt} = useAuth();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [showEditModal, setConfirmModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [idTarget, setIdTarget] = useState<any>(null);

  const navigate = useNavigate()

  const headers: Header[] = [
    {
      label: 'ตารางกะ',
      field: 'shift_name',
      order: null,
      isOrder: true,
    },
    {
      label: 'เวลาเข้า - ออกงาน',
      field: 'start_time',
      order: null,
      isOrder: true,
    },
    {
      label: 'วันที่เริ่ม - วันที่สิ้นสุด',
      field: 'start_date',
      order: null,
      isOrder: true,
    },
    {
      label: 'สถานะ',
      field: 'is_active',
      order: null,
      isOrder: true,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  useEffect(() => {
    fetchData();
  }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await EmployeeShiftService.getEmployeeShiftList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };
  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prevState => {
      return {...prevState, search: e.target.value};
    });
  };

  const onChangeOrder = (orderData: OrderData) => {
    setQueryParams({
      ...queryParams,
      ordering_field: orderData.field,
      ordering: orderData.order,
    });
  };

  const onAction = (status: 'approve' | 'reject', id: any) => {
    setIdTarget(id);
    if (status === 'approve') {
      setConfirmModal(true);
    } else {
      setRejectModal(true);
    }
  };

  const onReject = async () => {
    handleCloseModal();
    startLoading();
    try {
      await EmployeeShiftService.deleteEmployeeShift(idTarget)
      notificationService.successNotification('ลบสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ลบไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setRejectModal(false);
  };

  const handleOnAction = () => {
    handleCloseModal();
    fetchData();
  };

  const displayModal = () => {
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการลบคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onReject}
        isDeleteModel={true}
      />;
    }
    return;
  };

  return <div>
    {/*<RejectDialog open={} handleClose={} onRejectDialogValueChange={} handleRejectSubmitClick={}/>*/}
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">กำหนดตารางกะ</h2>
      {
        // hasPermission(permission, ['organizational_structures'], 'create') ?
        <Link to="/employee-shift/create">
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                  onClick={()=>{}}>
            <FontAwesomeIcon icon={faPlus} className="me-2"/>
            สร้างตารางกะ
          </Button>
        </Link>
        // : null
      }
    </div>
    {loaderComponent}
    {displayModal()}
    <div className="row mt-2">
      <div className="col-sm-12 col-md-3">
        <input className="form-control" type="text" value={queryParams.search}
               onChange={onChangeSearch} autoComplete="off"
               placeholder="ค้นหา..." name="search"/>
      </div>
      <div className="col-sm-12 col-md-2">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows.map((data, index) => {
            return <ShiftRow key={data.id} {...data} onAction={onAction} isNoAssignedEmployee={data?.shift_employee_assignment_details && data?.shift_employee_assignment_details?.length === 0}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
};

const ShiftRow: FC<EmployeeShiftRowProps> = ({
                                            id,
                                            shift_name,
                                            break_time_end,
                                            break_time_start,
                                            end_date,
                                            work_time_start,
                                            start_date,
                                            work_time_end,
                                            user_id,
                                            approver_action_user_id,
                                            approver_action_at,
                                            status,
                                            reason,
                                            onAction,
                                            isNoAssignedEmployee
                                               }) => {
  const {authJwt} = useAuth();

  const getApproverStatus = () => {
    if (status === 'waiting_for_approver')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (status === 'approver_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (!!approver_action_at)
      return <span className="badge bg-success">อนุมัติ</span>;
    return '-';
  };

  const getAdminStatus = () => {
    if (status === 'waiting_for_admin')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (status === 'admin_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (status === 'admin_approve')
      return <span className="badge bg-success">อนุมัติ</span>;
    return '';
  };

  const getStatusName = (status: string) => {
    switch (status) {
      case 'waiting_for_approver':
        return 'รออนุมัติ';
      case 'approved':
        return 'อนุมัติ';
      case 'rejected':
        return 'ไม่อนุมัติ';
      default:
        return '-';
    }
  }

  function convertToThaiDate(gregorianDateString: Date): string {
    // Parse the Gregorian date string
    const gregorianDate = new Date(gregorianDateString);

    // Add 543 to convert to Thai year
    const thaiYear = gregorianDate.getFullYear() + 543;

    // Thai month abbreviations
    const thaiMonths = [
      'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
      'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    // Format the date in Thai date format
    const day = gregorianDate.getDate();
    const month = thaiMonths[gregorianDate.getMonth()];
    const thaiDateStr = `${day} ${month} ${thaiYear}`;

    return thaiDateStr;
  }

  const navigate = useNavigate();

  const displayButton = () => {
    if (authJwt?.role === 'ADMIN') {
      return <>
        <Button variant="warning" type="button" className="me-2 px-4" onClick={(event)=>{
          event.stopPropagation()
          navigate(`/employee-shift/edit/${id}`)
        }}
                disabled={false}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>
        <Button variant="danger" type="button" className="me-2" onClick={(event)=>{
          event.stopPropagation()
          onAction('reject', id)
        }}
                disabled={false}>
          <FontAwesomeIcon icon={faCircleXmark}/>
        </Button>
      </>;
    }
    return <>
    </>;
  };

  return (
    <tr style={{cursor:"pointer"}} onClick={()=>{navigate(`/employee-shift/approver/${id}`)}}>
      <td>{isNoAssignedEmployee ? shift_name + " " + "(ยังไม่มีบุคลากร)" : shift_name}</td>
      <td>{work_time_start} - {work_time_end}</td>
      {/*<td>{Moment(start_date).format('DD/MM/YYYY')} - {Moment(end_date).format('DD/MM/YYYY')}</td>*/}
      <td>{`${convertToThaiDate(start_date)} - ${!end_date ? "ไม่สิ้นสุด" : convertToThaiDate(end_date)}`}</td>
      <td>{status === "rejected" ? getStatusName(status) + " " + '-' + " " + `(${reason || ''})` : getStatusName(status)}</td>
      {authJwt?.role === 'ADMIN' ? <td className={"d-flex flex-row justify-content-end"}>
        {
          displayButton()
        }
      </td> : <td></td>}
    </tr>
  )
  ;
};

export default EmployeeShiftListPage;
import React from 'react';
import LeaveSettingSections from "./leave-setting-sections-page";


const LeaveSettingCreatePage = () => {
  return (
    <div>
      <LeaveSettingSections isCreatePage={true}/>
    </div>
  );
};

export default LeaveSettingCreatePage;

import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {RegistrationPersonnelHistoryService} from "../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import { Link } from 'react-router-dom';
import {faFileExport} from '@fortawesome/free-solid-svg-icons';
import Moment from "moment";
import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  fetchEmployeeTypeOptions,

  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options,  
  position_name_Options, 
  employee_type_Options,
  retirement_year_Options,
} from '../../OptionsDropDown';

import * as XLSX from 'xlsx';

const headers: Header[] = [
  {
    label: 'ประเภทบุคลากร',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ชื่อ - สกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันเดือนปีเกิด',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันที่เริ่มงาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุงานปัจจุบัน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เงินเดือน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ปีที่เกษียณ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุงาน',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportPersonnelRetireListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [dataExport, setDataExport] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    employee_type: '',
    retirement_year: '',
    search: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelRetireList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const fetchDataExport = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelRetireList({page: 1,page_size: 100000,});
      setDataExport(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.employee_type,
    queryParams.retirement_year,
    queryParams.page_size,
    queryParams.page,
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
    fetchEmployeeTypeOptions();

    fetchDataExport();
  }, []);

  const exportToExcel = () => {
    const DataForExcel = [];
    DataForExcel.push(headers.map(item => item.label));
    dataExport.rows?.map((data:any)=>{
      DataForExcel.push([
        data.current_job_position?.employee_type ? data.current_job_position?.employee_type : '-',
        (data.prefix_name_th ? data.prefix_name_th : '')+(data.full_name_th ? data.full_name_th : '-'),
        data.current_job_position?.position_name ? data.current_job_position?.position_name : '-',
        data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : '-',
        data.birthday ? Moment(data.birthday).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-',
        data.start_date ? Moment(data.start_date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-',
        data.current_job_position?.period_of_employment ? data.current_job_position?.period_of_employment+' ปี' : '-',
        data.salary ? addCommas(data.salary) : '-',
        data.retirement_year ? data.retirement_year : '-',
        data.current_job_position?.period_to_retirement ? data.current_job_position?.period_to_retirement+' ปี' : '-',
      ])
    })

    const ws = XLSX.utils.aoa_to_sheet(DataForExcel);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'รายงานบุคลากรที่จะเกษียณอายุ.xlsx');
  };

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานบุคลากรที่จะเกษียณอายุ</h2>
      <Link to="#">
        <Button variant="primary" className="btn-action btn-mof-primary" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faFileExport} className="me-2"/>
          Export
        </Button>
      </Link>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ประเภทบุคลากร</label>
        <InputSelect value={queryParams.employee_type} options={employee_type_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                employee_type: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ปีที่เกษียณ</label>
        <InputSelect value={queryParams.retirement_year} options={retirement_year_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                retirement_year: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
                placeholder="ค้นหาจากชื่อ..." name="search"
                onChange={(e) => {
                  setQueryParams(prevState => {
                    return {
                      ...prevState,
                      search: e.target.value || ""
                    }
                  })
                }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.current_job_position?.employee_type ? data.current_job_position?.employee_type : '-'}</td>
                <td>{data.prefix_name_th ? data.prefix_name_th : ''}{data.full_name_th ? data.full_name_th : '-'}</td>
                <td>{data.current_job_position?.position_name ? data.current_job_position?.position_name : '-'}</td>
                <td>{data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : '-'}</td>
                <td>{data.birthday ? Moment(data.birthday).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-'}</td>
                <td>{data.start_date ? Moment(data.start_date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-'}</td>
                <td>{data.current_job_position?.period_of_employment ? data.current_job_position?.period_of_employment+' ปี' : '-'}</td>
                <td>{data.salary ? addCommas(data.salary) : '-'}</td>
                <td>{data.retirement_year ? data.retirement_year : '-'}</td>
                <td>{data.current_job_position?.period_to_retirement ? data.current_job_position?.period_to_retirement+' ปี' : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportPersonnelRetireListPage;
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { notificationService } from '../../../services/notification-service';

type InputReasonModalProps = {
    show: boolean;
    title: string;
    description: string | any;
    size?: 'sm' | 'lg' | 'xl';
    onHide: () => void;
    onConfirm: (reason: string) => void;
    isDeleteModel?: boolean;
};

const InputReasonModal: React.FC<InputReasonModalProps> = ({
                                                               show,
                                                               title,
                                                               description,
                                                               size,
                                                               onHide,
                                                               onConfirm,
                                                               isDeleteModel,
                                                           }) => {
    const [reason, setReason] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReason(event.target.value);
    };

    const handleOnConfirm = () => {
        setSubmitted(true);
        if (!reason && !isDeleteModel) {
            notificationService.warningNotification('กรุณากรอกข้อมูลให้ครบถ้วน', '');
            return;
        }
        onConfirm(reason);
    };

    return (
        <Modal show={show} onHide={onHide} centered size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {description}
              {!isDeleteModel && <Form noValidate validated={submitted}>
                <div className="row mt-2">
                  <div className="col-sm-12">
                    <label className="form-label required">เหตุผล</label>
                    <input type="text" className="form-control" name="reason"
                           placeholder="" value={reason} required={true}
                           onChange={handleOnChange}/>
                    <div className="invalid-feedback">
                      กรุณากรอกเหตุผล
                    </div>
                  </div>
                </div>
              </Form>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    ยกเลิก
                </Button>
                <Button variant="primary" onClick={handleOnConfirm}>
                    ยืนยัน
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InputReasonModal;

import { AuthJWT } from '../models/authentication.model';
import { ModuleAction, ModuleType, PermissionModel } from '../models/permission.model';
import apiService from '../services/api-service';

export const isAdmin = (authJwt: AuthJWT) => {
  return authJwt.role === 'ADMIN';
};

export const hasPermission = (permission: PermissionModel, modules: ModuleType[], moduleType: ModuleAction) => {
  return modules?.some(moduleName =>
    permission[moduleName] &&
    permission[moduleName][moduleType]
  );
};

export const canCheckInPage = async (data: any = {
  setF: null
}) => {
  const response = await apiService().post(`${process.env.REACT_APP_API_URI}/employees-check-ins/check-in-reqs`, {});
  if(data.setF) { data.setF(response.data.can); }
  return response.data.can;
};
import { FC } from 'react';
import AddressInputSelect, {
    AddressInputSelectModel
} from '../../../../../shared/components/molecules/commons/address-input-select';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../../shared/services/employee-service';
import { handleEmployeeInputChange } from '../../../../../shared/utils/function-state';
import { handleInputNumberChange } from '../../../../../shared/utils/function-validators';
import ZipcodeSelect from '../../inputs/zipcode-select';
import ProvinceSelect from "../../inputs/province-select";
import AmphureSelect from "../../inputs/amphure-select";
import TambonSelect from "../../inputs/tambon-select";

const EmergencyContactForm: FC = () => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);

    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';

    const onSelectAddress = (data: AddressInputSelectModel) => {
        // handleEmployeeInputChange({target: {name: 'emergency_contact_province_id', value: data.province}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'emergency_contact_amphure_id', value: data.amphure}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'emergency_contact_tambon_id', value: data.tambon}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'emergency_contact_postcode', value: data.zip_code}}, dispatch);
    };

    const handleZipcodeOptionChange = (dropdown: any) => {
        const value = {
            target: {
                name: 'emergency_contact_postcode',
                value: dropdown.value
            }
        };
        handleEmployeeInputChange(value, dispatch);
        // handleEmployeeInputChange({
        //     target: {
        //         name: 'emergency_contact_province_id',
        //         value: dropdown.amphure.province.value
        //     }
        // }, dispatch);
        // handleEmployeeInputChange({
        //     target: {
        //         name: 'emergency_contact_amphure_id',
        //         value: dropdown.amphure.value
        //     }
        // }, dispatch);
        // handleEmployeeInputChange({target: {name: 'emergency_contact_tambon_id', value: dropdown.id}}, dispatch);
    };

  const handleProvinceOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'emergency_contact_province_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleAmphureOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'emergency_contact_amphure_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleTambonOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'emergency_contact_tambon_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

    return (
        <div>
            <div className="row">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_full_name !== oldEmployee.emergency_contact_full_name ? 'field-warning' : ''}`}>
                    <label className="form-label required">ชื่อ - นามสกุล</label>
                    <input type="text" className="form-control" name="emergency_contact_full_name"
                           placeholder="" disabled={disable} value={employee.emergency_contact_full_name || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกชื่อ - นามสกุล
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_tel !== oldEmployee.emergency_contact_tel ? 'field-warning' : ''}`}>
                    <label className="form-label required">เบอร์โทรศัพท์</label>
                    <input type="text" className="form-control" name="emergency_contact_tel"
                           maxLength={10}
                           pattern=".{10,10}"
                           placeholder="" disabled={disable} value={employee.emergency_contact_tel || ''}
                           required={true}
                           onChange={(e) => handleInputNumberChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกเบอร์โทรศัพท์ 10 หลัก
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_relationship !== oldEmployee.emergency_contact_relationship ? 'field-warning' : ''}`}>
                    <label className="form-label required">ความสัมพันธ์</label>
                    <input type="text" className="form-control" name="emergency_contact_relationship"
                           placeholder="" disabled={disable} value={employee.emergency_contact_relationship || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกความสัมพันธ์
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`col-sm-12 col-md-8 pb-3 ${employee.emergency_contact_address !== oldEmployee.emergency_contact_address ? 'field-warning' : ''}`}>
                    <label className="form-label required">สถานที่</label>
                    <input type="text" className="form-control" name="emergency_contact_address"
                           placeholder="" disabled={disable} value={employee.emergency_contact_address || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกสถานที่
                    </div>
                </div>
                {/*<div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_postcode !== oldEmployee.emergency_contact_postcode ? 'field-warning' : ''}`}>*/}
                {/*    <label className="form-label required">รหัสไปรษณีย์</label>*/}
                {/*    <ZipcodeSelect*/}
                {/*        onChange={handleZipcodeOptionChange}*/}
                {/*        disable={disable}*/}
                {/*        value={employee.emergency_contact_postcode}/>*/}
                {/*    <input type="text" className="form-control" name="emergency_contact_postcode"*/}
                {/*           hidden={true}*/}
                {/*           value={employee.emergency_contact_postcode || ''}*/}
                {/*           required={true}*/}
                {/*           onChange={() => {*/}
                {/*           }}/>*/}
                {/*    <div className="invalid-feedback">*/}
                {/*        กรุณากรอกรหัสไปรษณีย์*/}
                {/*    </div>*/}
                {/*</div>*/}
              <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_postcode !== oldEmployee.emergency_contact_postcode ? 'field-warning' : ''}`}>
                <label className="form-label required">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" name="emergency_contact_postcode"
                       placeholder="" disabled={disable} value={employee.emergency_contact_postcode || ''}
                       required={true}
                       onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                <div className="invalid-feedback">
                  กรุณากรอกรหัสไปรษณีย์
                </div>
              </div>
            </div>
            {/*<div className={`pb-3*/}
            {/*${employee.emergency_contact_province_id !== oldEmployee.emergency_contact_province_id ? 'field-warning ': ''}*/}
            {/*${employee.emergency_contact_amphure_id !== oldEmployee.emergency_contact_amphure_id ? 'field-warning ': ''}*/}
            {/*${employee.emergency_contact_tambon_id !== oldEmployee.emergency_contact_tambon_id ? 'field-warning ': ''}*/}
            {/*${employee.emergency_contact_postcode !== oldEmployee.emergency_contact_postcode ? 'field-warning ': ''}*/}
            {/*`}>*/}
            {/*    <AddressInputSelect onSelectData={onSelectAddress} isDisable={false}*/}
            {/*                        province={employee.emergency_contact_province_id}*/}
            {/*                        amphure={employee.emergency_contact_amphure_id}*/}
            {/*                        tambon={employee.emergency_contact_tambon_id}*/}
            {/*                        zip_code={employee.emergency_contact_postcode}/>*/}
            {/*</div>*/}
          <div className="row">
          <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_province_id !== oldEmployee.emergency_contact_province_id ? 'field-warning' : ''}`}>
            <label className="form-label required">จังหวัด</label>
            <ProvinceSelect
              onChange={handleProvinceOptionChange}
              disable={disable}
              value={employee.emergency_contact_province_id}/>
            <input type="text" className="form-control" name="emergency_contact_province_id"
                   hidden={true}
                   value={employee.emergency_contact_province_id || ''}
                   required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">
              กรุณากรอกจังหวัด
            </div>
          </div>
          <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_amphure_id !== oldEmployee.emergency_contact_amphure_id ? 'field-warning' : ''}`}>
            <label className="form-label required">อำเภอ</label>
            <AmphureSelect
              onChange={handleAmphureOptionChange}
              disable={disable}
              value={employee.emergency_contact_amphure_id}/>
            <input type="text" className="form-control" name="emergency_contact_amphure_id"
                   hidden={true}
                   value={employee.emergency_contact_amphure_id || ''}
                   required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">
              กรุณากรอกอำเภอ
            </div>
          </div>
          <div className={`col-sm-12 col-md-4 pb-3 ${employee.emergency_contact_tambon_id !== oldEmployee.emergency_contact_tambon_id ? 'field-warning' : ''}`}>
            <label className="form-label required">ตำบล</label>
            <TambonSelect
              onChange={handleTambonOptionChange}
              disable={disable}
              value={employee.emergency_contact_tambon_id}/>
            <input type="text" className="form-control" name="emergency_contact_tambon_id"
                   hidden={true}
                   value={employee.emergency_contact_tambon_id || ''}
                   required={true}
                   onChange={() => {
                   }}/>
            <div className="invalid-feedback">
              กรุณากรอกตำบล
            </div>
          </div>
            </div>
        </div>
    );
};

export default EmergencyContactForm;
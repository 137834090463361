import React, { FC, useRef, useEffect, useState } from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import { Table } from 'react-bootstrap';
import { notificationService } from '../../../../shared/services/notification-service';
import { initialResponseData } from "../../../../shared/utils/constants";
import { ReportInOutLeaveService } from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import { AxiosError } from "axios/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import InputSelect from "../../../../shared/components/atoms/input-select";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Moment from "moment";

import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  fetchLeaveTypeOptions,

  department_offices_name_Options,
  division_group_office_name_Options,
  departments_name_Options,
  position_name_Options,
  leave_type_Options,
} from '../../OptionsDropDown';

import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, Text, View, PDFDownloadLink, Font } from '@react-pdf/renderer';
Font.register({ family: 'kanit-regular', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/L6VKvM17ZmevDynOiw7H9w.ttf' });
Font.register({ family: 'kanit-200', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/wfLWkj1C4tYl7MoiFWS3bA.ttf' });

const ReportInOutLeaveDetailPage: FC = () => {
  let { day, month, year }: any = useParams();

  const { loaderComponent, startLoading, stopLoading } = useSpinnerLoader();

  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    date: `${year - 543}-${month}-${day}`,
    search: '',
    page: 1,
    page_size: 10,
  });

  const fetchData = async () => {
    setQueryParamsExport({ ...queryParamsExport, user_id: '-' })

    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportInOutLeaveDetail(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const [dataExportAll, setDataExportAll] = useState({ count: 0, row: [] });

  const refButtonExport = useRef<(HTMLDivElement | null)[]>([]);
  const [dataExport, setDataExport] = useState({ count: 0, row: [] });

  const [queryParamsExport, setQueryParamsExport] = useState({
    user_id: '-',
    month: month,
    year: year - 543,
  });

  const fetchDataExport = async () => {
    try {
      if (queryParamsExport.user_id == '') {
        setDataExportAll(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport));
        setDataExport({ count: 0, row: [] });
      } else {
        setDataExport(await ReportInOutLeaveService.getReportInOutLeaveDetail_Export(queryParamsExport))
        setDataExportAll({ count: 0, row: [] });
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={6 + (leave_type_Options.slice(1).length) + (leave_type_Options.slice(1).length)} />;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  }, [
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.page_size,
    queryParams.page,
  ]);

  useEffect(() => {
    queryParamsExport.user_id !== '-' ?
      fetchDataExport()
      :
      setDataExport({ count: 0, row: [] }); setDataExportAll({ count: 0, row: [] });
  }, [
    queryParamsExport.user_id,
  ]);

  useEffect(() => {
    console.log("dataExportAll", dataExportAll)
    console.log("dataExport", dataExport)
  }, [
    dataExportAll, dataExport
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
    fetchLeaveTypeOptions();
  }, []);

  const thaiMonths = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  const getThaiDayName = (dateString: string) => {
    const date = new Date(dateString);
    const daysInThai = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
    return daysInThai[date.getDay()];
  };

  const PDF: React.FC<{ data: any }> = ({ data }) => (
    <Document>
      <Page size="A4" style={{ padding: 10 }}>
        <View>
          <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
            องค์การตลาดเพื่อเกษตรกร
          </Text>

          <Text style={{ fontFamily: 'kanit-regular', fontSize: 13, textAlign: 'center', marginBottom: 3 }}>
            รายงานการปฏิบัติงานของพนักงาน
          </Text>

          <Text style={{ fontFamily: 'kanit-regular', fontSize: 12, textAlign: 'center', marginBottom: 10 }}>
            กองทรัพยากรบุคคล {thaiMonths[parseInt(month) - 1]} {year}
          </Text>

          <View style={{ marginBottom: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'center' }}>
                รหัสพนักงาน
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '25%', fontSize: 11, textAlign: 'center' }}>
                ชื่อ-นามสกุล
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'center' }}>
                วันที่
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'center' }}>
                เวลาเข้า
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '10%', fontSize: 11, textAlign: 'center' }}>
                เวลาออก
              </Text>
              <Text style={{ fontFamily: 'kanit-regular', width: '35%', fontSize: 11, textAlign: 'center' }}>
                หมายเหตุ
              </Text>
            </View>

            {data.row?.map((data1: any, index1: any) => {
              return <>
                <View key={index1} style={{ flexDirection: 'row' }}>
                  <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'center' }}>
                    {data1.user?.code}
                  </Text>
                  <Text style={{ fontFamily: 'kanit-200', width: '25%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                    {data1.user?.prefix_name_th + data1.user?.firstname_th + " " + data1.user?.lastname_th}
                  </Text>
                </View>

                {data1.data?.map((data2: any, index2: any) => {
                  return <View key={index2} style={{ flexDirection: 'row' }}>
                    <View style={{ width: '35%' }}></View>
                    <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                      {getThaiDayName(data2.date) + " " + Moment(data2.date).format('DD')}
                    </Text>
                    <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                      {/* {Moment(data2.first_check_in_time).locale("th").format('HH:mm:ss')} */}
                      {(data2.first_check_in_time).substring(11, 19)}
                    </Text>
                    <Text style={{ fontFamily: 'kanit-200', width: '10%', fontSize: 10, textAlign: 'left', marginLeft: 5 }}>
                      {/* {Moment(data2.last_check_in_time).locale("th").format('HH:mm:ss')} */}
                      {(data2.last_check_in_time).substring(11, 19)}
                    </Text>
                  </View>
                })}

                <View style={{ marginTop: 10 }} />
              </>
            })}
          </View>
        </View>
      </Page>
    </Document>
  );

  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสถิติเข้า - ออกงานและการลา</h2>
      <a href="/report/report-in-out-leave/report-in-out-leave">
        <Button variant="" className="btn-action me-2 btn-outline-secondary" onClick={() => { }}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2" />
          ย้อนกลับ
        </Button>
      </a>
    </div>

    {loaderComponent}

    <h5 className="text-mof-primary m-0">{`${day}/${month}/${year}`}</h5>
    <br />

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e: any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }}
          required={true}
          isValidateError={false} />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
          placeholder="ค้นหาจากชื่อ..." name="search"
          onChange={(e) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                search: e.target.value || ""
              }
            })
          }} />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-3 gap-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>

        {
          dataExportAll.count === 0 ?
            <Button type="button" className="btn-mof-primary btn btn-primary" onClick={() => { setQueryParamsExport({ ...queryParamsExport, user_id: '' }) }}>สร้าง PDF</Button>
            :
            <PDFDownloadLink document={queryParamsExport.user_id === '' ? <PDF data={dataExportAll} /> : <></>} fileName={`รายงานสถิติเข้า-ออกงานและการลา (${thaiMonths[parseInt(month) - 1]} ${year}).pdf`}>
              {({ blob, url, loading, error }) => (
                loading ?
                  <Button type="button" className="btn-mof-primary btn btn-primary" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                  </Button>
                  :
                  <Button type="button" className="btn-mof-primary btn btn-primary" onClick={() => setQueryParamsExport({ ...queryParamsExport, user_id: '-' })}>ดาวน์โหลด PDF</Button>
              )}
            </PDFDownloadLink>
        }
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped hover>
        <thead>
          <tr className="align-middle text-center">
            <th rowSpan={2} className="border min-150">ชื่อ-สกุล</th>
            <th rowSpan={2} className="border min-150">สำนัก/ฝ่าย</th>
            <th rowSpan={2} className="border min-150">กอง/กลุ่ม</th>
            <th rowSpan={2} className="border min-150">แผนก</th>
            <th rowSpan={2} className="border min-150">ตำแหน่ง</th>
            <th colSpan={leave_type_Options.slice(1).length} className="border min-150">วันลาประจำเดือน{thaiMonths[parseInt(month) - 1]} {year}</th>
            <th colSpan={leave_type_Options.slice(1).length} className="border min-150">รวมวันลาทั้งหมด</th>
            <th rowSpan={2} className="border min-150">Export PDF</th>
          </tr>
          <tr className="align-middle text-center">
            {
              leave_type_Options.slice(1).map((data: any, index: any) => {
                return <td key={index} style={{ backgroundColor: '#6C757D', color: 'white', fontWeight: 'bold' }} className="border">{data.label}</td>
              })
            }
            {
              leave_type_Options.slice(1).map((data: any, index: any) => {
                return <td key={index} style={{ backgroundColor: '#6C757D', color: 'white', fontWeight: 'bold' }} className="border">{data.label}</td>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data.rows.map((data: any, index: any) => {
              return <tr key={index} className="text-center">
                <td>{data.prefix_name_th + data.firstname_th + " " + data.lastname_th}</td>
                <td>{data.job?.department_offices_name ? data.job?.department_offices_name : '-'}</td>
                <td>{data.job?.division_group_office_name ? data.job?.division_group_office_name : '-'}</td>
                <td>{data.job?.structure_departments_name ? data.job?.structure_departments_name : '-'}</td>
                <td>{data.job?.position_name ? data.job?.position_name : '-'}</td>
                {
                  leave_type_Options.slice(1).map((leave_type: any, index: any) => {
                    for (let i = 0; i < data.leave?.length; i++) {
                      if (leave_type.value == data.leave[i]?.id) {
                        return <td key={index}>{data.leave[i]?.count}</td>
                      }
                    }
                    return <td>0</td>
                  })
                }
                {
                  leave_type_Options.slice(1).map((leave_type: any, index: any) => {
                    for (let i = 0; i < data.leave_all?.length; i++) {
                      if (leave_type.value == data.leave_all[i]?.id) {
                        return <td key={index}>{data.leave_all[i]?.count}</td>
                      }
                    }
                    return <td>0</td>
                  })
                }
                <td align='center'>
                  <Button type="button" variant="primary" className="d-block" onClick={() => {
                    setQueryParamsExport({
                      ...queryParamsExport,
                      user_id: data.user_id
                    })

                    startLoading()

                    setTimeout(() => {
                      if (refButtonExport.current[index]) {
                        refButtonExport.current[index]?.click();
                      }

                      stopLoading()
                      setQueryParamsExport({ ...queryParamsExport, user_id: '-' })
                    }, 5000);
                  }}>
                    <FontAwesomeIcon icon={faFileExport} />
                  </Button>
                  <PDFDownloadLink document={queryParamsExport.user_id !== '' && queryParamsExport.user_id !== '-' ? <PDF data={dataExport} /> : <></>} fileName={`รายงานสถิติเข้า-ออกงานและการลา (${data.prefix_name_th + data.firstname_th + " " + data.lastname_th}).pdf`}>
                    {({ blob, url, loading, error }) => (
                      <span ref={(el: any) => refButtonExport.current[index] = el}></span>
                    )}
                  </PDFDownloadLink>
                </td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm
        current_page={queryParams.page}
        count={data.count}
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
      />
    </div>

  </>
}

export default ReportInOutLeaveDetailPage;
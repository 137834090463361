import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { WorkHistoryModel } from '../../../../shared/models/employee.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import {
  handleEmployeeSectionInputChange,
  handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import { convertDateToString, dateDiff } from '../../../../shared/utils/functions-date-time';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';
import InputCurrency from '../../../../shared/components/inputs/input-currency';
import { convertToPositiveNumber, handleNumericInput } from '../../../../shared/utils/function-validators';

interface WorkHistoryPassFormRowProps extends WorkHistoryModel {
  index: number;
}

const WorkHistoryPassForm: FC = () => {
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const [sorting, setSorting] = useState<SortingField>({
    field: null,
    order: null
  });

  const tableRowNoData = () => {
    if (employee.work_histories.length === 0) {
      return <TableRowNoData colspan={8}/>;
    }
    return null;
  };

  const headers: Header[] = [
    {
      label: 'สถานที่ทำงาน',
      field: 'location',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'วันที่เริ่มต้น - สิ้นสุด',
      field: 'start_date',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'อายุงาน',
      field: '-',
      order: null,
      isOrder: false,
    },
    {
      label: 'ตำแหน่ง',
      field: 'position',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'เงินเดือน',
      field: 'salary',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'เหตุผลที่ลาออก',
      field: 'reason',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'แนบไฟล์',
      field: 'file',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  const onChangeOrder = (orderData: OrderData) => {
    setSorting({
      field: orderData.field,
      order: orderData.order
    });
  };

  const dataOrder = () => {
    if (showOrder)
      return employee.work_histories.map(data => data).sort((a, b) => {
        return SortingByField(a, b, sorting);
      });
    return employee.work_histories;
  };


  return (
    <div className="overflow-auto">
      <Table bordered>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
        </thead>
        <tbody>
        {
          dataOrder().map((data, index) => {
            return <WorkHistoryRow
              key={index}
              index={index}
              {...data}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
  );
};

const WorkHistoryRow: FC<WorkHistoryPassFormRowProps> = ({
                                                           index,
                                                           location,
                                                           start_date,
                                                           end_date,
                                                           position,
                                                           salary,
                                                           reason,
                                                           file,
                                                           from_id,
                                                         }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const type = 'work_histories';
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  let fromEmployee: any | null = null;

  if (from_id) {
    const found = oldEmployee[type].find(data => data.id === from_id);
    if (found)
      fromEmployee = found;
  }

  const validateEditRequest = (value: any, key: keyof WorkHistoryModel) => {
    if (employeeAction.type === 'view-edit-request') {
      if (fromEmployee && from_id) {
        if (value !== fromEmployee[key])
          return 'field-warning';
      }
      if (!from_id) {
        return 'field-new';
      }
    }
    return '';
  };

  const [showModal, setShowModal] = useState(false);

  const deleteButton = () => {
    if (!disable) {
      return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
    }
    return null;
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleEmployeeSectionRemoveByIndex(type, index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    const startTime = {
      target: {
        name: 'start_date',
        value: value.startDate ? convertDateToString(value.startDate) : null
      }
    };
    const endTime = {
      target: {
        name: 'end_date',
        value: value.endDate ? convertDateToString(value.endDate) : null
      }
    };
    handleEmployeeSectionInputChange(type, index, startTime, dispatch);
    handleEmployeeSectionInputChange(type, index, endTime, dispatch);
  };

  const getDateDiff = () => {
    if (start_date && end_date) {
      const data = dateDiff(start_date, end_date);
      const result = [];
      if (data.years)
        result.push(`${Math.abs(data.years)} ปี`);
      if (data.months)
        result.push(`${Math.abs(data.months)} เดือน`);
      if (data.days)
        result.push(`${Math.abs(data.days)} วัน`);
      return result.length > 0 ? result.join(' ') : '1 วัน';
    }
    return 'กรุณาเลือกวันที่เริ่มต้น - สิ้นสุด';
  };

  const validateSalary = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = convertToPositiveNumber(event.target.value);
    handleNumericInput(event);
    handleEmployeeSectionInputChange(type, index, event, dispatch);
  };

  return (
    <tr className="bg-white">
      <td className={`${validateEditRequest(location, 'location')}`}>
        <input type="text" className="form-control" name="location" disabled={disable}
               placeholder="" value={location || ''} required={true}
               maxLength={250}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(start_date, 'start_date')} ${validateEditRequest(end_date, 'end_date')}`}>
        <DateRangeInput
          startDate={start_date ? new Date(start_date) : null}
          endDate={end_date ? new Date(end_date) : null}
          onChange={handleDateRangeChange}
          disable={disable}/>
      </td>
      <td className="align-self-center">
        <input type="text" className="form-control" disabled={true}
               value={getDateDiff()}/>
      </td>
      <td className={`${validateEditRequest(position, 'position')}`}>
        <input type="text" className="form-control" name="position" disabled={disable}
               maxLength={250}
               placeholder="" value={position || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(salary, 'salary')}`}>
        <InputCurrency value={salary === 0 ? '0' : salary || ''}
                       onChange={validateSalary}
                       disable={disable}
                       name="salary" required={true}/>
      </td>
      <td className={`${validateEditRequest(reason, 'reason')}`}>
        <input type="text" className="form-control" name="reason" disabled={disable}
               placeholder="" value={reason || ''} required={true}
               maxLength={250}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(file, 'file')}`}>
        <SectionFileForm section_key={'work_histories'}
                         index={
                           disable ? employee.work_histories.findIndex(data => data.location === location &&
                             data.start_date === start_date &&
                             data.end_date === end_date &&
                             data.position === position &&
                             data.salary === salary &&
                             data.reason === reason
                           ) : index
                         }/>
      </td>
      <td className="text-center">
        {deleteButton()}
      </td>
      <DeleteConfirmationModal
        show={showModal}
        title={'ยืนยันการลบข้อมูล'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </tr>
  );
};

export default WorkHistoryPassForm;
import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {LeaveOnlineService} from "../../../../shared/services/Report/LeaveOnline/leave-online";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";
import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  fetchLeaveTypeOptions,
  
  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options, 
  position_name_Options, 
  leave_type_Options, 
} from '../../OptionsDropDown';

const headers: Header[] = [
  {
    label: 'ชื่อ-สกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'สำนัก/ฝ่าย',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'กอง/กลุ่ม',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ประเภทการลา',
    field: '',
    order: null,
    isOrder: false,
  },
];

const ReportLeaveEachTypeListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    leave_type: '',
    date_start: '',
    date_end: '',
    search: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await LeaveOnlineService.getReportLeaveEachTypeList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.leave_type,
    queryParams.date_start, 
    queryParams.date_end,
    queryParams.page_size,
    queryParams.page
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
    fetchLeaveTypeOptions();
  }, []);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      date_start: value.startDate ? convertDateToString(value.startDate) : '',
      date_end: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสถิติการลาแต่ละประเภท</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ประเภทการลา</label>
        <InputSelect value={queryParams.leave_type} options={leave_type_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                leave_type: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
          <DateRangeInput
            startDate={queryParams.date_start ? new Date(queryParams.date_start) : null}
            endDate={queryParams.date_end ? new Date(queryParams.date_end) : null}
            onChange={handleDateRangeChange}
            disable={false}
          />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
          <input className="form-control" type="text" value={queryParams.search as string}
              placeholder="ค้นหาจากชื่อ..." name="search"
              onChange={(e) => {
                setQueryParams(prevState => {
                  return {
                    ...prevState,
                    search: e.target.value || ""
                  }
                })
              }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows.map((data:any,index:any) => {
              return <tr key={index}>
                <td>{data.name ? data.name : "-"}</td>
                <td>{data.department_offices_name ? data.department_offices_name : "-"}</td>
                <td>{data.division_group_office_name ? data.division_group_office_name : "-"}</td>
                <td>{data.structure_departments_name ? data.structure_departments_name : "-"}</td>
                <td>{data.position_name ? data.position_name : "-"}</td>
                <td>{data.leave_type_name ? data.leave_type_name : "-"}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportLeaveEachTypeListPage;
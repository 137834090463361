import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ReportInOutLeaveService} from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import Moment from "moment";
import {
  yearOptions, 
} from '../../OptionsDropDown';

const headers: Header[] = [
  {
    label: 'วันที่',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ชื่อวันหยุด',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportAnnualVacationListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    year: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportAnnualVacationList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.year,
    queryParams.page_size, 
    queryParams.page
  ]);

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานแสดงวันหยุดประจำปี</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ปี</label>
        <InputSelect value={queryParams.year} options={yearOptions} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                year: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.holiday_date ? Moment(data.holiday_date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-'}</td>
                <td>{data.holiday_name ? data.holiday_name : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportAnnualVacationListPage;
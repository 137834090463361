import React, { FC, useEffect, useState } from 'react';
import InputSelect from '../../../shared/components/atoms/input-select';
import { EmployeeType } from '../../../shared/utils/constants';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SummaryBar from './summary-bar';
import { apiEmployeeService } from '../../../shared/services/employee-service';
import { notificationService } from '../../../shared/services/notification-service';
import { AxiosError } from 'axios';
import { SelectDropdown } from '../../../shared/models/common.model';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationalStructure } from '../../../shared/services/organizational-structure-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import ReportModal from './modals/report-modal';
import { convertToCurrency } from '../../../shared/utils/currency';
import { getFileApplication, getFileExtension } from '../../../shared/utils/file-type';

const SummaryFilters: FC = () => {
    const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [employeeType, setEmployeeType] = useState<string | null>(null);
    const [structureDepartmentIndex, setStructureDepartmentIndex] = useState<null | number>(null);
    const [displayCount, setdisplayCount] = useState({
        inside: 0,
        outside: 0,
    });
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (organizationalStructure.personnels.length > 0) {
            onGetEmployeeCount();
        }
    }, [organizationalStructure.personnels]);


    useEffect(() => {
        onGetEmployeeCount();
    }, [employeeType, structureDepartmentIndex]);

    const getInsideFixId = () => {
        const sameStructureDepartment = organizationalStructure.personnels
            .filter(data => organizationalStructure.structure_positions[data.structure_position_index!].structure_department_index === structureDepartmentIndex);
        return sameStructureDepartment.map(data => data.user_id).join(',');
    };

    const getParamFilterReport = () => {
        const selectDepartment = typeof structureDepartmentIndex === 'number';

        return {
            employee_type: employeeType,
            inside: organizationalStructure.personnels.map(data => data.user_id).join(','),
            inside_fix: selectDepartment ? getInsideFixId() : ''
        };
    };

    const onGetEmployeeCount = async () => {
        startLoading();
        const data = await apiEmployeeService.getEmployeeCount(getParamFilterReport()).finally(() => {
            stopLoading();
        });
        setdisplayCount(prevState => ({
            inside: data.personnels,
            outside: data.count,
        }));
    };

    const handleOnChangeEmployeeType = (select: SelectDropdown) => {
        if (select.value) {
            const value = select.value!.value as string;
            setEmployeeType(value);
        } else {
            setEmployeeType(null);
        }
    };

    const handleOnChangeStructureDepartment = (select: SelectDropdown) => {
        if (select.value) {
            const value = select.value!.value as number;
            setStructureDepartmentIndex(value);
        } else {
            setStructureDepartmentIndex(null);
        }
    };

    const structureDepartmentOptions = organizationalStructure.structure_departments.map((data, index) => ({
        label: data.name,
        value: index
    }));

    const getStructureDepartmentName = () => {
        return organizationalStructure.structure_departments[structureDepartmentIndex as number].name;
    };

    const displayEmployeeType = (inside: boolean = false) => {
        let name = [];
        if (!!employeeType)
            name.push(employeeType);
        else
            name.push('ทั้งหมด');

        if (inside && typeof structureDepartmentIndex === 'number')
            name.push(getStructureDepartmentName());

        return <p className="m-0">(
            {
                name.join(' - ')
            }
            )</p>;
    };

    const getInsideCount = () => {
        return !!employeeType || typeof structureDepartmentIndex === 'number'
            ? displayCount.inside
            : organizationalStructure.personnels.length;
    };

    const getOutsideCount = () => {
        return displayCount.outside;
    };

    const onDownloadReport = async () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const downloadReport = async (type: 'excel' | 'csv' | 'pdf') => {
        try {
            startLoading();
            const params = getParamFilterReport();
            if (!employeeType && typeof structureDepartmentIndex != 'number') {
                params.inside_fix = params.inside;
            }

            const newParams = {
                ...params,
                id_personnels: organizationalStructure.personnels.map(data => data.id).join(','),
                file_type: type
            };
            const response = await apiEmployeeService.downloadOrganizationalStructureReport(newParams);
            const blob = new Blob([response.data], {type: getFileApplication(type)});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${organizationalStructure.name}.${getFileExtension(type)}`;
            a.click();
            URL.revokeObjectURL(url);
            notificationService.successNotification('ดาวน์โหลดรายงานสำเร็จ', '');
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('ดาวน์โหลดรายงานไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const handleConfirm = async (file_type: 'excel' | 'csv' | 'pdf') => {
        setShowModal(false);
        downloadReport(file_type);
    };

    const displayModal = () => {
        if (showModal) {
            return <ReportModal show={showModal} onHide={handleCloseModal} onConfirm={handleConfirm}/>;
        }
        return null;
    };

    return <>
        {loaderComponent}
        {displayModal()}
        <div className="row">
            <div className="col-sm-12 col-md-5">
                <InputSelect options={EmployeeType} placeholder="ประเภทบุคลากร" name="employee_type"
                             onChange={handleOnChangeEmployeeType} isClearable={true}
                             value={employeeType}/>
            </div>
            <div className="col-sm-12 col-md-5">
                <InputSelect options={structureDepartmentOptions} placeholder="แผนก"
                             name="structure_department_index"
                             onChange={handleOnChangeStructureDepartment} isClearable={true}
                             value={structureDepartmentIndex}/>
            </div>
            <div className="col-sm-12 col-md-2">
                <Button variant="outline-success" className="btn-mof-primary" type="button" onClick={onDownloadReport}>
                    <FontAwesomeIcon icon={faDownload}/>
                </Button>
            </div>
        </div>
        <div className="row h-75 mt-3">
            <div className="col-sm-12 col-md-6 d-flex flex-column justify-content-center">
                <div className="px-3 py-3 bg-card text-mof-primary rounded-4 shadow text-center">
                    <h6 className="m-0">บุคลากรอยู่ในโครงสร้าง</h6>
                    {displayEmployeeType(true)}
                    <h3 className="mt-2 mb-0">{
                        convertToCurrency(getInsideCount())
                    }</h3>
                </div>
                <div className="px-3 py-3 bg-card text-danger rounded-4 shadow text-center mt-4">
                    <h6 className="m-0">บุคลากรไม่อยู่ในโครงสร้าง</h6>
                    {displayEmployeeType()}
                    <h3 className="mt-2 mb-0">{
                        convertToCurrency(getOutsideCount())
                    }</h3>
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <SummaryBar
                    insideCount={
                        getInsideCount()
                    }
                    outsideCount={
                        getOutsideCount()
                    }/>
            </div>
        </div>
    </>;
};

export default SummaryFilters;
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewPersonnel,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateOrganizationalStructurePage
} from '../../../shared/services/organizational-structure-service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import PersonnelForm from '../components/forms/personnel-form';
import { GetPersonnelModel, PersonnelQueryParams } from '../../../shared/models/organizational-structure.model';
import PaginationForm from '../../../shared/components/molecules/commons/pagination-form';
import { ApiResponse } from '../../../shared/models/common.model';
import PersonnelModal, { PersonnelModelSelect } from '../components/modals/personnel-modal';
import { apiEmployeeService } from '../../../shared/services/employee-service';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../../shared/services/notification-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { EmployeeModel, EmployeeOrganizationalStructureList } from '../../../shared/models/employee.model';
import { getMessageContents } from '../../../shared/utils/error-messages';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import { convertToCurrency } from '../../../shared/utils/currency';
import SaveDraft from '../functions/save-draft';

const PersonnelPage: FC = () => {
  let {id} = useParams();
  const [tab, setTab] = useState<0 | 1>(0);
  const [data, setData] = useState<ApiResponse<GetPersonnelModel>>(
    {
      count: 0,
      total_pages: 0,
      current_page: 0,
      rows: [],
    }
  );
  const [queryParams, setQueryParams] = useState<PersonnelQueryParams>({
    page: 1,
    page_size: 10,
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showMessageModal, setMessageModal] = useState<any>('');

  useEffect(() => {
    updateOrganizationalStructurePage('personnel', dispatch);
  }, []);

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const handleModelNewData = () => {
    setShowAddModal(true);
  };

  const handleModalNewData = async (data: PersonnelModelSelect) => {
    setShowAddModal(false);
    addNewPersonnel(
      {
        value: {
          id: null,
          structure_position_id: data.structure_position_id,
          structure_position_index: data.structure_position_index,
          user_id: data.user_id,
        }
      },
      dispatch
    );

  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const switchTab = (tab: 0 | 1) => {
    setTab(tab);
  };

  const getParams = (tab: 0 | 1): EmployeeOrganizationalStructureList => {
    const personnelListString = organizationalStructure.personnels.map(data => data.user_id).join(',');
    if (tab === 0) {
      return {
        ...queryParams,
        includes: personnelListString,
        excludes: null

      };
    }

    const directors = organizationalStructure.directors
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const deputy_directors = organizationalStructure.deputy_directors
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const department_offices = organizationalStructure.department_offices
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const division_group_offices = organizationalStructure.division_group_offices
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const structure_departments = organizationalStructure.structure_departments
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const personnels = organizationalStructure.personnels
      .map(data => data.user_id).filter(data => !!data);

    const x = [];
    if (directors.length > 0)
      x.push(...directors)
    if (deputy_directors.length > 0)
      x.push(...deputy_directors)
    if (department_offices.length > 0)
      x.push(...department_offices)
    if (division_group_offices.length > 0)
      x.push(...division_group_offices)
    if (structure_departments.length > 0)
      x.push(...structure_departments)
    if (personnels.length > 0)
      x.push(...personnels)
    if (personnels.length > 0)
      x.push(...personnels)
    const excludeIdList = x.join(',')
    return {
      ...queryParams,
      includes: null,
      excludes: excludeIdList

    };
  };

  useEffect(() => {
    if (tab === 0 && organizationalStructure.personnels.length === 0) {
      setData({
        count: 0,
        total_pages: 0,
        current_page: 0,
        rows: [],
      });
      return;
    }
    const fetchData = async () => {
      startLoading();
      try {
        const result = await apiEmployeeService.getEmployeeOrganizationalStructureList(getParams(tab));

        const mapResult: GetPersonnelModel[] = result.rows.map((data: EmployeeModel): GetPersonnelModel => {
          const getPersonnel = organizationalStructure.personnels.find(personnel => {
            return personnel.user_id === data.user_id;
          });

          if (getPersonnel) {
            return {
              id: getPersonnel.id,
              structure_position_id: getPersonnel.structure_position_id,
              structure_position_index: getPersonnel.structure_position_index,
              user_id: data.user_id,
              employee: data
            };
          } else {
            return {
              id: null,
              structure_position_id: null,
              structure_position_index: null,
              user_id: data.user_id,
              employee: data
            };
          }
        });

        const newResult = {
          ...result,
          rows: mapResult
        };

        setData(newResult);
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
      } finally {
        stopLoading();
      }
    };
    fetchData().then();
  }, [tab, organizationalStructure.personnels, queryParams]);

  const getPreviousLink = () => {
    if (id) {
      return `/organizational-structures/${id}/structure-position`;
    }
    return '/organizational-structures/create/structure-position';
  };

  const getNextLink = () => {
    if (id) {
      navigate(`/organizational-structures/${id}/summary`);
    } else {
      navigate('/organizational-structures/create/summary');
    }
  };

  const validateForm = () => {
    if (organizationalStructureAction.status === 'view') {
      getNextLink();
      return;
    }
    const powerRateCap = organizationalStructure.structure_positions.map((data, index) => {
      const powerRateUse = organizationalStructure.personnels
        .filter(data => data.structure_position_index === index).length;
      return {
        name: `${data.name}`,
        power_rate: (data.power_rate || 0) - powerRateUse
      };
    });
    const powerRateRemaining = powerRateCap.filter(data => data.power_rate > 0);
    const powerRateOverLimit = powerRateCap.filter(data => data.power_rate < 0);

    if (powerRateRemaining.length > 0) {
      const message = powerRateRemaining.map(data => `ตำแหน่ง ${data.name} ยังเหลืออัตรากำลังอีก ${convertToCurrency(data.power_rate)} คน`);

      const contextMessage = <div><p>อัตรากำลังยังไม่ครบกำหนด</p>{getMessageContents(message)}</div>;
      setMessageModal(contextMessage);
      setShowConfirmModal(true);
      return;
    } else if (powerRateOverLimit.length > 0) {
      const message = powerRateOverLimit.map(data => `ตำแหน่ง ${data.name} อัตรากำลังเกิน ${convertToCurrency(Math.abs(data.power_rate))} คน`);
      const contextMessage = <div><p>อัตรากำลังเกินกำหนด</p>{getMessageContents(message)}</div>;
      setMessageModal(contextMessage);
      setShowConfirmModal(true);
      return;
    }

    getNextLink();
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmActionModal = () => {
    getNextLink();
  };

  const displayConfirmModal = () => {
    if (showConfirmModal) {
      return <ConfirmModal
        show={showConfirmModal}
        title="ข้ามการเพิ่มบุคลากร"
        description={showMessageModal}
        onHide={handleCloseConfirmModal}
        onConfirm={handleConfirmActionModal}
      />;
    }
    return null;
  };

  const displayLabelNextButton = () => {
    if (organizationalStructureAction.type === 'create') {
      return 'สร้างข้อมูล';
    }
    if (organizationalStructureAction.type === 'edit') {
      return 'บันทึกข้อมูล';
    }
    return 'ถัดไป';
  };

  return (
    <>
      {loaderComponent}
      {displayConfirmModal()}
      <div className="h-100 content-background-color rounded-4 px-4 py-4">
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <div className="d-flex justify-content-between">
              <Button variant={`${tab === 0 ? 'primary' : 'outline-primary'}`}
                      onClick={() => switchTab(0)}
                      className={`w-100 me-1 btn-action-height ${tab === 0 ? 'btn-mof-info' : 'btn-mof-outline-info'}`}>
                เข้ากรอบอัตรากำลัง
              </Button>
              <Button variant={`${tab === 1 ? 'primary' : 'outline-primary'}`}
                      onClick={() => switchTab(1)}
                      className={`w-100 ms-1 btn-action-height ${tab === 1 ? 'btn-mof-info' : 'btn-mof-outline-info'}`}>
                ไม่เข้ากรอบอัตรากำลัง
              </Button>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 d-flex justify-content-end">
            <Button variant="primary" className="btn-action btn-mof-primary" onClick={handleModelNewData}
                    hidden={disable}>
              <FontAwesomeIcon icon={faPlus} className="me-2"/>
              เพิ่ม
            </Button>
          </div>
        </div>

        <div className="mt-3">
          <PersonnelForm rows={data.rows} allowDelete={tab === 0}/>
        </div>
        <div className="">
          <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                          onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
        </div>

        <PersonnelModal show={showAddModal} onHide={handleCloseModal} onConfirm={handleModalNewData}/>
      </div>

      <div className="d-flex justify-content-end my-4">
        <Link to={getPreviousLink()}>
          <Button variant="secondary" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        <SaveDraft/>
        <Button variant="primary" className="btn-action me-2" onClick={validateForm}>
          {displayLabelNextButton()}
          <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
        </Button>
      </div>
    </>
  );
};

export default PersonnelPage;
import React, { ChangeEvent, FC, useState } from 'react';
import { Alert, Button, Form, Image } from 'react-bootstrap';

import './styles/login.scss';
import { notificationService } from '../../../shared/services/notification-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { apiAuthentication } from '../../../shared/services/authentication-service';
import { useAuth } from '../../../shared/authentications/auth-context';
import { Link, useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployeeReportModal from '../../employees/components/modals/employee-report-modal';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import InfoModal from '../../../shared/components/molecules/modals/info-modal';

interface LoginForm {
    email: string;
    password: string;
}

const LoginPage: FC = () => {
    const {login} = useAuth();
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState<LoginForm>({
        email: '',
        password: '',
    });
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [hidePassword, setHidePassword] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search);

    const [inValidToken, setInValidToken] = useState(!!queryParameters.get('invalid_token'));

    const handleOnChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setForm(data => {
            return {...data, email: e.target.value};
        });
    };

    const handleOnChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setForm(data => {
            return {...data, password: e.target.value};
        });
    };

    const onLogin = async (event: any) => {
        event.preventDefault();
        setSubmitted(true);
        if (!form.email || !form.password) {
            notificationService.warningNotification('กรุณากรอกข้อมูลให้ครบถ้วน', '');
            return;
        }
        startLoading();
        try {
            const result = await apiAuthentication.onLogin(form);
            login(result.token, result.role, result.permission, result.user_id);
            navigate('/');
        } catch (error) {
            const err = error as any;
            if (err?.response?.data?.message === 'Invalid user is inactive') {
                setShowModal(true);
            } else if (err?.response?.status === 400) {
                notificationService.dangerNotification('เข้าสู่ระบบไม่สำเร็จ', 'อีเมล หรือ รหัสผ่าน ไม่ถูกต้อง');
            } else {
                notificationService.dangerNotification('ระบบขัดข้อง', err.message);
            }
        } finally {
            stopLoading();
        }
    };

    const alertTokenInValid = () => {
        if (inValidToken) {
            return <Alert variant="danger" className="mt-4" onClose={() => setInValidToken(false)} dismissible>
                <Alert.Heading>401 Unauthorized</Alert.Heading>
                <p>
                    Token ไม่ถูกต้องหรือหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง
                </p>
            </Alert>;
        }
        return;
    };

    const onClickHidePassword = () => {
        setHidePassword(prevState => !prevState);
    };

    const onRedirectToRegister = () => {
        navigate('/register');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const displayModal = () => {
        if (showModal) {
            return <InfoModal
              show={showModal}
              title={'บัญชีได้ถูกระงับการใช้งาน'}
              description={'โปรดติดต่อกองเทคโนโลยีและสารสนเทศ (IT)'}
              onHide={handleCloseModal}
            />
        }
        return null;
    };

    return <Form noValidate validated={submitted} onSubmit={onLogin}>
        {loaderComponent}
        {displayModal()}
        <div className="container pt-5">
            <div className="m-auto login-logo">
                <Image src="static/ORTORKOR-LOGO.png" alt="logo" className="w-100"/>
            </div>
            <div className="bg-white shadow rounded px-sm-5 px-3 py-5 login-page m-auto mt-4">
                <div className="row">
                    <div className="col-sm-12">
                        <label className="form-label" htmlFor="email">ชื่อผู้ใช้งาน หรือ อีเมล</label>
                        <input type="text" className="form-control" id="email" name="email"
                               required
                               value={form.email} onChange={handleOnChangeEmail}/>
                        <div className="invalid-feedback">
                            กรุณากรอกชื่อผู้ใช้งาน หรือ อีเมล
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <label className="form-label" htmlFor="password">รหัสผ่าน</label>
                        <div className="input-group ">
                            <input type={hidePassword ? 'password' : 'text'} className="form-control" id="password"
                                   name="password"
                                   required
                                   value={form.password} onChange={handleOnChangePassword}/>
                            <span className="input-group-text pointer" onClick={onClickHidePassword}>
                                <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye}/>
                            </span>
                            <div className="invalid-feedback">
                                กรุณากรอกรหัสผ่าน
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="remember-login"/>
                            <label className="form-check-label pointer" htmlFor="remember-login">
                                จดจำรหัสผ่าน
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <Link to={'/forget-password'}>ลืมรหัสผ่าน?</Link>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12 col-md-6 mb-3">
                        <Button variant="primary" type="submit"
                                className="d-block w-100 btn-action-height btn-mof-primary">
                            เข้าสู่ระบบ
                        </Button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <Button variant="primary" type="button"
                                onClick={onRedirectToRegister}
                                className="d-block w-100 btn-action-height btn-mof-primary">
                            ลงทะเบียน
                        </Button>
                    </div>
                </div>
                {alertTokenInValid()}

            </div>
        </div>
    </Form>;
};

export default LoginPage;

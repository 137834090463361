import React from 'react';
import {Button} from "react-bootstrap";
import {Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";

type PageProps = {
  open: boolean;
  handleClose: () => void;
  onRejectDialogValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRejectSubmitClick: () => void;
};
const RejectDialog = ({open, handleClose, onRejectDialogValueChange, handleRejectSubmitClick}: PageProps) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            height: "344px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" sx={{fontWeight: "Medium", color: "#056310"}}
                      fontFamily={`"Anuphan", sans-serif`}>เหตุผลการไม่อนุมัติ</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="col-sm-12 col-md-12 mt-sm-3 mt-md-0 mt-xl-3" style={{padding: "0 32px 0 32px"}}>
            <div className={``}>
              <label className="form-label">เหตุผล</label>
              <TextField
                id="outlined-multiline-static"
                className="form-control"
                multiline
                rows={4}
                defaultValue={""}
                onChange={onRejectDialogValueChange}
              />
            </div>
          </div>
        </DialogContent>
        <div
          className="d-flex flex-row col-12 justify-content-center column-gap-3 mb-4">
          <div className="">
            <Button variant="secondary" type="button"
                    onClick={handleClose}
                    className="d-block btn-action"
            >
              ย้อนกลับ
            </Button>
          </div>
          <div className="">
            <Button type="button"
                    onClick={handleRejectSubmitClick}
                    className="d-block btn-action"
                    style={{backgroundColor: "green", border: "1px solid green"}}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default RejectDialog;


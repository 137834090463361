import React, { FC } from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationalStructure } from '../../../shared/services/organizational-structure-service';

const OrganizationChart: FC = () => {
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);

  const getDirectors = () => {
    return organizationalStructure.directors.map((rootData, rootIndex) => {
      return <div key={rootIndex} className="my-2 m-auto">
        <Tree
          key={rootIndex}
          lineWidth={'2px'}
          lineHeight={'20px'}
          lineBorderRadius={'10px'}
          label={<DisplayBox key={rootIndex}
                             label={rootData.name || '-'}
                             title={'ผู้อำนวยการ'}
                             type={'directors'}/>}
          children={[...getDeputyDirectors(), ...getOffices(rootIndex), ...getDirectorsDivisionGroupOffices(rootIndex), ...getDirectorsStructureDepartments(rootIndex)]}
        />
      </div>;
    });
  };

  const getDirectorsDivisionGroupOffices = (director_index: number) => {
    return organizationalStructure.division_group_offices.map((data, division_group_office_index) => {
      if (data.director_index === director_index && data.type === 'director') {
        return <TreeNode
          key={`DirectorsDivisionGroupOffices${division_group_office_index}`}
          label={<DisplayBox
            key={division_group_office_index}
            label={data.name || '-'}
            title={'กอง / กลุ่ม / สำนักงาน'}
            type={'division_group_offices'}/>}
          // children={getStructureDepartments(division_group_office_index)}
        />;
      }
      return [];
    });
  };

  const getDepartmentOfficeStructureDepartments = (department_office_index: number) => {
    return organizationalStructure.structure_departments.map((data, structure_department_index) => {
      if (data.department_office_index === department_office_index && data.type === 'department_office') {
        return <TreeNode
          key={`TreeNodeDepartmentOfficeStructureDepartments${structure_department_index}`}
          label={<DisplayBox
            key={`${department_office_index}StructureDepartments${structure_department_index}`}
            label={data.name || '-'}
            title={'แผนก'}
            type={'structure_departments'}/>}
          // children={getStructurePositions(structure_department_index)}
        />;
      }
      return [];
    });
  };

  const getDirectorsStructureDepartments = (director_index: number) => {
    return organizationalStructure.structure_departments.map((data, structure_department_index) => {
      if (data.director_index === director_index && data.type === 'director') {
        return <TreeNode
          key={`TreeNodeDirectorsStructureDepartments${structure_department_index}`}
          label={<DisplayBox
            key={`${director_index}StructureDepartments${structure_department_index}`}
            label={data.name || '-'}
            title={'แผนก'}
            type={'structure_departments'}/>}
          // children={getStructurePositions(structure_department_index)}
        />;
      }
      return [];
    });
  };

  const getDeputyDirectors = () => {
    return organizationalStructure.deputy_directors.map((data, index) => {
      return <TreeNode
        key={`DeputyDirectors${index}`}
        label={<DisplayBox key={index} label={data.name || '-'} title={'รองผู้อำนวยการ'}
                           type={'deputy_directors'}/>}
        children={[...getDepartments(index), ...getDeputyDirectorsDivisionGroupOffices(index), ...getDeputyDirectorsStructureDepartments(index)]}/>;
    });
  };

  const getDeputyDirectorsDivisionGroupOffices = (deputy_director_index: number) => {
    return organizationalStructure.division_group_offices.map((data, division_group_office_index) => {
      if (data.deputy_director_index === deputy_director_index && data.type === 'deputy_director') {
        return <TreeNode
          key={division_group_office_index}
          label={<DisplayBox
            key={`${deputy_director_index}DivisionGroupOffices${division_group_office_index}`}
            label={data.name || '-'}
            title={'กอง / กลุ่ม / สำนักงาน'}
            type={'division_group_offices'}/>}
          // children={getStructureDepartments(division_group_office_index)}
        />;
      }
      return [];
    });
  };

  const getDeputyDirectorsStructureDepartments = (deputy_director_index: number) => {
    return organizationalStructure.structure_departments.map((data, structure_department_index) => {
      if (data.deputy_director_index === deputy_director_index && data.type === 'deputy_director') {
        return <TreeNode
          key={`TreeNodeDirectorsStructureDepartments${structure_department_index}`}
          label={<DisplayBox
            key={`${deputy_director_index}StructureDepartments${structure_department_index}`}
            label={data.name || '-'}
            title={'แผนก'}
            type={'structure_departments'}/>}
          // children={getStructurePositions(structure_department_index)}
        />;
      }
      return [];
    });
  };

  const getDepartments = (deputy_director_index: number) => {
    return organizationalStructure.department_offices
      .map((data, department_office_index) => {
        if (data.deputy_director_index === deputy_director_index && data.type === 'department') {
          return <TreeNode
            key={department_office_index}
            label={<DisplayBox key={`${deputy_director_index}Departments${department_office_index}`}
                               label={data.name || '-'}
                               title={'ฝ่าย'}
                               type={'department'}/>}
            children={getDivisionGroupOffices(department_office_index)}/>;
        }
        return [];
      });
  };

  const getOffices = (director_index: number) => {
    return organizationalStructure.department_offices
      .map((data, department_office_index) => {
        if (data.director_index === director_index && data.type === 'office') {
          return <TreeNode
            key={`${director_index}_office_${department_office_index}`}
            label={<DisplayBox key={`${director_index}_office_${department_office_index}`}
                               label={data.name || '-'}
                               title={'สำนัก'}
                               type={'office'}/>}
            children={[...getDivisionGroupOffices(department_office_index), ...getDepartmentOfficeStructureDepartments(department_office_index)]}/>;
        }
        return [];
      });
  };

  const getDivisionGroupOffices = (department_office_index: number) => {
    return organizationalStructure.division_group_offices
      .map((data, division_group_office_index) => {
        if (data.department_office_index === department_office_index) {
          return <TreeNode
            key={`${department_office_index}DivisionGroupOffices${division_group_office_index}`}
            label={<DisplayBox
              key={division_group_office_index}
              label={data.name || '-'}
              title={'กอง / กลุ่ม / สำนักงาน'}
              type={'division_group_offices'}/>}
            // children={getStructureDepartments(division_group_office_index)}
          />;
        }
        return [];
      });
  };

  // const getStructureDepartments = (division_group_office_index: number) => {
  //   return organizationalStructure.structure_departments
  //     .map((data, structure_department_index) => {
  //       if (data.division_group_office_index === division_group_office_index) {
  //         return <TreeNode
  //           key={`${division_group_office_index}StructureDepartments${structure_department_index}`}
  //           label={<DisplayBox
  //             key={structure_department_index}
  //             label={data.name || '-'}
  //             title={'แผนก'}
  //             type={'structure_departments'}/>}
  //           children={getStructurePositions(structure_department_index)}/>;
  //       }
  //       return [];
  //     });
  // };
  //
  // const getStructurePositions = (structure_department_index: number) => {
  //   return organizationalStructure.structure_positions
  //     .map((data, structure_position_index) => {
  //       if (data.structure_department_index === structure_department_index) {
  //         return <TreeNode
  //           key={`${structure_department_index}StructurePositions${structure_position_index}`}
  //           label={
  //             <DisplayBox
  //               key={structure_position_index}
  //               label={data.name || '-'}
  //               title={'ตำแหน่ง'}
  //               type={'structure_positions'}/>}
  //         />;
  //       }
  //       return [];
  //     });
  // };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap overflow-auto">
        {
          getDirectors()
        }
      </div>
    </>
  );
};

interface DisplayBoxProps {
  title: string | null;
  label: string | null;
  type: 'directors' |
    'deputy_directors' |
    'department' |
    'office' |
    'division_group_offices' |
    'structure_departments' |
    'structure_positions';
}

const classNameWithTitle = {
  directors: 'directors',
  deputy_directors: 'deputy-directors',
  department: 'department-offices',
  office: 'department-offices',
  division_group_offices: 'division-group-offices',
  structure_departments: 'structure-departments',
  structure_positions: 'structure-positions',
};

const DisplayBox: FC<DisplayBoxProps> = ({label, title, type}) => {
  return <div className={`px-3 py-3 text-white rounded-4 organizational-structure-shadow
        min-organizational-structure-width ${classNameWithTitle[type]} text-center`}>
    <b className="d-block">{title}</b>
    {label}
  </div>;
};

export default OrganizationChart;
import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {RegistrationPersonnelHistoryService} from "../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import { Link } from 'react-router-dom';
import {faFileExport} from '@fortawesome/free-solid-svg-icons';
import {
  fetchPositionNameOptions,
  fetchGraduationLevelOptions,
  fetchGenderOptions,
  fetchAgeOptions,
  fetchSalaryOptions,
  fetchInsigniaNameOptions,

  position_name_Options, 
  graduation_level_Options, 
  genderOptions, 
  ageOptions, 
  salaryOptions, 
  insignia_name_Options,
} from '../../OptionsDropDown';

import * as XLSX from 'xlsx';

const headers: Header[] = [
  {
    label: 'ชื่อ - สกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'การศึกษา',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เพศ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เงินเดือน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เครื่องราชย์',
    field: '',
    order: null,
    isOrder: false,
  }
];

const SearchPersonnelQualificationListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [dataExport, setDataExport] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    position_name: '',
    graduation_level: '',
    gender: '',
    age: '',
    salary: '',
    insignia_name: '',
    search: '',
    page: 1,
    page_size: 10,
  });
  
  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await RegistrationPersonnelHistoryService.getSearchPersonnelQualificationList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const fetchDataExport = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getSearchPersonnelQualificationList({page: 1,page_size: 100000,});
      setDataExport(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.position_name, 
    queryParams.graduation_level, 
    queryParams.gender, 
    queryParams.age, 
    queryParams.salary, 
    queryParams.insignia_name,
    queryParams.page_size, 
    queryParams.page, 
  ]);

  useEffect(() => {
    fetchPositionNameOptions();
    fetchGraduationLevelOptions();
    fetchGenderOptions();
    fetchAgeOptions();
    fetchSalaryOptions();
    fetchInsigniaNameOptions();

    fetchDataExport();
  }, []);

  const exportToExcel = () => {
    const DataForExcel = [];
    DataForExcel.push(headers.map(item => item.label));
    dataExport.rows?.map((data:any)=>{
      DataForExcel.push([
        (data.prefix_name_th ? data.prefix_name_th : '') + (data.full_name_th ? data.full_name_th : '-'),
        data.current_job_position?.position_name ? data.current_job_position?.position_name : '-',
        data.graduation_level ? data.graduation_level : '-',
        data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-',
        data.age ? data.age : '-',
        data.salary ? addCommas(data.salary) : '-',
        data.insignia_name ? data.insignia_name : '-',
      ])
    })

    const ws = XLSX.utils.aoa_to_sheet(DataForExcel);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'คุณสมบัติของบุคลากร.xlsx');
  };

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">ค้นหาคุณสมบัติของบุคลากร</h2>
      <Link to="#">
        <Button variant="primary" className="btn-action btn-mof-primary" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faFileExport} className="me-2"/>
          Export
        </Button>
      </Link>
    </div>

    {loaderComponent}

    <div className="row g-3">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              position_name: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">การศึกษา</label>
        <InputSelect value={queryParams.graduation_level} options={graduation_level_Options} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              graduation_level: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">เพศ</label>
        <InputSelect value={queryParams.gender} options={genderOptions} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              gender: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ช่วงอายุ</label>
        <InputSelect value={queryParams.age} options={ageOptions} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              age: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ช่วงเงินเดือน</label>
        <InputSelect value={queryParams.salary} options={salaryOptions} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              salary: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">เครื่องราชย์</label>
        <InputSelect value={queryParams.insignia_name} options={insignia_name_Options} placeholder="" name="is_active"
                        onChange={(e:any) => {
                          setQueryParams(prevState => {
                            return {
                              ...prevState,
                              insignia_name: e.value.value || ""
                            }
                          })
                        }} required={true}
                        isValidateError={false}/>
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
                placeholder="ค้นหาจากชื่อ..." name="search"
                onChange={(e) => {
                  setQueryParams(prevState => {
                    return {
                      ...prevState,
                      search: e.target.value || ""
                    }
                  })
                }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.prefix_name_th ? data.prefix_name_th : ''}{data.full_name_th ? data.full_name_th : '-'}</td>
                <td>{data.current_job_position?.position_name ? data.current_job_position?.position_name : '-'}</td>
                <td>{data.graduation_level ? data.graduation_level : '-'}</td>
                <td>{data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-'}</td>
                <td>{data.age ? data.age : '-'}</td>
                <td>{data.salary ? addCommas(data.salary) : '-'}</td>
                <td>{data.insignia_name ? data.insignia_name : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default SearchPersonnelQualificationListPage;
import apiService from '../../api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/report';

export class ManageUserService {
  static async getReportLoginList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/last_login`, {params: data});
    return response.data;
  }

  static async getReportForgetPasswordList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/last_forget`, {params: data});
    return response.data;
  }

  static async getReportEditEmployeeDetailList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/edit_user_list`, {params: data});
    return response.data;
  }

  static async getReportEditEmployeeDetailDetail(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/edit_user`, {params: data});
    return response.data;
  }

  static async getReportLoginLatestList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/last_login`, {params: data});
    return response.data;
  }
}
import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getEmployeeAction,
  updateEmployeeActionType
} from '../../../shared/services/employee-service';
import { notificationService } from '../../../shared/services/notification-service';
import { handleEmployeeSectionRemoveByIndex } from '../../../shared/utils/function-state';
import { faBriefcase, faCalendarPlus, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EmployeeTabMenuProps {
  employeeId?: string;
  isView: boolean;
  onSelectTap: (tapMenu: number) => void;
}

const EmployeeTabMenu: FC<EmployeeTabMenuProps> = ({employeeId, onSelectTap, isView}) => {
  const [detailMenu, setDetailMenu] = useState<boolean>(true);
  const [historyWorkMenu, setHistoryWorkMenu] = useState<boolean>(false);
  const [historyLeaveMenu, setHistoryLeaveMenu] = useState<boolean>(false);

  const pathList = useLocation().pathname.split('/');
  const currentPath = pathList[pathList.length - 1];
  const isMePath = pathList.includes('me');
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearJobPositionNullData = () => {
    const indexNullData = employee.job_positions.findIndex(data => data.id === null);
    if (indexNullData !== -1) {
      handleEmployeeSectionRemoveByIndex('job_positions', indexNullData, dispatch);
    }
  };

  const onClickDetailMenu = () => {
    if (isView) {
      if (employeeAction.type !== 'view-edit-request') {
        updateEmployeeActionType('view', dispatch);
        clearJobPositionNullData();
      }
    } else {
      updateEmployeeActionType('create', dispatch);
    }
    setDetailMenu(true);
    setHistoryWorkMenu(false);
    setHistoryLeaveMenu(false);
    onSelectTap(1);
  };
  const onClickHistoryWorkMenu = (link: string) => {
    if (employeeAction.type === 'edit') {
      notificationService.infoNotification('กรุณากดบันทึกก่อนเปลี่ยนหน้า', '');
      return;
    }

    if (isView) {
      updateEmployeeActionType('view', dispatch);
      clearJobPositionNullData();
    }

    setDetailMenu(false);
    setHistoryWorkMenu(true);
    setHistoryLeaveMenu(false);
    onSelectTap(2);
    navigate(link);
  };
  const onClickHistoryLeaveMenu = (link: string) => {
    if (employeeAction.type === 'edit') {
      notificationService.infoNotification('กรุณากดบันทึกก่อนเปลี่ยนหน้า', '');
      return;
    }

    if (isView) {
      if (employeeAction.type !== 'view-edit-request') {
        updateEmployeeActionType('view', dispatch);
        clearJobPositionNullData();
      }
    } else {
      updateEmployeeActionType('create', dispatch);
    }

    setDetailMenu(false);
    setHistoryWorkMenu(false);
    setHistoryLeaveMenu(true);
    onSelectTap(3);
    navigate(link);
  };

  const getLinkInformation = () => {
    if (isMePath) {
      return `/employees/me/information`;
    }
    if (employeeAction.type === 'view-edit-request') {
      return `/profile-edit-requests/${employeeId}/information`;
    }
    return `/employees/${employeeId}/information`;
  };

  const getLinkLeaveHistory = () => {
    if (isMePath) {
      return `/employees/me/leave-history`;
    }
    if (employeeAction.type === 'view-edit-request') {
      return `/profile-edit-requests/${employeeId}/leave-history`;
    }
    return `/employees/${employeeId}/leave-history`;
  };

  if (isView) {
    return (
      <div className="row">
        <div className="col-6 col-md-6">
          <Link to={getLinkInformation()}>
            <Button variant={currentPath === 'information' ? 'outline-info' : 'info'}
                    onClick={onClickDetailMenu}
                    className={`w-100 btn-action ${currentPath === 'information' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div className="d-none d-sm-block">
                ข้อมูลทั่วไป
              </div>
              <div className="d-block d-sm-none">
                <FontAwesomeIcon icon={faUserPen}/>
              </div>
            </Button>
          </Link>
        </div>
        <div className="col-6 col-md-6">
          <Button variant={currentPath === 'leave-history' ? 'outline-info' : 'info'}
                  onClick={() => onClickHistoryLeaveMenu(getLinkLeaveHistory())}
                  className={`w-100 btn-action ${currentPath === 'leave-history' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
            <div className="d-none d-sm-block">
              ประวัติการลา
            </div>
            <div className="d-block d-sm-none">
              <FontAwesomeIcon icon={faCalendarPlus}/>
            </div>
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div className="col-4 col-md-4">
          <Link to={`/employees/create/information`}>
            <Button variant={currentPath === 'information' ? 'outline-info' : 'info'}
                    onClick={onClickDetailMenu}
                    className={`w-100 btn-action ${currentPath === 'information' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div className="d-none d-sm-block">
                ข้อมูลทั่วไป
              </div>
              <div className="d-block d-sm-none">
                <FontAwesomeIcon icon={faUserPen}/>
              </div>
            </Button>
          </Link>
        </div>
        <div className="col-4 col-md-4">
          <Button variant={currentPath === 'work-history' ? 'outline-info' : 'info'}
                  onClick={() => onClickHistoryWorkMenu(`/employees/create/work-history`)}
                  className={`w-100 btn-action ${currentPath === 'work-history' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
            <div className="d-none d-sm-block">
              ประวัติการทำงาน
            </div>
            <div className="d-block d-sm-none">
              <FontAwesomeIcon icon={faBriefcase}/>
            </div>
          </Button>
        </div>
        <div className="col-4 col-md-4">
          <Button variant={currentPath === 'leave-history' ? 'outline-info' : 'info'}
                  onClick={() => onClickHistoryLeaveMenu(`/employees/create/leave-history`)}
                  className={`w-100 btn-action ${currentPath === 'leave-history' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
            <div className="d-none d-sm-block">
              ประวัติการลา
            </div>
            <div className="d-block d-sm-none">
              <FontAwesomeIcon icon={faCalendarPlus}/>
            </div>
          </Button>
        </div>
      </div>
    );
  }
};

export default EmployeeTabMenu;
import { EmployeeModel, JobPosition } from '../models/employee.model';
import { differenceInCalendarMonths, differenceInDays, differenceInYears } from 'date-fns';

export const getPositionData = (employee: EmployeeModel): JobPosition => {
    if (employee.job_positions === null || employee.job_positions?.length === 0 || !employee.job_positions) {
        return {
            id: null,
            employee_id: null,
            employee_type: null,
            position_level: null,
            position_name: null,
            position_type: null,
            start_date: null,
            end_date: null,
            reason: null,
            order_no: null,
            title: null,
            is_currently: false,
            new_file: true,
            file_name: '',
            file: null,
            from_id: null,
        };
    }
    return employee.job_positions![employee.job_positions.length - 1];
};

export const calculateAge = (birthdate: any) => {
    const today = new Date();
    const birthDate = new Date(birthdate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
};

export const calculateRetirement = (birthdate: any) => {
    const currentDate = new Date();
    const parsedRetirementDate = new Date(birthdate);

    parsedRetirementDate.setFullYear(parsedRetirementDate.getFullYear() + 60);
    if (parsedRetirementDate.getMonth() === 9 && parsedRetirementDate.getDate() >= 2) {
        parsedRetirementDate.setFullYear(parsedRetirementDate.getFullYear() + 1);
    }

    parsedRetirementDate.setMonth(8);
    parsedRetirementDate.setDate(30);

    const years = differenceInYears(parsedRetirementDate, currentDate);
    const months = differenceInCalendarMonths(parsedRetirementDate, currentDate) % 12;
    const days = differenceInDays(parsedRetirementDate, currentDate) % 30;

    return {years, months, days};
};
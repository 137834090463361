import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { ResetPasswordForm } from '../../../shared/models/authentication.model';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { notificationService } from '../../../shared/services/notification-service';
import { apiAuthentication } from '../../../shared/services/authentication-service';

const ResetPasswordFormPage: FC = () => {
    let {token} = useParams();
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState<ResetPasswordForm>({
        id: null,
        user_id: null,
        password: '',
        confirm_password: '',
    });
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [passwordValidator, setPasswordValidator] = useState({
        isConfirmValid: false,
        isLengthValid: false,
        isUppercaseValid: false,
        isLowercaseValid: false,
        isNumberValid: false,
        isSpecialCharValid: false,
    });
    const [hidePassword, setHidePassword] = useState(true);
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        validatePassword();
    }, [form.password, form.confirm_password]);

    useEffect(() => {
        verifyToken();
    }, []);

    const verifyToken = async () => {
        startLoading();
        try {
            const res = await apiAuthentication.onTokenResetPasswordVerify(token);
            setForm(prevState => ({
                ...prevState,
                id: res.id,
                user_id: res.created_by_employee.user.id
            }))
        } catch (error) {
            const err = error as any;
            if (err.response?.status === 400) {
                notificationService.dangerNotification('Token ไม่ถูกต้อง', err.response.data.message);
            } else {
                notificationService.dangerNotification('ระบบขัดข้อง', err.message);
            }
            navigate('/login');
        } finally {
            stopLoading();
        }
    }

    const validatePassword = () => {
        const regexPassword = /[!@#$%^&*()_+|~\-=`{}\[\]:";'<>?,./]/;
        // Validate the password against individual criteria
        setPasswordValidator(prevState => ({
            isConfirmValid: form.password === form.confirm_password,
            isLengthValid: form.password.length >= 8,
            isUppercaseValid: /[A-Z]/.test(form.password),
            isLowercaseValid: /[a-z]/.test(form.password),
            isNumberValid: /\d/.test(form.password),
            isSpecialCharValid: regexPassword.test(form.password),
        }));
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setForm(prevState => ({
            ...prevState,
            password: password
        }));
    };

    const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setForm(prevState => ({
            ...prevState,
            confirm_password: password
        }));
    };

    const onClickHidePassword = () => {
        setHidePassword(prevState => !prevState);
    };

    const onClickConfirmHidePassword = () => {
        setHideConfirmPassword(prevState => !prevState);
    };

    const onResetPassword = async () => {
        setSubmitted(true);
        if (
            !passwordValidator.isConfirmValid ||
            !passwordValidator.isLengthValid ||
            !passwordValidator.isUppercaseValid ||
            !passwordValidator.isLowercaseValid ||
            !passwordValidator.isNumberValid ||
            !passwordValidator.isSpecialCharValid
        ) {
            notificationService.warningNotification('กรุณากรอกรหัสผ่านตามเงื่อนไขที่ระบุ', '');
            return;
        }

        startLoading();
        try {
            const res = await apiAuthentication.onResetPasswordSubmit(token, form);
            setForm(prevState => ({
                ...prevState,
                id: res.id,
                user_id: res.created_by_employee.user.id
            }))
            notificationService.successNotification('เปลี่ยนรหัสผ่านเรียบร้อย', 'กรุณาลองเข้าระบบอีกครั้ง');
            navigate('/login')
        } catch (error) {
            const err = error as any;
            notificationService.dangerNotification('ระบบขัดข้อง', err.message);
        } finally {
            stopLoading();
        }
    };

    return <Form noValidate validated={submitted} className="mb-5">
        {loaderComponent}
        <div className="container pt-5">
            <div className="m-auto login-logo">
                <Image src="/static/ORTORKOR-LOGO.png" alt="logo" className="w-100"/>
            </div>
            <div className="bg-white shadow rounded px-sm-5 px-3 py-5 register-page m-auto mt-4">
                <h4 className="text-mof-primary">
                    แก้ไขรหัสผ่าน
                </h4>

                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">รหัสผ่าน</label>
                        <div className="input-group ">
                            <input type={hidePassword ? 'password' : 'text'} className="form-control" id="password"
                                   name="password"
                                   required onChange={handlePasswordChange}
                                   value={form.password}/>
                            <span className="input-group-text pointer" onClick={onClickHidePassword}>
                                <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye}/>
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="username">ยืนยันรหัสผ่าน</label>
                        <div className="input-group ">
                            <input type={hideConfirmPassword ? 'password' : 'text'} className="form-control"
                                   id="confirm_password"
                                   name="confirm_password"
                                   required onChange={handlePasswordConfirmChange}
                                   value={form.confirm_password}/>
                            <span className="input-group-text pointer" onClick={onClickConfirmHidePassword}>
                                <FontAwesomeIcon icon={hideConfirmPassword ? faEyeSlash : faEye}/>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-3">
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isConfirmValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">ยืนยันรหัสผ่านเหมือนกัน</label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isLengthValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">อักขระอย่างน้อย 8 ตัว</label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isUppercaseValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">ตัวอักษรพิมพ์ใหญ่ (A-Z)</label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isLowercaseValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">ตัวอักษรพิมพ์เล็ก (a-z)</label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isNumberValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">ตัวเลข (0-9)</label>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={passwordValidator.isSpecialCharValid ? faCircleCheck : faCircle}
                                             color={'#056310'}/>
                            <label className="ms-2">{`อักขระพิเศษ(!@#$ % ^ & * ()_+|~-=\`{}[]:";'<>?,./)`}</label>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm-12 col-md-4">
                            <Button variant="primary" type="button"
                                    onClick={onResetPassword}
                                    className="d-block w-100 btn-action-height btn-mof-primary">
                                ยืนยัน
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Form>;
};

export default ResetPasswordFormPage;
import React, {Fragment} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import NavbarLayout from '../modules/cores/layouts/navbar-layout';
import SidebarLayout from '../modules/cores/layouts/sidebar-layout';
import EmployeeDetailPage from '../modules/employees/employee-detail-page';
import EmployeeInformationPage from '../modules/employees/pages/employee-information-page';
import EmployeeLeaveHistoryPage from '../modules/employees/pages/employee-leave-history-page';
import DirectorPage from '../modules/organizational-structures/pages/director-page';
import DeputyDirectorPage from '../modules/organizational-structures/pages/deputy-director-page';
import DivisionGroupOfficePage from '../modules/organizational-structures/pages/division-group-office-page';
import StructureDepartmentPage from '../modules/organizational-structures/pages/structure-department-page';
import StructurePositionPage from '../modules/organizational-structures/pages/structure-position-page';
import PersonnelPage from '../modules/organizational-structures/pages/personnel-page';
import SummaryPage from '../modules/organizational-structures/pages/summary-page';
import EmployeeWritePage from '../modules/employees/employee-write-page';
import OrganizationalStructuresListPage from '../modules/organizational-structures/organizational-structures-list-page';
import OrganizationalStructuresWritePage
  from '../modules/organizational-structures/organizational-structures-write-page';
import OrganizationalStructuresDetailPage
  from '../modules/organizational-structures/organizational-structures-detail-page';
import {useAuth} from '../shared/authentications/auth-context';
import EmployeeListPage from '../modules/employees/employee-list-page';
import {RolePermissions} from './role-permissions';
import PageNotFound from '../modules/cores/page-not-found/page-not-found';
import OfficeDepartmentPage from '../modules/organizational-structures/pages/office-department-page';
import RegisterPage from '../modules/cores/authentications/register-page';
import EmployeeRegistrationsPage from '../modules/employee-registrations/employee-registrations-page';
import AllUsersPage from '../modules/employee-registrations/pages/all-users-page';
import RegisterUserRequestsPage from '../modules/employee-registrations/pages/register-user-requests-page';
import ResetPasswordRequestsPage from '../modules/employee-registrations/pages/reset-password-requests-page';
import ForgetPasswordPage from '../modules/cores/authentications/forget-password-page';
import ResetPasswordFormPage from '../modules/cores/authentications/reset-password-form-page';
import EditProfileRequestsPage from '../modules/employee-registrations/pages/edit-profile-requests-page';
import EmployeeEditProfilePage from '../modules/employees/employee-edit-profile-page';
import PermissionMePage from '../modules/permissions/permission-me-page';
import PermissionListPage from '../modules/permissions/permission-list-page';
import HomePage from '../modules/cores/homes/home-page';
import ResetPasswordPage from '../modules/cores/homes/reset-password-page';
import LoginPage from '../modules/cores/authentications/login-page';
import EmployeeLeaveRequest from "../modules/employee-leaves/employee-leave-request-page";
import EmployeeLeaveHistoryPages from "../modules/employee-leaves/employee-leave-history-pages";
import ApproverLeaveRequestListPages from "../modules/employee-leaves/approver-leave-request-list-pages";
import ApproverLeaveRequestDetailPage from "../modules/employee-leaves/pages/approver-leave-request-detail-page";
import EmployeeShiftListPage from "../modules/employee-shifts/employee-shift-list-page";
import ShiftCreatePage from "../modules/employee-shifts/employee-shift-create-page";
import EmployeeShiftApproverPage from "../modules/employee-shifts/employee-shift-approver-page";
import EmployeeShiftPersonelPage from "../modules/employee-shifts/employee-shift-personel-page";
import EmployeeCheckInsListPage from "../modules/employee-check-ins/list-page";
import EmployeeCheckInsCheckInPage from "../modules/employee-check-ins/check-in-page";
import EmployeeCheckInsStatusPage from "../modules/employee-check-ins/status-page";
import EmployeeCheckInsManageCheckInPage from "../modules/employee-check-ins/manage-check-in";
import EmployeeCheckInsFormReqPage from "../modules/employee-check-ins/form-req-page";
import EmployeeShiftPersonelListPage from "../modules/employee-shifts/employee-shift-personel-list-page";
import EmployeeShiftEditPage from "../modules/employee-shifts/employee-shift-edit-page";

import ReportLoginListPage from '../modules/Report/ManageUser/ReportLogin/report-login-list-page';
import ReportForgetPasswordListPage from '../modules/Report/ManageUser/ReportForgetPassword/report-forget-password-list-page';
import ReportEditEmployeeDetailListPage from '../modules/Report/ManageUser/ReportEditEmployeeDetail/report-edit-employee-detail-list-page';
import ReportEditEmployeeDetailDetailPage from '../modules/Report/ManageUser/ReportEditEmployeeDetail/report-edit-employee-detail-detail-page';
import ReportLoginLatestListPage from '../modules/Report/ManageUser/ReportLoginLatest/report-login-latest-list-page';

import ReportPersonnelHistoryListPage from '../modules/Report/RegistrationPersonnelHistory/ReportPersonnelHistory/report-personnel-history-list-page';
import ReportPersonnelHistoryDetailPage from '../modules/Report/RegistrationPersonnelHistory/ReportPersonnelHistory/report-personnel-history-detail-page';
import SearchPersonnelQualificationListPage from '../modules/Report/RegistrationPersonnelHistory/SearchPersonnelQualification/search-personnel-qualification-list-page';
import ReportPersonnelRetireListPage from '../modules/Report/RegistrationPersonnelHistory/ReportPersonnelRetire/report-personnel-retire-list-page';
import ReportPersonnelJobVacancyListPage from '../modules/Report/RegistrationPersonnelHistory/ReportPersonnelJobVacancy/report-personnel-job-vacancy-list-page';

import ReportInOutLeaveListPage from '../modules/Report/ReportInOutLeave/ReportInOutLeave/report-in-out-leave-list-page';
import ReportInOutLeaveDetailPage from '../modules/Report/ReportInOutLeave/ReportInOutLeave/report-in-out-leave-detail-page';
import ReportNotScanInOutListPage from '../modules/Report/ReportInOutLeave/ReportNotScanInOut/report-not-scan-in-out-list-page';
import ReportPersonnelWorkEachShiftListPage from '../modules/Report/ReportInOutLeave/ReportPersonnelWorkEachShift/report-personnel-work-each-shift-list-page';
import ReportChangeShiftListPage from '../modules/Report/ReportInOutLeave/ReportChangeShift/report-change-shift-list-page';
import ReportAnnualVacationListPage from '../modules/Report/ReportInOutLeave/ReportAnnualVacation/report-annual-vacation-list-page';

import ReportLeaveEachTypeListPage from '../modules/Report/LeaveOnline/ReportLeaveEachType/report-leave-each-type-list-page';
import ReportSummaryInOutWorkListPage from '../modules/Report/LeaveOnline/ReportSummaryInOutWork/report-summary-in-out-work-list-page';

import SystemLogListPage from "../modules/system-logs/system-log-list-pages";
import LeaveSettingPage from "../modules/employee-leaves/leave-setting-pages";
import LeaveSettingSections from "../modules/employee-leaves/pages/leave-setting-sections-page";
import LeaveSettingCreatePage from "../modules/employee-leaves/pages/leave-setting-create-page";
import LeaveSettingViewPage from "../modules/employee-leaves/pages/leave-setting-view-page";
import NormalUsersPage from "../modules/employee-registrations/pages/normal-users-page";
import RetireUsersPage from "../modules/employee-registrations/pages/retire-users-page";
import ResignUsersPage from "../modules/employee-registrations/pages/resign-users-page";
import FiredUsersPage from "../modules/employee-registrations/pages/fired-users-page";
import ReportPersonnelWorkEachShiftDetailPage
  from "../modules/Report/ReportInOutLeave/ReportPersonnelWorkEachShift/report-personnel-work-each-shift-detail-page";

const Sidebar = () => (
  <div className="col-sm-12 col-md-3 col-xl-2 d-none d-md-block">
    <SidebarLayout/>
  </div>
);

const EmployeeSubRoutes = (
  <>
    <Route path="information" element={<EmployeeInformationPage/>}/>
    {/*<Route path="work-history" element={<EmployeeWorkHistoryPage/>}/>*/}
    <Route path="leave-history" element={<EmployeeLeaveHistoryPage/>}/>
  </>
);

const OrgStructureSubRoutes = (
  <>
    <Route path="director" element={<DirectorPage/>}/>
    <Route path="deputy-director" element={<DeputyDirectorPage/>}/>
    <Route path="office-department" element={<OfficeDepartmentPage/>}/>
    <Route path="division-group-office" element={<DivisionGroupOfficePage/>}/>
    <Route path="structure-department" element={<StructureDepartmentPage/>}/>
    <Route path="structure-position" element={<StructurePositionPage/>}/>
    <Route path="personnel" element={<PersonnelPage/>}/>
    <Route path="summary" element={<SummaryPage/>}/>
  </>
);

const MainRoutes = () => (
  <div className="col-sm-12 col-md-9 col-xl-10">
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/reset-password" element={<ResetPasswordPage/>}/>
      <Route path="/employees/me" element={<EmployeeDetailPage/>}>
        {EmployeeSubRoutes}
      </Route>
      <Route element={<RolePermissions modules={['employees']} moduleType={'read'}
                                       redirectPath="/"/>}>
        <Route path="/employees" element={<EmployeeListPage/>}/>
        <Route path="/employees/:id" element={<EmployeeDetailPage/>}>
          {EmployeeSubRoutes}
        </Route>
        <Route path="/employees/create" element={<EmployeeWritePage/>}>
          {EmployeeSubRoutes}
        </Route>
      </Route>
      <Route
        element={<RolePermissions modules={['organizational_structures']} moduleType={'read'}
                                  redirectPath="/"/>}>
        <Route path="/organizational-structures" element={<OrganizationalStructuresListPage/>}/>
        <Route path="/organizational-structures/create" element={<OrganizationalStructuresWritePage/>}>
          {OrgStructureSubRoutes}
        </Route>
        <Route path="/organizational-structures/:id" element={<OrganizationalStructuresDetailPage/>}>
          {OrgStructureSubRoutes}
        </Route>
      </Route>
      <Route element={<RolePermissions
        modules={['employee_actives', 'employee_register_requests', 'employee_reset_password_requests', 'employee_edit_requests']}
        moduleType={'read'} redirectPath="/"/>}>
        <Route path="/employee-registrations" element={<EmployeeRegistrationsPage/>}>
          <Route path="all-users" element={<AllUsersPage/>}/>
          <Route path="register-user-requests" element={<RegisterUserRequestsPage/>}/>
          <Route path="reset-password-requests" element={<ResetPasswordRequestsPage/>}/>
          <Route path="profile-edit-requests" element={<EditProfileRequestsPage/>}/>
          <Route path="normal-users" element={<NormalUsersPage/>}/>
          <Route path="retire-users" element={<RetireUsersPage/>}/>
          <Route path="resign-users" element={<ResignUsersPage/>}/>
          <Route path="fired-users" element={<FiredUsersPage/>}/>
        </Route>
        <Route path="profile-edit-requests/:id" element={<EmployeeEditProfilePage/>}>
          {EmployeeSubRoutes}
        </Route>
      </Route>
		<Route path="/permissions/me" element={<PermissionMePage/>}/>
		<Route element={<RolePermissions
			modules={['permission_approve']}
			moduleType={'read'} redirectPath="/"/>}>
			<Route path="/permissions/" element={<PermissionListPage/>}/>
		</Route>
      <Route path="/employee-leaves">
        <Route path="request" element={<EmployeeLeaveRequest/>}/>
        <Route path="leave-history" element={<EmployeeLeaveHistoryPages/>}/>
        <Route path="approver-request-list" element={<ApproverLeaveRequestListPages/>}/>
        <Route path="approver-request-list/:id/details" element={<ApproverLeaveRequestDetailPage/>}/>
        <Route path="leave-setting" element={<LeaveSettingPage/>}/>
        <Route path="leave-setting/create" element={<LeaveSettingCreatePage/>}/>
        <Route path="leave-setting/:id" element={<LeaveSettingViewPage/>}/>
      </Route>
      <Route path="/employee-check-ins">
        <Route path="list" element={<EmployeeCheckInsListPage/>}/>
        <Route path="check-in" element={<EmployeeCheckInsCheckInPage/>}/>
        <Route path="status" element={<EmployeeCheckInsStatusPage/>}/>
        <Route path="manage-check-in" element={<EmployeeCheckInsManageCheckInPage/>}/>
        <Route path="manage-check-in/create" element={<EmployeeCheckInsFormReqPage/>}/>
        <Route path="manage-check-in/edit/:id" element={<EmployeeCheckInsFormReqPage/>}/>
      </Route>
      <Route path="/employee-shift">
        <Route path="create" element={<ShiftCreatePage/>}/>
        <Route path="edit/:id" element={<EmployeeShiftEditPage/>}/>
        <Route path="approver/:id" element={<EmployeeShiftApproverPage/>}/>
        <Route path="employee-shift-list" element={<EmployeeShiftListPage/>}/>
        <Route path="employee-shift-personel-list" element={<EmployeeShiftPersonelListPage/>}/>
        <Route path="employee-shift-personel" element={<EmployeeShiftPersonelPage/>}/>
      </Route>
      <Route path="*" element={<PageNotFound/>}/>

      <Route path="/system-logs">
        <Route path="list" element={<SystemLogListPage/>}/>
      </Route>

      <Route path="/report">
        <Route path="manage-user">
          <Route path="report-login" element={<ReportLoginListPage/>}/>
          <Route path="report-forget-password" element={<ReportForgetPasswordListPage/>}/>
          <Route path="report-edit-employee-detail" element={<ReportEditEmployeeDetailListPage/>}/>
          <Route path="report-edit-employee-detail/:employee_id/details" element={<ReportEditEmployeeDetailDetailPage/>}/>
          <Route path="report-login-latest" element={<ReportLoginLatestListPage/>}/>
        </Route>
        <Route path="registration-personnel-history">
          <Route path="report-personnel-history" element={<ReportPersonnelHistoryListPage/>}/>
          <Route path="report-personnel-history/:id/details" element={<ReportPersonnelHistoryDetailPage/>}/>
          <Route path="search-personnel-qualification" element={<SearchPersonnelQualificationListPage/>}/>
          <Route path="report-personnel-retire" element={<ReportPersonnelRetireListPage/>}/>
          <Route path="report-personnel-job-vacancy" element={<ReportPersonnelJobVacancyListPage/>}/>
        </Route>
        <Route path="report-in-out-leave">
          <Route path="report-in-out-leave" element={<ReportInOutLeaveListPage/>}/>
          <Route path="report-in-out-leave/:day/:month/:year/details" element={<ReportInOutLeaveDetailPage/>}/>
          <Route path="report-not-scan-in-out" element={<ReportNotScanInOutListPage/>}/>
          <Route path="report-personnel-work-each-shift" element={<ReportPersonnelWorkEachShiftListPage/>}/>
          <Route path="report-personnel-work-each-shift/:id/details" element={<ReportPersonnelWorkEachShiftDetailPage/>}/>
          <Route path="report-change-shift" element={<ReportChangeShiftListPage/>}/>
          <Route path="report-annual-vacation" element={<ReportAnnualVacationListPage/>}/>
        </Route>
        <Route path="leave-online">
          <Route path="report-leave-each-type" element={<ReportLeaveEachTypeListPage/>}/>
          <Route path="report-summary-in-out-work" element={<ReportSummaryInOutWorkListPage/>}/>
        </Route>  
      </Route>
    </Routes>
  </div>
);

const Router = () => {
  const {isAuthenticated} = useAuth();

  return (
    <>
      {
        isAuthenticated ? (
          <NavbarLayout>
            <div className="row">
              <Sidebar/>
              <MainRoutes/>
            </div>
          </NavbarLayout>
        ) : (
          <Routes>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/register" element={<RegisterPage/>}/>
            <Route path="/forget-password" element={<ForgetPasswordPage/>}/>
            <Route path="/reset-password/:token" element={<ResetPasswordFormPage/>}/>
            <Route path="*" element={<Navigate to="/login" replace/>}/>
          </Routes>
        )
      }
    </>
  );
};

export default Router;

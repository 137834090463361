import apiService from '../../api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/report';

export class RegistrationPersonnelHistoryService {
  static async getReportPersonnelHistoryList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/list`, {params: data});
    return response.data;
  }

  static async getReportPersonnelHistoryDetail(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/get_user`, {params: data});
    return response.data;
  }

  static async getSearchPersonnelQualificationList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/list`, {params: data});
    return response.data;
  }

  static async getReportPersonnelRetireList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/list`, {params: data});
    return response.data;
  }

  static async getReportPersonnelJobVacancyList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/list_structure`, {params: data});
    return response.data;
  }
}
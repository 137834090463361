import { AppDispatch, RootState } from '../states/store';
import {
    DepartmentOfficeModel,
    DeputyDirectorModel,
    DirectorModel,
    DivisionGroupOfficeModel,
    OrganizationalStructureActionPage,
    OrganizationalStructureActionStatus,
    OrganizationalStructureActionType,
    OrganizationalStructureModel,
    OrganizationalStructureModelReform,
    PersonnelModel,
    StructureDepartmentModel,
    StructurePositionModel
} from '../models/organizational-structure.model';
import {
    addNewDepartmentOfficeField,
    addNewDeputyDirectorField,
    addNewDirectorField,
    addNewDivisionGroupOfficeField,
    addNewPersonnelField,
    addNewStructureDepartmentField,
    addNewStructurePositionField,
    deleteDepartmentOfficeField,
    deleteDeputyDirectorField,
    deleteDirectorField,
    deleteDivisionGroupOfficeField,
    deletePersonnelField,
    deleteStructureDepartmentField,
    deleteStructurePositionField,
    DepartmentOfficeFieldAction,
    DivisionGroupOfficeFieldAction,
    NewDepartmentOfficeFieldAction,
    NewDivisionGroupOfficeFieldAction,
    NewPersonnelFieldAction,
    NewStructureDepartmentFieldAction,
    NewStructurePositionFieldAction,
    PersonnelFieldAction,
    setOrganizationalStructure,
    setOrganizationalStructureId,
    StructureDepartmentFieldAction,
    StructurePositionFieldAction,
    updateDepartmentOfficeField,
    updateDeputyDirectorField,
    updateDirectorField,
    updateDivisionGroupOfficeField,
    updateOrganizationalStructureRootField,
    updatePersonnelField,
    updateStructureDepartmentField,
    updateStructurePositionField
} from '../states/organizational-structure-reducer';
import { fixValue } from '../utils/function-state';
import React from 'react';
import { handleNumericInput } from '../utils/function-validators';
import {
    afterSave,
    changePage,
    changeStatus,
    changeSubmitted,
    changeType
} from '../states/organizational-structure-action-reducer';
import { PaginationQueryParam } from '../models/common.model';
import apiService from './api-service';

const ORGANIZATIONAL_STRUCTURE_BASE_API = process.env.REACT_APP_API_URI + '/organizational-structures';

export const apiOrganizationalStructureService = {
    getOrganizationalStructure,
    createOrganizationalStructure,
    createOrganizationalStructureNest,
    getOrganizationalStructureDetail,
    validateOrganizationalStructure,
    deleteOrganizationalStructure,
    duplicateOrganizationalStructure,
    publishOrganizationalStructure,
    rejectOrganizationalStructure,
    approveOrganizationalStructure,
    terminateOrganizationalStructure,
    updateOrganizationalStructure,
};

async function getOrganizationalStructure(params: PaginationQueryParam) {
    return await apiService().get(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/`, {params});
}

async function createOrganizationalStructure(data: any) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/`, data);
}

async function updateOrganizationalStructure(id: any, data: any) {
    return await apiService().patch(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/`, data);
}

async function createOrganizationalStructureNest(id: number, data: OrganizationalStructureModelReform) {
    return await apiService().patch(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/write-nest/`, data);
}

async function getOrganizationalStructureDetail(id: number) {
    return await apiService().get(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/`);
}

async function validateOrganizationalStructure(data: OrganizationalStructureModelReform) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/validate-data/`, data);
}

async function deleteOrganizationalStructure(id: number) {
    return await apiService().delete(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/`);
}

async function duplicateOrganizationalStructure(id: number) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/duplicate/`);
}

async function publishOrganizationalStructure(id: number) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/publish/`);
}

async function rejectOrganizationalStructure(id: number, reason: string) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/reject/`, {reason: reason});
}

async function approveOrganizationalStructure(id: number) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/approve/`);
}

async function terminateOrganizationalStructure(id: number, reason: string) {
    return await apiService().post(`${ORGANIZATIONAL_STRUCTURE_BASE_API}/${id}/terminate/`, {reason: reason});
}

// State
export const getCurrentOrganizationalStructureAction = (state: RootState) => state.organizational_structure_action;

export const updateOrganizationalStructurePage = (page: OrganizationalStructureActionPage, dispatch: AppDispatch) => {
    dispatch(changePage(page));
};

export const updateOrganizationalStructureType = (type: OrganizationalStructureActionType, dispatch: AppDispatch) => {
    dispatch(changeType(type));
};

export const updateOrganizationalStructureStatus = (status: OrganizationalStructureActionStatus, dispatch: AppDispatch) => {
    dispatch(changeStatus(status));
};

export const organizationalStructureAfterSave = (save: boolean, dispatch: AppDispatch) => {
    dispatch(afterSave(save));
};

export const updateOrganizationalStructureActionSubmitted = (isSubmitted: boolean, dispatch: AppDispatch) => {
    dispatch(changeSubmitted(isSubmitted));
};


export const getCurrentOrganizationalStructure = (state: RootState): OrganizationalStructureModel => state.organizational_structure;

export const updateOrganizationalStructureState = (organizationalStructure: OrganizationalStructureModel, dispatch: AppDispatch) => {
    dispatch(setOrganizationalStructure(organizationalStructure));
};

// Function update data
export const handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>, dispatch: AppDispatch, fn: Function) => {
    handleNumericInput(event);
    fn(event, dispatch);
};

export const setOrganizationalStructureIdRoot = (id: number, dispatch: AppDispatch) => {
    dispatch(setOrganizationalStructureId(id));
};

export const updateOrganizationalStructureRoot = (event: React.ChangeEvent<HTMLInputElement> | fixValue, dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateOrganizationalStructureRootField({field: name as keyof OrganizationalStructureModel, value}));
};

export const updateDirector = (index: number, event: React.ChangeEvent<HTMLInputElement> | fixValue, dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateDirectorField({index: index, field: name as keyof DirectorModel, value}));
};

export const updateDeputyDirector = (index: number, event: React.ChangeEvent<HTMLInputElement> | fixValue, dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateDeputyDirectorField({index: index, field: name as keyof DeputyDirectorModel, value}));
};

export const updateDepartmentOffice = (index: number,
                                       event: React.ChangeEvent<HTMLInputElement> | fixValue,
                                       dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateDepartmentOfficeField({
        index: index,
        field: name as keyof DepartmentOfficeModel,
        value
    }));
};

export const updateDivisionGroupOffice = (index: number,
                                          event: React.ChangeEvent<HTMLInputElement> | fixValue,
                                          dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateDivisionGroupOfficeField({
        index: index,
        field: name as keyof DivisionGroupOfficeModel,
        value
    }));
};

export const updateStructureDepartment = (index: number,
                                          event: React.ChangeEvent<HTMLInputElement> | fixValue,
                                          dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateStructureDepartmentField({
        index: index,
        field: name as keyof StructureDepartmentModel,
        value
    }));
};

export const updateStructurePosition = (index: number,
                                        event: React.ChangeEvent<HTMLInputElement> | fixValue,
                                        dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updateStructurePositionField({
        index: index,
        field: name as keyof StructurePositionModel,
        value
    }));
};

export const updatePersonnel = (index: number,
                                event: React.ChangeEvent<HTMLInputElement> | fixValue,
                                dispatch: AppDispatch) => {
    const {name, value} = event.target;
    dispatch(updatePersonnelField({
        index: index,
        field: name as keyof PersonnelModel,
        value
    }));
};

// Function add new data

export const addNewDirector = (data: DirectorModel, dispatch: AppDispatch) => {
    dispatch(addNewDirectorField(data));
};

export const addNewDeputyDirector = (data: DeputyDirectorModel, dispatch: AppDispatch) => {
    dispatch(addNewDeputyDirectorField(data));
};

export const addNewDepartmentOffice = (data: NewDepartmentOfficeFieldAction, dispatch: AppDispatch) => {
    dispatch(addNewDepartmentOfficeField(data));
};

export const addNewDivisionGroupOffice = (data: NewDivisionGroupOfficeFieldAction, dispatch: AppDispatch) => {
    dispatch(addNewDivisionGroupOfficeField(data));
};

export const addNewStructureDepartment = (data: NewStructureDepartmentFieldAction, dispatch: AppDispatch) => {
    dispatch(addNewStructureDepartmentField(data));
};

export const addNewStructurePosition = (data: NewStructurePositionFieldAction, dispatch: AppDispatch) => {
    dispatch(addNewStructurePositionField(data));
};

export const addNewPersonnel = (data: NewPersonnelFieldAction, dispatch: AppDispatch) => {
    dispatch(addNewPersonnelField(data));
};

// Function delete data

export const deleteDirector = (index: number, dispatch: AppDispatch) => {
    dispatch(deleteDirectorField(index));
};

export const deleteDeputyDirector = (index: number, dispatch: AppDispatch) => {
    dispatch(deleteDeputyDirectorField(index));
};

export const deleteDepartmentOffice = (data: DepartmentOfficeFieldAction, dispatch: AppDispatch) => {
    dispatch(deleteDepartmentOfficeField(data));
};

export const deleteDivisionGroupOffice = (data: DivisionGroupOfficeFieldAction, dispatch: AppDispatch) => {
    dispatch(deleteDivisionGroupOfficeField(data));
};

export const deleteStructureDepartment = (data: StructureDepartmentFieldAction, dispatch: AppDispatch) => {
    dispatch(deleteStructureDepartmentField(data));
};

export const deleteStructurePosition = (data: StructurePositionFieldAction, dispatch: AppDispatch) => {
    dispatch(deleteStructurePositionField(data));
};

export const deletePersonnel = (data: PersonnelFieldAction, dispatch: AppDispatch) => {
    dispatch(deletePersonnelField(data));
};
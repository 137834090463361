import { SelectDropdown } from '../models/common.model';
import { AppDispatch } from '../states/store';
import {
    DepartmentOfficeModel,
    DivisionGroupOfficeModel,
    PersonnelModel,
    StructureDepartmentModel,
    StructurePositionModel
} from '../models/organizational-structure.model';
import {
    updateDepartmentOfficeField,
    updateDivisionGroupOfficeField,
    updatePersonnelField,
    updateStructureDepartmentField,
    updateStructurePositionField
} from '../states/organizational-structure-reducer';

export const handleDepartmentOfficeInputSelectChange = (index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof DepartmentOfficeModel) => {
    if (select.value) {
        const name = select.name;
        const value = select.value!.value;
        dispatch(updateDepartmentOfficeField({index: index, field: name as keyof DepartmentOfficeModel, value}));
    } else {
        if (name) {
            const value = null;
            dispatch(updateDepartmentOfficeField({index: index, field: name as keyof DepartmentOfficeModel, value}));
        }
    }
};

export const handleDivisionGroupOfficeInputSelectChange = (index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof DivisionGroupOfficeModel) => {
    if (select.value) {
        const name = select.name;
        const value = select.value!.value;
        dispatch(updateDivisionGroupOfficeField({index: index, field: name as keyof DivisionGroupOfficeModel, value}));
    } else {
        if (name) {
            const value = null;
            dispatch(updateDivisionGroupOfficeField({
                index: index,
                field: name as keyof DivisionGroupOfficeModel,
                value
            }));
        }
    }
};

export const handleStructureDepartmentInputSelectChange = (index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof StructureDepartmentModel) => {
    if (select.value) {
        const name = select.name;
        const value = select.value!.value;
        dispatch(updateStructureDepartmentField({index: index, field: name as keyof StructureDepartmentModel, value}));
    } else {
        if (name) {
            const value = null;
            dispatch(updateStructureDepartmentField({
                index: index,
                field: name as keyof StructureDepartmentModel,
                value
            }));
        }
    }
};

export const handleStructurePositionInputSelectChange = (index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof StructurePositionModel) => {
    if (select.value) {
        const name = select.name;
        const value = select.value!.value;
        dispatch(updateStructurePositionField({index: index, field: name as keyof StructurePositionModel, value}));
    } else {
        if (name) {
            const value = null;
            dispatch(updateStructurePositionField({
                index: index,
                field: name as keyof StructurePositionModel,
                value
            }));
        }
    }
};


export const handlePersonnelInputSelectChange = (index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof PersonnelModel) => {
    if (select.value) {
        const name = select.name;
        const value = select.value!.value;
        dispatch(updatePersonnelField({index: index, field: name as keyof PersonnelModel, value}));
    } else {
        if (name) {
            const value = null;
            dispatch(updatePersonnelField({
                index: index,
                field: name as keyof PersonnelModel,
                value
            }));
        }
    }
};

export const getFileExtension = (file_type: 'excel' | 'csv' | 'pdf') => {
    if (file_type === 'excel')
        return 'xlsx';
    if (file_type === 'csv')
        return 'csv';
    if (file_type === 'pdf')
        return 'pdf';
};

export const getFileApplication = (file_type: 'excel' | 'csv' | 'pdf') => {
    if (file_type === 'excel')
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (file_type === 'csv')
        return 'text/csv;charset=utf-8';
    if (file_type === 'pdf')
        return 'application/pdf';
};
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    apiOrganizationalStructureService,
    getCurrentOrganizationalStructure,
    getCurrentOrganizationalStructureAction,
    organizationalStructureAfterSave,
    setOrganizationalStructureIdRoot,
    updateOrganizationalStructureActionSubmitted,
    updateOrganizationalStructurePage,
    updateOrganizationalStructureStatus,
    updateOrganizationalStructureType
} from '../../../shared/services/organizational-structure-service';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { notificationService } from '../../../shared/services/notification-service';
import { AxiosError } from 'axios/index';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import OrganizationChart from '../components/organization-chart';
import { reformOrganizationalStructureData } from '../functions/convert-data';
import { getMessageContents } from '../../../shared/utils/error-messages';
import { OrganizationalStructureModelReform } from '../../../shared/models/organizational-structure.model';
import SummaryFilters from '../components/summary-filters';
import { useAuth } from '../../../shared/authentications/auth-context';

const SummaryPage: FC = () => {
    let {id} = useParams();
    const dispatch = useDispatch();
    const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
    const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const navigate = useNavigate();
    const {authJwt} = useAuth();
    const owner = organizationalStructure.created_by === authJwt?.employee_id;

    useEffect(() => {
        updateOrganizationalStructurePage('summary', dispatch);

    }, [organizationalStructureAction.page]);

    useEffect(() => {
        if (organizationalStructure.id === null && organizationalStructureAction.type === 'create') {
            onCreate().then();
        } else if (organizationalStructureAction.type === 'edit') {
            onSave().then();
        }
    }, []);

    const onValidateOrganizationalStructure = async (organizationalStructure: OrganizationalStructureModelReform) => {
        try {
            startLoading();
            const result = await apiOrganizationalStructureService.validateOrganizationalStructure(organizationalStructure);
            if (result.status === 200) {
                return true;
            }
        } catch (error: any) {
            if (error.response?.status === 400) {
                const messageList: any[] = error.response.data.details;
                const messageText = Array.from(
                    new Set(
                        messageList.map(data => {
                            return data.message;
                        })
                    )
                );
                notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', getMessageContents(messageText));
                handleBackButton();
            } else {
                notificationService.dangerNotification(
                    'เกิดข้อผิดพลาด',
                    'ไม่สามารถติดต่อกับเซิร์ฟเวอร์ได้'
                );
            }
            return false;
        } finally {
            stopLoading();
        }
    };

    const onCreate = async () => {
        updateOrganizationalStructureActionSubmitted(true, dispatch);
        const organizationalStructureReform = reformOrganizationalStructureData(organizationalStructure, null);
        const isValid = await onValidateOrganizationalStructure(organizationalStructureReform);
        if (isValid) {

            try {
                startLoading();
                const result = await apiOrganizationalStructureService.createOrganizationalStructure(organizationalStructure);
                setOrganizationalStructureIdRoot(result.data.id, dispatch);
                organizationalStructureReform.id = result.data.id;
                if (result.status === 200) {
                    // TODO Note
                    organizationalStructureAfterSave(true, dispatch);
                    updateOrganizationalStructureStatus('after_process', dispatch);

                    await writeNest(result.data.id, true);
                }
            } catch (error) {
                const err = error as AxiosError;
                if (err.response?.status === 400) {
                    notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
                } else {
                    notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', err.message);
                }
            } finally {
                stopLoading();
            }
        }
    };

    const onSave = async () => {
        updateOrganizationalStructureActionSubmitted(true, dispatch);
        const organizationalStructureReform = reformOrganizationalStructureData(organizationalStructure, null);
        const isValid = await onValidateOrganizationalStructure(organizationalStructureReform);
        if (isValid) {
            try {
                startLoading();
                organizationalStructureAfterSave(true, dispatch);
                updateOrganizationalStructureStatus('after_process', dispatch);

                await writeNest(organizationalStructure.id as number);
            } catch (error) {
                const err = error as AxiosError;
                if (err.response?.status === 400) {
                    notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
                } else {
                    notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
                }
            } finally {
                stopLoading();
            }
        }
    };

    const writeNest = async (id: number, fromCreated: boolean = false) => {
        const organizationalStructureReform = reformOrganizationalStructureData(organizationalStructure, id);
        try {
            startLoading();
            const result = await apiOrganizationalStructureService.createOrganizationalStructureNest(id, organizationalStructureReform);
            if (result.status === 200) {
                notificationService.successNotification('บันทึกข้อมูลสำเร็จ', '');
                if (fromCreated) {
                    updateOrganizationalStructureType('view', dispatch);
                    updateOrganizationalStructureStatus('view', dispatch);
                    navigate(`/organizational-structures/${id}/summary`);
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            if (err.response?.status === 400) {
                notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
            } else {
                notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
            }
        } finally {
            stopLoading();
        }
    };

    const handleOnNext = () => {
        // if (organizationalStructure.id === null) {
        //     onCreate().then();
        // } else if (organizationalStructureAction.type === 'edit') {
        //     onSave().then();
        // } else {
        //     navigate('/organizational-structures');
        // }
        navigate('/organizational-structures');
    };

    const displayLabelNextButton = () => {
        if (organizationalStructureAction.type === 'create') {
            return 'ถัดไป';
            // return 'สร้างข้อมูล';
        }
        if (organizationalStructureAction.type === 'edit') {
            return 'ถัดไป';
            // return 'บันทึกข้อมูล';
        }
        return 'ถัดไป';
    };

    const handleBackButton = () => {
        if (id) {
            navigate(`/organizational-structures/${id}/personnel`);
        } else {
            navigate('/organizational-structures/create/personnel');
        }
    };

    return (
        <>
            {loaderComponent}
            <div className="h-100 content-background-color rounded-4 px-4 py-4">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>สรุปโครงสร้างองค์กร</h3>
                        <SummaryFilters/>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-12">
                        <h3>ผังโครงสร้างองค์กร</h3>
                        <OrganizationChart/>
                    </div>
                </div>
            </div>

            {
                owner ? <div className="d-flex justify-content-end my-4">
                    <Button variant="secondary" className="btn-action me-2" onClick={handleBackButton}>
                        <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
                        ย้อนกลับ
                    </Button>
                    <Button variant="primary" className="btn-action me-2 btn-mof-primary" onClick={handleOnNext}>
                        {displayLabelNextButton()}
                        <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
                    </Button>
                </div> : null
            }
        </>
    );
};

export default SummaryPage;
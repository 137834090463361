import { StateEmployeeAttachment } from '../models/employee.model';

export const fileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const base64ToFile = async (base64String: string, fileName: string) => {
    const response = await fetch(base64String);
    const blob = await response.blob();
    return new File([blob], fileName);
};

export const getNewData = async (attachments: StateEmployeeAttachment) => {
    const newData = {
        education_history: attachments.education_history.filter(data => data.id === null),
        employee_certifier: attachments.employee_certifier.filter(data => data.id === null),
        insignia_history: attachments.insignia_history.filter(data => data.id === null),
        name_change_history: attachments.name_change_history.filter(data => data.id === null),
        outstanding_performance_award: attachments.outstanding_performance_award.filter(data => data.id === null),
        punishment_history: attachments.punishment_history.filter(data => data.id === null),
        resignation_retirement_death_history: attachments.resignation_retirement_death_history.filter(data => data.id === null),
        training_history: attachments.training_history.filter(data => data.id === null),
        job_position: attachments.job_position.filter(data => data.id === null),
    };
    const newDataInOneList = [
        ...newData.education_history,
        ...newData.employee_certifier,
        ...newData.insignia_history,
        ...newData.name_change_history,
        ...newData.outstanding_performance_award,
        ...newData.punishment_history,
        ...newData.resignation_retirement_death_history,
        ...newData.training_history,
        ...newData.job_position,
    ];
    const newDataWithFiles = await Promise.all(
        newDataInOneList.map(async data => {
            const file = await base64ToFile(data.file as string, data.file_name);
            return {
                ...data,
                file: file
            };
        })
    );
    return newDataWithFiles;
};

import React, {FC, useEffect, useState} from 'react';
import HomeCard from './components/home-card';
import VacationCard, {VacationCardModel} from './components/vacation-card';
import {vacationDataMock} from './mock-data';
import LeaveCard, {LeaveCardModel} from './components/leave-card';
import {Button} from 'react-bootstrap';
import {LeaveRequestService} from "../../../shared/services/leave-request";
import {notificationService} from "../../../shared/services/notification-service";
import {apiEmployeeService} from "../../../shared/services/employee-service";
import {useNavigate} from "react-router-dom";

type LeaveData = {
  type: string;
  used_days: number;
  total_days_allowed: number;
}

export type LeaveHistoryTypeTotal = {
  totalLeaveAmount: number;
  leaveByTypeTotal: LeaveData[];
  usedLeavePercentage: number;

}
const HomePage: FC = () => {

  const vacationData: VacationCardModel[] = vacationDataMock;

  const navigate = useNavigate();

  const [employeeData, setEmployeeData] = useState([]) as any;
  const [leaveHistoryTypeTotal, setLeaveHistoryTypeTotal] = useState({} as LeaveHistoryTypeTotal);

  const fetchData = async () => {
    try {
      const userId = {
        user_id: employeeData?.user?.id
      };
      const leaveHistoryTypeTotal = await LeaveRequestService.getLeaveHistoryTypeTotal(userId)
      if (leaveHistoryTypeTotal.length !== 0) {
        setLeaveHistoryTypeTotal(leaveHistoryTypeTotal)
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
    }
  };

  const getEmployeeMe = async () => {
    const response = await apiEmployeeService.getEmployeeMe();
    if (response.length !== 0) {
      setEmployeeData(response)
    }
  }
  useEffect(() => {
    getEmployeeMe()
    if (employeeData?.user?.id) {
      fetchData()
    }
  }, []);

  useEffect(() => {
    if (employeeData?.user?.id) {
      fetchData()
    }
  }, [employeeData?.user?.id]);

  return <>
    <HomeCard employeeData={employeeData} leaveHistoryTypeTotal={leaveHistoryTypeTotal}/>
    <div className="row mt-4">
      <div className="col-sm-12 col-md-8 px-1">
        <div className="card shadow px-3 py-3">
          <h4 className="text-success mb-2">วันหยุดประจำปี 2567</h4>

          {
            vacationData.map((data, index) => {
              return <VacationCard key={index} {...data}/>
            })
          }

        </div>
      </div>

      <div className="col-sm-12 col-md-4 px-1">
        <div className="card shadow px-3 py-3">
          <h4 className="text-leave mb-2">สิทธิ์การลา</h4>

          {
            leaveHistoryTypeTotal?.leaveByTypeTotal?.map((data, index) => {
              return <LeaveCard key={index} {...data}/>
            })
          }

          <div className="d-flex justify-content-end">
            <Button variant={`primary`}
                    className="btn-action btn-mof-info mt-4"
                    onClick={() => {
                      navigate('/employee-leaves/leave-history')
                    }}
            >
              ประวัติการลา
            </Button>
          </div>

        </div>
      </div>
    </div>
  </>
}

export default HomePage;
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type DeleteConfirmationModalProps = {
    show: boolean;
    title: string;
    description: string | any;
    size?: 'sm' | 'lg' | 'xl';
    onHide: () => void;
    onConfirm: () => void;
};

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
                                                                             show,
                                                                             title,
                                                                             description,
                                                                             size,
                                                                             onHide,
                                                                             onConfirm
                                                                         }) => {
    return (
        <Modal show={show} onHide={onHide} centered size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{description}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    ยกเลิก
                </Button>
                <Button variant="danger" onClick={onConfirm} className="text-white">
                    ลบ
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;

import apiService from '../../shared/services/api-service';
import {AxiosError} from "axios/index";

//สำนัก/ฝ่าย
const department_offices_name_Options:any = [];
department_offices_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchDepartmentsOfficeNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/departments_office_list`)).data.map((data: any) => {
        department_offices_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('สำนัก/ฝ่าย โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

//กอง/กลุ่ม
const division_group_office_name_Options:any = [];
division_group_office_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchDivisionGroupOfficeNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/division_list`)).data.map((data: any) => {
        division_group_office_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('กอง/กลุ่ม โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

//แผนก
const departments_name_Options:any = [];
departments_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchDepartmentsNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/departments_list`)).data.map((data: any) => {
        structure_departments_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('แผนก โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// ตำแหน่ง
const position_name_Options:any = [];
position_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchPositionNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/position_list`)).data.map((data: any) => {
        position_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ตำแหน่ง โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// ประเภทบุคลากร
const employee_type_Options:any = [];
employee_type_Options.push({label: 'ทั้งหมด', value: ''});
const fetchEmployeeTypeOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/employee_type_list`)).data.map((data: any) => {
        employee_type_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ประเภทบุคลากร โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// การศึกษา
const graduation_level_Options:any = [];
graduation_level_Options.push({label: 'ทั้งหมด', value: ''});
const fetchGraduationLevelOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/education_list`)).data.map((data: any) => {
        graduation_level_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('การศึกษา โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// เพศ
const genderOptions:any = [];
genderOptions.push({label: 'ทั้งหมด', value: ''});
const fetchGenderOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/gender_list`)).data.map((data: any) => {
        genderOptions.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('เพศ โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// ช่วงอายุ
const ageOptions:any = [];
ageOptions.push({label: 'ทั้งหมด', value: ''});
const fetchAgeOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/age_list`)).data.map((data: any) => {
        ageOptions.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ช่วงอายุ โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// ช่วงเงินเดือน
const salaryOptions:any = [];
salaryOptions.push({label: 'ทั้งหมด', value: ''});
const fetchSalaryOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/salary_list`)).data.map((data: any) => {
        salaryOptions.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ช่วงเงินเดือน โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// เครื่องราชย์
const insignia_name_Options:any = [];
insignia_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchInsigniaNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/insignia_list`)).data.map((data: any) => {
        insignia_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('เครื่องราชย์ โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

const currentYear = new Date().getFullYear()+543;

// ปีที่เกษียณ
const retirement_year_Options:any = [];
retirement_year_Options.push({label: 'ทั้งหมด', value: ''});
for (let i = (currentYear-50); i <= (currentYear+50) ; i++) {
  retirement_year_Options.push({label: i, value: i});
}

// ปี
const yearOptions:any = [];
yearOptions.push({label: 'ทั้งหมด', value: ''});
for (let i = (currentYear-50); i <= (currentYear+50) ; i++) {
  yearOptions.push({label: i, value: i-543});
}

// ประเภทการลา
const leave_type_Options:any = [];
leave_type_Options.push({label: 'ทั้งหมด', value: ''});
const fetchLeaveTypeOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/leave_type_list`)).data.map((data: any) => {
        leave_type_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ประเภทการลา โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

//========== สำหรับรายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง ==========
//สำนัก/ฝ่าย
const structure_department_offices_name_Options:any = [];
structure_department_offices_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchStructureDepartmentsOfficeNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/structure_departments_office_list`)).data.map((data: any) => {
        structure_department_offices_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('สำนัก/ฝ่าย โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

//กอง/กลุ่ม
const structure_division_group_office_name_Options:any = [];
structure_division_group_office_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchStructureDivisionGroupOfficeNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/structure_division_group_office_list`)).data.map((data: any) => {
        structure_division_group_office_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('กอง/กลุ่ม โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

//แผนก
const structure_departments_name_Options:any = [];
structure_departments_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchStructureDepartmentsNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/structure_departments_list`)).data.map((data: any) => {
        structure_departments_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('แผนก โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};

// ตำแหน่ง
const structure_position_name_Options:any = [];
structure_position_name_Options.push({label: 'ทั้งหมด', value: ''});
const fetchStructurePositionNameOptions = async () => {
    try {
      (await apiService().get(`${process.env.REACT_APP_API_URI}/report/structure_position_list`)).data.map((data: any) => {
        structure_position_name_Options.push(data);
      })
    } catch (error) {
      const err = error as AxiosError;
      console.log('ตำแหน่ง โหลดข้อมูลไม่สำเร็จ', err.message);
    }
};
//================================================================================

export {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  fetchEmployeeTypeOptions,
  fetchGraduationLevelOptions,
  fetchGenderOptions,
  fetchAgeOptions,
  fetchSalaryOptions,
  fetchInsigniaNameOptions,
  fetchLeaveTypeOptions,
  fetchStructureDepartmentsOfficeNameOptions,
  fetchStructureDivisionGroupOfficeNameOptions,
  fetchStructureDepartmentsNameOptions,
  fetchStructurePositionNameOptions,
  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options, 
  position_name_Options, 
  employee_type_Options,
  graduation_level_Options, 
  genderOptions, 
  ageOptions, 
  salaryOptions, 
  insignia_name_Options,
  retirement_year_Options,
  yearOptions,
  leave_type_Options,
  structure_department_offices_name_Options,
  structure_division_group_office_name_Options, 
  structure_departments_name_Options, 
  structure_position_name_Options, 
};
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Alert, Button, Table, Modal, Form } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSignIn, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams, useLocation, useParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';
import { apiEmployeeService } from '../../shared/services/employee-service';

const EmployeeCheckInsFormReqPage: FC = () => {
    
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        date: '',
        date2: '',
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();
    const [showModal, setShowModal] = useState({
        visible: false,
        type: 'danger',
        head: '',
        body: '',
        date: ''
    });

    const isEdit = location.pathname.startsWith('/employee-check-ins/manage-check-in/edit/');

    const [form1, setForm1] = useState({
        id: 0,
        dateFrom: '',
        dateTo: '',
        totalDay: '',
        dayType: '',
        reason: '',
        announce: '',
        status: 0
    });

    const setForm1_v2 = (newv: any) => {
        let newv2 = {...form1, ...newv}
        let totalDay = '';
        {
            let d1 = newv2.dateFrom;
            let d2 = newv2.dateTo;
            if(Moment(d1).isValid() && Moment(d2).isValid()) {
                totalDay = String(Moment(d2).diff(d1, 'day'));
            } else {
                totalDay = '';
            }
        }
        setForm1({...newv2, totalDay: totalDay});
    }

    //

    const fetchItem = async (data: any) => {
        startLoading();
        try {
            console.log(id);
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/reqs/get`, {
                params: {
                    id: id
                }
            });
            let v = response.data.data.item ? response.data.data.item : null;
            setForm1_v2({
                id: v.id,
                dateFrom: v.date_from,
                dateTo: v.date_to,
                dayType: v.day_type,
                reason: v.reason,
                announce: v.announce,
                status: v.status
            });
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const apiSubmit = async () => {
        startLoading();
        try {
            const response = await apiService().post(`${process.env.REACT_APP_API_URI}/employees-check-ins/reqs/create`, {
                date_from: form1.dateFrom,
                date_to: form1.dateTo,
                total_day: form1.totalDay,
                day_type: form1.dayType,
                reason: form1.reason,
                announce: form1.announce
            });
            notificationService.successNotification('สำเร็จ', 'เพิ่มข้อมูลสำเร็จ');
            navigate('/employee-check-ins/manage-check-in');
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const apiSubmitEdit = async (status: any) => {
        startLoading();
        try {
            const response = await apiService().post(`${process.env.REACT_APP_API_URI}/employees-check-ins/reqs/set-status`, {
                id: form1.id,
                status: status
            });
            notificationService.successNotification('สำเร็จ', 'อัพเดทข้อมูลสำเร็จ');
            navigate('/employee-check-ins/manage-check-in');
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    //

    useEffect(() => {
        let f = async () => {
            if(isEdit) {
                await fetchItem({});
            }
            // await fetchEmployeeMe();
            // await fetchCheckIn({ first: true });
            // setInterval(() => {
            //     let m = Moment();
            //     let a = m.format('HH : mm : ss');
            //     let b = parseInt(m.format('HHmm'));
            //     set_d_now(a);
            //     setCheckInOutText(b < 1300 ? 'เข้างาน' : 'ออกงาน');
            // }, 500);
        }
        f();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    //

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">ร้องขอเปิดระบบเข้า - ออกงานสำรอง</h2>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-4 mb-3">
                <label>วันเริ่มต้น</label>
                <input className="form-control" type="date" value={form1.dateFrom} onChange={e => { setForm1_v2({ dateFrom: e.target.value }); }} disabled={ isEdit }/>
            </div>
            <div className="col-sm-12 col-md-4 mb-3">
                <label>วันที่สิ้นสุด</label>
                <input className="form-control" type="date" value={form1.dateTo} onChange={e => { setForm1_v2({ dateTo: e.target.value }); }} disabled={ isEdit } />
            </div>
            <div className="col-sm-12 col-md-4 mb-3">
                <label>รวมวัน</label>
                <input className="form-control" type="text" value={form1.totalDay} disabled={ true } />
            </div>
            <div className="line-break"></div>
            <div className="col-sm-12 col-md-6 mb-3">
                <Form.Check
                    type={'radio'}
                    inline={ true }
                    name="page_group_1"
                    id={`default-1`}
                    label={'เต็มวัน'}
                    value={'1'}
                    checked={form1.dayType == '1'}
                    onChange={e => { setForm1_v2({ dayType: e.target.value }); }}
                    disabled={ isEdit }
                />
                <Form.Check
                    type={'radio'}
                    inline={ true }
                    name="page_group_1"
                    id={`default-2`}
                    label={'ครึ่งเข้า'}
                    value={'2'}
                    checked={form1.dayType == '2'}
                    onChange={e => { setForm1_v2({ dayType: e.target.value }); }}
                    disabled={ isEdit }
                />
                <Form.Check
                    type={'radio'}
                    inline={ true }
                    name="page_group_1"
                    id={`default-3`}
                    label={'ครึ่งบ่าย'}
                    value={'3'}
                    checked={form1.dayType == '3'}
                    onChange={e => { setForm1_v2({ dayType: e.target.value }); }}
                    disabled={ isEdit }
                />
            </div>
            <div className="line-break"></div>
            <div className="col-sm-12 col-md-9">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>เหตุผลที่ขอปิดระบบ</Form.Label>
                    <Form.Control as="textarea" rows={3} value={ form1.reason } onChange={e => { setForm1_v2({ reason: e.target.value }); }} disabled={ isEdit } />
                </Form.Group>
            </div>
            <div className="line-break"></div>
            <div className="col-sm-12 col-md-9">
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>กำหนดประกาศเงื่อนไขการใช้งานระบบเข้า - ออกงานสำรอง</Form.Label>
                    <Form.Control as="textarea" rows={3} value={ form1.announce } onChange={e => { setForm1_v2({ announce: e.target.value }); }} disabled={ isEdit }/>
                </Form.Group>
            </div>
            <div className="line-break"></div>
            <div className="col-sm-12 col-md-12">
                <div className="text-end mt-4">
                    <Link to="/employee-check-ins/manage-check-in" className="d-inline-block me-3">
                        <Button variant="outline-dark" type="button" className="px-4">
                            <FontAwesomeIcon icon={faArrowLeft} className="me-2"/>
                            ย้อนกลับ
                        </Button>
                    </Link>
                    { (() => {
                        if(isEdit) {
                            if(form1.status == 1 && isAdmin) {
                                return <span><Button variant="danger" type="button" className="px-4 me-3" onClick={() => { apiSubmitEdit(3) }}>ไม่อนุมัติ</Button>
                                <Button variant="success" type="button" className="px-4 me-3" onClick={() => { apiSubmitEdit(2) }}>อนุมัติ</Button></span>
                            };
                        } else {
                            return <Button variant="primary" type="button" className="px-4 me-3" onClick={apiSubmit}>บันทึก</Button>;
                        }
                    })() }
                    
                </div>
            </div>
        </div>
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsFormReqPage;
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import LeaveQuotaForm from './forms/leaves/leave-quota-form';

interface EmployeeLeaveQuotaProps {
    defaultKey?: AccordionEventKey;
}

const EmployeeLeaveQuota: FC<EmployeeLeaveQuotaProps> = ({defaultKey = '0'}) => {
    return (
        <Accordion defaultActiveKey={defaultKey} className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="text-mof-primary">
                    <h5 className="text-mof-primary m-0">วันลา</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <LeaveQuotaForm/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default EmployeeLeaveQuota;
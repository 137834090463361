import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard, faGear, faListCheck, faNewspaper, faSitemap, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useAuth } from '../../../shared/authentications/auth-context';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import { hasPermission, canCheckInPage } from '../../../shared/utils/roles';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { apiEmployeeService } from '../../../shared/services/employee-service';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../../shared/services/notification-service';
import { ProfileMe } from '../../../shared/models/employee.model';
import { initialStateEmployee } from '../../../shared/states/employee-reducer';
import { getMediaPath } from '../../../shared/utils/function-media';

import Collapse from '@mui/material/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const SidebarLayout: FC = () => {
  const navigate = useNavigate();
  const [showLogoutModal, setLogoutModal] = useState(false);
  const {logout, permission, onSetPermission, authJwt} = useAuth();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [profile, setProfile] = useState<ProfileMe>(initialStateEmployee);
  const [isImageError, setIsImageError] = useState(false);
  const [isApprovable, setIsApprovable] = useState(false);
  const [vCanCheckInPage, setVCanCheckInPage] = useState(false);

  const handleLogout = () => {
    setLogoutModal(true);
  };

  const handleCloseModal = () => {
    setLogoutModal(false);
  };

  const handleConfirmLogout = () => {
    logout();
    navigate('/login');
  };

  const displayAllModal = () => {
    if (showLogoutModal) {
      return <ConfirmModal
        show={showLogoutModal}
        title={'ยืนยันการออกจากระบบ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmLogout}
      />;
    }
  };

  const fetchEmployeeMe = async () => {
    startLoading();
    try {
      const employee = await apiEmployeeService.getEmployeeMe();
      let permission = employee.permission;
      if (!employee.active) {
        logout();
        navigate('/login');
      } else {
        const updatePermissions = (perms:any , isHighLevelPosition?: boolean) => ({
          ...perms,
          employee_leave_approve: {
            read: !!isHighLevelPosition,
            create: !!isHighLevelPosition,
            update: !!isHighLevelPosition,
            delete: !!isHighLevelPosition,
            export: !!isHighLevelPosition,
            approve: !!isHighLevelPosition,
          }
        });

        if (authJwt?.employee_id) {
          const employee = await apiEmployeeService.getEmployeeDetail(authJwt.employee_id);
          const hasNoPosition = !employee.current_job_position;
          const isLowLevelPosition = employee.job_positions.find((position:any) => position.is_currently)?.organizational_structure_level >= 6;

          if (hasNoPosition || isLowLevelPosition) {
            setIsApprovable(false);
            permission = updatePermissions(permission, false);

          } else {
            setIsApprovable(true);
            permission = updatePermissions(permission, true);
          }
        }
        setProfile(employee);
        onSetPermission(permission);
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchEmployeeMe();
    canCheckInPage({ setF: setVCanCheckInPage });
  }, []);

  const getFullName = () => {
    return profile.id ? `${profile.prefix_name_th} ${profile.firstname_th} ${profile.lastname_th}` : '-';
  };

  const getPhoto = () => {
    if (profile.photo === null) {
      return <div className="profile-logo">
        <FontAwesomeIcon icon={faUser}/>
      </div>;
    }
    const src = getMediaPath(profile.photo as string);
    return <Image className="profile-logo object-fit-cover"
                  src={isImageError ? '/static/no-image.png' : src}
                  alt="profile photo" onError={()=>{
                    setIsImageError(true)}}/>
  };

  const getRegistrationsPath = () => {
    if (permission.employee_actives.read)
      return 'all-users'
    if (permission.employee_register_requests.read)
      return 'register-user-requests'
    if (permission.employee_reset_password_requests.read)
      return 'reset-password-requests'
    if (permission.employee_edit_requests.read)
      return 'profile-edit-requests'
    return ''
  }

  const [ReportOpen, setReportOpen] = React.useState(false);
  const [leaveOpen, setLeaveOpen] = React.useState(false);
  const [shiftOpen, setShiftOpen] = React.useState(false);
  const [checkinOpen, setCheckinOpen] = React.useState(false);
  const [ManageUserOpen, setManageUserOpen] = React.useState(false);
  const [RegistrationPersonnelHistoryOpen, setRegistrationPersonnelHistoryOpen] = React.useState(false);
  const [ReportInOutLeaveOpen, setReportInOutLeaveOpen] = React.useState(false);
  const [LeaveOnlineOpen, setLeaveOnlineOpen] = React.useState(false);

  return <div className="p-3 card h-100">
    {loaderComponent}
    {displayAllModal()}
    <div className="row">
      <div className="col-sm-12 col-md-4">
        {getPhoto()}
      </div>
      <div className="col-sm-12 col-md-8">
        <h5>{getFullName()}</h5>
        <p className="text-secondary mb-0">ตำแหน่ง : {profile.current_job_position?.position_name || '-'}</p>
      </div>
      <a href={`/employees/me/information`} className="none-underline">
        <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faGear} className="me-2"/>
          ข้อมูลส่วนตัว
        </div>
      </a>
      {/*<a href={`/reset-password`} className="none-underline">*/}
      {/*  <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">*/}
      {/*    <FontAwesomeIcon icon={faGear} className="me-2"/>*/}
      {/*    แก้ไขรหัสผ่าน*/}
      {/*  </div>*/}
      {/*</a>*/}
      {
        hasPermission(permission, ['employees'], 'read') ? <a href={`/employees`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faNewspaper} className="me-2"/>
            ทะเบียนประวัติบุคลากร
          </div>
        </a> : null
      }
      {
        hasPermission(permission, ['organizational_structures'], 'read') ?
          <a href={`/organizational-structures`} className="none-underline">
            <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
              <FontAwesomeIcon icon={faSitemap} className="me-2"/>
              บริหารโครงสร้างองค์กร
            </div>
          </a> : null
      }
      {
        hasPermission(permission, ['employee_actives', 'employee_register_requests', 'employee_reset_password_requests', 'employee_edit_requests'], 'read') ?
          <a href={`/employee-registrations/${getRegistrationsPath()}`} className="none-underline">
            <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
              <FontAwesomeIcon icon={faAddressCard} className="me-2"/>
              การจัดการพนักงาน
            </div>
          </a> : null
      }
      {
        hasPermission(permission, ['employee_reset_password_requests'], 'read') ?
          <a href={`/employee-registrations/reset-password-requests?seperated=true`} className="none-underline">
            <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
              <FontAwesomeIcon icon={faAddressCard} className="me-2"/>
              คำขอรีเซ็ตรหัสผ่าน
            </div>
          </a>
        : null
      }
      <a href={`/permissions/me`} className="none-underline">
        <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faListCheck} className="me-2"/>
          คำร้องขอสิทธิ์การเข้าถึง
        </div>
      </a>
	  {
		hasPermission(permission, ['permission_approve'], 'read') || authJwt?.role === 'ADMIN' ?
      <a href={`/permissions`} className="none-underline">
        <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faGear} className="me-2"/>
          อนุมัติการกำหนดสิทธิ์
        </div>
      </a> : null
	  }
		{/*{*/}
      <a href={"#LeaveOnline"} className="none-underline">
        <div id="LeaveOnline" className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2" onClick={()=>setLeaveOpen(!leaveOpen)}>
              <span>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ระบบลาออนไลน์
              </span>
          {leaveOpen ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
        </div>
      </a>
      <Collapse in={leaveOpen} timeout="auto" unmountOnExit>
        {/*------------------------------ 1.บริหารจัดการผู้ใช้งาน ------------------------------*/}
		{/*hasPermission(permission, ['employee_leaves'], 'read') ?*/}
      <a href={`/employee-leaves/request`} className="none-underline">
        <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faSitemap} className="me-2"/>
          ลาออนไลน์
        </div>
      {/*</a> : null*/}
      </a>
		{/*}*/}
		{/*{*/}
		{/*hasPermission(permission, ['employee_leaves'], 'read') ?*/}
      <a href={`/employee-leaves/leave-history`} className="none-underline">
        <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faSitemap} className="me-2"/>
          ประวัติการลา
        </div>
      </a>
    {/*  </a> : null*/}
		{/*}*/}
		{
		hasPermission(permission, ['employee_leave_approve'], 'read') ?
      <a href={`/employee-leaves/approver-request-list`} className="none-underline">
        <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
          <FontAwesomeIcon icon={faSitemap} className="me-2"/>
          อนุมัติการลาของผู้ใต้บังคับบัญชา
        </div>
      </a> : null
		}
      {
        hasPermission(permission, ['employee_leave_setting'], 'read') ?
          <a href={`/employee-leaves/leave-setting`} className="none-underline">
            <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
              <FontAwesomeIcon icon={faSitemap} className="me-2"/>
              ตั้งค่าวันลา
            </div>
          </a> : null
      }
      </Collapse>
      {
        hasPermission(permission, ['employee_shift_personel_view'], 'read') ?
        <a href={`/employee-shift/employee-shift-personel`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSitemap} className="me-2"/>
            ตารางกะ
          </div>
        </a>
        : null
      }
      {
        hasPermission(permission, ['employee_shift'], 'read') || hasPermission(permission, ['employee_shift_personel'], 'read') ?
        <a href={"#ShiftManage"} className="none-underline">
        <div id="ShiftManage"
             className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded text-center mt-2 d-flex justify-content-between"
             onClick={() => setShiftOpen(!shiftOpen)}>
              <span>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ระบบจัดการตารางกะ
              </span>
          {shiftOpen ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
        </div>
      </a> : null
      }
      <Collapse in={shiftOpen} timeout="auto" unmountOnExit>
      {
        hasPermission(permission, ['employee_shift'], 'read') ?
          <a href={`/employee-shift/employee-shift-list`} className="none-underline">
            <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
              <FontAwesomeIcon icon={faSitemap} className="me-2"/>
              กำหนดตารางกะ
            </div>
          </a>
        : null
      }
      {
        hasPermission(permission, ['employee_shift_personel'], 'read') ?
        <a href={`/employee-shift/employee-shift-personel-list`} className="none-underline">
          <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSitemap} className="me-2"/>
            ตารางกะรายบุคคล
          </div>
        </a>
        : null
      }
      </Collapse>
      {
        hasPermission(permission, ['employee_reserve_checkin'], 'read') ||  hasPermission(permission, ['employee_reserve_checkin_manage'], 'read') ?
        <a href={"#ShiftManage"} className="none-underline">
        <div id="ShiftManage"
             className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded text-center mt-2 d-flex justify-content-between"
             onClick={() => setCheckinOpen(!checkinOpen)}>
              <span>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ระบบเข้า-ออกงานสำรอง
              </span>
          {checkinOpen ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
        </div>
      </a> : null}
      <Collapse in={checkinOpen} timeout="auto" unmountOnExit>
      {/* {
        hasPermission(permission, ['employee_reserve_checkin'], 'read') ?
        <a href={`/employee-check-ins/check-in`} className="none-underline">
          <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSignIn} className="me-2"/>
            เข้า-ออกงานสำรอง
          </div>
        </a>
          : null
      } */}
      {
        hasPermission(permission, ['employee_reserve_checkin_manage'], 'read') ?
        <a href={`/employee-check-ins/manage-check-in`} className="none-underline">
          <div className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSignIn} className="me-2"/>
            จัดการเข้า-ออกงานสำรอง
          </div>
        </a>
          : null
      }
      </Collapse>
      {/*{*/}
      {
        //hasPermission(permission, ['employee_reserve_checkin'], 'read') ?
        vCanCheckInPage ?
        <a href={`/employee-check-ins/check-in`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSignIn} className="me-2"/>
            เข้า-ออกงานสำรอง
          </div>
        </a>
        : null
      }
      {/*  hasPermission(permission, ['employee_checkin_report'], 'read') ?*/}
        <a href={`/employee-check-ins/list`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSignIn} className="me-2"/>
            เวลาเข้า-ออกงาน
          </div>
        </a>
          {/*: null*/}
      {/*}*/}
      {
        hasPermission(permission, ['device_status'], 'read') ?
        <a href={`/employee-check-ins/status`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSignIn} className="me-2"/>
            ตรวจสอบสถานะเครื่อง
          </div>
        </a>
          : null
      }


      {
        hasPermission(permission, ['system_logs'], 'read') ?
        <a href={`/system-logs/list`} className="none-underline">
          <div className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded mt-2">
            <FontAwesomeIcon icon={faSitemap} className="me-2"/>
            รายงานผู้ใช้งานระบบ
          </div>
        </a>
        : null
      }

      {/*------------------------------ รายงาน ------------------------------*/}
      {
        hasPermission(permission, ['report'], 'read') ?
        <a href={"#Report"} className="none-underline">
            <div id="Report" className="col-sm-12 bg-mof-primary text-white py-3 px-2 rounded text-center mt-2 d-flex justify-content-between" onClick={()=>setReportOpen(!ReportOpen)}>
              <span>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                รายงาน
              </span>
            {ReportOpen ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>}
          </div>
        </a> : null
      }

      <Collapse in={ReportOpen} timeout="auto" unmountOnExit>
          {/*------------------------------ 1.บริหารจัดการผู้ใช้งาน ------------------------------*/}
          <a href={"#ManageUser"} className="none-underline">
            <div id="ManageUser" className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2" onClick={()=>setManageUserOpen(!ManageUserOpen)}>

              {ManageUserOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              <span>
                บริหารจัดการผู้ใช้งาน
              </span>
            </div>
          </a>
                    <Collapse in={ManageUserOpen} timeout="auto" unmountOnExit>
                      {
                        <a href={`/report/manage-user/report-login`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานการเข้าใช้งานระบบ
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/manage-user/report-forget-password`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานผู้ใช้งานลืมรหัสผ่าน
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/manage-user/report-edit-employee-detail`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานการแก้ไขข้อมูลพนักงาน
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/manage-user/report-login-latest`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานเข้าใช้งานล่าสุด
                          </div>
                        </a>
                      }
                    </Collapse>

          {/*------------------------------ 2.ทะเบียนประวัติบุคลากร ------------------------------*/}
          <a href={'#RegistrationPersonnelHistory'} className="none-underline">
            <div id="RegistrationPersonnelHistory" className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2" onClick={()=>setRegistrationPersonnelHistoryOpen(!RegistrationPersonnelHistoryOpen)}>
              {RegistrationPersonnelHistoryOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              <span>
                ทะเบียนประวัติบุคลากร
              </span>
            </div>
          </a>
                    <Collapse in={RegistrationPersonnelHistoryOpen} timeout="auto" unmountOnExit>
                      {
                        <a href={`/report/registration-personnel-history/report-personnel-history`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานประวัติบุคลากร
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/registration-personnel-history/search-personnel-qualification`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            ค้นหาคุณสมบัติของบุคลากร
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/registration-personnel-history/report-personnel-retire`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานบุคลากรที่จะเกษียณอายุ
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/registration-personnel-history/report-personnel-job-vacancy`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง
                          </div>
                        </a>
                      }
                    </Collapse>

          {/*------------------------------ 3.รายงานสถิติเข้า - ออกงานและการลา ------------------------------*/}
          <a href={'#ReportInOutLeave'} className="none-underline">
            <div id="ReportInOutLeave" className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2" onClick={()=>setReportInOutLeaveOpen(!ReportInOutLeaveOpen)}>

              {ReportInOutLeaveOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              <span>
                สถิติเข้า - ออกงานและการลา
              </span>
            </div>
          </a>
                    <Collapse in={ReportInOutLeaveOpen} timeout="auto" unmountOnExit>
                      {
                        <a href={`/report/report-in-out-leave/report-in-out-leave`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานสถิติเข้า - ออกงานและการลา
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/report-in-out-leave/report-not-scan-in-out`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานสถิติจำนวนผู้ไม่สแกนเข้า - ออก
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/report-in-out-leave/report-personnel-work-each-shift`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานจำนวนบุคลากรที่ปฏิบัติงานในแต่ละกะ
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/report-in-out-leave/report-change-shift`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานการเปลี่ยนกะการทำงาน
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/report-in-out-leave/report-annual-vacation`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานแสดงวันหยุดประจำปี
                          </div>
                        </a>
                      }
                    </Collapse>

          {/*------------------------------ 4.ลาออนไลน์ ------------------------------*/}
          <a href={'#LeaveOnline'} className="none-underline">
            <div id="LeaveOnline" className="col-sm-12 bg-success-subtle text-mof-primary py-3 px-2 rounded mt-2" onClick={()=>setLeaveOnlineOpen(!LeaveOnlineOpen)}>
              {LeaveOnlineOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              <span>
                ลาออนไลน์
              </span>
            </div>
          </a>
                    <Collapse in={LeaveOnlineOpen} timeout="auto" unmountOnExit>
                      {
                        <a href={`/report/leave-online/report-leave-each-type`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded mt-2">
                            รายงานสถิติการลาแต่ละประเภท
                          </div>
                        </a>
                      }
                      {
                        <a href={`/report/leave-online/report-summary-in-out-work`} className="none-underline">
                          <div className="col-sm-12 bg-secondary-subtle text-mof-primary py-1 px-2 rounded text-center mt-2">
                            รายงานสรุปเข้าออกงาน
                          </div>
                        </a>
                      }
                    </Collapse>  
      </Collapse>

    </div>
  </div>;
};

export default SidebarLayout;
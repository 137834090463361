import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction
} from '../../../../shared/services/organizational-structure-service';
import { EmployeeDropdownParams } from '../../../../shared/models/employee.model';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { apiDropdownService } from '../../../../shared/services/dropdown-service';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Dropdown } from '../../../../shared/models/common.model';
import { InputActionMeta } from 'react-select/dist/declarations/src/types';

interface HeaderEmployeeSearchProps {
  onChange: (data: Dropdown) => void;
  value: any,
  required?: boolean;
  submitted?: boolean;
  changeValue: string;
}

const HeaderEmployeeSearch: FC<HeaderEmployeeSearchProps> = ({
                                                               onChange,
                                                               value,
                                                               required = false,
                                                               submitted = false,
                                                               changeValue
                                                             }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [localValue, setLocalValue] = useState({value: value, label: value});

  useEffect(() => {
    if (value) {
      updateLocal();
    }
  }, [value]);

  const updateLocal = async () => {
    const options = await apiDropdownService.getUserDropdown({search: '', excludes: null, id: value.toString()});
    const found = options.find((data: any) => data.value === value);
    if (found) {
      setLocalValue({
        label: found.label,
        value: found.value,
      });
    } else {
      setLocalValue({
        label: '',
        value: value
      });
    }

  }

  type OptionType = {
    value: number;
    label: string;
  };

  const getParams = (search: string): EmployeeDropdownParams => {
    const directors = organizationalStructure.directors
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const deputy_directors = organizationalStructure.deputy_directors
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const department_offices = organizationalStructure.department_offices
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const division_group_offices = organizationalStructure.division_group_offices
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const structure_departments = organizationalStructure.structure_departments
      .filter(data => !data.is_acting_in_position)
      .map(data => data.user_id).filter(data => !!data);
    const personnels = organizationalStructure.personnels
      .map(data => data.user_id).filter(data => !!data);

    const x = [];
    if (directors.length > 0)
      x.push(...directors)
    if (deputy_directors.length > 0)
      x.push(...deputy_directors)
    if (department_offices.length > 0)
      x.push(...department_offices)
    if (division_group_offices.length > 0)
      x.push(...division_group_offices)
    if (structure_departments.length > 0)
      x.push(...structure_departments)
    if (personnels.length > 0)
      x.push(...personnels)
    const excludeIdList = x.filter(data => data !== localValue.value).join(',')
    return {
      search: search,
      excludes: excludeIdList,
    };
  };

  const loadOptions = async (
    search: string,
    prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
  ) => {
    setIsLoading(true);

    let options = [];
    try {
      options = await apiDropdownService.getUserDropdown(getParams(search));
      options = options.map((data: any) => {
        const label = data.label.replace(/^(นาย|นางสาว|นาง)\s/, '$1');
        return {
          label: label,
          value: data.value,
        };
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching options:', error);
      setIsLoading(false);
    }
    return {
      options: options,
    };
  };

  const handleOnChange = (newValue: any) => {
    onChange(newValue);
    setLocalValue({
      label: newValue.label,
      value: newValue.value,
    });
  };

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setLocalValue({
        label: newValue,
        value: newValue,
      });
    }
  };

  return (
    <div>
      <AsyncPaginate
        isDisabled={disable}
        key={JSON.stringify(changeValue)}
        debounceTimeout={300}
        loadOptions={loadOptions}
        isLoading={isLoading}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        value={localValue}
        inputValue={localValue.value}
        placeholder=""
      />
    </div>
  );
};

export default HeaderEmployeeSearch;
import React, {FC, useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import PersonalRecordForm from '../forms/personal_records/personal-record-form';
import EmergencyContactForm from '../forms/personal_records/emergency-contact-form';
import AddressOfficialForm from '../forms/personal_records/address-official-form';
import AddressContactableForm from '../forms/personal_records/address-contactable-form';
import ScrollToTopButton from '../../../../shared/components/molecules/commons/scroll-to-top-button';
import { useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import { EmployeeModel } from '../../../../shared/models/employee.model';

interface EmployeePersonalRecordProps {
  defaultKey?: AccordionEventKey;
}

const EmployeePersonalRecord: FC<EmployeePersonalRecordProps> = ({defaultKey = '0'}) => {
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);
  const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);

  const keyList = [
    'prefix_name_en', 'firstname_en', 'lastname_en',
    'prefix_name_th', 'firstname_th', 'lastname_th',
    'address_contactable', 'address_official', 'birthday',
    'blood_type', 'code', 'contactable_amphure_id', 'contactable_province_id', 'contactable_tambon_id',
    'emergency_contact_address', 'emergency_contact_amphure_id', 'emergency_contact_full_name',
    'emergency_contact_postcode', 'emergency_contact_province_id', 'emergency_contact_relationship',
    'emergency_contact_tambon_id', 'emergency_contact_tel', 'ethnicity', 'gender', 'id_card',
    'id_card_date_expire', 'id_card_date_generate', 'nationality', 'phone', 'photo', 'postcode_contactable',
    'postcode_official', 'official_province_id', 'official_amphure_id', 'official_tambon_id', 'religion',
    'salary', 'new_email'
  ];

  const haveSomeEditField = () => {
    if (employeeAction.type === 'view-edit-request') {
      return keyList.some(key => {
        const kk = key as keyof EmployeeModel;
        return employee[kk] !== employeeEditProfile[kk];
      });
    }
    return false;
  };

  return (
    <Accordion defaultActiveKey={defaultKey} className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
          <h5 className="text-mof-primary m-0">ประวัติส่วนตัว</h5>
        </Accordion.Header>
        <Accordion.Body>
          <PersonalRecordForm/>
          <h5 className="text-mof-primary m-0 my-4 pt-2">กรณีเร่งด่วนติดต่อ</h5>
          <EmergencyContactForm/>
          <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ตามบัตรประชาชน</h5>
          <AddressOfficialForm/>
          <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ปัจจุบัน</h5>
          <AddressContactableForm/>
          <div className="mt-3">
            <ScrollToTopButton/>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EmployeePersonalRecord;
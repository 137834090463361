import { FC } from 'react';
import AddressInputSelect, {
  AddressInputSelectModel
} from '../../../../../shared/components/molecules/commons/address-input-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../../shared/services/employee-service';
import { handleEmployeeInputChange } from '../../../../../shared/utils/function-state';
import ZipcodeSelect from '../../inputs/zipcode-select';
import ProvinceSelect from "../../inputs/province-select";
import AmphureSelect from "../../inputs/amphure-select";
import TambonSelect from "../../inputs/tambon-select";

const AddressOfficialForm: FC = () => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);

  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';

  const onSelectAddress = (data: AddressInputSelectModel) => {
    // handleEmployeeInputChange({target: {name: 'official_province_id', value: data.province}}, dispatch);
    // handleEmployeeInputChange({target: {name: 'official_amphure_id', value: data.amphure}}, dispatch);
    // handleEmployeeInputChange({target: {name: 'official_tambon_id', value: data.tambon}}, dispatch);
    // handleEmployeeInputChange({target: {name: 'postcode_official', value: data.zip_code}}, dispatch);
  };

  const handleZipcodeOptionChange = (dropdown: any) => {
    const value = {
      target: {
        name: 'postcode_official',
        value: dropdown.value
      }
    };
    handleEmployeeInputChange(value, dispatch);
    handleEmployeeInputChange({
      target: {
        name: 'official_province_id',
        value: dropdown.amphure.province.value
      }
    }, dispatch);
    handleEmployeeInputChange({
      target: {
        name: 'official_amphure_id',
        value: dropdown.amphure.value
      }
    }, dispatch);
    handleEmployeeInputChange({target: {name: 'official_tambon_id', value: dropdown.id}}, dispatch);
  };

  const handleProvinceOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'official_province_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleAmphureOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'official_amphure_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleTambonOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'official_tambon_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  return (
    <div>
      <div className="row">
        <div
          className={`col-sm-12 col-md-8 pb-3 ${employee.address_official !== oldEmployee.address_official ? 'field-warning' : ''}`}>
          <label className="form-label required">สถานที่</label>
          <input type="text" className="form-control" name="address_official"
                 placeholder="" disabled={disable} value={employee.address_official || ''}
                 required={true}
                 onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
          <div className="invalid-feedback">
            กรุณากรอกสถานที่
          </div>
        </div>
        {/*<div*/}
        {/*  className={`col-sm-12 col-md-4 pb-3 ${employee.postcode_official !== oldEmployee.postcode_official ? 'field-warning' : ''}`}>*/}
        {/*  <label className="form-label required">รหัสไปรษณีย์</label>*/}
        {/*  <ZipcodeSelect*/}
        {/*    onChange={handleZipcodeOptionChange}*/}
        {/*    disable={disable}*/}
        {/*    value={employee.postcode_official}/>*/}
        {/*  <input type="text" className="form-control" name="postcode_official"*/}
        {/*         placeholder="กรุณาเลือก จังหวัด อำเภอ ตำบล" disabled={true}*/}
        {/*         hidden={true}*/}
        {/*         value={employee.postcode_official || ''}*/}
        {/*         required={true} readOnly={true}*/}
        {/*         onChange={() => {*/}
        {/*         }}/>*/}
        {/*  <div className="invalid-feedback">*/}
        {/*    กรุณากรอกรหัสไปรษณีย์*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={`col-sm-12 col-md-4 pb-3 ${employee.postcode_official !== oldEmployee.postcode_official ? 'field-warning' : ''}`}>
          <label className="form-label required">รหัสไปรษณีย์</label>
          <input type="text" className="form-control" name="postcode_official"
                 placeholder="" disabled={disable} value={employee.postcode_official || ''}
                 required={true}
                 onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
          <div className="invalid-feedback">
            กรุณากรอกรหัสไปรษณีย์
          </div>
        </div>
      </div>
      {/*<div className={`pb-3 */}
      {/*      ${employee.official_province_id !== oldEmployee.official_province_id ? 'field-warning ' : ''}*/}
      {/*      ${employee.official_amphure_id !== oldEmployee.official_amphure_id ? 'field-warning ' : ''}*/}
      {/*      ${employee.official_tambon_id !== oldEmployee.official_tambon_id ? 'field-warning ' : ''}*/}
      {/*      ${employee.postcode_official !== oldEmployee.postcode_official ? 'field-warning ' : ''}*/}
      {/*      `}>*/}
      {/*  <AddressInputSelect onSelectData={onSelectAddress} isDisable={true}*/}
      {/*                      province={employee.official_province_id}*/}
      {/*                      amphure={employee.official_amphure_id}*/}
      {/*                      tambon={employee.official_tambon_id}*/}
      {/*                      zip_code={employee.postcode_official}/>*/}
      {/*</div>*/}
      <div className="row">
        <div className={`col-sm-12 col-md-4 pb-3 ${employee.official_province_id !== oldEmployee.official_province_id ? 'field-warning' : ''}`}>
          <label className="form-label required">จังหวัด</label>
          <ProvinceSelect
            onChange={handleProvinceOptionChange}
            disable={disable}
            value={employee.official_province_id}/>
          <input type="text" className="form-control" name="official_province_id"
                 hidden={true}
                 value={employee.official_province_id || ''}
                 required={true}
                 onChange={() => {
                 }}/>
          <div className="invalid-feedback">
            กรุณากรอกจังหวัด
          </div>
        </div>
        <div className={`col-sm-12 col-md-4 pb-3 ${employee.official_amphure_id !== oldEmployee.official_amphure_id ? 'field-warning' : ''}`}>
          <label className="form-label required">อำเภอ</label>
          <AmphureSelect
            onChange={handleAmphureOptionChange}
            disable={disable}
            value={employee.official_amphure_id}/>
          <input type="text" className="form-control" name="official_amphure_id"
                 hidden={true}
                 value={employee.official_amphure_id || ''}
                 required={true}
                 onChange={() => {
                 }}/>
          <div className="invalid-feedback">
            กรุณากรอกอำเภอ
          </div>
        </div>
        <div className={`col-sm-12 col-md-4 pb-3 ${employee.official_tambon_id !== oldEmployee.official_tambon_id ? 'field-warning' : ''}`}>
          <label className="form-label required">ตำบล</label>
          <TambonSelect
            onChange={handleTambonOptionChange}
            disable={disable}
            value={employee.official_tambon_id}/>
          <input type="text" className="form-control" name="official_tambon_id"
                 hidden={true}
                 value={employee.official_tambon_id || ''}
                 required={true}
                 onChange={() => {
                 }}/>
          <div className="invalid-feedback">
            กรุณากรอกตำบล
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressOfficialForm;
import React, { FC, useEffect, useState } from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import OrganizationalStructuresTable from './components/organizational-structures-table';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../shared/services/notification-service';
import { apiOrganizationalStructureService } from '../../shared/services/organizational-structure-service';
import {
  OrganizationalStructureListQueryParams,
  OrganizationalStructureModelGet
} from '../../shared/models/organizational-structure.model';
import { useAuth } from '../../shared/authentications/auth-context';
import { hasPermission } from '../../shared/utils/roles';
import { OrderData } from '../../shared/components/table-header-ordering';

const OrganizationalStructuresListPage: FC = () => {
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [data, setData] = useState<ApiResponse<OrganizationalStructureModelGet>>(initialResponseData);
  const [queryParams, setQueryParams] = useState<OrganizationalStructureListQueryParams>({
    page: 1,
    page_size: 10,
    ordering_field: null,
    ordering: null
  });
  const {authJwt, permission} = useAuth();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await apiOrganizationalStructureService.getOrganizationalStructure(queryParams);
      setData(result.data);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, [queryParams]);

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const onClickAddData = () => {

  };

  const handleRefresh = () => {
    fetchData();
  };

  const onChangeOrder = (orderData: OrderData) => {
    setQueryParams({
      ...queryParams,
      ordering_field: orderData.field,
      ordering: orderData.order,
    });
  };

  return (
    <div>
      {loaderComponent}
      <div className="d-flex justify-content-between py-4">
        <h2 className="text-mof-primary m-0">โครงสร้างองค์กร</h2>
        {
          hasPermission(permission, ['organizational_structures'], 'create') ?
            <Link to="/organizational-structures/create/director">
              <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                      onClick={onClickAddData}>
                <FontAwesomeIcon icon={faPlus} className="me-2"/>
                สร้างโครงสร้างองค์กร
              </Button>
            </Link> : null
        }
      </div>

      <div className="py-2">
        <OrganizationalStructuresTable rows={data.rows} onRefresh={handleRefresh}
                                       onChangeOrder={onChangeOrder}/>
      </div>

      <div className="">
        <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                        onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
      </div>
    </div>
  );
};

export default OrganizationalStructuresListPage;
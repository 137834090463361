import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';

export interface Header {
    label: string;
    field: string;
    order: null | 'DESC' | 'ASC';
    isOrder: boolean;
}

export interface OrderData {
    field: string;
    order: null | 'DESC' | 'ASC';
}

interface TableHeaderOrderingProps {
    headers: Header[];
    onChangeOrder: (orderData: OrderData) => void;
    isShow: boolean;
    isCheckboxDisplay?: boolean | null;
    isCheckAll?: boolean;
    setIsCheckAll?: () => void;
}

interface HeaderOrderProps extends Header {
    index: number;
    onChange: (index: number, data: Header) => void;
    isShow: boolean;
    isCheckboxDisplay?: boolean | null;
    isCheckAll?: boolean;
    setIsCheckAll?: () => any;
}

const TableHeaderOrdering: FC<TableHeaderOrderingProps> = ({headers, onChangeOrder, isShow = false , isCheckboxDisplay = false, isCheckAll, setIsCheckAll}) => {
    const [localHeaders, setLocalHeaders] = useState(headers);
    const [currentOrderIndex, setCurrentOrderIndex] = useState(-1);

    const onChange = (index: number, data: Header) => {
        const updateNew = [...localHeaders];
        updateNew[index] = data;
        if (currentOrderIndex !== index) {
            setCurrentOrderIndex(index);
            if (currentOrderIndex !== -1)
                updateNew[currentOrderIndex].order = null;
        }
        setLocalHeaders(updateNew);

        onChangeOrder({
            order: updateNew[index].order,
            field: updateNew[index].field
        });
    };

    return <tr className="border-0">
        {
            localHeaders.map((header, index) => {
                return <HeaderOrder key={index}
                                    isShow={isShow}
                                    onChange={onChange}
                                    index={index}
                                    label={header.label}
                                    field={header.field}
                                    isOrder={header.isOrder}
                                    order={header.order}
                                    isCheckboxDisplay={index === 0 ? isCheckboxDisplay : null}
                                    isCheckAll={isCheckAll}
                                    setIsCheckAll={setIsCheckAll}
                />;
            })
        }
    </tr>;
};

const HeaderOrder: FC<HeaderOrderProps> = ({
                                               index,
                                               onChange,
                                               label,
                                               field,
                                               order,
                                               isOrder,
                                               isShow = false,
                                               isCheckboxDisplay = false,
                                               isCheckAll,
                                               setIsCheckAll
                                           }) => {
  console.log('isCheckAll12312312312', isCheckAll)
    const onClickSort = () => {
        if (!isOrder)
            return;

        let setOrder: null | 'DESC' | 'ASC' = null;
        if (order === 'DESC')
            setOrder = null;
        else if (order === 'ASC')
            setOrder = 'DESC';
        else if (order === null)
            setOrder = 'ASC';

        const data: Header = {
            label: label,
            field: field,
            order: setOrder,
            isOrder: isOrder,
        };
        onChange(index, data);
    };

    const iconSorting = () => {
        if (isOrder && isShow) {
            if (order === null)
                return <FontAwesomeIcon icon={faSort} className="ms-2"/>;
            if (order === 'DESC')
                return <FontAwesomeIcon icon={faSortDesc} className="ms-2"/>;
            if (order === 'ASC')
                return <FontAwesomeIcon icon={faSortAsc} className="ms-2"/>;
        }
        return null;
    };

    return <th onClick={onClickSort} className={`${label === '' ? '' : 'min-150'} ${isOrder ? 'pointer' : ''}`}>
        {isCheckboxDisplay ? <input className="form-check-input" type="checkbox"
                                    name="flexCheckDefault" defaultChecked={isCheckAll && isCheckAll ? isCheckAll : false} checked={isCheckAll && isCheckAll ? isCheckAll : false}
                                    disabled={false} onChange={(e) => {
                                        if (setIsCheckAll) {
                                          setIsCheckAll()
                                        }
        }} id="same-address"/> : null}
        {label}
        {isCheckboxDisplay ? null : iconSorting()}
    </th>;
};

export default TableHeaderOrdering;
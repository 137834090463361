import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type ConfirmModalProps = {
  show: boolean;
  title: string;
  description: string | any;
  size?: 'sm' | 'lg' | 'xl';
  confirmLabel?: string;
  onHide: () => void;
  onConfirm: () => void;
};


const ConfirmModal: React.FC<ConfirmModalProps> = ({
                                                     show,
                                                     title,
                                                     description,
                                                     size,
                                                     confirmLabel = 'ยืนยัน',
                                                     onHide,
                                                     onConfirm
                                                   }) => {
  return (
    <Modal show={show} onHide={onHide} centered size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;

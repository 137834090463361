import { ChangeEvent, FC, useEffect, useState } from 'react';
import AddressInputSelect, {
    AddressInputSelectModel
} from '../../../../../shared/components/molecules/commons/address-input-select';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../../shared/services/employee-service';
import { handleEmployeeInputChange } from '../../../../../shared/utils/function-state';
import ZipcodeSelect from '../../inputs/zipcode-select';
import ProvinceSelect from "../../inputs/province-select";
import AmphureSelect from "../../inputs/amphure-select";
import TambonSelect from "../../inputs/tambon-select";

const AddressContactableForm: FC = () => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);

    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const [sameAddress, setSameAddress] = useState(false);

    const onSelectAddress = (data: AddressInputSelectModel) => {
        // handleEmployeeInputChange({target: {name: 'contactable_province_id', value: data.province}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'contactable_amphure_id', value: data.amphure}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'contactable_tambon_id', value: data.tambon}}, dispatch);
        // handleEmployeeInputChange({target: {name: 'postcode_contactable', value: data.zip_code}}, dispatch);
    };

    const handleOnCheckSameAddress = (e: ChangeEvent<HTMLInputElement>) => {
        setSameAddress(e.target.checked);
        if (e.target.checked) {
            updateAddress();
        }
    };

    useEffect(() => {
        if (sameAddress) {
            updateAddress();
        }
    }, [
        employee.address_official,
        employee.official_province_id,
        employee.official_amphure_id,
        employee.official_tambon_id,
        employee.postcode_official,
    ]);

    const updateAddress = () => {
        handleEmployeeInputChange({
            target: {
                name: 'address_contactable',
                value: employee.address_official
            }
        }, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'contactable_province_id',
                value: employee.official_province_id
            }
        }, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'contactable_amphure_id',
                value: employee.official_amphure_id
            }
        }, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'contactable_tambon_id',
                value: employee.official_tambon_id
            }
        }, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'postcode_contactable',
                value: employee.postcode_official
            }
        }, dispatch);
    };

    const handleZipcodeOptionChange = (dropdown: any) => {
        const value = {
            target: {
                name: 'postcode_contactable',
                value: dropdown.value
            }
        };
        handleEmployeeInputChange(value, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'contactable_province_id',
                value: dropdown.amphure.province.value
            }
        }, dispatch);
        handleEmployeeInputChange({
            target: {
                name: 'contactable_amphure_id',
                value: dropdown.amphure.value
            }
        }, dispatch);
        handleEmployeeInputChange({target: {name: 'contactable_tambon_id', value: dropdown.id}}, dispatch);
    };

  const handleProvinceOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'contactable_province_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleAmphureOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'contactable_amphure_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const handleTambonOptionChange = (dropdown: any) => {
    handleEmployeeInputChange({
      target: {
        name: 'contactable_tambon_id',
        value: dropdown?.value
      }
    }, dispatch);
  }

  const isSameAddress = employee.contactable_amphure_id === employee.official_amphure_id
    && employee.contactable_province_id === employee.official_province_id &&
    employee.contactable_tambon_id === employee.official_tambon_id &&
    employee.postcode_contactable === employee.postcode_official;

    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" name="flexCheckDefault" defaultChecked={isSameAddress}
                               disabled={disable} onChange={handleOnCheckSameAddress} id="same-address"/>
                        <label className="form-check-label pointer" htmlFor="same-address">
                            เหมือนที่อยู่ตามบัตรประชาชน
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`col-sm-12 col-md-8 pb-3 ${employee.address_contactable !== oldEmployee.address_contactable ? 'field-warning' : ''}`}>
                    <label className="form-label required">สถานที่</label>
                    <input type="text" className="form-control" name="address_contactable"
                           placeholder="" disabled={disable || sameAddress} value={employee.address_contactable || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกสถานที่
                    </div>
                </div>
                {/*<div className={`col-sm-12 col-md-4 pb-3 ${employee.postcode_contactable !== oldEmployee.postcode_contactable ? 'field-warning' : ''}`}>*/}
                {/*    <label className="form-label required">รหัสไปรษณีย์</label>*/}
                {/*    <ZipcodeSelect*/}
                {/*        onChange={handleZipcodeOptionChange}*/}
                {/*        disable={disable || sameAddress}*/}
                {/*        value={employee.postcode_contactable}/>*/}
                {/*    <input type="text" className="form-control" name="postcode_contactable"*/}
                {/*           placeholder="กรุณาเลือก จังหวัด อำเภอ ตำบล" disabled={true}*/}
                {/*           hidden={true}*/}
                {/*           value={employee.postcode_contactable || ''}*/}
                {/*           required={true} readOnly={true}*/}
                {/*           onChange={() => {*/}
                {/*           }}/>*/}
                {/*    <div className="invalid-feedback">*/}
                {/*        กรุณากรอกรหัสไปรษณีย์*/}
                {/*    </div>*/}
                {/*</div>*/}
              <div className={`col-sm-12 col-md-4 pb-3 ${employee.postcode_contactable !== oldEmployee.postcode_contactable ? 'field-warning' : ''}`}>
                <label className="form-label required">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" name="postcode_contactable"
                       placeholder="" disabled={disable} value={employee.postcode_contactable || ''}
                       required={true}
                       onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                <div className="invalid-feedback">
                  กรุณากรอกรหัสไปรษณีย์
                </div>
              </div>
            </div>
            {/*<div className={`pb-3 */}
            {/*${employee.contactable_province_id !== oldEmployee.contactable_province_id ? 'field-warning ': ''}*/}
            {/*${employee.contactable_amphure_id !== oldEmployee.contactable_amphure_id ? 'field-warning ': ''}*/}
            {/*${employee.contactable_tambon_id !== oldEmployee.contactable_tambon_id ? 'field-warning ': ''}*/}
            {/*${employee.postcode_contactable !== oldEmployee.postcode_contactable ? 'field-warning ': ''}*/}
            {/*`}>*/}
            {/*    <AddressInputSelect onSelectData={onSelectAddress} isDisable={true}*/}
            {/*                        province={employee.contactable_province_id}*/}
            {/*                        amphure={employee.contactable_amphure_id}*/}
            {/*                        tambon={employee.contactable_tambon_id}*/}
            {/*                        zip_code={employee.postcode_contactable}/>*/}
            {/*</div>*/}
          <div className="row">
            <div className={`col-sm-12 col-md-4 pb-3 ${employee.contactable_province_id !== oldEmployee.contactable_province_id ? 'field-warning' : ''}`}>
              <label className="form-label required">จังหวัด</label>
              <ProvinceSelect
                onChange={handleProvinceOptionChange}
                disable={disable}
                value={employee.contactable_province_id}/>
              <input type="text" className="form-control" name="contactable_province_id"
                     hidden={true}
                     value={employee.contactable_province_id || ''}
                     required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณากรอกจังหวัด
              </div>
            </div>
            <div className={`col-sm-12 col-md-4 pb-3 ${employee.contactable_amphure_id !== oldEmployee.contactable_amphure_id ? 'field-warning' : ''}`}>
              <label className="form-label required">อำเภอ</label>
              <AmphureSelect
                onChange={handleAmphureOptionChange}
                disable={disable}
                value={employee.contactable_amphure_id}/>
              <input type="text" className="form-control" name="contactable_amphure_id"
                     hidden={true}
                     value={employee.contactable_amphure_id || ''}
                     required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณากรอกอำเภอ
              </div>
            </div>
            <div className={`col-sm-12 col-md-4 pb-3 ${employee.contactable_tambon_id !== oldEmployee.contactable_tambon_id ? 'field-warning' : ''}`}>
              <label className="form-label required">ตำบล</label>
              <TambonSelect
                onChange={handleTambonOptionChange}
                disable={disable}
                value={employee.contactable_tambon_id}/>
              <input type="text" className="form-control" name="contactable_tambon_id"
                     hidden={true}
                     value={employee.contactable_tambon_id || ''}
                     required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณากรอกตำบล
              </div>
            </div>
          </div>
        </div>
    );
};

export default AddressContactableForm;
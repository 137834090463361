import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { EditProfileRequest, RequestRegisterQueryParams } from '../../../shared/models/request.model';
import { ApiResponse } from '../../../shared/models/common.model';
import { initialResponseData } from '../../../shared/utils/constants';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../../shared/components/molecules/commons/pagination-form';
import TableRowNoData from '../../../shared/components/molecules/commons/table-row-no-data';
import { RequestService } from '../../../shared/services/request-service';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../../shared/services/notification-service';
import Moment from 'moment';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import InputReasonModal from '../../../shared/components/molecules/modals/input-reason-modal';
import { useNavigate } from 'react-router-dom';
import { hasPermission } from '../../../shared/utils/roles';
import { useAuth } from '../../../shared/authentications/auth-context';

interface EditProfileRequestsRowProps extends EditProfileRequest {
  onAction: (status: 'approve' | 'reject', id: any) => void;
}

const EditProfileRequestsPage: FC = () => {
  const [queryParams, setQueryParams] = useState<RequestRegisterQueryParams>({
    search: '',
    ordering_field: '',
    ordering: '',
    page: 1,
    page_size: 10,
  });
  const [data, setData] = useState<ApiResponse<EditProfileRequest>>(initialResponseData);
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [idTarget, setIdTarget] = useState<any>(null);

  const headers: Header[] = [
    {
      label: 'Username',
      field: 'username',
      order: null,
      isOrder: true,
    },
    {
      label: 'ชื่อ - นามสกุล',
      field: 'full_name_th',
      order: null,
      isOrder: true,
    },
    {
      label: 'อีเมล',
      field: 'email',
      order: null,
      isOrder: true,
    },
    {
      label: 'เบอร์โทรศัพท์',
      field: 'phone',
      order: null,
      isOrder: true,
    },
    {
      label: 'วันที่แก้ไข',
      field: 'created_at',
      order: null,
      isOrder: true,
    },
    {
      label: 'สถานะ',
      field: '',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  useEffect(() => {
    fetchData();
  }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await RequestService.getEditProfileRequest(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangeOrder = (orderData: OrderData) => {
    setQueryParams({
      ...queryParams,
      ordering_field: orderData.field,
      ordering: orderData.order,
    });
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prevState => {
      return {...prevState, search: e.target.value};
    });
  };

  const onAction = (status: 'approve' | 'reject', id: any) => {
    setIdTarget(id);
    if (status === 'approve') {
      setConfirmModal(true);
    } else {
      setRejectModal(true);
    }
  };

  const onApprove = async () => {
    handleCloseModal();
    startLoading();
    try {
      await RequestService.onApproveEditProfileRequest(idTarget, {});
      notificationService.successNotification('อนุมัติสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onReject = async (reason: any) => {
    handleCloseModal();
    startLoading();
    const data = {
      reason: reason
    };
    try {
      await RequestService.onRejectEditProfileRequest(idTarget, data);
      notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setRejectModal(false);
  };

  const displayModal = () => {
    if (showConfirmModal) {
      return <ConfirmModal
        show={showConfirmModal}
        title={'ยืนยันการอนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการอนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onApprove}
      />;
    }
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onReject}
      />;
    }
    return;
  };

  return <>
    {loaderComponent}
    {displayModal()}
    <div className="row">
      <div className="col-sm-12 col-md-3">
        <input className="form-control" type="text" value={queryParams.search}
               onChange={onChangeSearch}
               placeholder="ค้นหา..." name="search"/>
      </div>
      <div className="col-sm-12 col-md-2">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows.map((data, index) => {
            return <EditProfileRequestsRow key={data.id}
                                           {...data}
                                           onAction={onAction}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>
  </>;
};

const EditProfileRequestsRow: FC<EditProfileRequestsRowProps> = ({
                                                                   id,
                                                                   status,
                                                                   created_by_employee,
                                                                   created_at,
                                                                   onAction,
                                                                   created_by_user
                                                                 }) => {
  const {permission} = useAuth();
  const navigate = useNavigate();

  const getStatusDisplay = () => {
    if (status === 'WAITING_FOR_ACTIONS')
      return 'รอการอนุมัติ';
    if (status === 'APPROVED')
      return 'อนุมัติ';
    if (status === 'REJECTED')
      return 'ไม่อนุมัติ';
  };

  const handleOnApprove = (e: FormEvent) => {
    e.stopPropagation();
    onAction('approve', id);
  };

  const handleOnReject = (e: FormEvent) => {
    e.stopPropagation();
    onAction('reject', id);
  };

  const displayButton = () => {
    if (status === 'WAITING_FOR_ACTIONS')
      return hasPermission(permission, ['employee_edit_requests'], 'approve') ? <>
        <Button variant="secondary" className="" onClick={onNavigateToDetail}>
          ดูรายละเอียด
        </Button>
        {/*<Button variant="primary" className="btn-mof-primary" onClick={handleOnApprove}>*/}
        {/*  อนุมัติ*/}
        {/*</Button>*/}
        {/*<Button variant="danger" className="text-white ms-2" onClick={handleOnReject}>*/}
        {/*  ไม่อนุมัติ*/}
        {/*</Button>*/}
      </> : null;
    return <>
    </>;
  };

  const onNavigateToDetail = (e: FormEvent) => {
    navigate(`/profile-edit-requests/${id.toString()}/information`)
  }

  return <tr onClick={onNavigateToDetail} className="pointer">
    <td>{created_by_user?.username}</td>
    <td>{created_by_employee?.full_name_th}</td>
    <td>{created_by_user?.email}</td>
    <td>{created_by_employee?.phone}</td>
    <td>{Moment(created_at).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')}</td>
    <td>{getStatusDisplay()}</td>
    <td>
      {
        displayButton()
      }
    </td>
  </tr>;
};

export default EditProfileRequestsPage;
import React, {FC} from 'react';
import {LeaveTypeNames} from "../../../../shared/models/leave-request.model";

export interface LeaveCardModel {
  total_days_allowed: number;
  type: string;
  used_days: number;
}

const LeaveCard = ({used_days, total_days_allowed, type}: LeaveCardModel) => {
  return <div className="border-leave card border-2 my-1 px-4 py-2 rounded-4">
    <div className="row">
      <div className="col-sm-12 col-md-6">
        {type}
      </div>
      <div className="col-sm-12 col-md-6 d-flex justify-content-end text-leave">
        {used_days}/{total_days_allowed}
        <span className="ms-1">วัน</span>
      </div>
    </div>
  </div>
}

export default LeaveCard;
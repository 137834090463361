import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {ManageUserService} from "../../../../../shared/services/Report/ManageUser/manage-user";
import {useParams} from 'react-router-dom';
import {Table} from 'react-bootstrap';
import TableHeaderOrdering, {Header} from "../../../../../shared/components/table-header-ordering";
import TableRowNoData from "../../../../../shared/components/molecules/commons/table-row-no-data";
import {calculateAge} from '../../../../../shared/utils/function-employees';
import Moment from "moment";

const headers: Header[] = [
  {
    label: 'ชื่อ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'นามสกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เพศ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันเดือนปีเกิด',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุ',
    field: '',
    order: null,
    isOrder: false,
  }
];

const PersonnelFamilyDetail: FC = () => {

  let {employee_id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await ManageUserService.getReportEditEmployeeDetailDetail({employee_id: employee_id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData0 = () => {
    if (data[0]?.childern?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const tableRowNoData1 = () => {
    if (data[1]?.childern?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAge = (data:any) => {
		if (data) {
			return calculateAge(data)+" ปี";
		}
		return '';
	};

  const styleNewEdit_div = "bg-success-subtle";
  const styleNewEdit_input = "form-control mt-2 border-success border-3";

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">รายละเอียดครอบครัว</h5>
          </Accordion.Header>
          <Accordion.Body>
            {/* บิดา */}
            <h5 className="text-mof-primary m-0 pt-1 pb-2">บิดา</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_father_prefix_name === data[1]?.contact_father_prefix_name ? '' : styleNewEdit_div}`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_father_prefix_name ? data[1]?.contact_father_prefix_name : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_father_prefix_name === data[1]?.contact_father_prefix_name ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_father_prefix_name ? data[0]?.contact_father_prefix_name : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_father_firstname === data[1]?.contact_father_firstname ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_father_firstname ? data[1]?.contact_father_firstname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_father_firstname === data[1]?.contact_father_firstname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_father_firstname ? data[0]?.contact_father_firstname : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_father_lastname === data[1]?.contact_father_lastname ? '' : styleNewEdit_div}`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_father_lastname ? data[1]?.contact_father_lastname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_father_lastname === data[1]?.contact_father_lastname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_father_lastname ? data[0]?.contact_father_lastname : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_father_status === data[1]?.contact_father_status ? '' : styleNewEdit_div}`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_father_status ? data[1]?.contact_father_status : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_father_status === data[1]?.contact_father_status ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_father_status ? data[0]?.contact_father_status : ''}/>
              </div>
            </div>

            {/* มารดา */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">มารดา</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_mother_prefix_name === data[1]?.contact_mother_prefix_name ? '' : styleNewEdit_div}`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_mother_prefix_name ? data[1]?.contact_mother_prefix_name : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_mother_prefix_name === data[1]?.contact_mother_prefix_name ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_mother_prefix_name ? data[0]?.contact_mother_prefix_name : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_mother_firstname === data[1]?.contact_mother_firstname ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_mother_firstname ? data[1]?.contact_mother_firstname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_mother_firstname === data[1]?.contact_mother_firstname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_mother_firstname ? data[0]?.contact_mother_firstname : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_mother_lastname === data[1]?.contact_mother_lastname ? '' : styleNewEdit_div}`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_mother_lastname ? data[1]?.contact_mother_lastname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_mother_lastname === data[1]?.contact_mother_lastname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_mother_lastname ? data[0]?.contact_mother_lastname : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_father_status === data[1]?.contact_father_status ? '' : styleNewEdit_div}`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_father_status ? data[1]?.contact_father_status : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_father_status === data[1]?.contact_father_status ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_father_status ? data[0]?.contact_father_status : ''}/>
              </div>
            </div>

            {/* คู่สมรส */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">คู่สมรส</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_spouse_status === data[1]?.contact_spouse_status ? '' : styleNewEdit_div}`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_spouse_status ? data[1]?.contact_spouse_status : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_spouse_status === data[1]?.contact_spouse_status ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_spouse_status ? data[0]?.contact_spouse_status : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_spouse_firstname === data[1]?.contact_spouse_firstname ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_spouse_firstname ? data[1]?.contact_spouse_firstname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_spouse_firstname === data[1]?.contact_spouse_firstname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_spouse_firstname ? data[0]?.contact_spouse_firstname : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contact_spouse_lastname === data[1]?.contact_spouse_lastname ? '' : styleNewEdit_div}`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contact_spouse_lastname ? data[1]?.contact_spouse_lastname : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contact_spouse_lastname === data[1]?.contact_spouse_lastname ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contact_spouse_lastname ? data[0]?.contact_spouse_lastname : ''}/>
              </div>
            </div>

            {/* จำนวนบุตร */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">จำนวนบุตร</h5>
            <div className="overflow-auto mt-3">
              {data[0]?.childern?.length === 0 && data[1]?.childern?.length === 0 ? '' : <h6 className="text-mof-primary m-0 pt-2 pb-2">ข้อมูลก่อนหน้า</h6>}
              <Table bordered>
                <thead>
                  <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                </thead>
                <tbody>
                  {data[1]?.childern?.map((data:any,index:any) => {
                    return <tr key={index}>
                      <td>{data.first_name ? data.first_name : ''}</td>
                      <td>{data.last_name ? data.last_name : ''}</td>
                      <td>{data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : ''}</td>
                      <td>{data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : ''}</td>
                      <td>{data.birthday ? getAge(data.birthday) : ''}</td>
                    </tr>
                  })}
                  {tableRowNoData1()}
                </tbody>
              </Table>
            </div>

            {
              data[0]?.childern?.length === 0 && data[1]?.childern?.length === 0 ? '' :
              <div className="overflow-auto mt-3">
                <h6 className="text-mof-primary m-0 pt-2 pb-2">ข้อมูลล่าสุด</h6>
                <Table bordered>
                  <thead>
                    <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                  </thead>
                  <tbody>
                    {data[0]?.childern?.map((data:any,index:any) => {
                      return <tr key={index}>
                        <td>{data.first_name ? data.first_name : ''}</td>
                        <td>{data.last_name ? data.last_name : ''}</td>
                        <td>{data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : ''}</td>
                        <td>{data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : ''}</td>
                        <td>{data.birthday ? getAge(data.birthday) : ''}</td>
                      </tr>
                    })}
                    {tableRowNoData0()}
                  </tbody>
                </Table>
              </div>
            }
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelFamilyDetail;
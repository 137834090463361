import React from 'react';
import {Button, ProgressBar} from "react-bootstrap";
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import {formType} from "../pages/approver-leave-request-detail-page";
import {LeaveTypeNames} from "../../../shared/models/leave-request.model";
import {getMediaPath} from "../../../shared/utils/function-media";
import { Image } from 'react-bootstrap';

type PageProps = {
  open: boolean;
  handleClose: () => void;
  userData?: formType;
  userLeaveTypeTotalData?: {
    totalLeaveAmount: number;
    leaveByTypeTotal: LeaveData[];
    usedLeavePercentage: number;
  };
};

interface LeaveData {
  type: string;
  used_days: number;
  total_days_allowed: number;
}


const LeaveHistoryDialog = ({open, handleClose, userData, userLeaveTypeTotalData}: PageProps) => {
  const [isImageError, setIsImageError] = React.useState<boolean>(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"lg"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            height: "800px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" sx={{fontWeight: "Medium", color: "#056310"}}
                      fontFamily={`"Anuphan", sans-serif`}>ประวัติการลา</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex flex-row column-gap-2 row-gap-2 justify-content-center flex-wrap" style={{height:"fit-content"}}>

            <div className="home-card px-3 py-3 card shadow rounded-4" style={{width: "176px", height:"-webkit-fit-content"}}>
                <div className="d-flex justify-content-center object-fit-contain"
                     style={{maxHeight: '110px', minWidth: '96px'}}>
                    <Image className="profile-logo object-fit-cover" src={isImageError ? '/static/no-image.png' : userData?.created_by_user?.employee?.photo ? getMediaPath(userData?.created_by_user?.employee?.photo as string) : '/static/no-image.png'}
                         alt="not photo image" onError={()=>{setIsImageError(true)}}/>
                </div>
              <p className="my-2 text-center d-block">
               {userData?.created_by_user?.fullname_th}sds
              </p>
              <p className="text-secondary my-2 text-center d-block">
                <b>แผนก:</b> {userData?.created_by_user?.employee?.job_positions?.find((data) => data?.is_currently === true)?.position_name}
              </p>
            </div>

            <div className="home-card px-3 py-4 card shadow rounded-4 row-gap-1"
                 style={{width: "176px", height: "auto"}}>
              <div className="w-100 d-flex justify-content-between">
                <h2 className="text-danger d-inline">
                  <b>0</b>
                </h2>
                <h3 className="d-inline">
                  <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
                </h3>
              </div>
              <p className="text-secondary mb-1 mt-3">
                จำนวนขาดงาน
              </p>
              <div className="d-flex justify-content-between column-gap-3">
                <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'danger'}/>
                <span className="text-secondary">0%</span>
              </div>
            </div>

            <div className="home-card px-3 py-4 card shadow rounded-4 row-gap-1"
                 style={{width: "176px", height: "auto"}}>
              <div className="w-100 d-flex justify-content-between">
                <h2 className="text-danger d-inline">
                  <b>{userLeaveTypeTotalData?.totalLeaveAmount}</b>
                </h2>
                <h3 className="d-inline">
                  <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
                </h3>
              </div>
              <p className="text-secondary mb-1 mt-3">
                จำนวนลางาน
              </p>
              <div className="d-flex justify-content-between column-gap-3">
                <ProgressBar className="home-process-bar w-75 d-inline-flex" now={userLeaveTypeTotalData?.usedLeavePercentage} variant={'danger'}/>
                <span className="text-secondary">{userLeaveTypeTotalData?.usedLeavePercentage}%</span>
              </div>
            </div>

            <div className="home-card px-3 py-4 card shadow rounded-4 row-gap-1"
                 style={{width: "176px", height: "auto"}}>
              <div className="w-100 d-flex justify-content-between">
                <h2 className="text-danger d-inline">
                  <b>0</b>
                </h2>
                <h3 className="d-inline">
                  <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
                </h3>
              </div>
              <p className="text-secondary mb-1 mt-3">
                จำนวนเข้างานสาย
              </p>
              <div className="d-flex justify-content-between column-gap-3">
                <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'danger'}/>
                <span className="text-secondary">0%</span>
              </div>
            </div>

            <div className="home-card px-3 py-4 card shadow rounded-4 row-gap-1"
                 style={{width: "176px", height: "auto"}}>
              <div className="w-100 d-flex justify-content-between">
                <h2 className="text-info d-inline">
                  <b>0</b>
                </h2>
                <h3 className="d-inline">
                  <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
                </h3>
              </div>
              <p className="text-info mb-1 mt-3">
                จำนวนตรงเวลา
              </p>
              <div className="d-flex justify-content-between column-gap-3">
                <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'info'}/>
                <span className="text-secondary">0%</span>
              </div>
            </div>

          </div>

          <div className="px-3 py-3 card shadow rounded-4" style={{transform: "scale(0.9)", marginTop: "16px"}}>
            <Typography variant="h5" align="left" sx={{fontWeight: "Medium", color: "#007BFF", marginBottom: "16px"}}
                        fontFamily={`"Anuphan", sans-serif`}>สิทธ์การลา</Typography>
            {
              userLeaveTypeTotalData?.leaveByTypeTotal?.map((data) => {
                return <>
                  <div className="border-leave card border-2 my-1 px-4 py-2 rounded-4">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        {data?.type}
                      </div>
                      <div className="col-sm-12 col-md-6 d-flex justify-content-end text-leave">
                        {data?.used_days}/{data?.total_days_allowed}
                        <span className="ms-1">วัน</span>
                      </div>
                    </div>
                  </div>
                </>
              })
            }
          </div>
        </DialogContent>
        <div
          className="d-flex flex-row col-12 justify-content-center column-gap-3 mb-4">
          <div className="">
            <Button variant="secondary" type="button"
                    onClick={handleClose}
                    className="d-block btn-action"
            >
              ย้อนกลับ
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default LeaveHistoryDialog;


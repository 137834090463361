import React, {FC, ReactElement, useEffect, useState} from 'react';
import { Pagination } from 'react-bootstrap';
import { PaginationComponentProp, SelectDropdown } from '../../../models/common.model';
import { PageSizeOptions } from '../../../utils/constants';
import InputSelect from '../../atoms/input-select';

const PaginationForm: FC<PaginationComponentProp> = ({
                                                         current_page = 1,
                                                         count = 0,
                                                         page_size = 10,
                                                         onChangePageSize,
                                                         onChangePage
                                                     }) => {
    const total_pages = Math.ceil(count / page_size);
    const noData = total_pages === 0;

    const [paginationItems, setPaginationItems] = useState<ReactElement[]>([]);

  useEffect(() => {
    generatePaginationItems();
  }, [current_page, total_pages]);

  const generatePaginationItems = () => {
    const maxPageButtons = 5;
    let startPage, endPage;

    if (total_pages <= maxPageButtons) {

      startPage = 1;
      endPage = total_pages;
    } else {

      const maxPagesBeforeCurrentPage = Math.floor(maxPageButtons / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPageButtons / 2) - 1;
      if (current_page <= maxPagesBeforeCurrentPage) {

        startPage = 1;
        endPage = maxPageButtons;
      } else if (current_page + maxPagesAfterCurrentPage >= total_pages) {

        startPage = total_pages - maxPageButtons + 1;
        endPage = total_pages;
      } else {

        startPage = current_page - maxPagesBeforeCurrentPage;
        endPage = current_page + maxPagesAfterCurrentPage;
      }
    }

    const newPaginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      newPaginationItems.push(
        <Pagination.Item key={i} active={i === current_page} onClick={() => handleOnChangePage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    setPaginationItems(newPaginationItems);

  };

    const handleOnChangePageSize = (e: SelectDropdown) => {
        if (e.value) {
            onChangePageSize(+e.value!.value);
        }
    };

    const handleOnChangePage = (page: number) => {
        onChangePage(page);
    };

    const handlePrevPage = () => {
        onChangePage(current_page - 1);
    };

    const handleNextPage = () => {
        onChangePage(current_page + 1);
    };

  const isLeftEllipsisDisabled = current_page <= 10;
  const isRightEllipsisDisabled = current_page >= total_pages - 9;


    return (
        // <div className="d-flex flex-column flex-sm-row justify-content-between">
      <div className="col">
            <div className="d-flex row-cols-6">
                <p className="me-2 align-self-center" style={{width:"fit-content"}}>ข้อมูลต่อหน้า</p>
                <InputSelect options={PageSizeOptions} placeholder="กรุณาเลือก" name="page_size" value={page_size}
                             onChange={handleOnChangePageSize} isClearable={false} isSearchable={false}/>
                <p className="ms-2 align-self-center">ทั้งหมด <b>{count}</b> ข้อมูล</p>

            </div>
            <div className="d-flex row-cols-6 mt-3">
                <Pagination>
                    <Pagination.First disabled={current_page === 1} onClick={() => handleOnChangePage(1)}/>
                    <Pagination.Prev disabled={current_page === 1} onClick={handlePrevPage}/>
                    <Pagination.Ellipsis onClick={()=>{onChangePage(current_page - 10);}} disabled={isLeftEllipsisDisabled}/>
                    {paginationItems}
                    <Pagination.Ellipsis onClick={()=>{onChangePage(current_page + 10);}} disabled={isRightEllipsisDisabled}/>
                    <Pagination.Next disabled={current_page === total_pages || noData} onClick={handleNextPage}/>
                    <Pagination.Last disabled={current_page === total_pages || noData}
                                     onClick={() => handleOnChangePage(total_pages)}/>
                </Pagination>
            </div>
        </div>
    );
};

export default PaginationForm;

import {dayType} from "./types/type";

export const days: dayType[] = [
  {
    day: "SUN",
    value: 'sunday'
  },
  {
    day: "MON",
    value: 'monday'
  },
  {
    day: "TUE",
    value: 'tuesday'
  },
  {
    day: "WED",
    value: 'wednesday'
  },
  {
    day: "THU",
    value: 'thursday'
  },
  {
    day: "FRI",
    value: 'friday'
  },
  {
    day: "SAT",
    value: 'saturday'
  },
]

export const shiftRepeatEvery: {
  label: string;
  value: string;
}[] = [
  {
    label: "ทุก สัปดาห์",
    value: "every_week"
  },
  {
    label: "ทุก 1 สัปดาห์",
    value: "every_1_week"
  },
  {
    label: "ทุก 2 สัปดาห์",
    value: "every_2_weeks"
  },
  {
    label: "ทุก 3 สัปดาห์",
    value: "every_3_weeks"
  },
]
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { faCaretLeft, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '../../../../shared/models/common.model';
import InputSelect from '../../../../shared/components/atoms/input-select';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationalStructure } from '../../../../shared/services/organizational-structure-service';
import { notificationService } from '../../../../shared/services/notification-service';
import { convertToCurrency } from '../../../../shared/utils/currency';
import HeaderEmployeeSearch from '../inputs/header-employee-search';

type PersonnelModalProps = {
  show: boolean;
  onHide: () => void;
  onConfirm: (data: PersonnelModelSelect) => void;
};

export interface PersonnelModelSelect {
  structure_position_id: null | number;
  structure_position_index: null | number;
  user_id: null | number;
}

const PersonnelModal: React.FC<PersonnelModalProps> = ({
                                                         show,
                                                         onHide,
                                                         onConfirm
                                                       }) => {
  const [data, setData] = useState<PersonnelModelSelect>({
    structure_position_id: null,
    structure_position_index: null,
    user_id: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const [userId, setUserId] = useState<any>(null);

  const handleOnSelectEmployee = (value: Dropdown) => {
    const updateData: PersonnelModelSelect = {
      ...data,
      user_id: +value.value
    };
    setData(updateData);
    setUserId(value.value);
  };

  const handleOnSelectStructurePosition = (dataSelect: any) => {
    if (data.structure_position_index === dataSelect?.value?.value)
      return;
    const updateData: PersonnelModelSelect = {
      ...data,
      structure_position_index: dataSelect?.value?.value as number
    };
    setData(updateData);
  };

  const getPowerRateRemain = (structure_position_index: number | null) => {
    if (structure_position_index === null)
      return {
        label: '(อัตรากำลัง: -/-)',
        disabled: false
      };
    const powerRateCap = organizationalStructure.structure_positions[structure_position_index].power_rate || 0;
    const powerRateUsed = organizationalStructure.personnels.filter(data => data.structure_position_index === structure_position_index).length;
    return {
      label: `(อัตรากำลัง: ${convertToCurrency(powerRateUsed)}/${convertToCurrency(powerRateCap)})`,
      disabled: powerRateUsed >= powerRateCap
    };
  };

  const getPaymentOptions = () => {
    return organizationalStructure.structure_positions.map((data, index) => {
      const {label, disabled} = getPowerRateRemain(index);
      return {
        label: `${data.pay_account_number || '-'} ${label}`,
        value: index,
        disabled: disabled
      };
    });
  };

  const getPositionNameOptions = () => {
    return organizationalStructure.structure_positions.map((data, index) => {
      const {label, disabled} = getPowerRateRemain(index);
      return {
        label: `${data.name || '-'} ${label}`,
        value: index,
        disabled: disabled
      };
    });
  };

  const handleOnCLick = () => {
    if (!data.user_id || typeof data.structure_position_index != 'number') {
      notificationService.warningNotification('กรุณาเลือกข้อมูลให้ครบถ้วน', '');
      setSubmitted(true);
      return;
    }
    onConfirm(data);
    setSubmitted(false);
    setUserId(null);
    setData({
      structure_position_id: null,
      structure_position_index: null,
      user_id: null,
    });
  };

  const handleOnHide = () => {
    setSubmitted(false);
    setUserId(null);
    setData({
      structure_position_id: null,
      structure_position_index: null,
      user_id: null,
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide} centered size={'lg'}>
      <Modal.Header style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Modal.Title>
          <h4 className="text-center text-mof-primary m-0">
            เพิ่มบุคลากรเข้าโครงสร้างองค์กร
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={submitted}>
          <div className="row">
            <div className="col-sm-12">
              <label className="form-label required">รหัสพนักงาน หรือ ชื่อ - นามสกุล</label>
              <HeaderEmployeeSearch onChange={(value) => handleOnSelectEmployee(value)}
                                    value={userId}
                                    changeValue={organizationalStructure.personnels.map(data => data.user_id).join(',')}/>
              <input type="text" className="form-control" name="user_id" hidden={true}
                     value={data.user_id || ''} required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณาเลือกพนักงาน
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-md-6">
              <label className="form-label required">เลขบัญชีถือจ่าย</label>
              <InputSelect options={getPaymentOptions()} placeholder="" name="pay_account_number"
                           onChange={(e) => handleOnSelectStructurePosition(e)}
                           isClearable={false}
                           value={data.structure_position_index}/>
              <input type="text" className="form-control" name="structure_position_id" hidden={true}
                     value={data.structure_position_index?.toString() || ''} required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณาเลือกเลขบัญชีถือจ่าย
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <label className="form-label required">ตำแหน่ง</label>
              <InputSelect options={getPositionNameOptions()} placeholder="" name="name"
                           onChange={(e) => handleOnSelectStructurePosition(e)}
                           isClearable={false}
                           value={data.structure_position_index}/>
              <input type="text" className="form-control" name="structure_position_id" hidden={true}
                     value={data.structure_position_index?.toString() || ''} required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณาเลือกตำแหน่ง
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
        <Button variant="secondary" className="btn-action" onClick={handleOnHide}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
        <Button variant="primary" className="btn-action btn-mof-primary" onClick={handleOnCLick}>
          <FontAwesomeIcon icon={faPencil} className="me-2"/>
          เพิ่ม
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonnelModal;

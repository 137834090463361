import React, { useEffect, useState } from 'react';
// form
// @mui
import AlarmIcon from '@mui/icons-material/Alarm';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
// components
import ClearIcon from '@mui/icons-material/Clear';
import MenuPopover from './MenuPopover';
import styles from '../mui/css/timepicker.module.css';

// ----------------------------------------------------------------------

const DEFAULT_HOURS = Array.from(Array(24).keys());
const DEFAULT_MINUTES = Array.from(Array(60).keys());

type IProps = {
  mode?: 'HH' | 'HH:mm';
  minTime?: string;
  maxTime?: string;
  onTimeChange?: (value: string) => void;
  currentTime?: string;
  disabled?: boolean;
  timeType?: string;
  handleClearTime?: () => void;
  isSubmited?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFTimePicker({
                                        mode = 'HH:mm',
                                        minTime,
                                        maxTime,
                                        onTimeChange,
                                        disabled = false,
                                        currentTime,
                                        timeType,
                                        handleClearTime,
                                        isSubmited,
                                        ...other
                                      }: Props) {
  const [openTime, setOpenTime] = useState<HTMLElement | null>(null);
  const [hour, setHour] = useState<number[]>(DEFAULT_HOURS);
  const [minute] = useState<number[]>(DEFAULT_MINUTES);

  const isOnlyHour = mode == 'HH' ? true : false;

  useEffect(() => {
    const isEndTime = timeType == 'endTime' || timeType == 'breakTimeEnd';
    const isCheckMinTime = minTime != '';
    if (isEndTime && isOnlyHour && minTime && isCheckMinTime) {
      const _hourIndex = parseInt(minTime.substring(0, 2), 10);
      const _newHour = DEFAULT_HOURS.slice(_hourIndex + 1, DEFAULT_HOURS.length);
      setHour([..._newHour, 0]);
    }

    const isStartTime = timeType == 'startTime' || timeType == 'breakTimeStart';
    const isCheckMaxTime = maxTime != '';
    if (isStartTime && isOnlyHour && maxTime && isCheckMaxTime) {
      let _hourIndex = parseInt(maxTime.substring(0, 2), 10);
      if (_hourIndex == 0) {
        _hourIndex = 24;
      }
      const _newHour = DEFAULT_HOURS.slice(0, _hourIndex);
      setHour([..._newHour]);
    }
  }, [isOnlyHour, minTime, maxTime, timeType]);

  const handleOpenTime = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) return;
    setOpenTime(event.currentTarget);
  };

  const handleCloseTime = () => {
    setOpenTime(null);
  };

  const handleClear = () => {
    if (handleClearTime) return handleClearTime();
  };

  const handleClearIconClick = (event:any) => {
    handleClear();
    event.stopPropagation();
  };

  const currentValue = currentTime === '' ? '00:00' : currentTime;
  const currentHour:any = currentValue?.substring(0, 2);
  const currentMinute:any = currentValue?.substring(3, 5);

  return (
    <>
      <TextField
        fullWidth
        onClick={handleOpenTime}
        autoComplete="off"
        InputProps={{
          sx: { height:"40px", width:"150px" },
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  sx={{ visibility: currentTime ? 'visible' : 'hidden' }}
                  onClick={handleClearIconClick}
                  disabled={disabled}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
              <InputAdornment position="end">
                <IconButton edge="end">
                  <AlarmIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
          onKeyDown: (event) => {
            event.preventDefault();
          },
          readOnly: true,
        }}
        disabled={disabled}
        error={currentTime === '' && isSubmited}
        {...other}
      />
      <MenuPopover
        disabledArrow
        open={Boolean(openTime)}
        anchorEl={openTime}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ width: 150 }}
        onClose={handleCloseTime}
      >
        <div>
          <Stack direction="row" sx={{ height: 200 }}>
            <Paper
              className={styles.paper1}
              sx={{ maxHeight: 200, width: isOnlyHour ? 140 : 65, overflow: 'auto', p: 0.75 }}
            >
              {hour?.map((item) => {
                const hourValue = item < 10 ? `0${item}` : item;
                const selected = parseInt(currentHour, 10) == item ? true : false;
                return (
                  <MenuItem
                    key={`hour-${item}`}
                    selected={selected}
                    autoFocus={selected}
                    onClick={() => {
                      if (onTimeChange) {
                        onTimeChange(`${hourValue}:${currentMinute}`);
                      }
                    }}
                    sx={{
                      justifyContent: 'center',
                      borderRadius: 0.75,
                    }}
                  >
                    {hourValue}
                  </MenuItem>
                );
              })}
            </Paper>
            {!isOnlyHour ? (
              <Paper
                className={styles.paper2}
                sx={{ maxHeight: 200, overflow: 'auto', p: 0.75 }}
              >
                {minute?.map((item) => {
                  const minuteValue = item < 10 ? `0${item}` : item;
                  const selected = parseInt(currentMinute, 10) == item ? true : false;
                  return (
                    <MenuItem
                      key={`min-${item}`}
                      selected={selected}
                      autoFocus={selected}
                      onClick={() => {
                        if (onTimeChange) {
                          onTimeChange(`${currentHour}:${minuteValue}`);
                          handleCloseTime();
                        }
                      }}
                      sx={{
                        justifyContent: 'center',
                        borderRadius: 0.75,
                      }}
                    >
                      {minuteValue}
                    </MenuItem>
                  );
                })}
              </Paper>
            ) : null}
          </Stack>
        </div>
      </MenuPopover>
    </>
  );
}

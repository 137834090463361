import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { fileToBase64 } from '../../../shared/utils/function-attachment';
import { handleEmployeeInputChange } from '../../../shared/utils/function-state';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEmployee, getEmployeeAction } from '../../../shared/services/employee-service';
import { getMediaPath } from '../../../shared/utils/function-media';
import { Image } from 'react-bootstrap';

const EmployeePhotoForm: FC = () => {
    const dispatch = useDispatch();
    const employee = useSelector(getCurrentEmployee);
    const employeeAction = useSelector(getEmployeeAction);
    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const [imageUrlString, setImageUrlString] = useState<string | null>(null);
    const [isImageError, setIsImageError] = useState<boolean>(false);

    const photoFileInputRef = useRef<HTMLInputElement>(null);
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (fileList && fileList.length > 0) {
            fileToBase64(fileList[0]).then(base64String => {
                const value = {
                    target: {
                        name: 'photo',
                        value: base64String as string
                    }
                };
                const objectUrl = URL.createObjectURL(fileList[0]);
                setImageUrlString(objectUrl);
                handleEmployeeInputChange(value, dispatch);
            });
        }
    };

    const handleButtonClickUploadPhotoFile = () => {
        if (disable) {
            return;
        }

        if (photoFileInputRef.current) {
            photoFileInputRef.current.click();
        }
    };

    const displayPhoto = () => {
        if (employee.photo) {
            const src = imageUrlString !== null ? imageUrlString as string : getMediaPath(employee.photo);
            return <Image className={`employee-photo object-fit-cover ${!disable ? 'pointer' : ''}`}
                        src={isImageError ? "/static/no-image.png" : src}
                        onClick={handleButtonClickUploadPhotoFile}
                        alt="photo image"
                        onError={(e: any) => {setIsImageError(true)}}
            />

        }
        return <>
            <img src="/static/no-image.png"
                 className={!disable ? 'pointer' : ''}
                 onClick={handleButtonClickUploadPhotoFile}
                 alt="not photo image"/>
        </>;
    };

    return (
        <div className="employee-image-card d-flex justify-content-center">
            <input type="file"
                   ref={photoFileInputRef}
                   onChange={handleFileChange}
                   style={{display: 'none'}}
                   accept="image/*"
            />
            {displayPhoto()}
        </div>
    );
};

export default EmployeePhotoForm;
export const PageSizeOptions = [
    {label: '5', value: 5},
    {label: '10', value: 10},
    {label: '25', value: 25},
    {label: '50', value: 50},
];

export const PrefixNameTHOptions = [
    {label: 'นาย', value: 'นาย'},
    {label: 'นางสาว', value: 'นางสาว'},
    {label: 'นาง', value: 'นาง'},
];

export const PrefixNameAdultOptions = [
    {label: 'นาย', value: 'นาย'},
    {label: 'นางสาว', value: 'นางสาว'},
    {label: 'นาง', value: 'นาง'},
    {label: 'ว่าที่ ร.ต.หญิง', value: 'ว่าที่ ร.ต.หญิง'},
    {label: 'ว่าที่ ร.ต.', value: 'ว่าที่ ร.ต.'},
]

export const PrefixNameMaleOptions = [
    {label: 'นาย', value: 'นาย'},
];

export const PrefixNameFeMaleOptions = [
    {label: 'นางสาว', value: 'นางสาว'},
    {label: 'นาง', value: 'นาง'},
]

export const PrefixNameContactSpouseOptions = [
    {label: 'นาย', value: 'นาย'},
    {label: 'นางสาว', value: 'นางสาว'},
    {label: 'นาง', value: 'นาง'},
]

export const PrefixNameENOptions = [
    {label: 'Mr.', value: 'Mr.'},
    {label: 'Miss', value: 'Miss'},
    {label: 'Mrs.', value: 'Mrs.'},
    {label: 'Acting SubLt.', value: 'Acting SubLt.'},
];

export const GenderOptions = [
    {label: 'ชาย', value: 'MALE'},
    {label: 'หญิง', value: 'FEMALE'}
];

export const BloodType = [
    {label: 'A', value: 'A'},
    {label: 'B', value: 'B'},
    {label: 'AB', value: 'AB'},
    {label: 'O', value: 'O'},
];

export const EmployeeType = [
    {label: 'ทั้งหมด', value: null},
    {label: 'พนักงาน', value: 'พนักงาน'},
    {label: 'ลูกจ้างประจำ', value: 'ลูกจ้างประจำ'},
    {label: 'ลูกจ้างชั่วคราว', value: 'ลูกจ้างชั่วคราว'},
];

export const PositionLevel = [
    {label: 'ระดับ 7-10', value: 'ระดับ 7-10'},
    {label: 'ระดับ 6', value: 'ระดับ 6'},
    {label: 'ระดับ 3-5', value: 'ระดับ 3-5'},
];

export const PositionName = [
    {label: 'ตำแหน่งหลัก', value: 'ตำแหน่งหลัก'},
];


export const PositionType = [
    {label: 'รักษาการในตำแหน่ง', value: 'รักษาการในตำแหน่ง'},
    {label: 'ช่วยปฏิบัติงานแทนในตำแหน่ง', value: 'ช่วยปฏิบัติงานแทนในตำแหน่ง'},
    {label: 'ทำการแทนในตำแหน่ง', value: 'ทำการแทนในตำแหน่ง'},
    {label: 'ช่วยปฏิบัติงานแทนอีกตำแหน่ง', value: 'ช่วยปฏิบัติงานแทนอีกตำแหน่ง'},
];

export type EmployeeAttachmentType =
  'education_history' |
  'employee_certifier' |
  'insignia_history' |
  'name_change_history' |
  'outstanding_performance_award' |
  'punishment_history' |
  'resignation_retirement_death_history' |
  'training_history' |
  'job_position'

export const initialResponseData = {
    count: 0,
    total_pages: 0,
    current_page: 0,
    rows: [],
};

export const UserActiveStatus = [
    {label: 'ทั้งหมด', value: null},
    {label: 'ใช้งาน', value: true},
    {label: 'ระงับการใช้งาน', value: false},
];

export const UserActiveStatusOptions = [
  {label: 'ปกติ', value: null},
  {label: 'เกษียณ', value: 'resign'},
  {label: 'ลาออก', value: 'retire'},
  {label: 'ไล่ออก', value: 'fire'},
]

export const DivisionGroupOfficeTypeOptions = [
    {label: 'ผู้อำนวยการ', value: 'director'},
    {label: 'รองผู้อำนวยการ', value: 'deputy_director'},
    {label: 'สำนัก / ฝ่าย', value: 'normal'},
];

export const StructureDepartmentOfficeTypeOptions = [
    {label: 'ผู้อำนวยการ', value: 'director'},
    {label: 'รองผู้อำนวยการ', value: 'deputy_director'},
    {label: 'สำนัก / ฝ่าย', value: 'department_office'},
    {label: 'กอง / กลุ่ม / สำนักงาน', value: 'normal'},
];

export const LeaveRequestStatusOptions = [
    {label: 'ทั้งหมด', value: ""},
    {label: 'รออนุมัติ', value: 'waiting_for_approver'},
    {label: 'อนุมัติ', value: 'approved'},
    {label: 'ไม่อนุมัติ', value: 'rejected'},
];

export const LeaveRequestTypeOptions = [
    {label: 'ทั้งหมด', value: null},
    {label: 'ลาป่วย', value: 'sick_leave'},
    {label: 'ลาป่วยจากการปฏิบัติงาน', value: 'sick_form_work'},
    {label: 'ลากิจ', value: 'personal_leave'},
    {label: 'ลาพักผ่อนประจำปี', value: 'annual_leave'},
    {label: 'ลาคลอดบุตร', value: 'maternity_leave'},
    {label: 'ลาเลี้ยงดูบุตร', value: 'childcare_leave'},
    {label: 'อุปสมบทเเละฮัจย์', value: 'religions_or_hajj_pilgrimage_leave'},
    {label: 'ลารับราชการทหาร', value: 'military_leave'},
];

export const TotalLeaveTime = [
    {label: 'เต็มวัน', value: 'เต็มวัน'},
    {label: 'ครึ่งวันเช้า', value: 'ครึ่งวันเช้า'},
    {label: 'ครึ่งวันบ่าย', value: 'ครึ่งวันบ่าย'},
];

export const initialPermission = {
    employees: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
    organizational_structures: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
    employee_actives: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
    employee_register_requests: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
    employee_reset_password_requests: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
    employee_edit_requests: {
        read: false,
        create: false,
        update: false,
        delete: false,
        export: false,
        approve: false,
    },
	permission_approve: {
		read: false,
		create: false,
		update: false,
		delete: false,
		export: false,
		approve: false,
	},
	permission_request: {
		read: false,
		create: false,
		update: false,
		delete: false,
		export: false,
		approve: false,
	},
	employee_leaves: {
		read: false,
		create: false,
		update: false,
		delete: false,
		export: false,
		approve: false,
	},
	employee_leave_approve: {
		read: false,
		create: false,
		update: false,
		delete: false,
		export: false,
		approve: false,
	},
  employee_leave_setting: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_shift: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_shift_personel: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_shift_personel_view: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_reserve_checkin: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_reserve_checkin_manage: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  employee_checkin_report: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  device_status: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  system_logs: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
  report: {
    read: false,
    create: false,
    update: false,
    delete: false,
    export: false,
    approve: false,
  },
}

export const LeaveCondition = [
  {
    leave_type : '',
    description : ""
  },
  {
    leave_type : 'sick_leave',
    description : "ให้ยื่นใบลา 7 วันหลัง หลังจากการลาเเล้ว"
  },
  {
    leave_type : 'sick_form_work',
    description : "ให้ยื่นใบลา 7 วันหลัง หลังจากการลาเเล้ว"
  },
  {
    leave_type : 'personal_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน หรือยื่นในวันที่ลา"
  },
  {
    leave_type : 'annual_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน 1 วัน"
  },
  {
    leave_type : 'maternity_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน 7 วัน หรือ หลังจากการลาเเล้ว 7 วัน"
  },
  {
    leave_type : 'childcare_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน หรือยื่นในวันที่ลา"
  },
  {
    leave_type : 'religions_or_hajj_pilgrimage_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน ไม่น้อยกว่า 7 วัน"
  },
  {
    leave_type : 'military_leave',
    description : "ให้ยื่นใบลาล่วงหน้าก่อน ไม่น้อยกว่า 7 วัน"
  },
]
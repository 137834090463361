import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../shared/services/notification-service';
import {
  initialResponseData, LeaveRequestStatusOptions,
  LeaveRequestTypeOptions,
} from "../../../shared/utils/constants";
import TableHeaderOrdering, {Header} from "../../../shared/components/table-header-ordering";
import PaginationForm from "../../../shared/components/molecules/commons/pagination-form";
import {ApiResponse} from "../../../shared/models/common.model";
import TableRowNoData from "../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {LeaveRequest, LeaveRequestQueryParams, LeaveTypeNames} from "../../../shared/models/leave-request.model";
import Moment from "moment";
import {apiEmployeeService} from "../../../shared/services/employee-service";
import {EmployeeListQueryParams} from "../../../shared/models/employee.model";

export const initialHeaders: Header[] = [
  {
    label: '',
    field: '',
    order: null,
    isOrder: true,
  },
  {
    label: 'รหัสพนักงาน',
    field: 'full_name_th',
    order: null,
    isOrder: true,
  },
  {
    label: 'ชื่อ - นามสกุล',
    field: 'full_name_th',
    order: null,
    isOrder: true,
  },
  {
    label: 'เลขบัญชีถือจ่าย',
    field: 'position_level',
    order: null,
    isOrder: true,
  },
  {
    label: 'ตำแหน่ง',
    field: 'role',
    order: null,
    isOrder: true,
  },
  {
    label: 'แผนก',
    field: 'department',
    order: null,
    isOrder: true,
  },
  {
    label: 'กอง/กลุ่ม/สำนักงาน',
    field: 'group',
    order: null,
    isOrder: true,
  },
];

type PagePropTypes = {
  setQueryParamState?: (setData: React.Dispatch<React.SetStateAction<EmployeeListQueryParams>>) => void;
  onAssignEmployeeShift?: (data: any | null , isChecked: boolean) => void;
  isCheckboxDisplay?: boolean;
  currentHeaders: Header[];
  isChecked?: number[];
  isCheckAll?: (data: any) => boolean;
  setIsCheckAll?: (data: any) => void;
  isCreatePage?: boolean;
  createPageState?: any;
}

const AssignedEmployeeShiftSection = ({ setQueryParamState ,
                                        onAssignEmployeeShift,
                                        isCheckboxDisplay = true,
                                        currentHeaders,
                                        isChecked,
                                        isCheckAll,
                                        setIsCheckAll, isCreatePage = false, createPageState}: PagePropTypes) => {
  const [data, setData] = useState<ApiResponse<LeaveRequest>>(initialResponseData);
  const [queryParams, setQueryParams] = useState<EmployeeListQueryParams>({
    page: 1,
    page_size: 10,
    employee_type: null,
    position_name: '',
    search: '',
    structure_departments_name: '',
    ordering_field: null,
    ordering: null,
  });
  const [headers, setHeaders] = useState<Header[]>([])
  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const [currentDataState, setCurrentDataState] = useState<any[]>([]);

  const currentState = !isCreatePage ? data?.rows : createPageState;
  const currentCount = !isCreatePage ? data?.count : createPageState?.length;

  useEffect(() => {
    const start = (queryParams.page - 1) * queryParams.page_size;
    const end = start + queryParams.page_size;

    if (isCreatePage) {
      setCurrentDataState(currentState.slice(start, end));
    } else {

      setCurrentDataState(currentState);
    }
  }, [currentState, queryParams.page, queryParams.page_size, isCreatePage]);

  useEffect(() => {
    if (setQueryParamState) {
      setQueryParamState(setQueryParams);
    }
  }, [setQueryParamState]);


  const fetchData = async () => {
    startLoading();
    try {
      const result = await apiEmployeeService.getEmployeeList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size,
      page: 1
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page, queryParams.search ,queryParams.position_name, queryParams.structure_departments_name]);


  // useEffect(() => {
  //   setQueryParams({
  //     ...queryParams,
  //   })
  // }, []);

  const setDefaultChecked = (userId: number) => {
    if (isChecked) {
      return isChecked.includes(userId);
    } else {
      return false;
    }
  }

  useEffect(() => {
    return () => {

    };
  }, []);


  return <div>
    {loaderComponent}
    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={currentHeaders || initialHeaders} onChangeOrder={() => {
        }} isShow={true} isCheckboxDisplay={isCheckboxDisplay} isCheckAll={isCheckAll && isCheckAll(data.rows)} setIsCheckAll={()=>{setIsCheckAll && setIsCheckAll(data.rows)}}/>
        </thead>
        <tbody>
        {
          currentDataState.map((data: any) => {
            return <tr key={data.id}>
              {/*<td>{data.created_by_user?.employee?.fullname_th}</td>*/}
              {/*<td>{data.created_by_user?.employee?.job_positions.find(data => data?.is_currently)?.position_name}</td>*/}
              {/*<td>{(LeaveTypeNames as Record<string, string>)[data.leave_request_details[0]?.leave_type]}</td>*/}
              {/*<td>{Moment(data.leave_request_details[0]?.start_date).utcOffset('-00:00').format('DD/MM/YYYY HH:mm:ss')}</td>*/}
              {/*<td>{Moment(data.leave_request_details[0]?.end_date).utcOffset('-00:00').format('DD/MM/YYYY HH:mm:ss')}</td>*/}
              {/*<td>{data.approver_action_user?.employee?.fullname_th}</td>*/}
              {/*<td>{(data.status)}</td>*/}
              {
                isCheckboxDisplay ?
                <td>
                <input className="form-check-input" type="checkbox" value={data.user?.id}
                       name="flexCheckDefault" checked={setDefaultChecked(data.user?.id)}
                       disabled={false} onChange={(e) => {
                  if (e.target.checked) {
                    if (onAssignEmployeeShift) {
                      onAssignEmployeeShift(data, true)
                    }
                  } else {
                    if (onAssignEmployeeShift) {
                      onAssignEmployeeShift(data, false)
                    }
                  }
                }} id="same-address"/>
              </td> : null}
              <td>{data?.code || '-' }</td>
              <td>{data?.prefix_name_th + " " + data?.firstname_th + " " + data?.lastname_th || '-' }</td>
              <td>{data?.current_job_position?.pay_account_number || '-'}</td>
              <td>{data?.current_job_position?.position_name || '-'}</td>
              <td>{data?.current_job_position?.structure_departments_name || '-'}</td>
              <td>{data?.current_job_position?.division_group_office_name || '-'}</td>
            </tr>
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="mt-3">
      <PaginationForm current_page={queryParams.page} count={currentCount} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
}

export default AssignedEmployeeShiftSection;
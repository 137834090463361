import {FC} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import PersonnelHistory from './components/personnel-history';
import PersonnelFamilyDdetail from './components/personnel-family-detail';
import PersonnelRename from './components/personnel-rename';
import PersonnelEducation from './components/personnel-education';
import PersonnelTraining from './components/personnel-training';
import PersonnelInsignia from './components/personnel-insignia';
import PersonnelPunishment from './components/personnel-punishment';
import PersonnelOutstandingAwards from './components/personnel-outstanding-awards';
import PersonnelResignationRetireDeath from './components/personnel-resignation-retire-death';
import PersonnelCertifier from './components/personnel-certifier';
import PersonnelWorkOrtokor from './components/personnel-work-ortorkor';
import PersonnelWorkHistory from './components/personnel-work-history';

const ReportEditEmployeeDetailDetailPage: FC = () => {
  const {loaderComponent} = useSpinnerLoader();

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายการแก้ไขข้อมูลพนักงาน</h2>
      <Link to="/report/manage-user/report-edit-employee-detail">
        <Button variant="" className="btn-action me-2 btn-outline-secondary" onClick={()=>{}}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
      </Link>
    </div>

    {loaderComponent}

    <div className="h-100">
      <PersonnelHistory/>
      <PersonnelFamilyDdetail/>
      <PersonnelRename/>
      <PersonnelEducation/>
      <PersonnelTraining/>
      <PersonnelInsignia/>
      <PersonnelPunishment/>
      <PersonnelOutstandingAwards/>
      <PersonnelResignationRetireDeath/>
      <PersonnelCertifier/>
      <PersonnelWorkOrtokor/>
      <PersonnelWorkHistory/>
    </div>

  </div>;
}

export default ReportEditEmployeeDetailDetailPage;
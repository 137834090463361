import React, { FC, ReactNode, useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { apiDropdownService } from '../../../../shared/services/dropdown-service';
import { Dropdown } from '../../../../shared/models/common.model';
import { InputActionMeta } from 'react-select/dist/declarations/src/types';

interface TambonSelectProps {
    value: any,
    disable: boolean,
    onChange: (data: Dropdown) => void;
}

const TambonSelect: FC<TambonSelectProps> = ({value, disable, onChange}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [localValue, setLocalValue] = useState({value: value, label: ""});

  const getTambonName = async () => {
    setIsLoading(true);
    try {
      let options = await apiDropdownService.getTambonsDropdown({tambon_id: value , tambon_name: null});
      if (options) {
        setLocalValue({
          label: options[0]?.label as any,
          value: value
        });

      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching options:', error);
      setIsLoading(false);
    }
  };

    useEffect(() => {
        if (value) {
          getTambonName();
        }
    }, [value]);

    type OptionType = {
        value: number;
        label: string;
    };

    const getParams = (search: string , tambon_id: number | null) => {
        return {
            tambon_name: search,
            tambon_id: tambon_id
        };
    };

    const loadOptions = async (
        search: string,
        prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
    ) => {
        let options = [];
        if (!search && !!localValue.value) {
            search = localValue.value;
        }

        if (search) {
            setIsLoading(true);
            try {
                options = await apiDropdownService.getTambonsDropdown(getParams(search, null));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching options:', error);
                setIsLoading(false);
            }
        }
        return {
            options: options,
        };
    };

    const handleOnChange = (newValue: any) => {
        onChange(newValue);
        setLocalValue({
            label: newValue.label,
            value: null,
        });
    };

    const MyOption = (props: any) => {
        const {innerProps, innerRef} = props;

        return (
            <div ref={innerRef} {...innerProps}
                 className={`px-1 py-1 pointer primary-select`}>
                <p className="m-0" style={{paddingLeft:"10px"}}>{props?.label}</p>
            </div>
        );
    };

    const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            setLocalValue({
                label: newValue,
                value: newValue,
            });
        }
    };

    const noOptionMessage = (obj: {
        inputValue: string;
    }) => {
        return <>กรุณาตำบล เพื่อค้นหา...</>
    }

    return <>
        <AsyncPaginate
            debounceTimeout={300}
            loadOptions={loadOptions}
            isLoading={isLoading}
            onChange={handleOnChange}
            isDisabled={disable}
            value={localValue}
            styles={{
                input: (baseStyles, state) => ({
                    ...baseStyles,
                    input: {
                        opacity: '1 !important',
                        visibility: 'visible'
                    },
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    display: 'none'
                })
            }}
            onInputChange={onInputChange}
            noOptionsMessage={noOptionMessage}
            inputValue={localValue.label}
            controlShouldRenderValue={false}
            components={{Option: MyOption}}
            placeholder=""
        />
    </>;
};

export default TambonSelect;
import apiService from './api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/system-logs';

export class SystemLogService {
  static async getList(params: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}`, { params });
    return response.data;
  }

  static async create(data: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}`, data);
    return response.data;
  }
}

import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import LeaveHistoryForm from './forms/leaves/leave-history-form';

interface EmployeeLeaveHistoryProps {
    defaultKey?: AccordionEventKey;
}

const EmployeeLeaveHistory: FC<EmployeeLeaveHistoryProps> = ({defaultKey = '0'}) => {
    return (
        <Accordion defaultActiveKey={defaultKey} className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header className="text-mof-primary">
                    <h5 className="text-mof-primary m-0">ประวัติ</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <LeaveHistoryForm/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default EmployeeLeaveHistory;
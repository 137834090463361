import {AmphureParam, ProvinceParam, TambonParam} from '../models/address.model';
import { EmployeeDropdownParams } from '../models/employee.model';
import apiService from './api-service';

const ADDRESS_BASE_API = process.env.REACT_APP_API_URI + '/dropdown';

export const apiDropdownService = {
    getProvinceDropdown,
    getAmphureDropdown,
    getTambonsDropdown,
    getEmployeeDropdown,
    getZipcodeDropdown,
    getUserDropdown,
    getPositionDropdown,
    getStructureDepartmentDropdown,
    getLeaveRequestDropdown
};

async function getProvinceDropdown(params: ProvinceParam) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/provinces`, {params});
    return response.data;
}

async function getAmphureDropdown(params: AmphureParam) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/amphures`, {params});
    return response.data;
}

async function getTambonsDropdown(params: TambonParam) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/tambons`, {params});
    return response.data;
}

async function getEmployeeDropdown(params: EmployeeDropdownParams) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/employees`, {params});
    return response.data;
}

async function getUserDropdown(params: EmployeeDropdownParams) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/users`, {params});
    return response.data;
}

async function getPositionDropdown(params: { search: string }) {
  const response = await apiService().get(`${ADDRESS_BASE_API}/position`, {params});
  return response.data;
}
async function getStructureDepartmentDropdown(params: { search: string }) {
  const response = await apiService().get(`${ADDRESS_BASE_API}/structure_department`, {params});
  return response.data;
}

async function getZipcodeDropdown(params: any) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/zipcodes`, {params});
    return response.data;
}

async function getLeaveRequestDropdown(params: any) {
    const response = await apiService().get(`${ADDRESS_BASE_API}/leaves`, {params});
    return response.data;
}
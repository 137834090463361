import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { PermissionRequest, PermissionRequestQueryParams } from '../../shared/models/permission.model';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import { PermissionService } from '../../shared/services/permission-service';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import PermissionApproveModal from './components/permission-approve-modal';

interface PermissionRowProps extends PermissionRequest {
  onAction: (status: 'approve' | 'reject', id: any) => void;
}

const PermissionListPage: FC = () => {
  const [queryParams, setQueryParams] = useState<PermissionRequestQueryParams>({
    search: '',
    ordering_field: '',
    ordering: '',
    page: 1,
    page_size: 10,
  });
  const [data, setData] = useState<ApiResponse<PermissionRequest>>(initialResponseData);
  const {authJwt} = useAuth();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [idTarget, setIdTarget] = useState<any>(null);

  const headers: Header[] = [
    {
      label: 'Username',
      field: 'username',
      order: null,
      isOrder: true,
    },
    {
      label: 'ชื่อ - นามสกุล',
      field: 'full_name_th',
      order: null,
      isOrder: true,
    },
    {
      label: 'อีเมล',
      field: 'email',
      order: null,
      isOrder: true,
    },
    {
      label: 'เบอร์โทรศัพท์',
      field: 'phone',
      order: null,
      isOrder: true,
    },
    {
      label: 'วันที่สร้างคำขอ',
      field: 'created_at',
      order: null,
      isOrder: false,
    },
    {
      label: 'สถานะ',
      field: 'is_active',
      order: null,
      isOrder: true,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  useEffect(() => {
    fetchData();
  }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await PermissionService.getPermissionRequest(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };
  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQueryParams(prevState => {
      return {...prevState, search: e.target.value};
    });
  };

  const onChangeOrder = (orderData: OrderData) => {
    setQueryParams({
      ...queryParams,
      ordering_field: orderData.field,
      ordering: orderData.order,
    });
  };

  const onAction = (status: 'approve' | 'reject', id: any) => {
    setIdTarget(id);
    if (status === 'approve') {
      setConfirmModal(true);
    } else {
      setRejectModal(true);
    }
  };

  const onReject = async (reason: any) => {
    handleCloseModal();
    startLoading();
    const data = {
      reason: reason
    };
    try {
      await PermissionService.onPermissionRequestReject(idTarget, data);
      notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setRejectModal(false);
  };

  const handleOnAction = () => {
    handleCloseModal();
    fetchData();
  };

  const displayModal = () => {
    if (showConfirmModal) {
      return <PermissionApproveModal
        permissionRequestId={idTarget}
        show={showConfirmModal}
        edited={authJwt?.role === 'ADMIN'}
        onHide={handleCloseModal}
        onAction={handleOnAction}
      />;
    }
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onReject}
      />;
    }
    return;
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">อนุมัติการกำหนดสิทธิ์</h2>
    </div>

    {loaderComponent}
    {displayModal()}
    <div className="row mt-2">
      <div className="col-sm-12 col-md-3">
        <input className="form-control" type="text" value={queryParams.search}
               onChange={onChangeSearch} autoComplete="off"
               placeholder="ค้นหา..." name="search"/>
      </div>
      <div className="col-sm-12 col-md-2">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows.map((data, index) => {
            return <PermissionRow key={data.id} {...data} onAction={onAction}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
};

const PermissionRow: FC<PermissionRowProps> = ({
                                                 id,
                                                 created_by_user,
                                                 status,
                                                 created_at,
                                                 approver_action_user,
                                                 admin_action_user,
                                                 approver_action_at,
                                                 admin_action_at,
                                                 onAction,
                                               }) => {
  const {authJwt} = useAuth();

  const getApproverStatus = () => {
    if (status === 'waiting_for_approver')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (status === 'approver_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (!!approver_action_at)
      return <span className="badge bg-success">อนุมัติ</span>;
    return '-';
  };

  const getAdminStatus = () => {
    if (status === 'waiting_for_admin')
      return <span className="badge bg-secondary">รอการอนุมัติ</span>;
    if (status === 'admin_reject')
      return <span className="badge bg-danger">ไม่อนุมัติ</span>;
    if (status === 'admin_approve')
      return <span className="badge bg-success">อนุมัติ</span>;
    return '';
  };

  const handleOnApprove = () => {
    onAction('approve', id);
  };

  const handleOnReject = () => {
    onAction('reject', id);
  };

  const displayButton = () => {
    if (status === 'waiting_for_approver' || (authJwt?.role === 'ADMIN' && (status === 'waiting_for_approver' || status === 'waiting_for_admin')))
      return <>
        <Button variant="primary" className="btn-mof-primary" onClick={handleOnApprove}>
          ตรวจสอบ
        </Button>
        <Button variant="danger" className="text-white ms-2" onClick={handleOnReject}>
          ไม่อนุมัติ
        </Button>
      </>;
    return <>
    </>;
  };

  return <tr>
    <td>{created_by_user?.username}</td>
    <td>{created_by_user.employee.full_name_th}</td>
    <td>{created_by_user.email}</td>
    <td>{created_by_user.employee.phone}</td>
    <td>{Moment(created_at).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')}</td>
    <td>
      <div className="mb-2">
        <p className="m-0">Approver: {getApproverStatus()}</p>
        <small className="text-secondary">
          {approver_action_user?.email || '-'}
          <br/>
          {approver_action_at ? `(${Moment(approver_action_at).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')})` : ''}
        </small>
      </div>
      <div>
        <p className="m-0">Admin: {getAdminStatus()}</p>
        {
          admin_action_at ? <small className="text-secondary">
              {admin_action_user?.email || '-'}
              <br/>
              {admin_action_at ? `(${Moment(admin_action_at).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')})` : ''}
            </small> :
            ''
        }
      </div>
      {/*{getStatusDisplay()}*/}
    </td>
    <td>
      {
        displayButton()
      }
    </td>
  </tr>;
  ;
};

export default PermissionListPage;
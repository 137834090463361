import {
    OrganizationalStructureAction,
    OrganizationalStructureActionPage,
    OrganizationalStructureActionStatus,
    OrganizationalStructureActionType
} from '../models/organizational-structure.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialOrganizationalStructureAction: OrganizationalStructureAction = {
    page: 'director',
    type: 'view',
    isSubmitted: false,
    after_save: false,
    status: 'view'
};

export const organizationalStructureActionReducer = createSlice({
    name: 'organizational_structure_action',
    initialState: initialOrganizationalStructureAction,
    reducers: {
        changePage: (state, action: PayloadAction<OrganizationalStructureActionPage>) => {
            state.page = action.payload;
        },
        changeType: (state, action: PayloadAction<OrganizationalStructureActionType>) => {
            state.type = action.payload;
        },
        changeStatus: (state, action: PayloadAction<OrganizationalStructureActionStatus>) => {
            state.status = action.payload;
        },
        changeSubmitted: (state, action: PayloadAction<boolean>) => {
            state.isSubmitted = action.payload;
        },
        afterSave: (state, action: PayloadAction<boolean>) => {
            state.after_save = action.payload;
        }
    },
});

export const {
    changePage,
    changeType,
    changeSubmitted,
    changeStatus,
    afterSave
} = organizationalStructureActionReducer.actions;

// Export the reducer
export default organizationalStructureActionReducer.reducer;

import React, { FC, useEffect, useRef } from 'react';
import { ChildrenProps } from '../models/commons.model';

interface ClickOutsideDetectorProps extends ChildrenProps {
    onClickOutside: () => void;
}

const ClickOutsideDetector: FC<ClickOutsideDetectorProps> = ({children, onClickOutside}) => {
    const targetRef = useRef<any>(null);

    const handleDocumentClick = (event: MouseEvent) => {
        if (targetRef.current && !targetRef.current.contains(event.target as Node)) {
            onClickOutside();
        }
    };

    useEffect(() => {
        const delayTimeout = setTimeout(() => {
            document.addEventListener('mousedown', handleDocumentClick);
        }, 500);

        return () => {
            clearTimeout(delayTimeout);
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [targetRef]);

    return (
        <div ref={targetRef}>
            {children}
        </div>
    );
};

export default ClickOutsideDetector;
import React, {FC, FormEvent, useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExport, faSearch} from '@fortawesome/free-solid-svg-icons';
import InputSelect from '../../../shared/components/atoms/input-select';
import {EmployeeType} from '../../../shared/utils/constants';
import {EmployeeFilterData} from '../../../shared/models/employee.model';
import {SelectDropdown} from '../../../shared/models/common.model';
import EmployeeReportModal from './modals/employee-report-modal';
import {apiEmployeeService} from '../../../shared/services/employee-service';
import {notificationService} from '../../../shared/services/notification-service';
import {AxiosError} from 'axios';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import {getFileApplication, getFileExtension} from '../../../shared/utils/file-type';
import {hasPermission} from '../../../shared/utils/roles';
import {useAuth} from '../../../shared/authentications/auth-context';

interface FilterFormProps {
  onFilter: (data: EmployeeFilterData) => void;
}

const EmployeeFilterForm: FC<FilterFormProps> = ({onFilter}) => {
  const [filterData, setFilterData] = useState<EmployeeFilterData>({
    employee_type: null,
    position_name: '',
    search: '',
  });
  const [showModal, setShowModal] = useState(false);
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const {permission} = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e: SelectDropdown) => {
    setFilterData({
      ...filterData,
      [e.name]: e.value?.value || null,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onFilter(filterData);
  };

  const onDownloadReport = async () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = async (report_type: 'report_1' | 'report_2' | 'report_3' | 'report_4', file_type: 'excel' | 'csv' | 'pdf') => {
    setShowModal(false);
    downloadReport(report_type, file_type);
  };

  const getReportName = (report_type: 'report_1' | 'report_2' | 'report_3' | 'report_4') => {
    if (report_type === 'report_1')
      return 'รายงานประวัติบุคลากร';
    if (report_type === 'report_2')
      return 'รายงานแสดงข้อมูลบุคลากรที่มีคุณสมบัติตามที่ต้องการ';
    if (report_type === 'report_3')
      return 'รายงานบุคลากรที่จะเกษียณอายุ';
    if (report_type === 'report_4')
      return 'รายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง';
  };

  const downloadReport = async (report_type: 'report_1' | 'report_2' | 'report_3' | 'report_4', file_type: 'excel' | 'csv' | 'pdf') => {
    try {
      startLoading();

      const params = {
        report_type: report_type,
        file_type: file_type,
      };
      const response = await apiEmployeeService.downloadEmployeeReport(params);
      const blob = new Blob([response.data], {type: getFileApplication(file_type)});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${getReportName(report_type)}.${getFileExtension(file_type)}`;
      a.click();
      URL.revokeObjectURL(url);
      notificationService.successNotification('ดาวน์โหลดรายงานสำเร็จ', '');
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ดาวน์โหลดรายงานไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const displayModal = () => {
    if (showModal) {
      return <EmployeeReportModal show={showModal} onHide={handleCloseModal} onConfirm={handleConfirm}/>;
    }
    return null;
  };

  useEffect(() => {
    setFilterData({
      employee_type: null,
      position_name: '',
      search: '',
    })
  }, []);


  return (
    <form onSubmit={handleSubmit}>
      {displayModal()}
      {loaderComponent}
      <div className="row row-gap-3">
        <div className="col-sm-12 col-md-3">
          <InputSelect options={EmployeeType} placeholder="ประเภทบุคลากร" name="employee_type"
                       value={filterData.employee_type}
                       onChange={handleSelectChange}/>
        </div>
        <div className="col-sm-12 col-md-3">
          <input type="text" className="form-control" name="position_name"
                 placeholder="ตำแหน่ง" value={filterData.position_name}
                 onChange={handleChange}/>
        </div>
        <div className="col-sm-12 col-md-3">
          <input className="form-control" type="text" value={filterData.search}
                 placeholder="ค้นหา..." name="search"
                 onChange={handleChange}/>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="primary" type="submit" className="px-4">
            <FontAwesomeIcon icon={faSearch}/>
          </Button>
          <Button variant="secondary" type="button" className="px-4 ms-3" onClick={onDownloadReport}
                  disabled={!hasPermission(permission, ['employees'], 'export')}>
            <FontAwesomeIcon icon={faFileExport}/>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EmployeeFilterForm;
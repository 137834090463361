import React, { ChangeEvent, FC, useState } from 'react';
import {
  deleteDepartmentOffice,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateDepartmentOffice,
  updateDivisionGroupOffice, updateStructureDepartment
} from '../../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import { DepartmentOfficeModel } from '../../../../shared/models/organizational-structure.model';
import { faMinus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputSelect from '../../../../shared/components/atoms/input-select';
import {
  handleDepartmentOfficeInputSelectChange
} from '../../../../shared/services/organizational-structure-input-change-state';
import { convertToPositiveNumber, handleNumericInput } from '../../../../shared/utils/function-validators';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputCurrency from '../../../../shared/components/inputs/input-currency';
import { convertToCurrency } from '../../../../shared/utils/currency';
import { Dropdown } from '../../../../shared/models/common.model';
import HeaderEmployeeSearch from '../inputs/header-employee-search';

interface DepartmentOfficeFormProps extends DepartmentOfficeModel {
  index: number;
}

const OfficeDepartmentForm: FC<DepartmentOfficeFormProps> = ({
                                                               index,
                                                               id,
                                                               director_id,
                                                               director_index,
                                                               deputy_director_id,
                                                               deputy_director_index,
                                                               code,
                                                               name,
                                                               power_rate,
                                                               type,
                                                               is_acting_in_position,
                                                               is_empty,
                                                               user_id,
                                                               pay_account_number,
                                                               level,
                                                             }) => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    organizationalStructure.division_group_offices.forEach((data, dataIndex) => {
      if (data.department_office_index === index) {
        const resetId = {
          target: {
            name: 'department_office_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'department_office_index',
            value: null,
          }
        };
        updateDivisionGroupOffice(dataIndex, resetId, dispatch);
        updateDivisionGroupOffice(dataIndex, resetIndex, dispatch);
      } else if ((data.department_office_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'department_office_index',
            value: (data.department_office_index as number) - 1,
          }
        };
        updateDivisionGroupOffice(dataIndex, resetIndex, dispatch);
      }
    });
    organizationalStructure.structure_departments.forEach((data, dataIndex) => {
      if (data.department_office_index === index && data.type === 'department_office') {
        const resetId = {
          target: {
            name: 'department_office_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'department_office_index',
            value: null,
          }
        };
        updateStructureDepartment(dataIndex, resetId, dispatch);
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      } else if ((data.department_office_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'department_office_index',
            value: (data.department_office_index as number) - 1,
          }
        };
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      }
    });
    deleteDepartmentOffice({index: index}, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const displayModal = () => {
    if (showModal) {
      const child = organizationalStructure.division_group_offices
        .filter(data => data.department_office_index === index);
      const child2 = organizationalStructure.structure_departments
        .filter(data => data.department_office_index === index && data.type === 'department_office');
      const message = <>
        <p>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</p>
        {
          child.length > 0 ?
            <p className="m-0">
              มี กอง / กลุ่ม / สำนักงาน ภายใต้ สำนัก /
              ฝ่าย <b>{name}</b> ทั้งหมด <b>{child.length}</b> หน่วย
            </p> : ''
        }
        {
          child.map((data, index) => {
            return <p key={index} className="m-0">- {data.name} (อัตรากำลัง: {convertToCurrency(data.power_rate)})</p>;
          })
        }
        {
          child.length > 0 ?
            <div className="alert alert-warning mb-0 mt-2" role="alert">
              <FontAwesomeIcon icon={faWarning} className="me-2"/>
              เมื่อกดลบแล้ว กอง / กลุ่ม / สำนักงาน ภายใต้ทั้งหมดจะต้องเลือก สำนัก / ฝ่าย ใหม่
            </div> : ''
        }
        {
          child2.length > 0 ?
            <p className="m-0">มี แผนก ภายใต้สำนัก / ฝ่าย<b>{name}</b> ทั้งหมด <b>{child2.length}</b> แผนก
            </p> : ''
        }
        {
          child2.map((data, index) => {
            return <p key={index} className="m-0">- แผนก {data.name} (อัตรากำลัง: {data.power_rate})</p>;
          })
        }
        {
          child2.length > 0 ?
            <div className="alert alert-warning mb-0 mt-2" role="alert">
              <FontAwesomeIcon icon={faWarning} className="me-2"/>
              เมื่อกดลบแล้วแผนกภายใต้ทั้งหมดจะต้องเลือก สำนัก / ฝ่าย ใหม่
            </div> : ''
        }
      </>;
      return <DeleteConfirmationModal
        show={showModal}
        size={'lg'}
        title={'ยืนยันการลบข้อมูล'}
        description={message}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />;

    }
    return;
  };

  const buttonDisplay = () => {
    return <button className="btn btn-danger" type="button" onClick={handleDelete} hidden={disable || index === 0}>
      <FontAwesomeIcon icon={faMinus}/>
    </button>;
  };

  const getOptions = () => {
    if (type === 'office')
      return organizationalStructure.directors.map((data, index) => {
        return {
          label: `${data.name || '-'}`,
          value: index
        };
      });
    if (type === 'department')
      return organizationalStructure.deputy_directors.map((data, index) => {
        return {
          label: `${data.name || '-'} (${data.pay_account_number || 'ยังไม่ได้กรอกค่า'})`,
          value: index
        };
      });
    return [];

  };

  const validatePositivePowerRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = convertToPositiveNumber(event.target.value);
    updateDepartmentOffice(index, event, dispatch);
  };

  const handleChangeType = (
    event: React.MouseEvent<HTMLElement>,
    selectType: string,
  ) => {
    if (selectType === null)
      return;

    const resetDirectorIndex = {
      target: {
        name: 'director_index',
        value: selectType === 'office' ? 0 : null
      }
    };
    const resetDeputyDirectorIndex = {
      target: {
        name: 'deputy_director_index',
        value: null
      }
    };
    const updateType = {
      target: {
        name: 'type',
        value: selectType
      }
    };
    updateDepartmentOffice(index, resetDirectorIndex, dispatch);
    updateDepartmentOffice(index, resetDeputyDirectorIndex, dispatch);
    updateDepartmentOffice(index, updateType, dispatch);
  };

  const getDropdownLabel = () => {
    if (type === 'office')
      return 'ผู้อำนวยการ';
    if (type === 'department')
      return 'รองผู้อำนวยการ';
    return 'ผู้อำนวยการ / รองผู้อำนวยการ';
  };

  const getCodeLabel = () => {
    if (type === 'office')
      return 'รหัสสำนัก';
    if (type === 'department')
      return 'รหัสฝ่าย';
    return 'รหัสสำนัก / ฝ่าย';
  };

  const getNameLabel = () => {
    if (type === 'office')
      return 'ชื่อสำนัก';
    if (type === 'department')
      return 'ชื่อฝ่าย';
    return 'ชื่อสำนัก / ฝ่าย';
  };

  const getDropdownName = () => {
    if (type === 'office')
      return 'director_index';
    if (type === 'department')
      return 'deputy_director_index';
    return 'not-select';
  };

  const getDropdownValue = () => {
    if (type === 'office')
      return director_index;
    if (type === 'department')
      return deputy_director_index;
    return null;
  };

  const handleOnCheckEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    const setIsEmpty = {
      target: {
        name: 'is_empty',
        value: e.target.checked
      }
    };
    // const resetPayAccountNumber = {
    //   target: {
    //     name: 'pay_account_number',
    //     value: e.target.checked ? '-' : null
    //   }
    // };
    updateDepartmentOffice(index, setIsEmpty, dispatch);
    // updateDepartmentOffice(index, resetPayAccountNumber, dispatch);

    if (e.target.checked) {
      const setUserId = {
        target: {
          name: 'user_id',
          value: null
        }
      };
      updateDepartmentOffice(index, setUserId, dispatch);
    }
  };

  const handleOnCheckActingInPosition = (e: ChangeEvent<HTMLInputElement>) => {
    const check = {
      target: {
        name: 'is_acting_in_position',
        value: e.target.checked
      }
    };
    updateDepartmentOffice(index, check, dispatch);
  };

  const handleOnSelectEmployee = (value: Dropdown) => {
    const updateData = {
      target: {
        name: 'user_id',
        value: +value.value
      }
    };
    updateDepartmentOffice(index, updateData, dispatch);
  };

  const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumericInput(e);
    updateDepartmentOffice(index, e, dispatch);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-3">
          <ToggleButtonGroup
            color="primary"
            value={type}
            exclusive
            fullWidth
            disabled={disable}
            onChange={handleChangeType}
          >
            <ToggleButton value="office">ผู้อำนวยการ</ToggleButton>
            <ToggleButton value="department">รองผู้อำนวยการ</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">{getDropdownLabel()}</label>
          <InputSelect options={getOptions()} placeholder="กรุณาเลือก" name={getDropdownName()}
                       onChange={e => handleDepartmentOfficeInputSelectChange(index, e, dispatch)}
                       value={getDropdownValue()}
                       isClearable={false} isDisable={disable || type === 'office' || type === null}/>
          <input type="text" className="form-control" name="deputy_director_index" hidden={true}
                 value={getDropdownValue()?.toString() || ''} required={true} onChange={() => {
          }}/>
          <div className="invalid-feedback">
            กรุณากรอกเลือกรองผู้อำนวยการ
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">{getCodeLabel()}</label>
          <input type="text" className="form-control" name="code" disabled={disable || type === null}
                 placeholder="" value={code || ''} required={true}
                 onChange={(e) => updateDepartmentOffice(index, e, dispatch)}/>
          <div className="invalid-feedback">
            กรุณากรอก{getCodeLabel()}
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">{getNameLabel()}</label>
          <div className="input-group">
            <input type="text" className="form-control rounded" name="name"
                   disabled={disable || type === null}
                   placeholder="" value={name || ''} required={true}
                   onChange={(e) => updateDepartmentOffice(index, e, dispatch)}/>
            <div className="invalid-feedback">
              กรุณากรอก{getNameLabel()}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ระดับ</label>
          <div className="input-group">
            <input type="text" className="form-control" name="level" disabled={disable}
                   onChange={(e) => updateDepartmentOffice(index, e, dispatch)}
                   placeholder="" value={level || ''} required={true}/>
            <div className="input-group-append ps-2">
              {buttonDisplay()}
            </div>
            <div className="invalid-feedback">
              กรุณากรอกระดับ
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">อัตรากำลัง</label>
          <InputCurrency value={power_rate || ''} onChange={validatePositivePowerRate}
                         disable={disable || getDropdownValue() === null}
                         name="power_rate" required={true}/>
          <div className="invalid-feedback">
            กรุณากรอกอัตรากำลัง
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">เลขบัญชีถือจ่าย</label>
          <input type="text" className="form-control" name="pay_account_number" disabled={disable}
                 placeholder="" value={pay_account_number || ''} required={true}
                 onChange={validateNumber}/>
          <div className="invalid-feedback">
            กรุณากรอกเลขบัญชีถือจ่าย
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ชื่อผู้อำนวยการสำนัก</label>
          <div className="input-group">
            {
              is_empty ?
                <input type="text" className="form-control" name="name" disabled={true}
                       placeholder="" value={'ตำแหน่งว่างอยู่'} required={true}/>
                :
                <div className="w-100">
                  <HeaderEmployeeSearch onChange={(value) => handleOnSelectEmployee(value)} value={user_id}
                                        changeValue={organizationalStructure.department_offices.map(data => data.user_id).join(',')}/>
                </div>
            }
            <input type="text" className="form-control" name="user_id" hidden={true} required={true}
                   value={user_id || ''} onChange={() => {
            }}/>
            {
              !is_empty ?
                <div className="invalid-feedback">
                  กรุณากรอกชื่อ ผู้อำนวยการสำนัก
                </div>
                : null
            }
          </div>
        </div>
        <div className="row mt-3 justify-content-end">
          <div className="col-sm-12 col-md-2">
            <div className="form-check">
              <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_empty}
                     name={`is_empty_${index}`}
                     disabled={disable}
                     onChange={handleOnCheckEmpty}
                     id={`is_empty_${index}`}/>
              <label className="form-check-label pointer" htmlFor={`is_empty_${index}`}>
                ตำแหน่งว่างอยู่ตอนนี้
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-2">
            <div className="form-check">
              <input className="form-check-input pointer no-validate" type="checkbox"
                     defaultChecked={is_acting_in_position}
                     name={`is_acting_in_position${index}`}
                     disabled={disable}
                     onChange={handleOnCheckActingInPosition}
                     id={`is_acting_in_position${index}`}/>
              <label className="form-check-label pointer" htmlFor={`is_acting_in_position${index}`}>
                รักษาการในตำแหน่ง
              </label>
            </div>
          </div>
        </div>
      </div>
      {displayModal()}
      {index === organizationalStructure.department_offices.length - 1 ? null : <hr/>}
    </>
  );
};

export default OfficeDepartmentForm;
import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../shared/services/employee-service';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import {
    PunishmentHistoryModel,
    ResignationRetirementDeathHistoryModel
} from '../../../../shared/models/employee.model';
import {
    getFixValue,
    handleEmployeeSectionInputChange,
    handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { convertDateToString } from '../../../../shared/utils/functions-date-time';
import DateInput from '../../../../shared/components/atoms/date-input';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';
import InputSelect from "../../../../shared/components/atoms/input-select";

interface ResignationRetirementDeathHistoryRowProps extends ResignationRetirementDeathHistoryModel {
    index: number;
}

const ResignationRetirementDeathHistoryForm: FC = () => {
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const [sorting, setSorting] = useState<SortingField>({
        field: null,
        order: null
    });

    const tableRowNoData = () => {
        if (employee.resignation_retirement_death_histories.length === 0) {
            return <TableRowNoData colspan={5}/>;
        }
        return null;
    };

    const headers: Header[] = [
        {
            label: 'คำสั่ง อตก. ที่',
            field: 'order_no',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'สั่ง ณ วันที่',
            field: 'date',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'สถานะ',
            field: 'status',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'แนบไฟล์',
            field: 'file',
            order: null,
            isOrder: false,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const onChangeOrder = (orderData: OrderData) => {
        setSorting({
            field: orderData.field,
            order: orderData.order
        });
    };

    const dataOrder = () => {
        if (showOrder)
            return employee.resignation_retirement_death_histories.map(data => data).sort((a, b) => {
                return SortingByField(a, b, sorting);
            });
        return employee.resignation_retirement_death_histories;
    };

    return (
        <div className="h-100 overflow-x">
            <Table bordered>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
                </thead>
                <tbody>
                {
                    dataOrder().map((data, index) => {
                        return <ResignationRetirementDeathHistoryRow
                            key={index}
                            index={index}
                            {...data}
                        />;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
    );
};

const ResignationRetirementDeathHistoryRow: FC<ResignationRetirementDeathHistoryRowProps> = ({
                                                                                                 index,
                                                                                                 date,
                                                                                                 status,
                                                                                                 order_no,
                                                                                                 file,
                                                                                                 from_id,
                                                                                             }) => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const section_type = 'resignation_retirement_death_histories';
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);
    let fromEmployee: any | null = null;

    if (from_id) {
        const found = oldEmployee[section_type].find(data => data.id === from_id);
        if (found)
            fromEmployee = found;
    }

    const validateEditRequest = (value: any, key: keyof ResignationRetirementDeathHistoryModel) => {
        if (employeeAction.type === 'view-edit-request') {
            if (fromEmployee && from_id) {
                if (value !== fromEmployee[key])
                    return 'field-warning';
            }
            if (!from_id) {
                return 'field-new';
            }
        }
        return '';
    }

    const [showModal, setShowModal] = useState(false);

    const deleteButton = () => {
        if (!disable) {
            return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
        }
        return null;
    };

    const handleDelete = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        handleEmployeeSectionRemoveByIndex(section_type, index, dispatch);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <tr className="bg-white">
            <td className={`${validateEditRequest(order_no, 'order_no')}`}>
                <input type="text" className="form-control" name="order_no" disabled={disable}
                       placeholder="" value={order_no || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(date, 'date')}`}>
                <DateInput date={date ? new Date(date) : null} required={true}
                           positionAbsolute={false}
                           onChange={
                               (value: Date | null) => handleEmployeeSectionInputChange(
                                   section_type,
                                   index,
                                   getFixValue('date', value ? convertDateToString(value) : null),
                                   dispatch
                               )
                           }
                           disable={disable}/>
            </td>
            <td className={`${validateEditRequest(status, 'status')}`}>
                {/*<input type="text" className="form-control" name="status" disabled={disable}*/}
                {/*       placeholder="" value={status || ''} required={true}*/}
                {/*       onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>*/}
              <div>
                  <InputSelect options={[{label: "ลาออก", value: "ลาออก"},{label: "เกษียณ", value: "เกษียณ"},{label: "ไล่ออก", value: "ไล่ออก"}]}
                               placeholder="สถานะ" name="status" required={true} value={status || ''} isDisable={disable}
                               onChange={(e:any) => handleEmployeeSectionInputChange(section_type, index, e, dispatch, true)}/>
              </div>
            </td>
            <td className={`${validateEditRequest(file, 'file')}`}>
                <SectionFileForm section_key={'resignation_retirement_death_histories'} index={
                    disable ? employee.resignation_retirement_death_histories.findIndex(data =>
                        data.date === date &&
                        data.status === status &&
                        data.order_no === order_no
                    ) : index
                }/>
            </td>
            <td className="text-center">
                {deleteButton()}
            </td>
            <DeleteConfirmationModal
                show={showModal}
                title={'ยืนยันการลบข้อมูล'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
                onHide={handleCloseModal}
                onConfirm={handleConfirmDelete}
            />
        </tr>
    );
};

export default ResignationRetirementDeathHistoryForm;
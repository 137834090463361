import { FC } from 'react';

interface TableRowNoDataProp {
    colspan: number;
}

const TableRowNoData: FC<TableRowNoDataProp> = ({colspan}) => {
    return (
        <tr>
            <td colSpan={colspan} className="text-center">
                ไม่พบข้อมูล
            </td>
        </tr>
    );
};

export default TableRowNoData;
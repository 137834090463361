import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";
import { BarChart, Bar, XAxis} from 'recharts';
import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,

  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options, 
  position_name_Options, 
} from '../../OptionsDropDown';
import {LeaveOnlineService} from "../../../../shared/services/Report/LeaveOnline/leave-online";

const ReportSummaryInOutWorkListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    date_start: '',
    date_end: '',
    search: '',
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await LeaveOnlineService.getReportSummaryInOutWorkList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.date_start, 
    queryParams.date_end,
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
  }, []);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      date_start: value.startDate ? convertDateToString(value.startDate) : '',
      date_end: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  function formatNumber(num:any) {
    if (Math.floor(num) === num) {
        return num.toFixed(0);
    } else {
        return num.toFixed(2);
    }
  }
  
  const CustomLabel = ({ x, y, width, value, total }:any) => (
    <text x={x + width / 2} y={y - 10} fill="#000" textAnchor="middle">
      <tspan x={x + width / 2} dy="-1.2em">{`${value} คน`}</tspan>
      <tspan x={x + width / 2} dy="1.2em">{`${formatNumber((value*100)/total)} %`}</tspan>
    </text>
  );

  const CustomXAxisTick = ({ x, y, payload }:any) => {
    const words = payload.value.split('*');
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={15}textAnchor="middle" fill="#666">
          <tspan x="0" dy="0em">{words[0]}</tspan>
          <tspan x="0" dy="1.2em">{words[1]}</tspan>
        </text>
      </g>
    );
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสรุปเข้าออกงาน</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
          <DateRangeInput
            startDate={queryParams.date_start ? new Date(queryParams.date_start) : null}
            endDate={queryParams.date_end ? new Date(queryParams.date_end) : null}
            onChange={handleDateRangeChange}
            disable={false}
          />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
          <input className="form-control" type="text" value={queryParams.search as string}
              placeholder="ค้นหาจากชื่อกะ..." name="search"
              onChange={(e) => {
                setQueryParams(prevState => {
                  return {
                    ...prevState,
                    search: e.target.value || ""
                  }
                })
              }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <br /><br />

    <div className="d-flex justify-content-between flex-wrap">
      {data.rows?.map((data:any,index:any) => {
        return <div key={index} className='mb-5'>
            <h5 className="text-mof-primary m-0">{data.detail?.shift_name} {data.detail?.work_time_start.substring(0, 5)} - {data.detail?.work_time_end.substring(0, 5)}</h5>
            <BarChart
              width={600}
              height={400}
              data={[
                {
                  name: 'เข้างาน*(ปกติ)',
                  count: data.count?.on_time,
                },
                {
                  name: 'สาย*(8:30 - 9:59)',
                  count: data.count?.late,
                },
                {
                  name: 'ขาด*(เกิน 10:00)',
                  count: (data.count?.total - (data.count?.on_time + data.count?.late)),
                }
            ]}
              margin={{
                top: 70,
                bottom: 15,
              }}
            >
              <XAxis dataKey="name" tick={<CustomXAxisTick />}/>
              <Bar dataKey="count" barSize={50} fill={index % 2 === 0 ? "green" : "red"} label={{ position: 'top' , content: <CustomLabel total={data.count?.total}/> }}/>
            </BarChart>
          </div>
        })
      }
    </div>

  </div>;
}

export default ReportSummaryInOutWorkListPage;
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';

const EmployeeCheckInsStatusPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        date: '',
        date2: '',
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();
    const [sfdv_latest_dt, set_sfdv_latest_dt] = useState<any>(null);

    

    useEffect(() => {
        fetchData();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    const fetchData = async () => {
        startLoading();
        try {
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/info`, {
                params: {}
            });
            for(let i=0; i<response.data.list.length; ++i) {
                let obj = response.data.list[i];
                if(obj.key == 'sfdv_latest_dt') {
                    set_sfdv_latest_dt(obj.value);
                }
            }
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">ตรวจสอบสถานะเครื่อง</h2>
        </div>
        <div>สถานะเครื่อง: { (() => {
            let d = Moment(sfdv_latest_dt).isValid() ? Moment(sfdv_latest_dt) : null;
            let diff = null;
            if(d) { diff = Moment().diff(d, 'minute'); }
            if(d && diff != null) {
                if(diff <= 3) {
                    return <b className="text-success">ออนไลน์</b>;
                }
            }
            return <b className="text-danger">ออฟไลน์</b>;
        })() }</div>
        <div>ทำงานล่าสุด: <b>{ Moment(sfdv_latest_dt).isValid() ? Moment(sfdv_latest_dt).format('DD/MM/YYYY HH:mm:ss') : '-' }</b></div>
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsStatusPage;
import {
    EmployeeActionStatus,
    EmployeeActionType,
    EmployeeListQueryParams,
    EmployeeModel,
    EmployeeOrganizationalStructureList,
    EmployeeSection
} from '../models/employee.model';
import { AppDispatch, RootState } from '../states/store';
import { setEmployee, setEmployeeId } from '../states/employee-reducer';
import { afterSave, changeStatus, changeSubmitted, changeType } from '../states/employee-action-reducer';
import apiService from './api-service';
import { setEmployeeEditRequest } from '../states/employee-edit-request-reducer';

const EMPLOYEE_BASE_API = process.env.REACT_APP_API_URI + '/employees';

export const apiEmployeeService = {
    getEmployeeList,
    getEmployeeDetail,
    getEmployeeCount,
    downloadOrganizationalStructureReport,
    downloadEmployeeReport,
    updateEmployee,
    createEmployee,
    updateEmployeeSection,
    validateEmployee,
    getEmployeeOrganizationalStructureList,
    getEmployeeMe,
    selfEditEmployee,
	getEmployeeRecentCode
};

async function getEmployeeList(params: EmployeeListQueryParams) {
    const response = await apiService().get(`${EMPLOYEE_BASE_API}`, {params});
    return response.data;
}

async function getEmployeeRecentCode(params: EmployeeListQueryParams) {
	const response = await apiService().get(`${EMPLOYEE_BASE_API}/employees-recent-code`, {params});
	return response.data;
}

async function getEmployeeDetail(id: number) {
    const response = await apiService().get(`${EMPLOYEE_BASE_API}/${id}`);
    return response.data;
}

async function getEmployeeMe() {
    const response = await apiService().get(`${EMPLOYEE_BASE_API}/me`);
    return response.data;
}

async function getEmployeeCount(data?: any) {
    const response = await apiService().post(`${EMPLOYEE_BASE_API}/count`, data);
    return response.data;
}

async function downloadOrganizationalStructureReport(data?: any) {
    return await apiService().post(
        `${EMPLOYEE_BASE_API}/organizational-structure-reports`,
        data,
        {
            responseType: 'blob'
        }
    );
}

async function downloadEmployeeReport(data?: any) {
    return await apiService().post(
        `${EMPLOYEE_BASE_API}/reports`,
        data,
        {
            responseType: 'blob'
        }
    );
}

async function updateEmployee(id: number, data: EmployeeModel) {
    return await apiService().patch(`${EMPLOYEE_BASE_API}/${id}`, data);
}

async function selfEditEmployee(id: number, data: EmployeeModel) {
    return await apiService().post(`${EMPLOYEE_BASE_API}/${id}/edit-request`, data);
}

async function validateEmployee(data: EmployeeModel) {
    return await apiService().post(`${EMPLOYEE_BASE_API}/validate-data/`, data);
}

async function createEmployee(data: EmployeeModel) {
    return await apiService().post(`${EMPLOYEE_BASE_API}/`, data);
}

async function updateEmployeeSection(id: number, data: EmployeeSection) {
    return await apiService().patch(`${EMPLOYEE_BASE_API}/${id}/sections/`, data);
}

async function getEmployeeOrganizationalStructureList(params: EmployeeOrganizationalStructureList) {
    const response = await apiService().get(`${EMPLOYEE_BASE_API}/organizational-structures`,
        {params});
    return response.data;
}

// State

export const updateEmployeeEditProfileState = (employee: EmployeeModel, dispatch: AppDispatch) => {
    // update the user in the Redux store
    dispatch(setEmployeeEditRequest(employee));
};

export const updateEmployeeState = (employee: EmployeeModel, dispatch: AppDispatch) => {
    // update the user in the Redux store
    dispatch(setEmployee(employee));
};

export const updateEmployeeId = (employeeId: number, dispatch: AppDispatch) => {
    // update the user in the Redux store
    dispatch(setEmployeeId(employeeId));
};

export const getCurrentEmployee = (state: RootState) => state.employee;
export const getCurrentEmployeeEditProfile = (state: RootState) => state.employee_edit_request;

export const updateEmployeeActionType = (type: EmployeeActionType, dispatch: AppDispatch) => {
    dispatch(changeType(type));
};

export const updateEmployeeActionStatus = (status: EmployeeActionStatus, dispatch: AppDispatch) => {
    dispatch(changeStatus(status));
};

export const employeeActionAfterSave = (save: boolean, dispatch: AppDispatch) => {
    dispatch(afterSave(save));
};

export const updateEmployeeActionSubmitted = (isSubmitted: boolean, dispatch: AppDispatch) => {
    dispatch(changeSubmitted(isSubmitted));
};

export const getEmployeeAction = (state: RootState) => state.employee_action;

import React, {FC, useEffect, useState} from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faGear,
  faListCheck,
  faNewspaper,
  faRightFromBracket, faSignIn,
  faSitemap
} from '@fortawesome/free-solid-svg-icons';
import { ChildrenProps } from '../../../shared/models/commons.model';
import { useAuth } from '../../../shared/authentications/auth-context';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import {useNavigate} from 'react-router-dom';
import {hasPermission, canCheckInPage} from "../../../shared/utils/roles";


const NavbarLayout: FC<ChildrenProps> = ({children}) => {
  const [showLogoutModal, setLogoutModal] = useState(false);
  const {logout, permission, onSetPermission, authJwt} = useAuth();
  const navigate = useNavigate();

  const [isHidden, setIsHidden] = useState(false);
  const [vCanCheckInPage, setVCanCheckInPage] = useState(false);

  const handleLogout = () => {
    setLogoutModal(true);
  };

  const handleCloseModal = () => {
    setLogoutModal(false);
  };

  const handleConfirmLogout = () => {
    logout();
    // navigate('/login');
  };

  useEffect(() => {
    setIsHidden(true);
    canCheckInPage({ setF: setVCanCheckInPage });
  }, []);

  const displayAllModal = () => {
    if (showLogoutModal) {
      return <ConfirmModal
        show={showLogoutModal}
        title={'ยืนยันการออกจากระบบ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmLogout}
      />;
    }
  };

  const navigateToHome = () => {
    navigate('/');
  }

  
  return (
    <>
      {displayAllModal()}
      <Navbar expand="md" className="bg-mof-primary text-white overflow-hidden nav-blackground1" >
        <Navbar.Brand href="" className="text-white navbar-size">
          <Image src="/static/ORTORKOR-LOGO.png" alt="logo" className="navbar-logo ms-5 d-block pointer" onClick={navigateToHome}/>
          {/*<div className="navbar-background d-none d-sm-block">*/}
          {/*  <Image src="/static/ORTORKOR-LOGO.png" alt="logo" className="navbar-logo pointer" onClick={navigateToHome}/>*/}
          {/*</div>*/}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="me-2 text-white navbar-toggle py-2" onClick={()=>{setIsHidden(prevState => !prevState)}}>
          <FontAwesomeIcon icon={faBars}/>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" in={!isHidden}>
          <Nav className="justify-content-end d-none d-lg-block">
            <div className="text-white me-5 pointer" onClick={handleLogout}>
              <FontAwesomeIcon icon={faRightFromBracket}/>
            </div>
          </Nav>
          <Nav className="d-sm-flex d-md-none row-gap-3 mt-3" onClick={()=>{setIsHidden(true)}}>
            <a href="/employees/me/information" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faGear} className="me-2"/>
                ข้อมูลส่วนตัว
              </div>
            </a>
            <a href="/reset-password" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faGear} className="me-2"/>
                เเก้ไขรหัสผ่าน
              </div>
            </a>
            {
              hasPermission(permission, ['employees'], 'read') ?
            <a href="/employees" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faNewspaper} className="me-2"/>
                ทะเบียนประวัติบุคลากร
              </div>
            </a> : null
            }
            {
              hasPermission(permission, ['organizational_structures'], 'read') ?
            <a href="/organizational-structures" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                บริหารโครงสร้างองค์กร
              </div>
            </a> : null
            }
            {
              hasPermission(permission, ['employee_actives', 'employee_register_requests', 'employee_reset_password_requests', 'employee_edit_requests'], 'read') ?
            <a href="/employee-registrations/all-users" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faNewspaper} className="me-2"/>
                ทะเบียนพนักงาน
              </div>
            </a> : null
            }
            {
              hasPermission(permission, ['permission_approve'], 'read') || authJwt?.role === 'ADMIN' ?
            <a href="/permissions" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faGear} className="me-2"/>
                อนุมัติการกำหนดสิทธิ์
              </div>
            </a> : null
            }
            <a href="/permissions/me" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faListCheck} className="me-2"/>
                คำร้องขอสิทธิ์การเข้าถึง
              </div>
            </a>
            <a href="/employee-leaves/request" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ลาออนไลน์
              </div>
            </a>
            <a href="/employee-leaves/leave-history" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ประวัติการลา
              </div>
            </a>
            {
              hasPermission(permission, ['employee_leave_approve'], 'read') ?
            <a href="/employee-leaves/approver-request-list" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                อนุมัติการลาของผู้ใต้บังคับบัญชา
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['employee_leave_setting'], 'read') ?
            <a href="/employee-leaves/leave-setting" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ตั้งค่าวันลา
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['employee_shift'], 'read') ?
            <a href="/employee-shift/employee-shift-list" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                กำหนดตารางกะ
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['employee_shift_personel'], 'read') ?
            <a href="/employee-shift/employee-shift-personel-list" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                กำหนดตารางกะรายบุคคล
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['employee_shift_personel_view'], 'read') ?
            <a href="/employee-shift/employee-shift-personel" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                ตารางกะ
              </div>
            </a>
            : null
            }
            {
              //hasPermission(permission, ['employee_reserve_checkin'], 'read') ?
            vCanCheckInPage ?
            <a href="/employee-check-ins/check-in" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSignIn} className="me-2"/>
                เข้า-ออกงานสำรอง
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['employee_reserve_checkin_manage'], 'read') ?
            <a href="/employee-check-ins/manage-check-in" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSignIn} className="me-2"/>
                จัดการเข้า-ออกงานสำรอง
              </div>
            </a>
            : null
            }
            <a href="/employee-check-ins/list" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSignIn} className="me-2"/>
                เวลาเข้า-ออกงาน
              </div>
            </a>
            {
              hasPermission(permission, ['device_status'], 'read') ?
            <a href="/employee-check-ins/status" className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSignIn} className="me-2"/>
                ตรวจสอบสถานะเครื่อง
              </div>
            </a>
            : null
            }
            {
              hasPermission(permission, ['system_logs'], 'read') ?
            <a href={`/system-logs/list`} className="none-underline">
              <div className="text-white ms-4" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}}>
                <FontAwesomeIcon icon={faSitemap} className="me-2"/>
                รายงานผู้ใช้งานระบบ
              </div>
            </a>
            : null
            }
            <Nav.Item>
              <div className="text-danger ms-4 mt-2 mb-3" style={{border:"1px solid white", borderRadius:"8px" , width:"fit-content", padding:"6px"}} onClick={handleLogout}>
                <FontAwesomeIcon icon={faRightFromBracket} className="me-2"/>
                ออกจากระบบ
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="mt-3 px-3">
        {children}
      </div>
    </>
  );
};

export default NavbarLayout;
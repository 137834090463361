import apiService from './api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/users';


export class UserService {
  static async onActive(id: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/${id}/active`, {});
    return response.data;
  }

  static async onInActive(id: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/${id}/inactive`, {});
    return response.data;
  }

  static async onResetPassword(data: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/reset-password/`, data);
    return response.data;
  }
}

import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../shared/services/notification-service';
import {Link, useNavigate} from 'react-router-dom';
import {
  initialResponseData, LeaveRequestStatusOptions,
  LeaveRequestTypeOptions,
} from "../../shared/utils/constants";
import InputSelect from "../../shared/components/atoms/input-select";
import {LeaveRequestService} from "../../shared/services/leave-request";
import TableHeaderOrdering, {Header} from "../../shared/components/table-header-ordering";
import PaginationForm from "../../shared/components/molecules/commons/pagination-form";
import {ApiResponse} from "../../shared/models/common.model";
import TableRowNoData from "../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {LeaveRequest, LeaveRequestQueryParams, LeaveTypeNames} from "../../shared/models/leave-request.model";
import Moment from "moment";
import {useAuth} from "../../shared/authentications/auth-context";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import InputReasonModal from "../../shared/components/molecules/modals/input-reason-modal";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";

const headers: Header[] = [
  {
    label: 'ประเภท',
    field: 'leave_type',
    order: null,
    isOrder: false,
  },
  {
    label: '',
    field: '',
    order: null,
    isOrder: false,
  },
];

const LeaveSettingPage: FC = () => {
  const {authJwt} = useAuth();
  const [data, setData] = useState<ApiResponse<LeaveRequest>>(initialResponseData);
  const [showRejectModal, setRejectModal] = useState(false);
  const [queryParams, setQueryParams] = useState<LeaveRequestQueryParams>({
    request_id: '',
    status: '',
    search: '',
    page: 1,
    page_size: 10,
    leave_limit_by_type_id: '',
  });
  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();
  const [idTarget, setIdTarget] = useState('');

  const navigate = useNavigate();

  const handleCloseModal = () => {
    // setConfirmModal(false);
    setRejectModal(false);
  };

  const onReject = async (id: any) => {
    handleCloseModal();
    startLoading();
    try {
      // await EmployeeShiftService.deleteEmployeeShift(idTarget)
      if (idTarget) {
        await LeaveRequestService.deleteLeaveLimit({id: idTarget});
      }
      notificationService.successNotification('ลบสำเร็จ', '');
      await fetchData();
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ลบไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const fetchData = async () => {
    startLoading();
    try {
      const result = await LeaveRequestService.getLeaveLimitByType(queryParams)
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const displayModal = (id?: any) => {
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการลบคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={()=>{onReject(id)}}
        isDeleteModel={true}
      />;
    }
    return;
  };

  const displayButton = (id: any) => {
    if (authJwt?.role === 'ADMIN') {
      return <>
        <Button variant="warning" type="button" className="me-2 px-4" onClick={(event)=>{
          event.stopPropagation()
          navigate(`/employee-leaves/leave-setting/${id}?id=${id}&isEdit=true`)
        }}
                disabled={false}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>
        <Button variant="danger" type="button" className="me-2" onClick={(event)=>{
          event.stopPropagation()
          // onAction('reject', id)
          setRejectModal(true)
          setIdTarget(id)
        }}
                disabled={false}>
          <FontAwesomeIcon icon={faCircleXmark}/>
        </Button>
      </>;
    }
    return <>
    </>;
  };


  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page]);

    useEffect(() => {
    setQueryParams({
      ...queryParams,
      leave_limit_by_type_id: '',
    });
    }, []);

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">ตั้งค่าวันลา</h2>
      {
        // hasPermission(permission, ['organizational_structures'], 'create') ?
        <Link to="/employee-leaves/leave-setting/create">
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                  onClick={()=>{}}>
            <FontAwesomeIcon icon={faPlus} className="me-2"/>
            เพิ่มวันลา
          </Button>
        </Link>
        // : null
      }
    </div>
    {loaderComponent}
    {displayModal()}
    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={() => {
        }} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows.map((data: any) => {
            return <tr key={data.id} style={{cursor: "pointer"}} onClick={() => {
              navigate(`${data.id}?id=${data.id}`)
            }}>
              <td>{data?.leave_type_name}</td>
              {authJwt?.role === 'ADMIN' ? <td className={"d-flex flex-row justify-content-end"}>
                {
                  displayButton(data.id)
                }
              </td> : <td></td>}
            </tr>
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data?.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
}

export default LeaveSettingPage;
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiOrganizationalStructureService,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  organizationalStructureAfterSave,
  setOrganizationalStructureIdRoot,
  updateOrganizationalStructureActionSubmitted,
  updateOrganizationalStructureStatus, updateOrganizationalStructureType
} from '../../../shared/services/organizational-structure-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { reformOrganizationalStructureData } from './convert-data';
import { AxiosError } from 'axios';
import { notificationService } from '../../../shared/services/notification-service';

const SaveDraft: FC = () => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const navigate = useNavigate();

  const onSaveDraft = () => {
    if (organizationalStructure.id === null && organizationalStructureAction.type === 'create') {
      onCreate().then();
    } else if (organizationalStructureAction.type === 'edit') {
      onSave().then();
    }
  };

  const onCreate = async () => {
    updateOrganizationalStructureActionSubmitted(true, dispatch);
    const organizationalStructureReform = reformOrganizationalStructureData(organizationalStructure, null);

    try {
      startLoading();
      const result = await apiOrganizationalStructureService.createOrganizationalStructure(organizationalStructure);
      setOrganizationalStructureIdRoot(result.data.id, dispatch);
      organizationalStructureReform.id = result.data.id;
      if (result.status === 200) {
        organizationalStructureAfterSave(true, dispatch);
        updateOrganizationalStructureStatus('after_process', dispatch);

        await writeNest(result.data.id, true);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
      } else {
        notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  const onSave = async () => {
    updateOrganizationalStructureActionSubmitted(true, dispatch);
    try {
      startLoading();
      const result = await apiOrganizationalStructureService.updateOrganizationalStructure(organizationalStructure.id, organizationalStructure);
      if (result.status === 200) {
        organizationalStructureAfterSave(true, dispatch);
        updateOrganizationalStructureStatus('after_process', dispatch);

        await writeNest(organizationalStructure.id as number);
      }

    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
      } else {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  const writeNest = async (id: number, fromCreated: boolean = false) => {
    const organizationalStructureReform = reformOrganizationalStructureData(organizationalStructure, id);
    try {
      startLoading();
      const result = await apiOrganizationalStructureService.createOrganizationalStructureNest(id, organizationalStructureReform);
      if (result.status === 200) {
        notificationService.successNotification('บันทึกข้อมูลสำเร็จ', '');
        updateOrganizationalStructureType('view', dispatch);
        updateOrganizationalStructureStatus('view', dispatch);
        navigate(`/organizational-structures/`);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
      } else {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  if (disable)
    return <></>

  return <>
    {loaderComponent}
    <Button variant="primary" type="button" className="btn-action me-2" onClick={onSaveDraft}>
      บันทึกร่าง
    </Button>
  </>;
};

export default SaveDraft;
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import FamilyDetailForm from '../forms/personal_records/family-detail-form';
import ScrollToTopButton from '../../../../shared/components/molecules/commons/scroll-to-top-button';
import ChildForm from '../forms/child-form';
import SectionAddRow from '../section-add-row';
import { useDispatch, useSelector } from 'react-redux';
import { ChildModel, EmployeeModel } from '../../../../shared/models/employee.model';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeFamilyDetailProps {
  defaultKey?: AccordionEventKey;
}

const EmployeeFamilyDetail: FC<EmployeeFamilyDetailProps> = ({defaultKey = '0'}) => {
  const dispatch = useDispatch();
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);
  const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
  const keyList = [
    'contact_father_firstname', 'contact_father_lastname',
    'contact_father_prefix_name', 'contact_father_status', 'contact_mother_firstname',
    'contact_mother_lastname', 'contact_mother_prefix_name', 'contact_mother_status',
    'contact_spouse_prefix_name', 'contact_spouse_firstname', 'contact_spouse_lastname',
    'contact_spouse_status'
  ];

  const haveSomeEditField = () => {
    if (employeeAction.type === 'view-edit-request') {
      const normalEditField = keyList.some(key => {
        const kk = key as keyof EmployeeModel;
        return employee[kk] !== employeeEditProfile[kk];
      });

      const lengthNotSame = employee.children.length !== employeeEditProfile.children.length;

      if (normalEditField || lengthNotSame)
        return true;

      return employee.children.some((data, index) => {
        return Object.keys(data).some(key => {
          const kk = key as keyof ChildModel;
          if (key === 'id' || key === 'employee_id' || key === 'created_at'  || key === 'updated_at' || key === 'from_id')
            return false;
          return employeeEditProfile.children[index][kk] !== data[kk];
        });
      });
    }
    return false;
  };

  const onAddNewData = () => {
    const initData: ChildModel = {
      id: null,
      employee_id: null,
      first_name: null,
      last_name: null,
      gender: null,
      birthday: null,
      file: null,
      new_file: true,
      file_name: '',
      from_id: null,
    };
    handleEmployeeSectionAddNewData('children', initData, dispatch);
  };

  return (
    <Accordion defaultActiveKey={defaultKey} className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
          <h5 className="text-mof-primary m-0">รายละเอียดครอบครัว</h5>
        </Accordion.Header>
        <Accordion.Body>
          <FamilyDetailForm/>
          <h5 className="text-mof-primary m-0 my-4 pt-2">จำนวนบุตร</h5>
          <ChildForm/>
          <SectionAddRow onAddNewData={onAddNewData}/>
          <div className="mt-3">
            <ScrollToTopButton/>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EmployeeFamilyDetail;
import React, {FC, useEffect, useState} from 'react';
import InputSelect from '../../../../../shared/components/atoms/input-select';
import {
	BloodType,
	GenderOptions,
	PrefixNameAdultOptions,
	PrefixNameENOptions
} from '../../../../../shared/utils/constants';
import {useDispatch, useSelector} from 'react-redux';
import {
	apiEmployeeService,
	getCurrentEmployee,
	getCurrentEmployeeEditProfile,
	getEmployeeAction
} from '../../../../../shared/services/employee-service';
import {
	handleEmployeeInputChange,
	handleEmployeeInputSelectChange,
	handleEmployeeUserInputChange
} from '../../../../../shared/utils/function-state';
import {calculateAge, calculateRetirement} from '../../../../../shared/utils/function-employees';
import {convertToPositiveNumber, handleInputNumberChange} from '../../../../../shared/utils/function-validators';
import DateInput from '../../../../../shared/components/atoms/date-input';
import {convertDateToString} from '../../../../../shared/utils/functions-date-time';
import InputCurrency from '../../../../../shared/components/inputs/input-currency';
import {Logger} from "sass";
import {useNavigate} from "react-router-dom";
import {EmployeeGetModel, EmployeeListQueryParams, EmployeeModel} from "../../../../../shared/models/employee.model";
import {AxiosError} from "axios";
import {notificationService} from "../../../../../shared/services/notification-service";
import {ApiResponse} from "../../../../../shared/models/common.model";
import useSpinnerLoader from '../../../../../shared/components/spinner-loader';
import {updateField} from "../../../../../shared/states/employee-reducer";
import {useAuth} from "../../../../../shared/authentications/auth-context";

const PersonalRecordForm: FC = () => {
	const dispatch = useDispatch();
	const employeeAction = useSelector(getEmployeeAction);
	const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
	const employee = useSelector(employeeStateTarget);
	const oldEmployee = useSelector(getCurrentEmployee);
  const {authJwt} = useAuth()

	const [newEmail, setNewEmail] = React.useState<string | null | undefined>('');

	const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';

	const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();

	const [data, setData] = useState(
		{
			employees: {
				count: 0,
				total_pages: 0,
				current_page: 0,
				rows: [],
			},
			currentDate: '',
		}
	);

	const [queryParams, setQueryParams] = useState<EmployeeListQueryParams>({
		page: 1,
		page_size: 5000,
		employee_type: null,
		position_name: '',
		search: '',
		ordering_field: null,
		ordering: null,
	});

	const getAge = () => {
		if (employee.birthday) {
			return `${calculateAge(employee.birthday)} ปี`;
		}
		return 'กรุณาเลือกวันเดือนปีเกิด';
	};

	const getRetireIn = () => {
		if (employee.birthday) {
			const result = calculateRetirement(employee.birthday);
			const retire = [];
			if (result.years < 0 || result.months < 0 || result.days < 0) {
				return 'เกษียณอายุแล้ว';
			}
			if (result.years)
				retire.push(`${result.years} ปี`);
			if (result.months)
				retire.push(`${result.months} เดือน`);
			if (result.days)
				retire.push(`${result.days} วัน`);

			return retire.join(' ');
		}
		return 'กรุณาเลือกวันเดือนปีเกิด';
	};

	const handleDateInput = (date: Date | null) => {
		const data = {
			target: {
				name: 'birthday',
				value: date ? convertDateToString(date) : null
			}
		};
		handleEmployeeInputChange(data, dispatch);
	};

	const validateSalary = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.target.value = convertToPositiveNumber(event.target.value);
		handleInputNumberChange(event, dispatch);
	};

	const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleEmployeeUserInputChange(e, dispatch);
		if (e.target.value === '') {
			setNewEmail(null);
		} else {
			setNewEmail(e.target.value);

		}
	}

	useEffect(() => {
		if (employeeAction.type === 'create') {
			fetchData();
		}
	}, [queryParams,employeeAction.type]);

	const fetchData = async () => {
		startLoading();
		try {
			const result = await apiEmployeeService.getEmployeeRecentCode(queryParams);
			setData(result);
		} catch (error) {
			const err = error as AxiosError;
			notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
		} finally {
			stopLoading();
		}
	};

	useEffect(() => {
		setNewEmail('');
	}, []);

	useEffect(() => {
		if (employee.new_email) {
			setNewEmail(employee.new_email);
		}
	}, [employee.new_email]);

	useEffect(() => {
		if (employee.new_email === null) {
			setNewEmail(employee.user.email);
		}
	}, [employee.new_email]);

	useEffect(() => {
		if (employee.new_email === null && employee.user.email) {
			setNewEmail(employee.user.email);
		}
	}, [employee.new_email,employee.user.email]);

	useEffect(() => {
		if (employee.new_email === null && employee.user.email && employeeAction.type === 'view-edit-request') {
			setNewEmail("");
		}
	}, [employee.new_email,oldEmployee.new_email]);

	useEffect(() => {
		if (employeeAction.isSubmitted) {
			setNewEmail(oldEmployee.new_email);
		}
	}, [employeeAction]);

	useEffect(() => {
		if (employeeAction.isSubmitted && newEmail === null) {
			setNewEmail("");
		}
	}, [employeeAction]);

	function generateNextCodeAndFindDuplicates(data: any, prefix:string) {
		let maxCode = 0;
		const duplicates: any = {};
		const idsWithDuplicates : any = [];

		data.forEach((item:any) => {
			if (item.code?.startsWith(prefix)) {
				const currentCodeNumber = parseInt(item.code.substring(prefix.length), 10);
				if (duplicates[item.code]) {
					idsWithDuplicates.push({
						id: item.id,
						user: item.user,
						code: item.code
					});
				} else {
					duplicates[item.code] = true;
				}
				maxCode = Math.max(maxCode, currentCodeNumber);
			}
		});

		const nextCode = `${prefix}${String(maxCode + 1).padStart(3, '0')}`;

		return {
			nextCode,
			idsWithDuplicates: idsWithDuplicates.length > 0 ? idsWithDuplicates : 'No duplicates found'
		};
	}

	const year = parseInt(data?.currentDate?.split(',')[0].split('/')[2], 10);
	const yearPrefix = year + 543;
	const prefix = yearPrefix.toString().slice(2);
	const results = generateNextCodeAndFindDuplicates(data.employees?.rows, prefix);

	useEffect(() => {
		if (employeeAction.type === 'create' && !employee.code) {
			dispatch(updateField({field: "code", value: results.nextCode ?? employee.code}));
		}
	}, [employeeAction.type ,results.nextCode])

	return (
		<div>
			<div className="row">
				{/*<div className={`col-sm-12 col-md-4 pb-3 ${employee.code !== oldEmployee.code ? 'field-warning' : ''}`}>*/}
        <div className={`col-sm-12 col-md-4 pb-3`}>
					<label className="form-label required">รหัสพนักงาน</label>
					<input type="text" className="form-control" name="code" disabled={!disable && authJwt?.role === 'ADMIN' ? false : true}
						   placeholder="รหัสพนักงาน" value={employee.code || ""} required={true}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกรหัสพนักงาน
					</div>
				</div>
				<div className={`col-sm-12 col-md-4 pb-3`}>
					<label className="form-label">เกษียณอายุในอีก</label>
					<input type="text" className="form-control" name="retire_in" disabled={true}
						   placeholder="เกษียณอายุในอีก" readOnly={true} value={getRetireIn()}/>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.id_card !== oldEmployee.id_card ? 'field-warning' : ''}`}>
					<label className="form-label required">บัตรประจำตัวประชาชน</label>
					<input type="text" className="form-control" name="id_card" disabled={disable}
						   maxLength={13}
						   pattern=".{13,13}"
						   placeholder="บัตรประจำตัวประชาชน" value={employee.id_card || ''} required={true}
						   onChange={(e) => handleInputNumberChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.prefix_name_th !== oldEmployee.prefix_name_th ? 'field-warning' : ''}`}>
					<label className="form-label required">คำนำหน้า (TH)</label>
					<InputSelect options={PrefixNameAdultOptions} placeholder="กรุณาเลือก" name="prefix_name_th"
								 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
								 isDisable={disable} isClearable={false} value={employee.prefix_name_th}/>
					<input type="text" className="form-control" name="prefix_name_th" hidden={true}
						   value={employee.prefix_name_th || ''} required={true} onChange={() => {
					}}/>
					<div className="invalid-feedback">
						กรุณาเลือกคำนำหน้า (TH)
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.firstname_th !== oldEmployee.firstname_th ? 'field-warning' : ''}`}>
					<label className="form-label required">ชื่อ (TH)</label>
					<input type="text" className="form-control" name="firstname_th" disabled={disable}
						   placeholder="" value={employee.firstname_th || ''} required={true}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกชื่อ (TH)
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.lastname_th !== oldEmployee.lastname_th ? 'field-warning' : ''}`}>
					<label className="form-label required">นามสกุล (TH)</label>
					<input type="text" className="form-control" name="lastname_th" disabled={disable}
						   placeholder="" value={employee.lastname_th || ''} required={true}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}
					/>
					<div className="invalid-feedback">
						กรุณากรอกนามสกุล (TH)
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.prefix_name_en !== oldEmployee.prefix_name_en ? 'field-warning' : ''}`}>
					<label className="form-label required">คำนำหน้า (EN)</label>
					<InputSelect options={PrefixNameENOptions} placeholder="กรุณาเลือก" name="prefix_name_en"
								 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
								 isDisable={disable} isClearable={false} value={employee.prefix_name_en}/>
					<input type="text" className="form-control" name="prefix_name_en" hidden={true}
						   value={employee.prefix_name_en || ''} required={true} onChange={() => {
					}}/>
					<div className="invalid-feedback">
						กรุณาเลือกคำนำหน้า (EN)
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.firstname_en !== oldEmployee.firstname_en ? 'field-warning' : ''}`}>
					<label className="form-label required">ชื่อ (EN)</label>
					<input type="text" className="form-control" name="firstname_en"
						   placeholder="" disabled={disable} value={employee.firstname_en || ''} required={true}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกชื่อ (EN)
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.lastname_en !== oldEmployee.lastname_en ? 'field-warning' : ''}`}>
					<label className="form-label required">นามสกุล (EN)</label>
					<input type="text" className="form-control" name="lastname_en"
						   placeholder="" disabled={disable} value={employee.lastname_en || ''} required={true}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกนามสกุล (EN)
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.birthday !== oldEmployee.birthday ? 'field-warning' : ''}`}>
					<label className="form-label required">วันเดือนปีเกิด</label>
					<DateInput date={(employee.birthday && new Date(employee.birthday)) || null}
							   onChange={handleDateInput}
							   required={true}
							   maxDate={new Date()}
							   disable={disable}/>
					<input type="date" className="form-control" name="birthday"
						   placeholder="" disabled={disable} hidden={true}
						   value={(employee.birthday && new Date(employee.birthday).toISOString().slice(0, 10)) || ''}
						   required={true}
						   onChange={() => {
						   }}/>
					<div className="invalid-feedback">
						กรุณาเลือกวันเดือนปีเกิด
					</div>
				</div>
				<div className={`col-sm-12 col-md-4 pb-3`}>
					<label className="form-label">อายุ</label>
					<input type="text" className="form-control" name="age" disabled={true}
						   placeholder="" readOnly={true} value={getAge()}/>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.gender !== oldEmployee.gender ? 'field-warning' : ''}`}>
					<label className="form-label required">เพศ</label>
					<InputSelect options={GenderOptions} placeholder="กรุณาเลือก" name="gender"
								 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
								 value={employee.gender}
								 isClearable={false} isDisable={disable}/>
					<input type="text" className="form-control" name="gender" hidden={true}
						   value={employee.gender || ''} required={true} onChange={() => {
					}}/>
					<div className="invalid-feedback">
						กรุณาเลือกเพศ
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.ethnicity !== oldEmployee.ethnicity ? 'field-warning' : ''}`}>
					<label className="form-label">เชื้อชาติ</label>
					<input type="text" className="form-control" name="ethnicity" disabled={disable}
						   placeholder="" value={employee.ethnicity || ''}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}
					/>
					<div className="invalid-feedback">
						กรุณาเลือกเชื้อชาติ
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.nationality !== oldEmployee.nationality ? 'field-warning' : ''}`}>
					<label className="form-label">สัญชาติ</label>
					<input type="text" className="form-control" name="nationality" disabled={disable}
						   placeholder="" value={employee.nationality || ''} required={false}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}
					/>
					<div className="invalid-feedback">
						กรุณาเลือกสัญชาติ
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.religion !== oldEmployee.religion ? 'field-warning' : ''}`}>
					<label className="form-label">ศาสนา</label>
					<input type="text" className="form-control" name="religion" disabled={disable}
						   placeholder="" value={employee.religion || ''}
						   onChange={(e) => handleEmployeeInputChange(e, dispatch)}
					/>
					<div className="invalid-feedback">
						กรุณาเลือกศาสนา
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.phone !== oldEmployee.phone ? 'field-warning' : ''}`}>
					<label className="form-label required">เบอร์โทรศัพท์</label>
					<input type="tel" className="form-control" name="phone"
						   maxLength={10}
						   pattern=".{10,10}"
						   placeholder="" disabled={disable} value={employee.phone || ''} required={true}
						   onChange={(e) => handleInputNumberChange(e, dispatch)}/>
					<div className="invalid-feedback">
						กรุณากรอกเบอร์โทรศัพท์ 10 หลัก
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.new_email !== oldEmployee.new_email ? 'field-warning' : ''}`}>
					<label className="form-label">อีเมล</label>
					<input type="email" className="form-control" name="email"
						   placeholder="" disabled={disable}
						   value={newEmail || ''}
						   onChange={(e) => handleUserInputChange(e)}/>
					<div className="invalid-feedback">
						กรุณากรอกอีเมล
					</div>
				</div>
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.blood_type !== oldEmployee.blood_type ? 'field-warning' : ''}`}>
					<label className="form-label">กรุ๊ปเลือด</label>
					<InputSelect options={BloodType} placeholder="กรุณาเลือก" name="blood_type"
								 value={employee.blood_type || ''}
								 onChange={(e) => handleEmployeeInputSelectChange(e, dispatch)}
								 isClearable={false} isDisable={disable}/>
					<input type="text" className="form-control" name="blood_type" hidden={true}
						   value={employee.blood_type || ''} onChange={() => {
					}}/>
					<div className="invalid-feedback">
						กรุณาเลือกกรุ๊ปเลือด
					</div>
				</div>
			</div>
			<div className="row">
				<div
					className={`col-sm-12 col-md-4 pb-3 ${employee.salary !== oldEmployee.salary ? 'field-warning' : ''}`}>
					<label className="form-label">เงินเดือน</label>
					<InputCurrency value={employee.salary === 0 ? '0' : employee.salary || ''} onChange={validateSalary}
								   disable={disable}
								   name="salary" required={false}/>
				</div>
			</div>
		</div>
	);
};

export default PersonalRecordForm;
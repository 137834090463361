import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {RegistrationPersonnelHistoryService} from "../../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import {useParams} from 'react-router-dom';
import {calculateAge, calculateRetirement} from '../../../../../shared/utils/function-employees';
import Moment from "moment";

const PersonnelHistory: FC = () => {

  let {id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelHistoryDetail({id: id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAge = () => {
		if (data.birthday) {
			return `${calculateAge(data.birthday)} ปี`;
		}
		return '-';
	};

  const getRetireIn = () => {
		if (data.birthday) {
			const result = calculateRetirement(data.birthday);
			const retire = [];
			if (result.years < 0 || result.months < 0 || result.days < 0) {
				return 'เกษียณอายุแล้ว';
			}
			if (result.years)
				retire.push(`${result.years} ปี`);
			if (result.months)
				retire.push(`${result.months} เดือน`);
			if (result.days)
				retire.push(`${result.days} วัน`);

			return retire.join(' ');
		}
		return '-';
	};

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">ประวัติส่วนตัว</h5>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">รหัสพนักงาน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.code ? data.code : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เกษียณอายุในอีก</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={getRetireIn()}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เลขบัตรประจำตัวประชาชน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.id_card ? data.id_card : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.prefix_name_th ? data.prefix_name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.firstname_th ? data.firstname_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.lastname_th ? data.lastname_th : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">คำนำหน้า (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.prefix_name_en ? data.prefix_name_en : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.firstname_en ? data.firstname_en : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">นามสกุล (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.lastname_en ? data.lastname_en : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">วันเดือนปีเกิด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">อายุ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={getAge()}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เพศ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เชื้อชาติ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.ethnicity ? data.ethnicity : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">สัญชาติ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.nationality ? data.nationality : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ศาสนา</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.religion ? data.religion : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เบอร์โทรศัพท์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.phone ? data.phone : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">อีเมล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.user?.email ? data.user?.email : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">กรุ๊ปเลือด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.blood_type ? data.blood_type : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เงินเดือน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.salary ? addCommas(data.salary) : '-'}/>
              </div>
            </div>

            {/* กรณีเร่งด่วนติดต่อ */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">กรณีเร่งด่วนติดต่อ</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ - นามสกุล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_full_name ? data.emergency_contact_full_name : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">เบอร์โทรศัพท์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_tel ? data.emergency_contact_tel : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ความสัมพันธ์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_relationship ? data.emergency_contact_relationship : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-8 pb-6`}>
                <label className="form-label">สถานที่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_address ? data.emergency_contact_address : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_postcode ? data.emergency_contact_postcode : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_province?.name_th ? data.emergency_contact_province?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_amphure?.name_th ? data.emergency_contact_amphure?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.emergency_contact_tambon?.name_th ? data.emergency_contact_tambon?.name_th : '-'}/>
              </div>
            </div>

            {/* ที่อยู่ตามบัตรประชาชน */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ตามบัตรประชาชน</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-8 pb-6`}>
                <label className="form-label">ที่อยู่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.address_official ? data.address_official : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.postcode_official ? data.postcode_official : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.official_province?.name_th ? data.official_province?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.official_amphure?.name_th ? data.official_amphure?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.official_tambon?.name_th ? data.official_tambon?.name_th : '-'}/>
              </div>
            </div>

            {/* ที่อยู่ปัจจุบัน */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ปัจจุบัน</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-8 pb-6`}>
                <label className="form-label">ที่อยู่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.address_contactable ? data.address_contactable : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.postcode_contactable ? data.postcode_contactable : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contactable_province?.name_th ? data.contactable_province?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contactable_amphure?.name_th ? data.contactable_amphure?.name_th : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contactable_tambon?.name_th ? data.contactable_tambon?.name_th : '-'}/>
              </div>
            </div>
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelHistory;
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowTrendUp} from '@fortawesome/free-solid-svg-icons';
import {ProgressBar} from 'react-bootstrap';
import {EmployeeModel} from "../../../../shared/models/employee.model";
import {LeaveHistoryTypeTotal} from "../home-page";
import {getMediaPath} from "../../../../shared/utils/function-media";

type PageProps = {
  employeeData: EmployeeModel;
  leaveHistoryTypeTotal: LeaveHistoryTypeTotal;
}

const HomeCard = ({employeeData, leaveHistoryTypeTotal}: PageProps) => {

  return <>
    <div className="d-flex flex-wrap row-gap-3 column-gap-3" style={{height:"-webkit-fit-content"}}>

      <div className="home-card px-3 py-4 card shadow rounded-4">
        <div className="w-100 d-flex justify-content-between">
          <h2 className="text-danger d-inline">
            <b>0</b>
          </h2>
          <h3 className="d-inline">
            <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
          </h3>
        </div>
        <p className="text-secondary mb-1 mt-3">
          จำนวนขาดงาน
        </p>
        <div className="d-flex justify-content-between">
          <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'danger'}/>
          <span className="text-secondary">0%</span>
        </div>
      </div>

      <div className="home-card px-3 py-4 card shadow rounded-4">
        <div className="w-100 d-flex justify-content-between">
          <h2 className="text-danger d-inline">
            <b>{leaveHistoryTypeTotal?.totalLeaveAmount}</b>
          </h2>
          <h3 className="d-inline">
            <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
          </h3>
        </div>
        <p className="text-secondary mb-1 mt-3">
          จำนวนลางาน
        </p>
        <div className="d-flex justify-content-between">
          <ProgressBar className="home-process-bar w-75 d-inline-flex" now={leaveHistoryTypeTotal.usedLeavePercentage}
                       variant={'danger'}/>
          <span className="text-secondary">{leaveHistoryTypeTotal.usedLeavePercentage || "0"}%</span>
        </div>
      </div>

      <div className="home-card px-3 py-4 card shadow rounded-4">
        <div className="w-100 d-flex justify-content-between">
          <h2 className="text-danger d-inline">
            <b>0</b>
          </h2>
          <h3 className="d-inline">
            <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
          </h3>
        </div>
        <p className="text-secondary mb-1 mt-3">
          จำนวนเข้างานสาย
        </p>
        <div className="d-flex justify-content-between">
          <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'danger'}/>
          <span className="text-secondary">0%</span>
        </div>
      </div>

      <div className="home-card px-3 py-4 card shadow rounded-4">
        <div className="w-100 d-flex justify-content-between">
          <h2 className="text-info d-inline">
            <b>0</b>
          </h2>
          <h3 className="d-inline">
            <FontAwesomeIcon icon={faArrowTrendUp} className="me-2"/>
          </h3>
        </div>
        <p className="text-secondary mb-1 mt-3">
          จำนวนตรงเวลา
        </p>
        <div className="d-flex justify-content-between">
          <ProgressBar className="home-process-bar w-75 d-inline-flex" now={0} variant={'info'}/>
          <span className="text-secondary">0%</span>
        </div>
      </div>
    </div>
  </>
}

export default HomeCard;
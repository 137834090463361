import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/styless.scss';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import employeeReducer from './shared/states/employee-reducer';
import employeeActionReducer from './shared/states/employee-action-reducer';
import { ReactNotifications } from 'react-notifications-component';
import organizationalStructureReducer from './shared/states/organizational-structure-reducer';
import organizationalStructureActionReducer from './shared/states/organizational-structure-action-reducer';
import employeeEditRequestReducer from './shared/states/employee-edit-request-reducer';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = configureStore({
  reducer: {
    employee: employeeReducer,
    employee_action: employeeActionReducer,
    employee_edit_request: employeeEditRequestReducer,
    organizational_structure: organizationalStructureReducer,
    organizational_structure_action: organizationalStructureActionReducer,
  },
});


root.render(
  <Provider store={store}>
    <ReactNotifications/>
    <App/>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

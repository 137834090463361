import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { SelfResetPassword } from '../../../shared/models/authentication.model';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { Button, Form } from 'react-bootstrap';
import { notificationService } from '../../../shared/services/notification-service';
import { useAuth } from '../../../shared/authentications/auth-context';
import { useNavigate } from 'react-router-dom';
import { apiAuthentication } from '../../../shared/services/authentication-service';
import { UserService } from '../../../shared/services/user-service';
import {SystemLogService} from "../../../shared/services/system-log-service";

const ResetPasswordPage: FC = () => {
  const {logout} = useAuth();
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState<SelfResetPassword>({
    old_password: '',
    password: '',
    confirm_password: '',
  });
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [passwordValidator, setPasswordValidator] = useState({
    isNotMatchOldValid: false,
    isConfirmValid: false,
    isLengthValid: false,
    isUppercaseValid: false,
    isLowercaseValid: false,
    isNumberValid: false,
    isSpecialCharValid: false,
  });
  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hidePassword, setHidePassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const navigate = useNavigate();

  const {authJwt} = useAuth();

  const createSystemLog = async (data: any) => {
    try {
      await SystemLogService.create(data);
    } catch (error) {
      const err = error as any;
      notificationService.dangerNotification('ระบบขัดข้อง', err.message);
    }
  }

  useEffect(() => {
    createSystemLog({
      employee_id: authJwt?.employee_id,
      module: 'reset-password',
      action: 'view',
    });
  }, []);


  useEffect(() => {
    validatePassword();
  }, [form.password, form.confirm_password, form.old_password]);

  const validatePassword = () => {
    const regexPassword = /[!@#$%^&*()_+|~\-=`{}\[\]:";'<>?,./]/;
    // Validate the password against individual criteria
    setPasswordValidator(prevState => ({
      isNotMatchOldValid: form.password !== form.old_password,
      isConfirmValid: form.password === form.confirm_password,
      isLengthValid: form.password.length >= 8,
      isUppercaseValid: /[A-Z]/.test(form.password),
      isLowercaseValid: /[a-z]/.test(form.password),
      isNumberValid: /\d/.test(form.password),
      isSpecialCharValid: regexPassword.test(form.password),
    }));
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setForm(prevState => ({
      ...prevState,
      password: password
    }));
  };

  const handlePasswordOldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setForm(prevState => ({
      ...prevState,
      old_password: password
    }));
  };

  const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setForm(prevState => ({
      ...prevState,
      confirm_password: password
    }));
  };

  const onClickHidePassword = () => {
    setHidePassword(prevState => !prevState);
  };

  const onClickHideOldPassword = () => {
    setHideOldPassword(prevState => !prevState);
  };

  const onClickConfirmHidePassword = () => {
    setHideConfirmPassword(prevState => !prevState);
  };

  const onResetPassword = async () => {
    setSubmitted(true);
    if (
      !passwordValidator.isConfirmValid ||
      !passwordValidator.isLengthValid ||
      !passwordValidator.isUppercaseValid ||
      !passwordValidator.isLowercaseValid ||
      !passwordValidator.isNumberValid ||
      !passwordValidator.isSpecialCharValid
    ) {
      notificationService.warningNotification('กรุณากรอกรหัสผ่านตามเงื่อนไขที่ระบุ', '');
      return;
    }

    startLoading();
    try {
      const res = await UserService.onResetPassword(form);
      createSystemLog({
        employee_id: authJwt?.employee_id,
        module: 'reset-password',
        action: 'edit',
      });
      notificationService.successNotification('เปลี่ยนรหัสผ่านเรียบร้อย', 'กรุณาลองเข้าระบบอีกครั้ง');
      logout();
      navigate('/login');
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">
        แก้ไขรหัสผ่าน
      </h2>
    </div>

    <Form noValidate validated={submitted} className="">
      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label" htmlFor="id_card">รหัสผ่านเก่า</label>
          <div className="input-group ">
            <input type={hideOldPassword ? 'password' : 'text'} className="form-control" id="password"
                   name="password"
                   required onChange={handlePasswordOldChange}
                   value={form.old_password}/>
            <span className="input-group-text pointer" onClick={onClickHideOldPassword}>
              <FontAwesomeIcon icon={hideOldPassword ? faEyeSlash : faEye}/>
          </span>
          </div>
        </div>
      </div>

      <hr/>

      <div className="row mt-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label" htmlFor="id_card">รหัสผ่านใหม่</label>
          <div className="input-group ">
            <input type={hidePassword ? 'password' : 'text'} className="form-control" id="password"
                   name="password"
                   required onChange={handlePasswordChange}
                   value={form.password}/>
            <span className="input-group-text pointer" onClick={onClickHidePassword}>
                                <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye}/>
                            </span>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label" htmlFor="username">ยืนยันรหัสผ่าน</label>
          <div className="input-group ">
            <input type={hideConfirmPassword ? 'password' : 'text'} className="form-control"
                   id="confirm_password"
                   name="confirm_password"
                   required onChange={handlePasswordConfirmChange}
                   value={form.confirm_password}/>
            <span className="input-group-text pointer" onClick={onClickConfirmHidePassword}>
                                <FontAwesomeIcon icon={hideConfirmPassword ? faEyeSlash : faEye}/>
                            </span>
          </div>
        </div>
        <div className="d-flex flex-column mt-3">
          <div>
            <FontAwesomeIcon icon={passwordValidator.isNotMatchOldValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">รหัสผ่านต้องไม่ซ้ำกับรหัสผ่านเดิม</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isConfirmValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">ยืนยันรหัสผ่านใหม่เหมือนกัน</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isLengthValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">อักขระอย่างน้อย 8 ตัว</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isUppercaseValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">ตัวอักษรพิมพ์ใหญ่ (A-Z)</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isLowercaseValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">ตัวอักษรพิมพ์เล็ก (a-z)</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isNumberValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">ตัวเลข (0-9)</label>
          </div>
          <div>
            <FontAwesomeIcon icon={passwordValidator.isSpecialCharValid ? faCircleCheck : faCircle}
                             color={'#056310'}/>
            <label className="ms-2">{`อักขระพิเศษ(!@#$ % ^ & * ()_+|~-=\`{}[]:";'<>?,./)`}</label>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-12 col-md-4">
            <Button variant="primary" type="button"
                    onClick={onResetPassword}
                    className="d-block btn-action btn-mof-primary">
              บันทึก
            </Button>
          </div>
        </div>

      </div>
    </Form>

  </>
}

export default ResetPasswordPage;
import React, { useState } from 'react';
import LoaderSpinner from './molecules/commons/loader-spinner';

function useSpinnerLoader() {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = () => {
        setIsLoading(true);
    };

    const stopLoading = () => {
        setIsLoading(false);
    };

    const loaderComponent = isLoading ? <LoaderSpinner/> : null;

    return {loaderComponent, isLoading, startLoading, stopLoading};
}

// export const useSpinnerLoader = {SpinnerLoader}
export default useSpinnerLoader;
import { Outlet, useParams } from 'react-router-dom';
import EmployeeCard from './components/employee-card';
import EmployeeTabMenu from './components/employee-tab-menu';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  employeeActionAfterSave,
  getEmployeeAction,
  updateEmployeeActionStatus,
  updateEmployeeActionSubmitted,
  updateEmployeeActionType,
  updateEmployeeEditProfileState,
  updateEmployeeState,
} from '../../shared/services/employee-service';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import { RequestService } from '../../shared/services/request-service';
import { initialStateEmployee } from '../../shared/states/employee-reducer';

const EmployeeEditProfilePage: FC = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [requestStatus, setRequestStatus] = useState('');

  const fetchData = async () => {
    startLoading();
    try {
      const requestData: any = await RequestService.getEditProfileRequestDetail(id);
      const employee = {
        ...requestData.created_by_employee,
        user: requestData.created_by_user,
      };
      const newEmployee = {
        ...requestData.new_employee,
        user: requestData.created_by_user,
      };

      setRequestStatus(requestData.status);

      updateEmployeeState(employee, dispatch);
      updateEmployeeEditProfileState(newEmployee, dispatch);
      updateEmployeeActionType('view-edit-request', dispatch);
      updateEmployeeActionStatus('view', dispatch);
      employeeActionAfterSave(false, dispatch);
      updateEmployeeActionSubmitted(false, dispatch);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeTab = (tabMenu: number) => {

  };

  return (
    <div className="pb-5">
      {loaderComponent}
      <div className="pt-4 pb-2">
        <EmployeeCard editRequestStatus={requestStatus}/>
      </div>
      <hr/>
      <EmployeeTabMenu isView={true} employeeId={id!} onSelectTap={onChangeTab}/>
      <Outlet/>
    </div>
  );
};

export default EmployeeEditProfilePage;

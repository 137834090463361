import React, { FC, useState } from 'react';
import {
  deleteStructurePosition,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updatePersonnel,
  updateStructurePosition
} from '../../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import { StructurePositionModel } from '../../../../shared/models/organizational-structure.model';
import { faMinus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputSelect from '../../../../shared/components/atoms/input-select';
import {
  handleStructurePositionInputSelectChange
} from '../../../../shared/services/organizational-structure-input-change-state';
import { convertToPositiveNumber, handleNumericInput } from '../../../../shared/utils/function-validators';
import { notificationService } from '../../../../shared/services/notification-service';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { convertToCurrency } from '../../../../shared/utils/currency';
import InputCurrency from '../../../../shared/components/inputs/input-currency';

interface StructurePositionFormProps extends StructurePositionModel {
  index: number;
  lastButton: boolean;
}

const StructurePositionForm: FC<StructurePositionFormProps> = ({
                                                                 index,
                                                                 id,
                                                                 structure_department_id,
                                                                 structure_department_index,
                                                                 pay_account_number,
                                                                 name,
                                                                 power_rate,
                                                                 lastButton,
                                                                 level,
                                                               }) => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    organizationalStructure.personnels.forEach((data, dataIndex) => {
      if (data.structure_position_index === index) {
        const resetId = {
          target: {
            name: 'structure_position_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'structure_position_index',
            value: null,
          }
        };
        updatePersonnel(dataIndex, resetId, dispatch);
        updatePersonnel(dataIndex, resetIndex, dispatch);
      } else if ((data.structure_position_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'structure_position_index',
            value: (data.structure_position_index as number) - 1,
          }
        };
        updatePersonnel(dataIndex, resetIndex, dispatch);
      }
    });
    deleteStructurePosition({index: index}, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const displayModal = () => {
    if (showModal) {
      const child = organizationalStructure.personnels
        .filter(data => data.structure_position_index === index);
      const message = <>
        <p>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</p>
        {
          child.length > 0 ?
            <p className="m-0">
              มี บุคลากร ภายใต้ ตำแหน่ง <b>{name}</b> ทั้งหมด <b>{child.length}</b> คน
            </p> : ''
        }
        {
          child.length > 0 ?
            <div className="alert alert-warning mb-0 mt-2" role="alert">
              <FontAwesomeIcon icon={faWarning} className="me-2"/>
              เมื่อกดลบแล้ว บุคลากรภายใต้ทั้งหมดจะต้องเลือก ตำแหน่ง ใหม่
            </div> : ''
        }
      </>;
      return <DeleteConfirmationModal
        show={showModal}
        size={'lg'}
        title={'ยืนยันการลบข้อมูล'}
        description={message}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />;

    }
    return;
  };

  const buttonDisplay = () => {
    return <button className="btn btn-danger" type="button" onClick={handleDelete} hidden={disable || index === 0}>
      <FontAwesomeIcon icon={faMinus}/>
    </button>;
  };

  const getOptions = () => {
    return organizationalStructure.structure_departments.map((data, index) => {
      const {label, disabled} = getPowerRateRemain(index, data.power_rate || 0, data.is_empty);
      return {
        label: `${data.name || '-'} ${convertToCurrency(label)}`,
        value: index,
        disabled: disabled
      };
    });
  };

  const getPowerRateRemain = (structure_department_index: number | null, powerRateCap: number, isEmptyInPosition: boolean) => {
    const powerRateUsed = organizationalStructure.structure_positions
      .filter(data => data.structure_department_index === structure_department_index)
      .map(data => parseInt(String(data.power_rate || 0)))
      .reduce((total_used, power_rate) => {
        return total_used + power_rate;
      }, 0);
    return {
      label: `(อัตรากำลัง: ${convertToCurrency(isEmptyInPosition ? powerRateUsed : powerRateUsed + 1)}/${convertToCurrency(powerRateCap)})`,
      disabled: isEmptyInPosition ? powerRateUsed >= powerRateCap : powerRateUsed + 1 >= powerRateCap
    };
  };

  const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumericInput(e);
    updateStructurePosition(index, e, dispatch);
  };

  const validateMaxPowerRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = convertToPositiveNumber(event.target.value);

    if (structure_department_index !== null) {
      let powerRateCap = organizationalStructure.structure_departments[structure_department_index].power_rate || 0;
      if (!organizationalStructure.structure_departments[structure_department_index].is_empty) {
        powerRateCap = Math.max(0, powerRateCap - 1);
      }
      const powerRateUse = organizationalStructure.structure_positions
        .filter((data, data_index) => data.structure_department_index === structure_department_index && data_index !== index)
        .map(data => data.power_rate || 0)
        .map(Number)
        .reduce((total_used, power_rate) => {
          return total_used + power_rate;
        }, 0);
      const powerRateRemain = powerRateCap - powerRateUse;
      if (+event.target.value > powerRateRemain) {
        event.target.value = powerRateRemain.toString();
        notificationService.infoNotification('อัตรากำลังเกินกำหนด', `ปรับอัตรากำลังให้พอดีกับยอดที่เหลืออยู่คือ ${convertToCurrency(powerRateRemain)}`);
      }
    }
    updateStructurePosition(index, event, dispatch);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">แผนก</label>
          <InputSelect options={getOptions()} placeholder="กรุณาเลือก" name="structure_department_index"
                       onChange={e => handleStructurePositionInputSelectChange(index, e, dispatch)}
                       value={structure_department_index}
                       isClearable={false} isDisable={disable}/>
          <input type="text" className="form-control" name="structure_department_index" hidden={true}
                 value={structure_department_index?.toString() || ''} required={true} onChange={() => {
          }}/>
          <div className="invalid-feedback">
            กรุณากรอกเลือกแผนก
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">เลขบัญชีถือจ่าย</label>
          <input type="text" className="form-control" name="pay_account_number" disabled={disable}
                 placeholder="" value={pay_account_number || ''} required={true}
                 onChange={validateNumber}/>
          <div className="invalid-feedback">
            กรุณากรอกเลขบัญชีถือจ่าย
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ชื่อตำแหน่ง</label>
          <div className="input-group">
            <input type="text" className="form-control rounded" name="name" disabled={disable}
                   placeholder="" value={name || ''} required={true}
                   onChange={(e) => updateStructurePosition(index, e, dispatch)}/>
            <div className="input-group-append ps-2">
              {buttonDisplay()}
            </div>
            <div className="invalid-feedback">
              กรุณากรอกชื่อตำแหน่ง
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">อัตรากำลัง</label>
          <InputCurrency value={power_rate || ''} onChange={validateMaxPowerRate}
                         disable={disable || structure_department_index === null}
                         name="power_rate" required={true}/>
          <div className="invalid-feedback">
            กรุณากรอกอัตรากำลัง
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ระดับ</label>
          <div className="input-group">
            <input type="text" className="form-control" name="level" disabled={disable}
                   onChange={(e) => updateStructurePosition(index, e, dispatch)}
                   placeholder="" value={level || ''} required={true}/>
            <div className="invalid-feedback">
              กรุณากรอกระดับ
            </div>
          </div>
        </div>
      </div>
      {displayModal()}
      {index === organizationalStructure.structure_positions.length - 1 ? null : <hr/>}
    </>
  );
};

export default StructurePositionForm;
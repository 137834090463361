import axios from 'axios';
import { getToken, removeToken } from '../authentications/auth';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            removeToken();
            window.location.href = '/login?invalid_token=true';
        }
        return Promise.reject(error);
    }
);

function setHeader(token: string) {
    if (token) {
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers['Authorization'];
    }
}

const apiService = () => {
    setHeader(getToken()!);
    return axiosInstance;
};

export default apiService;
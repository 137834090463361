import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../shared/services/notification-service';
import {useNavigate} from 'react-router-dom';
import {
  initialResponseData, LeaveRequestStatusOptions,
  LeaveRequestTypeOptions,
} from "../../shared/utils/constants";
import InputSelect from "../../shared/components/atoms/input-select";
import {LeaveRequestService} from "../../shared/services/leave-request";
import TableHeaderOrdering, {Header} from "../../shared/components/table-header-ordering";
import PaginationForm from "../../shared/components/molecules/commons/pagination-form";
import {ApiResponse} from "../../shared/models/common.model";
import TableRowNoData from "../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {LeaveRequest, LeaveRequestQueryParams, LeaveTypeNames} from "../../shared/models/leave-request.model";
import Moment from "moment";
import {apiDropdownService} from "../../shared/services/dropdown-service";
import InputReasonModal from "../../shared/components/molecules/modals/input-reason-modal";

const headers: Header[] = [
  {
    label: 'ชื่อ - นามสกุล',
    field: 'full_name_th',
    order: null,
    isOrder: true,
  },
  {
    label: 'ตำแหน่ง',
    field: 'role',
    order: null,
    isOrder: true,
  },
  {
    label: 'ประเภทการลา',
    field: 'leave_type',
    order: null,
    isOrder: true,
  },
  {
    label: 'วันที่เริ่มลา',
    field: 'start_date',
    order: null,
    isOrder: true,
  },
  {
    label: 'วันสิ้นสุดการลา',
    field: 'end_date',
    order: null,
    isOrder: false,
  },
  {
    label: 'ผู้อนุมัติ',
    field: 'approver_action_user',
    order: null,
    isOrder: true,
  },
  {
    label: 'สถานะ',
    field: 'status',
    order: null,
    isOrder: false,
  },
  {
    label: '',
    field: 'cancel',
    order: null,
    isOrder: false,
  },
];

const ApproverLeaveRequestListPages: FC = () => {
  const [data, setData] = useState<ApiResponse<LeaveRequest>>(initialResponseData);
  const [queryParams, setQueryParams] = useState<LeaveRequestQueryParams>({
    request_id: '',
    leave_limit_by_type_id: '',
    status: '',
    search: '',
    page: 1,
    page_size: 10,
  });
  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();
  const [leaveTypeOptions, setLeaveTypeOptions] = useState<any>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [requestId, setRequestId] = useState<number | null>(null)

  const navigate = useNavigate();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await LeaveRequestService.getApproverLeaveRequestList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows.length === 0) {
      return <TableRowNoData colspan={8}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  const getStatusName = (status: string) => {
    switch (status) {
      case 'waiting_for_approver':
        return 'รออนุมัติ';
      case 'approved':
        return 'อนุมัติ';
      case 'rejected':
        return 'ไม่อนุมัติ';
      case 'cancelled':
        return 'ยกเลิก';
      default:
        return '-';
    }
  }

  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page]);

    useEffect(() => {
    setQueryParams({
      ...queryParams,
      leave_limit_by_type_id: '',
    });
    }, []);

  const leaveRequestDropdown = async () => {
    const leaveRequestTypeOptions = await apiDropdownService.getLeaveRequestDropdown({});
    if (leaveRequestTypeOptions) {
      setLeaveTypeOptions(leaveRequestTypeOptions);
    }
  }

  const getLeaveTypeName = (leaveTypeId: string) => {
    const leaveTypeOption:any = leaveTypeOptions.find((data:any) => data.value === leaveTypeId);
    return leaveTypeOption?.label || "-";
  }

  const handleCancelSubmitClick = async () => {
    try {
      startLoading();
      const response = await LeaveRequestService.sendLeaveRequestStatus({
        leave_request_id: requestId,
        status: 'cancelled',
        reject_reason: null,
        isApproverUserActionType: true
      })
      if (response) {
        notificationService.successNotification('ยืนยันการยกเลิกเเล้ว', '');
        setDialogOpen(false)
        fetchData();
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  }

  useEffect(() => {
    leaveRequestDropdown();
  }, []);

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">อนุมัติการลาของผู้ใต้บังคับบัญชา</h2>
    </div>

    {loaderComponent}
    {dialogOpen && <InputReasonModal
        show={dialogOpen}
        title={'ยืนยันการยกเลิก'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกคำขอนี้'}
        onHide={() => {
          setDialogOpen(false)
        }}
        onConfirm={handleCancelSubmitClick}
        isDeleteModel={true}
    />}
    <div className="row mt-2">
      <div className="flex-row col-sm-12 col-md-4">
        <label className="form-label">ประเภทการลา</label>
        <InputSelect value={queryParams.leave_limit_by_type_id} options={leaveTypeOptions} placeholder="" name="is_active"
                     onChange={(e) => {
                       setQueryParams(prevState => {
                         return {
                           ...prevState,
                           leave_limit_by_type_id: e.value.value || ""
                         }
                       })
                     }} required={true}
                     isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-4">
        <label className="form-label">สถานะ</label>
        <InputSelect value={queryParams.status} options={LeaveRequestStatusOptions} placeholder="" name="is_active"
                     onChange={(e) => {
                       setQueryParams(prevState => {
                         return {
                           ...prevState,
                           status: e.value.value || ""
                         }
                       })
                     }} required={true}
                     isValidateError={false}/>
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
               placeholder="ค้นหาจากชื่อ..." name="search"
               onChange={(e) => {
                 setQueryParams(prevState => {
                   return {
                     ...prevState,
                     search: e.target.value || ""
                   }
                 })
               }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={() => {
        }} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows.map((data: LeaveRequest) => {
            return <tr key={data.id} style={{cursor: "pointer"}} onClick={() => {
              navigate(`${data.id}/details`)
            }}>
              <td>{data.created_by_user?.employee?.fullname_th}</td>
              <td>{data.created_by_user?.employee?.job_positions?.find(data => data.is_currently)?.position_name}</td>
              <td>{getLeaveTypeName(data.leave_request_details[0]?.leave_limit_by_type_id)}</td>
              <td>{Moment(data.leave_request_details[0]?.start_date).utcOffset('-00:00').format('DD/MM/YYYY HH:mm:ss')}</td>
              <td>{Moment(data.leave_request_details[0]?.end_date).utcOffset('-00:00').format('DD/MM/YYYY HH:mm:ss')}</td>
              <td>{data.approver_action_user?.employee?.fullname_th}</td>
              <td>{getStatusName(data.status)}</td>
              <td>{data.status !== "cancelled" && <Button variant="danger" className="text-white"
                                                                     style={{marginLeft: "-50px", zIndex: 1000}} onClick={(e:any) => {
                e.stopPropagation();
                setDialogOpen(true)
                setRequestId(data.id)
              }}>
                  ยกเลิก
              </Button>}</td>
            </tr>
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
}

export default ApproverLeaveRequestListPages;
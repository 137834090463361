import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import { InsigniaHistoryModel } from '../../../../shared/models/employee.model';
import {
  getFixValue,
  handleEmployeeSectionInputChange,
  handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { handleNumericInput } from '../../../../shared/utils/function-validators';
import { convertDateToString } from '../../../../shared/utils/functions-date-time';
import DateInput from '../../../../shared/components/atoms/date-input';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';

interface InsigniaHistoryRowProps extends InsigniaHistoryModel {
  index: number;
}

const InsigniaHistoryForm: FC = () => {
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const [sorting, setSorting] = useState<SortingField>({
    field: null,
    order: null
  });

  const tableRowNoData = () => {
    if (employee.insignia_histories.length === 0) {
      return <TableRowNoData colspan={6}/>;
    }
    return null;
  };

  const headers: Header[] = [
    {
      label: 'ประจำปี',
      field: 'year',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'วันที่ได้รับเครื่องราชย์',
      field: 'date_of_award',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ประเภท',
      field: 'type',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'เครื่องราชอิสริยาภรณ์',
      field: 'name',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'แนบไฟล์',
      field: 'file',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  const onChangeOrder = (orderData: OrderData) => {
    setSorting({
      field: orderData.field,
      order: orderData.order
    });
  };

  const dataOrder = () => {
    if (showOrder)
      return employee.insignia_histories.map(data => data).sort((a, b) => {
        return SortingByField(a, b, sorting);
      });
    return employee.insignia_histories;
  };

  return (
    <div className="h-100 overflow-x">
      <Table bordered>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
        </thead>
        <tbody>
        {
          dataOrder().map((data, index) => {
            return <InsigniaHistoryRow
              key={index}
              index={index}
              {...data}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
  );
};

const InsigniaHistoryRow: FC<InsigniaHistoryRowProps> = ({
                                                           index,
                                                           year,
                                                           date_of_award,
                                                           type,
                                                           name,
                                                           file,
                                                           from_id,
                                                         }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const section_type = 'insignia_histories';
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  let fromEmployee: any | null = null;

  if (from_id) {
    const found = oldEmployee[section_type].find(data => data.id === from_id);
    if (found)
      fromEmployee = found;
  }

  const validateEditRequest = (value: any, key: keyof InsigniaHistoryModel) => {
    if (employeeAction.type === 'view-edit-request') {
      if (fromEmployee && from_id) {
        if (value !== fromEmployee[key])
          return 'field-warning';
      }
      if (!from_id) {
        return 'field-new';
      }
    }
    return '';
  };

  const [showModal, setShowModal] = useState(false);

  const deleteButton = () => {
    if (!disable) {
      return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
    }
    return null;
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleEmployeeSectionRemoveByIndex(section_type, index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <tr className="bg-white">
      <td className={`${validateEditRequest(year, 'year')}`}>
        <input type="text" className="form-control" name="year" disabled={disable}
               placeholder="" value={year || ''} required={true} maxLength={4}
               pattern=".{4,4}"
               onChange={(e) => {
                 handleNumericInput(e);
                 handleEmployeeSectionInputChange(section_type, index, e, dispatch);
               }}/>
      </td>
      <td className={`${validateEditRequest(date_of_award, 'date_of_award')}`}>
        <DateInput date={date_of_award ? new Date(date_of_award) : null} required={true}
                   positionAbsolute={false}
                   onChange={
                     (value: Date | null) => handleEmployeeSectionInputChange(
                       section_type,
                       index,
                       getFixValue('date_of_award', value ? convertDateToString(value) : null),
                       dispatch
                     )
                   }
                   disable={disable}/>
      </td>
      <td className={`${validateEditRequest(type, 'type')}`}>
        <input type="text" className="form-control" name="type" disabled={disable}
               placeholder="" value={type || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
      </td>
      <td>
        <input type="text" className="form-control" name="name" disabled={disable}
               placeholder="" value={name || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(file, 'file')}`}>
        <SectionFileForm section_key={'insignia_histories'} index={
          disable ? employee.insignia_histories.findIndex(data =>
            data.year === year &&
            data.date_of_award === date_of_award &&
            data.type === type &&
            data.name === name
          ) : index
        }/>
      </td>
      <td className="text-center">
        {deleteButton()}
      </td>
      <DeleteConfirmationModal
        show={showModal}
        title={'ยืนยันการลบข้อมูล'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </tr>
  );
};

export default InsigniaHistoryForm;
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import EducationHistoryForm from '../forms/education-history-form';
import { useDispatch, useSelector } from 'react-redux';
import { EducationHistoryModel } from '../../../../shared/models/employee.model';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import SectionAddRow from '../section-add-row';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeEducationHistoryProps {
  defaultKey?: AccordionEventKey;
}

const EmployeeEducationHistory: FC<EmployeeEducationHistoryProps> = ({defaultKey = '0'}) => {
  const dispatch = useDispatch();
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);

  const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
  const haveSomeEditField = () => {
    if (employeeAction.type === 'view-edit-request') {
      const lengthNotSame = employee.education_histories.length !== employeeEditProfile.education_histories.length;

      if (lengthNotSame)
        return true;

      return employee.education_histories.some((data, index) => {
        return Object.keys(data).some(key => {
          const kk = key as keyof EducationHistoryModel;
          if (key === 'id' || key === 'employee_id' || key === 'created_at'  || key === 'updated_at' || key === 'from_id')
            return false;
          return employeeEditProfile.education_histories[index][kk] !== data[kk];
        });
      });
    }
    return false;
  };

  const onAddNewData = () => {
    const initData: EducationHistoryModel = {
      id: null,
      employee_id: null,
      school_name: null,
      graduation_level: null,
      graduation_major: null,
      graduation_year: null,
      file: null,
      new_file: true,
      file_name: '',
      from_id: null,
    };
    handleEmployeeSectionAddNewData('education_histories', initData, dispatch);
  };

  return (
    <Accordion defaultActiveKey={defaultKey} className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
          <h5 className="text-mof-primary m-0">ประวัติการศึกษา</h5>
        </Accordion.Header>
        <Accordion.Body>
          <EducationHistoryForm/>
          <SectionAddRow onAddNewData={onAddNewData}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EmployeeEducationHistory;
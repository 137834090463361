import { Store } from 'react-notifications-component';
import { iNotification } from 'react-notifications-component/dist/src/typings';


function successNotification(title: string, message: string, options: iNotification = {
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
}) {
    Store.addNotification({...options, title: title, message: message});
}

function warningNotification(title: string, message: any, duration = 5000, options: iNotification = {
    type: 'warning',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: duration,
        onScreen: true
    }
}) {
    Store.addNotification({...options, title: title, message: message});
}

function dangerNotification(title: string, message: any, options: iNotification = {
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        onScreen: true,
    }
}) {
    Store.addNotification({...options, title: title, message: message});
}

function infoNotification(title: string, message: string, options: iNotification = {
    type: 'info',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
        duration: 5000,
        onScreen: true,
    }
}) {
    Store.addNotification({...options, title: title, message: message});
}

export const notificationService = {
    successNotification,
    warningNotification,
    dangerNotification,
    infoNotification,
};
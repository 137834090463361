import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJs,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';

interface SummaryBarProps {
    insideCount: number;
    outsideCount: number;
}

const SummaryBar: FC<SummaryBarProps> = ({insideCount = 0, outsideCount = 0}) => {

    const dataLabelOption: LabelOptions = {
        align: 'top',
        anchor: 'end',
        offset: 0,
        color: 'black',
        font: {
            size: 18
        }
    };
    const options: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
            },
            tooltip: {
                enabled: false
            },
            datalabels: dataLabelOption
        },
        scales: {
            x: {
                display: false,
                grid: {
                    drawOnChartArea: false
                }
            },
            y: {
                display: false,
                beginAtZero: true,
                grid: {
                    drawOnChartArea: false,
                }
            },
        },
    };

    const labels = [''];

    const data = {
        labels,
        datasets: [
            {
                label: 'บุคลากรอยู่ในโครงสร้าง',
                data: [insideCount],
                backgroundColor: '#056310',
                borderRadius: 10,
                borderSkipped: false,

            },
            {
                label: 'บุคลากรไม่อยู่ในโครงสร้าง',
                data: [outsideCount],
                backgroundColor: '#E60019',
                borderRadius: 10,
                borderSkipped: false,
            },
        ],
    };
    ChartJs.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    return <div className="h-100">
        <Bar options={options} data={data} plugins={[ChartDataLabels]}/>
    </div>;
};

export default SummaryBar;
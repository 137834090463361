import React, { FC } from 'react';
import { useAuth } from '../shared/authentications/auth-context';
import { Navigate, Outlet } from 'react-router-dom';
import { ModuleAction, ModuleType } from '../shared/models/permission.model';

interface ProtectRouteProps {
  fixRoles?: string[];
  redirectPath: string;
  modules: ModuleType[];
  moduleType: ModuleAction;
}

export const RolePermissions: FC<ProtectRouteProps> = ({fixRoles = [], redirectPath, modules, moduleType}) => {
  const {authJwt, permission, logout} = useAuth();

  if (authJwt === null) {
    logout();
  }

  const havePermissionInModules = modules.some(moduleName =>
    permission[moduleName] && permission[moduleName][moduleType]);
  if (havePermissionInModules) {
    return <Outlet/>;
  }
  if (modules.some(moduleName => moduleName === "permission_approve") && moduleType === "read" && authJwt!.role === "ADMIN") {
	return <Outlet/>;
  }

  if (fixRoles.includes(authJwt!.role)) {
    return <Outlet/>;
  }

  // notificationService.infoNotification('คุณไม่มีสิทธิ์เข้าถึงหน้านี้', '');
  // return <Navigate to={redirectPath}/>;
  return null
};


import apiService from '../../api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/report';

export class LeaveOnlineService {
  static async getReportLeaveEachTypeList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave_type_stat`, {params: data});
    return response.data;
  }

  static async getReportSummaryInOutWorkList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/summarize_check_in`, {params: data});
    return response.data;
  }
}
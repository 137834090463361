import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewStructureDepartment,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateOrganizationalStructurePage
} from '../../../shared/services/organizational-structure-service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import StructureDepartmentForm from '../components/forms/structure-department-form';
import { getMessageContents } from '../../../shared/utils/error-messages';
import { convertToCurrency } from '../../../shared/utils/currency';
import SaveDraft from '../functions/save-draft';
import { StructureDepartmentModel } from '../../../shared/models/organizational-structure.model';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';

const StructureDepartmentPage: FC = () => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  let {id} = useParams();
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [showSkipModalData, setShowSkipModalData] = useState<any>({
    title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
    message: 'ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่',
  })

  useEffect(() => {
    updateOrganizationalStructurePage('structure-department', dispatch);
  }, []);

  const getPreviousLink = () => {
    if (id) {
      return `/organizational-structures/${id}/division-group-office`;
    }
    return '/organizational-structures/create/division-group-office';
  };

  const getNextLink = () => {
    if (id) {
      navigate(`/organizational-structures/${id}/structure-position`);
    } else {
      navigate('/organizational-structures/create/structure-position');
    }
  };

  const addNewData = () => {
    addNewStructureDepartment(
      {
        value: {
          id: null,
          department_office_id: null,
          department_office_index: null,
          division_group_office_id: null,
          division_group_office_index: null,
          director_id: null,
          director_index: null,
          deputy_director_id: null,
          deputy_director_index: null,
          type: 'normal',
          name: null,
          power_rate: null,
          user_id: null,
          is_acting_in_position: false,
          is_empty: false,
          pay_account_number: null,
          level: '',
        }
      },
      dispatch
    );
  };

  const DisplayButton = () => {
    if (!disable) {
      return <button className="btn btn-primary d-block px-4 btn-action-height btn-mof-primary" onClick={addNewData}
                     hidden={disable} type="button">
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        เพิ่มข้อมูล
      </button>;
    }
    return;
  };

  const disablePowerRate = (data: StructureDepartmentModel) => {
    if (data.type === 'normal') {
      return typeof data.division_group_office_index !== 'number';
    } else if (data.type === 'director') {
      return typeof data.director_index !== 'number';
    } else if (data.type === 'department_office') {
      return typeof data.department_office_index !== 'number';
    } else {
      return typeof data.deputy_director_index !== 'number';
    }
  }

  const validatePowerRateOfficeDepartment = () => {
    const powerRateCap = organizationalStructure.department_offices.map((departmentData, index) => {
      const powerRateUse = organizationalStructure.structure_departments
        .filter(data => data.department_office_index === index)
        .map(data => data.power_rate || 0)
        .map(Number)
        .reduce((total, power_rate) => {
          return total + power_rate;
        }, 0);
      return {
        name: `${departmentData.name}`,
        power_rate: !departmentData.is_empty ? ((departmentData.power_rate || 0) - 1) - powerRateUse : (departmentData.power_rate || 0) - powerRateUse
      };
    });
    const powerRateRemaining = powerRateCap.filter(data => data.power_rate > 0);
    const powerRateOverLimit = powerRateCap.filter(data => data.power_rate < 0);

    if (powerRateRemaining.length > 0) {
      const message = powerRateRemaining.map(data => `${data.name} ยังเหลืออัตรากำลังอีก ${convertToCurrency(data.power_rate)} คน`);

      setShowSkipModalData({
        title: 'อัตรากำลังยังไม่ครบกำหนด',
        message: getMessageContents(message),
      })
      setShowSkipModal(true);
      // notificationService.warningNotification('อัตรากำลังยังไม่ครบกำหนด', getMessageContents(message), 6000);
      return;
    } else if (powerRateOverLimit.length > 0) {
      const message = powerRateOverLimit.map(data => `${data.name} อัตรากำลังเกิน ${convertToCurrency(Math.abs(data.power_rate))} คน`);

      setShowSkipModalData({
        title: 'อัตรากำลังเกินกำหนด',
        message: getMessageContents(message),
      })
      setShowSkipModal(true);
      // notificationService.warningNotification('อัตรากำลังเกินกำหนด', getMessageContents(message), 6000);
      return;
    }
    getNextLink();
  }

  const validateForm = () => {
    if (organizationalStructureAction.status === 'view') {
      getNextLink();
      return;
    }
    setSubmitted(true);
    const inValid = organizationalStructure.structure_departments
      .some(data => {
        const isUserNotSelect = !data.is_empty && !data.user_id;
        return !data.name ||
          isUserNotSelect ||
          !data.pay_account_number ||
          !data.level ||
          disablePowerRate(data) ||
          (data.power_rate || -1) < 0;
      });
    if (inValid) {
      setShowSkipModalData({
        title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
        message: 'ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่',
      })
      setShowSkipModal(true);
      return;
    }
    const powerRateCap = organizationalStructure.division_group_offices.map((divisionGroupData, index) => {
      const powerRateUse = organizationalStructure.structure_departments
        .filter(data => data.division_group_office_index === index)
        .map(data => data.power_rate || 0)
        .map(Number)
        .reduce((total, power_rate) => {
          return total + power_rate;
        }, 0);
      return {
        name: `${divisionGroupData.name}`,
        power_rate: !divisionGroupData.is_empty ? ((divisionGroupData.power_rate || 0) - 1) - powerRateUse : (divisionGroupData.power_rate || 0) - powerRateUse
      };
    });
    const powerRateRemaining = powerRateCap.filter(data => data.power_rate > 0);
    const powerRateOverLimit = powerRateCap.filter(data => data.power_rate < 0);

    if (powerRateRemaining.length > 0) {
      const message = powerRateRemaining.map(data => `${data.name} ยังเหลืออัตรากำลังอีก ${convertToCurrency(data.power_rate)} คน`);

      setShowSkipModalData({
        title: 'อัตรากำลังยังไม่ครบกำหนด',
        message: getMessageContents(message),
      })
      setShowSkipModal(true);
      // notificationService.warningNotification('อัตรากำลังยังไม่ครบกำหนด', getMessageContents(message), 6000);
      return;
    } else if (powerRateOverLimit.length > 0) {
      const message = powerRateOverLimit.map(data => `${data.name} อัตรากำลังเกิน ${convertToCurrency(Math.abs(data.power_rate))} คน`);

      setShowSkipModalData({
        title: 'อัตรากำลังเกินกำหนด',
        message: getMessageContents(message),
      })
      setShowSkipModal(true);
      // notificationService.warningNotification('อัตรากำลังเกินกำหนด', getMessageContents(message), 6000);
      return;
    }
    // validatePowerRateOfficeDepartment();
    getNextLink();

  };

  const handleCloseSkipModal = () => {
    setShowSkipModal(false);
  }

  const handleConfirmSkipModal = () => {
    getNextLink();
  }

  return (
    <Form noValidate validated={organizationalStructureAction.isSubmitted || submitted}
          className="custom-no-validation">
      <ConfirmModal
        show={showSkipModal}
        title={showSkipModalData.title}
        description={showSkipModalData.message}
        confirmLabel={'ใช่'}
        onHide={handleCloseSkipModal}
        onConfirm={handleConfirmSkipModal}
      />
      <div className="h-100 content-background-color rounded-4 px-4 py-4">
        {
          organizationalStructure.structure_departments.map((data, index) => {
            return <StructureDepartmentForm key={index}
                                            index={index}
                                            lastButton={index === organizationalStructure.structure_departments.length - 1}
                                            {...data}/>;
          })
        }
        <div className="row">
          <div className="col-12">
            {DisplayButton()}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end my-4">
        <Link to={getPreviousLink()}>
          <Button variant="secondary" type="button" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        <SaveDraft/>
        <Button variant="primary" type="button" className="btn-action me-2" onClick={validateForm}>
          ถัดไป
          <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
        </Button>
      </div>
    </Form>
  );
};

export default StructureDepartmentPage;
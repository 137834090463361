import React, { useEffect, useState } from 'react';
import ImagePreviewer from './image-previewer';
import { Button, Modal } from 'react-bootstrap';
import PDFPreviewer from './pdf-previewer';
import { base64ToFile } from '../../../utils/function-attachment';
import { getMediaPath } from '../../../utils/function-media';

interface FilePreviewerProps {
    fileUrl: string;
    fileName: string;
    show: boolean;
    base64String: boolean;
    onHide: () => void;
}

const FilePreviewer: React.FC<FilePreviewerProps> = ({fileUrl, fileName, show, base64String, onHide}) => {
    const [fileUrlString, setFileUrlString] = useState<string | File>(getMediaPath(fileUrl));
    const [fileExtension, setFileExtension] = useState<string>('');

    useEffect(() => {
        getFile().then();
    }, [fileUrl]);

    const getFile = async () => {
        if (base64String) {
            const convertedFile = await base64ToFile(fileUrl, fileName);
            setFileUrlString(convertedFile);
            getFileExtensionFromFile(convertedFile);
        } else {
            setFileUrlString(getMediaPath(fileUrl));
            getFileExtension();
        }
    };

    const getFileExtension = () => {
        const fileExtension = fileUrl.split('.').pop()?.toLowerCase() || '';
        setFileExtension(fileExtension);
    };

    const getFileExtensionFromFile = (convertedFile: File) => {
        const fileExtension = convertedFile.name.split('.').pop()?.toLowerCase() || '';
        setFileExtension(fileExtension);
    };

    const supportedFileTypes = ['jpg', 'jpeg', 'png', 'gif'];

    const displayPreviewer = () => {
        if (supportedFileTypes.includes(fileExtension)) {
            return <ImagePreviewer imageUrl={fileUrlString} fileName={fileName}/>;
        } else if (fileExtension === 'pdf') {
            return <PDFPreviewer fileUrl={fileUrlString} fileName={fileName}/>;
        } else {
            return <p>
                .{fileExtension} นามสกุลไฟล์นี้ไม่รองรับการดูตัวอย่างไฟล์
            </p>;
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>ดูตัวอย่างไฟล์</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {
                        displayPreviewer()
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    ปิด
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FilePreviewer;

import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ManageUserService} from "../../../../shared/services/Report/ManageUser/manage-user";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import Moment from "moment";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";

const headers: Header[] = [
  {
    label: 'Username',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ชื่อ - สกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เข้าใช้งานล่าสุด',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportLoginListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    date_start: '',
    date_end: '',
    search: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await ManageUserService.getReportLoginList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };
  
  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page, queryParams.date_start, queryParams.date_end]);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      date_start: value.startDate ? convertDateToString(value.startDate) : '',
      date_end: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานการเข้าใช้งานระบบ</h2>
    </div>

    {loaderComponent}

    <div className="row mt-2">
      <div className="flex-row col-sm-12 col-md-4">
          <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
          <DateRangeInput
            startDate={queryParams.date_start ? new Date(queryParams.date_start) : null}
            endDate={queryParams.date_end ? new Date(queryParams.date_end) : null}
            onChange={handleDateRangeChange}
            disable={false}
          />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
          <input className="form-control" type="text" value={queryParams.search as string}
              placeholder="ค้นหาจากชื่อ..." name="search"
              onChange={(e) => {
                setQueryParams(prevState => {
                  return {
                    ...prevState,
                    search: e.target.value || ""
                  }
                })
              }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.username ? data.username : '-'}</td>
                <td>{data.prefix_name_th ? data.prefix_name_th : ''}{data.full_name_th ? data.full_name_th : '-'}</td>
                <td>{data.current_job_position?.position_name ? data.current_job_position?.position_name : '-'}</td>
                <td>{data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : '-'}</td>
                <td>{data.last_login ? Moment(data.last_login).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportLoginListPage;
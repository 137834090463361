import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface InfoModalProps {
  show: boolean;
  title: string;
  description: string | any;
  size?: 'sm' | 'lg' | 'xl';
  onHide: () => void;
}

const InfoModal: FC<InfoModalProps> = ({
                                         show,
                                         title,
                                         description,
                                         size,
                                         onHide,
                                       }) => {
  return (
    <Modal show={show} onHide={onHide} centered size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          ปิด
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getEmployeeAction } from '../../../shared/services/employee-service';

interface SectionAddRowProps {
  onAddNewData: () => void;
}

const SectionAddRow: FC<SectionAddRowProps> = ({onAddNewData}) => {
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'job_position_view' || employeeAction.type === 'view-edit-request';

  const handleOnAddNewData = () => {
    onAddNewData();
  };

  return (
    <>
      <Button variant="primary" className="btn-action me-2 btn-mof-primary" disabled={disable}
              onClick={handleOnAddNewData}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        เพิ่ม
      </Button>
    </>
  );
};

export default SectionAddRow;
import {
  DepartmentOfficeModelReform,
  DeputyDirectorModelReform,
  DirectorModelReform,
  DivisionGroupOfficeModelReform,
  OrganizationalStructureModel,
  OrganizationalStructureModelReform,
  PersonnelModel,
  StructureDepartmentModelReform,
  StructurePositionModelReform
} from '../../../shared/models/organizational-structure.model';

export const convertBackendToFrontend = (dataMaster: OrganizationalStructureModelReform) => {
  const combineFromDeputyDirectorsAndDirectors: (DirectorModelReform | DeputyDirectorModelReform)[] = [...dataMaster.deputy_directors, ...dataMaster.directors];

  const department_offices_array: any[] = combineFromDeputyDirectorsAndDirectors.map((data, index) => {
    return data.department_offices.map((nData, nIndex) => {
      let index_value = {};
      if (nData.type === 'department') {
        index_value = {
          deputy_director_index: index
        };
      } else {
        const directorIndex = dataMaster.directors.findIndex(dData => dData.id === nData.director_id);
        index_value = {
          director_index: directorIndex
        };
      }
      return {
        ...nData,
        ...index_value
      };
    });
  });
  const department_offices: DepartmentOfficeModelReform[] = [].concat.apply([], department_offices_array);

  const division_group_offices_array: any[] = department_offices.map((data, index) => {
    return data.division_group_offices.map((nData, nIndex) => {
      return {
        ...nData,
        department_office_index: index,
      };
    });
  });
  const division_group_offices_form_director_array: any[] = combineFromDeputyDirectorsAndDirectors.map((data, index) => {
    return data.division_group_offices.map((nData, nIndex) => {
      let index_value = {};
      if (nData.type === 'deputy_director') {
        index_value = {
          deputy_director_index: index
        };
      } else {
        const directorIndex = dataMaster.directors.findIndex(dData => dData.id === nData.director_id);
        index_value = {
          director_index: directorIndex
        };
      }
      return {
        ...nData,
        ...index_value
      };
    });
  });
  const division_group_offices_form_director: DivisionGroupOfficeModelReform[] = [].concat.apply([], division_group_offices_form_director_array);
  const division_group_offices: DivisionGroupOfficeModelReform[] = [].concat.apply([], [...division_group_offices_array, ...division_group_offices_form_director]);

  const structure_departments_from_department_office: any[] = department_offices.map((data, index) => {
    return data.structure_departments.map((nData, nIndex) => {
      return {
        ...nData,
        department_office_index: index,
      };
    });
  });
  const structure_departments_array: any[] = division_group_offices.map((data, index) => {
    return data.structure_departments.map((nData, nIndex) => {
      return {
        ...nData,
        division_group_office_index: index,
      };
    });
  });
  const structure_departments_form_director_array: any[] = combineFromDeputyDirectorsAndDirectors.map((data, index) => {
    return data.structure_departments.map((nData, nIndex) => {
      let index_value = {};
      if (nData.type === 'deputy_director') {
        index_value = {
          deputy_director_index: index
        };
      } else {
        const directorIndex = dataMaster.directors.findIndex(dData => dData.id === nData.director_id);
        index_value = {
          director_index: directorIndex
        };
      }
      return {
        ...nData,
        ...index_value
      };
    });
  });
  const structure_departments_form_director: StructureDepartmentModelReform[] = [].concat.apply([], structure_departments_form_director_array);
  const structure_departments: StructureDepartmentModelReform[] = [].concat.apply([], [...structure_departments_array, ...structure_departments_form_director, ...structure_departments_from_department_office]);

  const structure_positions_array: any[] = structure_departments.map((data, index) => {
    return data.structure_positions.map((nData, nIndex) => {
      return {
        ...nData,
        structure_department_index: index,
      };
    });
  });
  const structure_positions: StructurePositionModelReform[] = [].concat.apply([], structure_positions_array);

  const personnels_array: any[] = structure_positions.map((data, index) => {
    return data.personnels.map((nData, nIndex) => {
      return {
        ...nData,
        structure_position_index: index,
      };
    });
  });
  const personnels: PersonnelModel[] = [].concat.apply([], personnels_array);

  return {
    department_offices,
    division_group_offices,
    structure_departments,
    structure_positions,
    personnels,
  };
};

export const reformOrganizationalStructureData = (organizationalStructure: OrganizationalStructureModel, id: number | null): OrganizationalStructureModelReform => {
  const structure_positions: any[] = organizationalStructure.structure_positions.map((data, index) => {
    return {
      ...data,
      personnels: organizationalStructure.personnels.filter((item) => item.structure_position_index === index)
    };
  });
  const structure_departments: StructureDepartmentModelReform[] = organizationalStructure.structure_departments.map((data, index) => {
    return {
      ...data,
      structure_positions: structure_positions.filter((item) => item.structure_department_index === index)
    };
  });
  const division_group_offices: DivisionGroupOfficeModelReform[] = organizationalStructure.division_group_offices.map((data, index) => {
    return {
      ...data,
      structure_departments: structure_departments.filter((item) => item.division_group_office_index === index)
    };
  });
  const department_offices: DepartmentOfficeModelReform[] = organizationalStructure.department_offices.map((data, index) => {
    return {
      ...data,
      division_group_offices: division_group_offices.filter((item) => item.department_office_index === index),
      structure_departments: structure_departments.filter((item) => item.department_office_index === index && item.type === 'department_office'),
    };
  });
  const deputy_directors: DeputyDirectorModelReform[] = organizationalStructure.deputy_directors.map((data, index) => {
    return {
      ...data,
      department_offices: department_offices.filter((item) => item.deputy_director_index === index && item.type === 'department'),
      division_group_offices: division_group_offices.filter((item) => item.deputy_director_index === index && item.type === 'deputy_director'),
      structure_departments: structure_departments.filter((item) => item.deputy_director_index === index && item.type === 'deputy_director'),
    };
  });
  const directors: DirectorModelReform[] = organizationalStructure.directors.map((data, index) => {
    return {
      ...data,
      department_offices: department_offices.filter((item) => item.director_index === index && item.type === 'office'),
      division_group_offices: division_group_offices.filter((item) => item.director_index === index && item.type === 'director'),
      structure_departments: structure_departments.filter((item) => item.director_index === index && item.type === 'director'),
    };
  });
  return {
    id: id,
    name: organizationalStructure.name,
    created_by: organizationalStructure.created_by,
    budget_year: organizationalStructure.budget_year,
    status: organizationalStructure.status,
    active: organizationalStructure.active,
    directors: directors,
    deputy_directors: deputy_directors,
  };
};
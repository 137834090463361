import React, { FC, useEffect, useState } from 'react';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatToThaiDate } from '../../utils/functions-date-time';
import Calendar from 'react-calendar';
import { Value } from 'react-calendar/src/shared/types';
import ClickOutsideDetector from '../click-outside-detector';

interface DateInputProps {
    date: Date | null;
    disable: boolean;
    required: boolean;
    minDate?: Date;
    maxDate?: Date;
    positionAbsolute?: boolean;
    onChange: (value: Date | null) => void;
    placeHolder?: string;
}

const DateInput: FC<DateInputProps> = ({date, disable, required, minDate, maxDate, positionAbsolute = true, onChange, placeHolder}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateThaiFormat, setDateThaiFormat] = useState<Date | null>(date);

    useEffect(() => {
        if (date !== dateThaiFormat) {
            setDateThaiFormat(date)
        }
    }, [onChange]);

    const handleThaiDateInputClick = () => {
        if (disable) {
            return;
        }
        setShowCalendar(true);
    };

    const handleDateRangeSelection = (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
        setDateThaiFormat(value as Date | null);
        setShowCalendar(false);
        onChange(value as Date | null);
    };

    const getValue = () => {
        return dateThaiFormat
            ? formatToThaiDate(dateThaiFormat)
            : '';
    };

    const onClickOutsideCalendar = () => {
        setShowCalendar(false);
    };

    return (
        <>
            <div className="form-group">
                <span className="form-control-icon me-1">
                    <FontAwesomeIcon icon={faCalendar}/>
                </span>
                <input type="text" className="form-control form-date-control" placeholder={placeHolder ? placeHolder : ""} required={required}
                       value={getValue()} disabled={disable} onChange={() => {
                }} onClick={handleThaiDateInputClick}/>
                {showCalendar && (
                    <ClickOutsideDetector onClickOutside={onClickOutsideCalendar}>
                        <div className={`${positionAbsolute ? 'position-absolute calendar-index' : ''}`}>
                            <Calendar
                                locale="th-US"
                                value={dateThaiFormat}
                                minDate={minDate}
                                maxDate={maxDate}
                                onChange={handleDateRangeSelection}
                            />
                        </div>
                    </ClickOutsideDetector>
                )}
            </div>
        </>
    );
};


export default DateInput;
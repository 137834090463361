import React from 'react';
import LeaveSettingSections from "./leave-setting-sections-page";


const LeaveSettingViewPage = () => {
  return (
    <div>
      <LeaveSettingSections isViewPage={true}/>
    </div>
  );
};

export default LeaveSettingViewPage;

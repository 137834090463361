import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Important: Use the worker from the react-pdf library.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface PDFPreviewerProps {
    fileUrl: string | File;
    fileName: string;
}

const PDFPreviewer: React.FC<PDFPreviewerProps> = ({fileUrl, fileName}) => {
    const [numPages, setNumPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-center flex-column flex-sm-row">
                <button className="btn btn-primary px-3" onClick={goToPrevPage} disabled={currentPage <= 1}>
                    <FontAwesomeIcon icon={faCaretLeft}/>
                </button>
                <p className="mb-0 align-self-center mx-2">
                    หน้า <b>{currentPage}</b> จาก {numPages}
                </p>
                <button className="btn btn-primary px-3" onClick={goToNextPage} disabled={currentPage >= numPages}>
                    <FontAwesomeIcon icon={faCaretRight}/>
                </button>
            </div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={currentPage}>
                </Page>
            </Document>
        </div>
    );
};

export default PDFPreviewer;
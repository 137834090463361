import React, { ChangeEvent, FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { JobPosition } from '../../../../shared/models/employee.model';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import {
  getFixValue,
  handleEmployeeSectionInputChange,
  handleEmployeeSectionInputSelectChange,
  handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';
import InputSelect from '../../../../shared/components/atoms/input-select';
import { EmployeeType } from '../../../../shared/utils/constants';
import DateInput from '../../../../shared/components/atoms/date-input';
import { convertDateToString, dateDiff } from '../../../../shared/utils/functions-date-time';

interface JobPositionNewFormRowProps extends JobPosition {
  index: number;
}

const JobPositionNewForm: FC = () => {
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const [sorting, setSorting] = useState<SortingField>({
    field: null,
    order: null
  });

  const tableRowNoData = () => {
    if (employee.job_positions.length === 0) {
      return <TableRowNoData colspan={12}/>;
    }
    return null;
  };

  const headers: Header[] = [
    {
      label: 'ประเภทบุคลากร',
      field: 'employee_type',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ระดับตำแหน่ง',
      field: 'position_level',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'วันที่ครองตำแหน่ง',
      field: 'tenure_position_date',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ตำแหน่ง',
      field: 'position_name',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ลักษณะตำแหน่ง',
      field: 'position_type',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'วันที่เริ่มงาน',
      field: 'start_date',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'วันที่สิ้นสุด',
      field: 'end_date',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'อายุงาน',
      field: '-',
      order: null,
      isOrder: false,
    },
    {
      label: 'คำสั่ง อตก. ที่',
      field: 'order_no',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'เรื่อง',
      field: 'title',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'หมายเหตุ',
      field: 'reason',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'แนบไฟล์',
      field: 'file',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  const onChangeOrder = (orderData: OrderData) => {
    setSorting({
      field: orderData.field,
      order: orderData.order
    });
  };

  const dataOrder = () => {
    if (showOrder)
      return employee.job_positions.map(data => data).sort((a, b) => {
        return SortingByField(a, b, sorting);
      });
    return employee.job_positions;
  };

  return (
    <div className="h-100 overflow-x">
      <Table bordered>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
        </thead>
        <tbody>
        {
          dataOrder().map((data, index) => {
            return <JobPositionRow
              key={index}
              index={index}
              {...data}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
  );
};

const JobPositionRow: FC<JobPositionNewFormRowProps> = ({
                                                          index,
                                                          employee_id,
                                                          employee_type,
                                                          position_level,
                                                          position_name,
                                                          position_type,
                                                          start_date,
                                                          end_date,
                                                          is_currently,
                                                          reason,
                                                          order_no,
                                                          title,
                                                          file,
                                                          from_id,
                                                          tenure_position_date
                                                        }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const type = 'job_positions';
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  let fromEmployee: any | null = null;

  if (from_id) {
    const found = oldEmployee[type].find(data => data.id === from_id);
    if (found)
      fromEmployee = found;
  }

  const validateEditRequest = (value: any, key: keyof JobPosition) => {
    if (employeeAction.type === 'view-edit-request') {
      if (fromEmployee && from_id) {
        if (value !== fromEmployee[key])
          return 'field-warning';
      }
      if (!from_id) {
        return 'field-new';
      }
    }
    return '';
  };


  const [showModal, setShowModal] = useState(false);

  const deleteButton = () => {
    if (!disable) {
      return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
    }
    return null;
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleEmployeeSectionRemoveByIndex(type, index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getDateDiff = () => {
    if (start_date) {
      const currentEnd = end_date ? end_date : new Date().toDateString();
      const data = dateDiff(start_date, currentEnd);
      const result = [];
      if (data.years)
        result.push(`${Math.abs(data.years)} ปี`);
      if (data.months)
        result.push(`${Math.abs(data.months)} เดือน`);
      if (data.days)
        result.push(`${Math.abs(data.days)} วัน`);
      return result.length > 0 ? result.join(' ') : '1 วัน';
    }
    return 'กรุณาเลือกวันที่เริ่มต้นทำงาน';
  };

  const onCheckCurrently = (e: ChangeEvent<HTMLInputElement>) => {
    const setIsCurrently = {
      target: {
        name: 'is_currently',
        value: e.target.checked
      }
    };
    handleEmployeeSectionInputChange(type, index, setIsCurrently, dispatch);

    if (e.target.checked) {
      employee.job_positions
        .forEach((data, DataIndex) => {
          if (data.is_currently && DataIndex !== index) {
            const resetIsCurrently = {
              target: {
                name: 'is_currently',
                value: false
              }
            };
            handleEmployeeSectionInputChange(type, DataIndex, resetIsCurrently, dispatch);
          }
        });
      const resetEndDate = {
        target: {
          name: 'end_date',
          value: null
        }
      };
      handleEmployeeSectionInputChange(type, index, resetEndDate, dispatch);
    }
  };

  return (
    <tr className="bg-white">
      <td className={`min-230 ${validateEditRequest(employee_type, 'employee_type')}`}>
        <InputSelect options={EmployeeType} placeholder="กรุณาเลือก" name="employee_type"
                     onChange={(e) => handleEmployeeSectionInputSelectChange(type, index, e, dispatch)}
                     isDisable={disable} isClearable={false}
                     value={employee_type}/>
        <input type="text" className="form-control" name="employee_type" hidden={true}
               value={employee_type || ''} required={true}
               onChange={() => {
               }}/>
        <div className="invalid-feedback">
          กรุณาเลือกประเภทบุคลากร
        </div>
      </td>
      <td className={`min-230 ${validateEditRequest(position_level, 'position_level')}`}>
        <input type="text" className="form-control" name="position_level" disabled={disable}
               maxLength={100}
               placeholder="" value={position_level || ''} required={false}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`min-230 ${validateEditRequest(tenure_position_date, 'tenure_position_date')}`}>
          <DateInput date={tenure_position_date ? new Date(tenure_position_date) : null} required={true}
                    positionAbsolute={false}
                    onChange={
                      (value: Date | null) => handleEmployeeSectionInputChange(
                        type,
                        index,
                        getFixValue('tenure_position_date', value ? convertDateToString(value) : null),
                        dispatch
                      )
                    }
                    disable={disable}/>
      </td>
      <td className={`min-230 ${validateEditRequest(position_name, 'position_name')}`}>
        <input type="text" className="form-control" name="position_name" disabled={disable}
               maxLength={100}
               placeholder="" value={position_name || ''} required={false}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`min-230 ${validateEditRequest(position_type, 'position_type')}`}>
        <input type="text" className="form-control" name="position_type" disabled={disable}
               maxLength={250}
               placeholder="" value={position_type || ''} required={false}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(start_date, 'start_date')}`}>
        <DateInput date={start_date ? new Date(start_date) : null} required={true}
                   positionAbsolute={false}
                   onChange={
                     (value: Date | null) => handleEmployeeSectionInputChange(
                       type,
                       index,
                       getFixValue('start_date', value ? convertDateToString(value) : null),
                       dispatch
                     )
                   }
                   disable={disable}/>
      </td>
      <td className={`min-230 ${validateEditRequest(end_date, 'end_date')}`}>
        <DateInput date={end_date ? new Date(end_date) : null} required={false}
                   positionAbsolute={false}
                   onChange={
                     (value: Date | null) => handleEmployeeSectionInputChange(
                       type,
                       index,
                       getFixValue('end_date', value ? convertDateToString(value) : null),
                       dispatch
                     )
                   }
                   disable={disable || is_currently}/>
        <div className="form-check">
          <input className="form-check-input pointer no-validate" type="checkbox"
                 checked={is_currently}
                 name={`is_currently_${index}`}
                 disabled={disable}
                 onChange={onCheckCurrently}
                 id={`is_currently_${index}`}/>
          <label className="form-check-label pointer" htmlFor={`is_currently_${index}`}>
            ดำรงตำแหน่งถึงปัจจุบัน
          </label>
        </div>
      </td>
      <td className={`align-self-center`}>
        <input type="text" className="form-control" disabled={true}
               value={getDateDiff()}/>
      </td>
      <td className={`min-230 ${validateEditRequest(order_no, 'order_no')}`}>
        <input type="text" className="form-control" name="order_no" disabled={disable}
               maxLength={250}
               placeholder="" value={order_no || ''} required={false}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`min-230 ${validateEditRequest(title, 'title')}`}>
        <input type="text" className="form-control" name="title" disabled={disable}
               maxLength={250}
               placeholder="" value={title || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`min-230 ${validateEditRequest(reason, 'reason')}`}>
        <input type="text" className="form-control" name="reason" disabled={disable}
               placeholder="" value={reason || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(file, 'file')}`}>
        <SectionFileForm section_key={'job_positions'} index={
          disable ? employee.job_positions.findIndex(data =>
            employee_type === data.employee_type &&
            position_level === data.position_level &&
            position_name === data.position_name &&
            position_type === data.position_type &&
            start_date === data.start_date &&
            end_date === data.end_date &&
            reason === data.reason &&
            order_no === data.order_no &&
            title === data.title &&
            file === data.file
          ) : index
        }/>
      </td>
      <td className="text-center">
        {deleteButton()}
      </td>
      <DeleteConfirmationModal
        show={showModal}
        title={'ยืนยันการลบข้อมูล'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </tr>
  );
};

export default JobPositionNewForm;
import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { EmployeeCertifierModel, PunishmentHistoryModel } from '../../../../shared/models/employee.model';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../shared/services/employee-service';
import {
    handleEmployeeSectionInputChange,
    handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';

interface CertifierRowProps extends EmployeeCertifierModel {
    index: number;
}

const CertifierForm: FC = () => {
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const [sorting, setSorting] = useState<SortingField>({
        field: null,
        order: null
    });

    const tableRowNoData = () => {
        if (employee.employee_certifiers.length === 0) {
            return <TableRowNoData colspan={5}/>;
        }
        return null;
    };

    const headers: Header[] = [
        {
            label: 'ชื่อ - นามสกุล',
            field: 'name',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'ความสัมพันธ์',
            field: 'relationship',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'ตำแหน่ง',
            field: 'position',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'แนบไฟล์',
            field: 'file',
            order: null,
            isOrder: false,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const onChangeOrder = (orderData: OrderData) => {
        setSorting({
            field: orderData.field,
            order: orderData.order
        });
    };

    const dataOrder = () => {
        if (showOrder)
            return employee.employee_certifiers.map(data => data).sort((a, b) => {
                return SortingByField(a, b, sorting);
            });
        return employee.employee_certifiers;
    };

    return (
        <div className="h-100 overflow-x">
            <Table bordered>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
                </thead>
                <tbody>
                {
                    dataOrder().map((data, index) => {
                        return <CertifierRow
                            key={index}
                            index={index}
                            {...data}
                        />;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
    );
};

const CertifierRow: FC<CertifierRowProps> = ({
                                                 index,
                                                 name,
                                                 relationship,
                                                 position,
                                                 file,
                                                 from_id,
                                             }) => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const section_type = 'employee_certifiers';
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);
    let fromEmployee: any | null = null;

    if (from_id) {
        const found = oldEmployee[section_type].find(data => data.id === from_id);
        if (found)
            fromEmployee = found;
    }

    const validateEditRequest = (value: any, key: keyof EmployeeCertifierModel) => {
        if (employeeAction.type === 'view-edit-request') {
            if (fromEmployee && from_id) {
                if (value !== fromEmployee[key])
                    return 'field-warning';
            }
            if (!from_id) {
                return 'field-new';
            }
        }
        return '';
    }

    const [showModal, setShowModal] = useState(false);

    const deleteButton = () => {
        if (!disable) {
            return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
        }
        return null;
    };

    const handleDelete = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        handleEmployeeSectionRemoveByIndex(section_type, index, dispatch);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <tr className="bg-white">
            <td className={`${validateEditRequest(name, 'name')}`}>
                <input type="text" className="form-control" name="name" disabled={disable}
                       placeholder="" value={name || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(relationship, 'relationship')}`}>
                <input type="text" className="form-control" name="relationship" disabled={disable}
                       placeholder="" value={relationship || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(position, 'position')}`}>
                <input type="text" className="form-control" name="position" disabled={disable}
                       placeholder="" value={position || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(section_type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(file, 'file')}`}>
                <SectionFileForm section_key={'employee_certifiers'} index={
                    disable ? employee.employee_certifiers.findIndex(data =>
                        data.name === name &&
                        data.relationship === relationship &&
                        data.position === position
                    ) : index
                }/>
            </td>
            <td className="text-center">
                {deleteButton()}
            </td>
            <DeleteConfirmationModal
                show={showModal}
                title={'ยืนยันการลบข้อมูล'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
                onHide={handleCloseModal}
                onConfirm={handleConfirmDelete}
            />
        </tr>
    );
};

export default CertifierForm;
import React, { ChangeEvent, FC, useState } from 'react';
import {
  deleteDivisionGroupOffice,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateDivisionGroupOffice,
  updateStructureDepartment
} from '../../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import { DivisionGroupOfficeModel } from '../../../../shared/models/organizational-structure.model';
import { faMinus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputSelect from '../../../../shared/components/atoms/input-select';
import {
  handleDivisionGroupOfficeInputSelectChange
} from '../../../../shared/services/organizational-structure-input-change-state';
import { convertToPositiveNumber, handleNumericInput } from '../../../../shared/utils/function-validators';
import { notificationService } from '../../../../shared/services/notification-service';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { convertToCurrency } from '../../../../shared/utils/currency';
import InputCurrency from '../../../../shared/components/inputs/input-currency';
import { Dropdown, SelectDropdown } from '../../../../shared/models/common.model';
import HeaderEmployeeSearch from '../inputs/header-employee-search';
import { DivisionGroupOfficeTypeOptions } from '../../../../shared/utils/constants';

interface DivisionGroupOfficeFormProps extends DivisionGroupOfficeModel {
  index: number;
  lastButton: boolean;
}

const DivisionGroupOfficeForm: FC<DivisionGroupOfficeFormProps> = ({
                                                                     index,
                                                                     id,
                                                                     department_office_id,
                                                                     department_office_index,
                                                                     director_id,
                                                                     director_index,
                                                                     deputy_director_id,
                                                                     deputy_director_index,
                                                                     type,
                                                                     code,
                                                                     name,
                                                                     power_rate,
                                                                     lastButton,
                                                                     is_acting_in_position,
                                                                     is_empty,
                                                                     user_id,
                                                                     pay_account_number,
                                                                     level,
                                                                   }) => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    organizationalStructure.structure_departments.forEach((data, dataIndex) => {
      if (data.division_group_office_index === index) {
        const resetId = {
          target: {
            name: 'division_group_office_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'division_group_office_index',
            value: null,
          }
        };
        updateStructureDepartment(dataIndex, resetId, dispatch);
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      } else if ((data.division_group_office_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'division_group_office_index',
            value: (data.division_group_office_index as number) - 1,
          }
        };
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      }
    });
    deleteDivisionGroupOffice({index: index}, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const displayModal = () => {
    if (showModal) {
      const child = organizationalStructure.structure_departments
        .filter(data => data.division_group_office_index === index);
      const message = <>
        <p>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</p>
        {
          child.length > 0 ?
            <p className="m-0">
              มี แผนก ภายใต้ กอง / กลุ่ม / สำนักงาน <b>{name}</b> ทั้งหมด <b>{child.length}</b> แผนก
            </p> : ''
        }
        {
          child.map((data, index) => {
            return <p key={index} className="m-0">- {data.name} (อัตรากำลัง: {convertToCurrency(data.power_rate)})</p>;
          })
        }
        {
          child.length > 0 ?
            <div className="alert alert-warning mb-0 mt-2" role="alert">
              <FontAwesomeIcon icon={faWarning} className="me-2"/>
              เมื่อกดลบแล้ว แผนกภายใต้ทั้งหมดจะต้องเลือก กอง / กลุ่ม / สำนักงาน ใหม่
            </div> : ''
        }
      </>;
      return <DeleteConfirmationModal
        show={showModal}
        size={'lg'}
        title={'ยืนยันการลบข้อมูล'}
        description={message}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />;

    }
    return;
  };

  const buttonDisplay = () => {
    return <button className="btn btn-danger" type="button" onClick={handleDelete} hidden={disable || index === 0}>
      <FontAwesomeIcon icon={faMinus}/>
    </button>;
  };

  const getOptions = () => {
    if (type === 'normal') {
      return organizationalStructure.department_offices.map((data, index) => {
        const {label, disabled} = getPowerRateRemain(index, data.power_rate || 0, data.is_empty);
        return {
          label: `${data.name || '-'} ${convertToCurrency(label)}`,
          value: index,
          disabled: disabled
        };
      });
    } else if (type === 'director') {
      return organizationalStructure.directors.map((data, index) => {
        return {
          label: `${data.name || '-'}`,
          value: index,
          disabled: false
        };
      })
    } else if (type === 'deputy_director') {
      return organizationalStructure.deputy_directors.map((data, index) => {
        return {
          label: `${data.name || '-'} (${data.pay_account_number})`,
          value: index,
          disabled: false
        };
      })
    }

    return [];
  };

  const getOptionLabel = () => {
    if (type === 'normal') {
      return 'สำนัก / ฝ่าย';
    } else if (type === 'director') {
      return 'ผู้อำนวยการ';
    } else {
      return 'รองผู้อำนวยการ';
    }
  }

  // const getPowerRateRemain = (department_office_index: number | null, powerRateCap: number) => {
  //   const powerRateUsed = organizationalStructure.division_group_offices
  //     .filter(data => data.department_office_index === department_office_index)
  //     .map(data => parseInt(String(data.power_rate || 0)))
  //     .reduce((total_used, power_rate) => {
  //       return total_used + power_rate;
  //     }, 0);
  //   return {
  //     label: `(อัตรากำลัง: ${convertToCurrency(powerRateUsed)}/${convertToCurrency(powerRateCap)})`,
  //     disabled: powerRateUsed >= powerRateCap
  //   };
  // };

  const getPowerRateRemain = (department_office_index: number | null, powerRateCap: number, isEmptyInPosition: boolean) => {
    // let powerRateUsed = organizationalStructure.division_group_offices
    //   .filter(data => data.department_office_index === department_office_index)
    //   .map(data => {
    //     // Convert power_rate to an integer, defaulting to 0 if it's not available.
    //     const powerRate = parseInt(String(data.power_rate || 0));
    //     // Check if is_empty is false and subtract 1 from the powerRate, ensuring it doesn't go below 0.
    //     // return powerRate;
    //   return powerRate;
    //   })
    //   .reduce((total_used, power_rate) => {
    //     // Sum up the adjusted power rates.
    //     return total_used + power_rate;
    //   }, 0);
    let powerRateUse;
    const currentDivisionGroupPowerRateUsed = organizationalStructure.division_group_offices
      .filter(data => data.department_office_index === department_office_index)
      .map(data => {
        const powerRate = parseInt(String(data.power_rate || 0));
        return powerRate;
      })
      .reduce((total_used, power_rate) => {
        return total_used + power_rate;
      }, 0);

    const currentDepartmentPowerRateUsed = organizationalStructure.structure_departments
      .filter(data => data.department_office_index === department_office_index)
      .map(data => {
        const powerRate = parseInt(String(data.power_rate || 0));
        return powerRate;
      })
      .reduce((total_used, power_rate) => {
        return total_used + power_rate;
      }, 0);
    powerRateUse = currentDivisionGroupPowerRateUsed + currentDepartmentPowerRateUsed;
    return {
      label: `(อัตรากำลัง: ${convertToCurrency(isEmptyInPosition ? powerRateUse : powerRateUse + 1)}/${convertToCurrency(powerRateCap)})`,
      disabled: isEmptyInPosition ? powerRateUse >= powerRateCap : powerRateUse + 1 >= powerRateCap
    };
  };

  const validateMaxPowerRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = convertToPositiveNumber(event.target.value);

    if (department_office_index !== null) {
      let powerRateCap = organizationalStructure.department_offices[department_office_index].power_rate || 0;

      if (!organizationalStructure.department_offices[department_office_index].is_empty) {
        powerRateCap = Math.max(0, powerRateCap - 1);
      }

      let powerRateUse;
      const usedDivisionGroupOfficePowerRate = organizationalStructure.division_group_offices
        .filter((data, data_index) => data.department_office_index === department_office_index && data_index !== index)
        .map(data => data.power_rate || 0)
        .map(Number)
        .reduce((total_used, power_rate) => total_used + power_rate, 0);

      const usedStructureDepartmentPowerRate = organizationalStructure.structure_departments
        .filter(data => data.department_office_index === department_office_index)
        .map(data => data.power_rate || 0)
        .map(Number)
        .reduce((total_used, power_rate) => total_used + power_rate, 0);

      powerRateUse = usedDivisionGroupOfficePowerRate + usedStructureDepartmentPowerRate;

      const powerRateRemain = powerRateCap - powerRateUse;
      if (+event.target.value > powerRateRemain) {
        event.target.value = powerRateRemain.toString();
        notificationService.infoNotification('อัตรากำลังเกินกำหนด', `ปรับอัตรากำลังให้พอดีกับยอดที่เหลืออยู่คือ ${convertToCurrency(powerRateRemain)}`);
      }
    }
    updateDivisionGroupOffice(index, event, dispatch);
  };

  const handleOnCheckEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    const setIsEmpty = {
      target: {
        name: 'is_empty',
        value: e.target.checked
      }
    };
    // const resetPayAccountNumber = {
    //   target: {
    //     name: 'pay_account_number',
    //     value: e.target.checked ? '-' : null
    //   }
    // };
    updateDivisionGroupOffice(index, setIsEmpty, dispatch);
    // updateDivisionGroupOffice(index, resetPayAccountNumber, dispatch);

    if (e.target.checked) {
      const setUserId = {
        target: {
          name: 'user_id',
          value: null
        }
      };
      updateDivisionGroupOffice(index, setUserId, dispatch);
    }
  };

  const handleOnCheckActingInPosition = (e: ChangeEvent<HTMLInputElement>) => {
    const check = {
      target: {
        name: 'is_acting_in_position',
        value: e.target.checked
      }
    };
    updateDivisionGroupOffice(index, check, dispatch);
  };

  const handleOnSelectEmployee = (value: Dropdown) => {
    const updateData = {
      target: {
        name: 'user_id',
        value: +value.value
      }
    };
    updateDivisionGroupOffice(index, updateData, dispatch);
  };

  const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumericInput(e);
    updateDivisionGroupOffice(index, e, dispatch);
  };

  const resetPowerRate = () => {
    const resetPowerRate = {
      target: {
        name: 'power_rate',
        value: null
      }
    }
    updateDivisionGroupOffice(index, resetPowerRate, dispatch);
  }

  const resetDirector = () => {
    const resetDirectorId = {
      target: {
        name: 'director_id',
        value: null
      }
    }
    const resetDirectorIndex = {
      target: {
        name: 'director_index',
        value: null
      }
    }
    updateDivisionGroupOffice(index, resetDirectorId, dispatch);
    updateDivisionGroupOffice(index, resetDirectorIndex, dispatch);
  }

  const resetDeputyDirector = () => {
    const resetDeputyDirectorId = {
      target: {
        name: 'deputy_director_id',
        value: null
      }
    }
    const resetDeputyDirectorIndex = {
      target: {
        name: 'deputy_director_index',
        value: null
      }
    }
    updateDivisionGroupOffice(index, resetDeputyDirectorId, dispatch);
    updateDivisionGroupOffice(index, resetDeputyDirectorIndex, dispatch);
  }

  const resetDepartmentOffice = () => {
    const resetDepartmentOfficeId = {
      target: {
        name: 'department_office_id',
        value: null
      }
    }
    const resetDepartmentOfficeIndex = {
      target: {
        name: 'department_office_index',
        value: null
      }
    }
    updateDivisionGroupOffice(index, resetDepartmentOfficeId, dispatch);
    updateDivisionGroupOffice(index, resetDepartmentOfficeIndex, dispatch);
  }

  const defaultDeputy = () => {
    const defaultDirectorId = {
      target: {
        name: 'director_id',
        value: organizationalStructure.directors[0].id
      }
    };
    const defaultDirectorIndex = {
      target: {
        name: 'director_index',
        value: 0
      }
    };
    updateDivisionGroupOffice(index, defaultDirectorId, dispatch);
    updateDivisionGroupOffice(index, defaultDirectorIndex, dispatch);
  }

  const onTypeChange = (e: SelectDropdown) => {
    if (e.value?.value === 'normal' && type !== 'normal') {
      resetDirector();
      resetDeputyDirector();
      resetPowerRate();
    } else if (e.value?.value === 'director' && type !== 'director') {
      resetDepartmentOffice();
      resetDeputyDirector();
      defaultDeputy();
      resetPowerRate();
    } else if (e.value?.value === 'deputy_director' && type !== 'deputy_director') {
      resetDirector();
      resetDepartmentOffice();
      resetPowerRate();
    }
    handleDivisionGroupOfficeInputSelectChange(index, e, dispatch);
  }

  const typeDisableForDirector = () => {
    return type === 'director';
  }

  const getSelectValue = () => {
    if (type === 'normal') {
      return department_office_index;
    } else if (type === 'director') {
      return director_index;
    } else {
      return deputy_director_index;
    }
  }

  const getSelectName = () => {
    if (type === 'normal') {
      return 'department_office_index';
    } else if (type === 'director') {
      return 'director_index';
    } else {
      return 'deputy_director_index';
    }
  }

  const disablePowerRate = () => {
    if (type === 'normal') {
      return department_office_index === null;
    } else if (type === 'director') {
      return director_index === null;
    } else {
      return deputy_director_index === null;
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ประเภท</label>
          <InputSelect options={DivisionGroupOfficeTypeOptions} name="type"
                       value={type}
                       isClearable={false} isDisable={disable}
                       onChange={onTypeChange}
                       placeholder=""/>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">{getOptionLabel()}</label>
          <InputSelect options={getOptions()} placeholder="กรุณาเลือก" name={getSelectName()}
                       onChange={e => handleDivisionGroupOfficeInputSelectChange(index, e, dispatch)}
                       value={getSelectValue()}
                       isClearable={false} isDisable={disable || typeDisableForDirector()}/>
          <input type="text" className="form-control" name={getSelectName()} hidden={true}
                 value={getSelectValue()?.toString() || ''} required={true} onChange={() => {
          }}/>
          <div className="invalid-feedback">
            กรุณากรอกเลือก{getOptionLabel()}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">รหัสกอง / กลุ่ม / สำนักงาน</label>
          <input type="text" className="form-control" name="code" disabled={disable}
                 placeholder="" value={code || ''} required={true}
                 onChange={(e) => updateDivisionGroupOffice(index, e, dispatch)}/>
          <div className="invalid-feedback">
            กรุณากรอกรหัสกอง / กลุ่ม / สำนักงาน
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ชื่อกอง / กลุ่ม / สำนักงาน</label>
          <div className="input-group">
            <input type="text" className="form-control rounded" name="name" disabled={disable}
                   placeholder="" value={name || ''} required={true}
                   onChange={(e) => updateDivisionGroupOffice(index, e, dispatch)}/>
            <div className="invalid-feedback">
              กรุณากรอกชื่อกอง / กลุ่ม / สำนักงาน
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ระดับ</label>
          <div className="input-group">
            <input type="text" className="form-control" name="level" disabled={disable}
                   onChange={(e) => updateDivisionGroupOffice(index, e, dispatch)}
                   placeholder="" value={level || ''} required={true}/>
            <div className="input-group-append ps-2">
              {buttonDisplay()}
            </div>
            <div className="invalid-feedback">
              กรุณากรอกระดับ
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">อัตรากำลัง</label>
          <InputCurrency value={power_rate || ''} onChange={validateMaxPowerRate}
                         disable={disable || disablePowerRate()}
                         name="power_rate" required={true}/>
          <div className="invalid-feedback">
            กรุณากรอกอัตรากำลัง
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">เลขบัญชีถือจ่าย</label>
          <input type="text" className="form-control" name="pay_account_number" disabled={disable}
                 placeholder="" value={pay_account_number || ''} required={true}
                 onChange={validateNumber}/>
          <div className="invalid-feedback">
            กรุณากรอกเลขบัญชีถือจ่าย
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <label className="form-label required">ชื่อหัวหน้า กอง / กลุ่ม / สำนักงาน</label>
          <div className="input-group">
            {
              is_empty ?
                <input type="text" className="form-control" name="name" disabled={true}
                       placeholder="" value={'ตำแหน่งว่างอยู่'} required={true}/>
                :
                <div className="w-100">
                  <HeaderEmployeeSearch onChange={(value) => handleOnSelectEmployee(value)} value={user_id}
                                        changeValue={organizationalStructure.division_group_offices.map(data => data.user_id).join(',')}/>
                </div>
            }
            <input type="text" className="form-control" name="user_id" hidden={true} required={true}
                   value={user_id || ''} onChange={() => {
            }}/>
            {
              !is_empty ?
                <div className="invalid-feedback">
                  กรุณากรอกชื่อ หัวหน้า กอง / กลุ่ม / สำนักงาน
                </div>
                : null
            }
          </div>
        </div>
        <div className="row mt-3 justify-content-end">
          <div className="col-sm-12 col-md-2">
            <div className="form-check">
            <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_empty}
                     name={`is_empty_${index}`}
                     disabled={disable}
                     onChange={handleOnCheckEmpty}
                     id={`is_empty_${index}`}/>
              <label className="form-check-label pointer" htmlFor={`is_empty_${index}`}>
                ตำแหน่งว่างอยู่ตอนนี้
              </label>
            </div>
          </div>
          <div className="col-sm-12 col-md-2">
            <div className="form-check">
              <input className="form-check-input pointer no-validate" type="checkbox"
                     defaultChecked={is_acting_in_position}
                     name={`is_acting_in_position${index}`}
                     disabled={disable}
                     onChange={handleOnCheckActingInPosition}
                     id={`is_acting_in_position${index}`}/>
              <label className="form-check-label pointer" htmlFor={`is_acting_in_position${index}`}>
                รักษาการในตำแหน่ง
              </label>
            </div>
          </div>
        </div>
      </div>
      {displayModal()}
      {index === organizationalStructure.division_group_offices.length - 1 ? null : <hr/>}
    </>
  );
};

export default DivisionGroupOfficeForm;
import { FC, useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { faCaretLeft, faFileExport} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import {RegistrationPersonnelHistoryService} from "../../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import {AxiosError} from "axios/index";
import {notificationService} from '../../../../../shared/services/notification-service';
import useSpinnerLoader from '../../../../../shared/components/spinner-loader';
import {calculateAge, calculateRetirement} from '../../../../../shared/utils/function-employees';
import Moment from "moment";
import { dateDiff } from '../../../../../shared/utils/functions-date-time';
import { getMediaPath } from '../../../../../shared/utils/function-media';

import * as XLSX from 'xlsx';

import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Image ,Font } from '@react-pdf/renderer';
Font.register({ family: 'kanit-regular', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/L6VKvM17ZmevDynOiw7H9w.ttf' });
Font.register({ family: 'kanit-200', format: "truetype", src: 'https://fonts.gstatic.com/s/kanit/v1/wfLWkj1C4tYl7MoiFWS3bA.ttf' });

interface ComponentProps {
  isCheckedAll: boolean;
  isCheckedHistory: boolean;
  isCheckedFamilyDetail: boolean;
  isCheckedRename: boolean;
  isCheckedEducation: boolean;
  isCheckedTraining: boolean;
  isCheckedInsignia: boolean;
  isCheckedPunishment: boolean;
  isCheckedOutstandingAwards: boolean;
  isCheckedResignationRetireDeath: boolean;
  isCheckedCertifier: boolean;
  isCheckedWorkOrtokor: boolean;
  isCheckedWorkHistory: boolean;
}

const PersonnelHeader: FC<ComponentProps> = ({ 
                                                isCheckedAll, 
                                                isCheckedHistory, 
                                                isCheckedFamilyDetail, 
                                                isCheckedRename, 
                                                isCheckedEducation, 
                                                isCheckedTraining, 
                                                isCheckedInsignia, 
                                                isCheckedPunishment, 
                                                isCheckedOutstandingAwards, 
                                                isCheckedResignationRetireDeath, 
                                                isCheckedCertifier,
                                                isCheckedWorkOrtokor,
                                                isCheckedWorkHistory
                                              }) => {
  let {id} = useParams();
  const [data, setData] = useState<any>([]);
  const [isDisableExport, setDisableExport] = useState<boolean>();

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelHistoryDetail({id: id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(isCheckedAll || isCheckedHistory || isCheckedFamilyDetail || isCheckedRename || 
      isCheckedEducation || isCheckedTraining || isCheckedInsignia || isCheckedPunishment || 
      isCheckedOutstandingAwards || isCheckedResignationRetireDeath || isCheckedCertifier || isCheckedWorkOrtokor || isCheckedWorkHistory){
      setDisableExport(false);
    }else{
      setDisableExport(true);
    }
  }, [isCheckedAll, 
    isCheckedHistory, 
    isCheckedFamilyDetail, 
    isCheckedRename, 
    isCheckedEducation, 
    isCheckedTraining, 
    isCheckedInsignia, 
    isCheckedPunishment, 
    isCheckedOutstandingAwards, 
    isCheckedResignationRetireDeath, 
    isCheckedCertifier,
    isCheckedWorkOrtokor,
    isCheckedWorkHistory]);

  const getRetireIn = () => {
		if (data.birthday) {
			const result = calculateRetirement(data.birthday);
			const retire = [];
			if (result.years < 0 || result.months < 0 || result.days < 0) {
				return 'เกษียณอายุแล้ว';
			}
			if (result.years)
				retire.push(`${result.years} ปี`);
			if (result.months)
				retire.push(`${result.months} เดือน`);
			if (result.days)
				retire.push(`${result.days} วัน`);

			return retire.join(' ');
		}
		return '-';
	};

  const getAge = (data:any) => {
		if (data) {
			return calculateAge(data)+" ปี";
		}
		return '-';
	};

  const getDateDiff = (start_date:any,end_date:any) => {
    if (start_date && end_date) {
      const data = dateDiff(start_date, end_date);
      const result = [];
      if (data.years)
        result.push(`${Math.abs(data.years)} ปี`);
      if (data.months)
        result.push(`${Math.abs(data.months)} เดือน`);
      if (data.days)
        result.push(`${Math.abs(data.days)} วัน`);
      return result.length > 0 ? result.join(' ') : '1 วัน';
    }
    return '-';
  };

  const exportToExcel = () => {
    var DataForExcel = [];
    const wb = XLSX.utils.book_new();

    if(isCheckedAll){
      isCheckedHistory = true;
      isCheckedFamilyDetail = true;
      isCheckedRename = true;
      isCheckedEducation = true;
      isCheckedTraining = true;
      isCheckedInsignia = true;
      isCheckedPunishment = true;
      isCheckedOutstandingAwards = true; 
      isCheckedResignationRetireDeath = true;
      isCheckedCertifier = true;
      isCheckedWorkOrtokor = true;
      isCheckedWorkHistory = true;
    }

    // ประวัติส่วนตัว
    if(isCheckedHistory){
      DataForExcel = [];
      DataForExcel.push(["รหัสพนักงาน","เกษียณอายุในอีก","เลขบัตรประจำตัวประชาชน","คำนำหน้า (TH)","ชื่อ (TH)","นามสกุล (TH)","คำนำหน้า (EN)","ชื่อ (EN)","นามสกุล (EN)","วันเดือนปีเกิด","อายุ","เพศ","เชื้อชาติ","สัญชาติ","ศาสนา","เบอร์โทรศัพท์","อีเมล","กรุ๊ปเลือด","เงินเดือน"]);
      DataForExcel.push([
        data.code ? data.code : '-',
        getRetireIn(),
        data.id_card ? data.id_card : '-',
        data.prefix_name_th ? data.prefix_name_th : '-',
        data.firstname_th ? data.firstname_th : '-',
        data.lastname_th ? data.lastname_th : '-',
        data.prefix_name_en ? data.prefix_name_en : '-',
        data.firstname_en ? data.firstname_en : '-',
        data.lastname_en ? data.lastname_en : '-',
        data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : '-',
        getAge(data.birthday),
        data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-',
        data.ethnicity ? data.ethnicity : '-',
        data.nationality ? data.nationality : '-',
        data.religion ? data.religion : '-',
        data.phone ? data.phone : '-',
        data.user?.email ? data.user?.email : '-',
        data.blood_type ? data.blood_type : '-',
        data.salary ? addCommas(data.salary) : '-',
      ])
      
      // กรณีเร่งด่วนติดต่อ
      DataForExcel.push([]);
      DataForExcel.push(["กรณีเร่งด่วนติดต่อ"]);
      DataForExcel.push(["ชื่อ - นามสกุล","เบอร์โทรศัพท์","ความสัมพันธ์","สถานที่","รหัสไปรษณีย์","จังหวัด","อำเภอ","ตำบล"]);
      DataForExcel.push([
        data.emergency_contact_full_name ? data.emergency_contact_full_name : '-',
        data.emergency_contact_tel ? data.emergency_contact_tel : '-',
        data.emergency_contact_relationship ? data.emergency_contact_relationship : '-',
        data.emergency_contact_address ? data.emergency_contact_address : '-',
        data.emergency_contact_postcode ? data.emergency_contact_postcode : '-',
        data.emergency_contact_province?.name_th ? data.emergency_contact_province?.name_th : '-',
        data.emergency_contact_amphure?.name_th ? data.emergency_contact_amphure?.name_th : '-',
        data.emergency_contact_tambon?.name_th ? data.emergency_contact_tambon?.name_th : '-',
      ])

      // ที่อยู่ตามบัตรประชาชน
      DataForExcel.push([]);
      DataForExcel.push(["ที่อยู่ตามบัตรประชาชน"]);
      DataForExcel.push(["สถานที่","รหัสไปรษณีย์","จังหวัด","อำเภอ","ตำบล"]);
      DataForExcel.push([
        data.address_official ? data.address_official : '-',
        data.postcode_official ? data.postcode_official : '-',
        data.official_province?.name_th ? data.official_province?.name_th : '-',
        data.official_amphure?.name_th ? data.official_amphure?.name_th : '-',
        data.official_tambon?.name_th ? data.official_tambon?.name_th : '-',
      ])

      // ที่อยู่ปัจจุบัน
      DataForExcel.push([]);
      DataForExcel.push(["ที่อยู่ปัจจุบัน"]);
      DataForExcel.push(["สถานที่","รหัสไปรษณีย์","จังหวัด","อำเภอ","ตำบล"]);
      DataForExcel.push([
        data.address_contactable ? data.address_contactable : '-',
        data.postcode_contactable ? data.postcode_contactable : '-',
        data.contactable_province?.name_th ? data.contactable_province?.name_th : '-',
        data.contactable_amphure?.name_th ? data.contactable_amphure?.name_th : '-',
        data.contactable_tambon?.name_th ? data.contactable_tambon?.name_th : '-',
      ])

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติส่วนตัว');
    }

    // รายละเอียดครอบครัว
    if(isCheckedFamilyDetail){
      DataForExcel = [];

      // บิดา
      DataForExcel.push(["บิดา"]);
      DataForExcel.push(["คำนำหน้า (TH)","ชื่อ (TH)","นามสกุล (TH)","สถานภาพ"]);
      DataForExcel.push([
        data.contact_father_prefix_name ? data.contact_father_prefix_name : '-',
        data.contact_father_firstname ? data.contact_father_firstname : '-',
        data.contact_father_lastname ? data.contact_father_lastname : '-',
        data.contact_father_status ? data.contact_father_status : '-',
      ])
      
      // มารดา
      DataForExcel.push([]);
      DataForExcel.push(["มารดา"]);
      DataForExcel.push(["คำนำหน้า (TH)","ชื่อ (TH)","นามสกุล (TH)","สถานภาพ"]);
      DataForExcel.push([
        data.contact_mother_prefix_name ? data.contact_mother_prefix_name : '-',
        data.contact_mother_firstname ? data.contact_mother_firstname : '-',
        data.contact_mother_lastname ? data.contact_mother_lastname : '-',
        data.contact_mother_status ? data.contact_mother_status : '-',
      ])

      // คู่สมรส
      DataForExcel.push([]);
      DataForExcel.push(["คู่สมรส"]);
      DataForExcel.push(["สถานภาพ","ชื่อ (TH)","นามสกุล (TH)"]);
      DataForExcel.push([
        data.contact_spouse_status ? data.contact_spouse_status : '-',
        data.contact_spouse_firstname ? data.contact_spouse_firstname : '-',
        data.contact_spouse_lastname ? data.contact_spouse_lastname : '-',
      ])

      // จำนวนบุตร
      DataForExcel.push([]);
      DataForExcel.push(["จำนวนบุตร"]);
      DataForExcel.push(["ชื่อ","นามสกุล","เพศ","อายุ"]);
      data.childern?.map((data: any) => {
        DataForExcel.push([
          data.first_name ? data.first_name : '-',
          data.last_name ? data.last_name : '-',
          data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-',
          data.birthday ? getAge(data.birthday) : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'รายละเอียดครอบครัว');
    }

    // ประวัติการเปลี่ยนชื่อ
    if(isCheckedRename){
      DataForExcel = [];

      DataForExcel.push(["ชื่อ","นามสกุล","วันที่เปลี่ยนแปลง","เหตุผล"]);
      data.name_change_histories?.map((data: any) => {
        DataForExcel.push([
          data.first_name ? data.first_name : '-',
          data.last_name ? data.last_name : '-',
          data.date ? Moment(data.date).add(543, 'year').format('DD/MM/YYYY') : '-',
          data.reason ? data.reason : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติการเปลี่ยนชื่อ');
    }

    // ประวัติการศึกษา
    if(isCheckedEducation){
      DataForExcel = [];

      DataForExcel.push(["ชื่อ","ระดับ","สาขาวิชา","ปีที่สำเร็จการศึกษา"]);
      data.education_histories?.toReversed().map((data: any) => {
        DataForExcel.push([
          data.school_name ? data.school_name : '-',
          data.graduation_level ? data.graduation_level : '-',
          data.graduation_major ? data.graduation_major : '-',
          data.graduation_year ? data.graduation_year : '-',
        ])
      })

      const wsHistory = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, wsHistory, 'ประวัติการศึกษา');
    }

    // ประวัติการฝึกอบรม
    if(isCheckedTraining){
      DataForExcel = [];

      DataForExcel.push(["หลักสูตร","ประเภทการฝึกอบรม","สถาบัน / ประเทศ"]);
      data.training_histories?.map((data: any) => {
        DataForExcel.push([
          data.course ? data.course : '-',
          data.course_type ? data.course_type : '-',
          data.location ? data.location : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติการฝึกอบรม');
    }

    // ประวัติเครื่องราชอิสริยาภรณ์
    if(isCheckedInsignia){
      DataForExcel = [];

      DataForExcel.push(["ประจำปี","วันที่ได้รับเครื่องราชย์","ประเภท","เครื่องราชอิสริยาภรณ์"]);
      data.insignia_histories?.map((data: any) => {
        DataForExcel.push([
          data.year ? data.year : '-',
          data.date_of_award ? Moment(data.date_of_award).add(543, 'year').format('DD/MM/YYYY') : '-',
          data.type ? data.type : '-',
          data.name ? data.name : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติเครื่องราชอิสริยาภรณ์');
    }

    // ประวัติการลงโทษ
    if(isCheckedPunishment){
      DataForExcel = [];

      DataForExcel.push(["เลขที่คำสั่ง","เรื่อง","วันที่"]);
      data.punishment_histories?.map((data: any) => {
        DataForExcel.push([
          data.order_no ? data.order_no : '-',
          data.name ? data.name : '-',
          data.date ? Moment(data.date).add(543, 'year').format('DD/MM/YYYY') : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติการลงโทษ');
    }

    // ผลงานดีเด่น/รางวัล
    if(isCheckedOutstandingAwards){
      DataForExcel = [];

      DataForExcel.push(["ประจำปี","ผลงานดีเด่น"]);
      data.outstanding_performance_awards?.map((data: any) => {
        DataForExcel.push([
          data.year ? data.year : '-',
          data.name ? data.name : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ผลงานดีเด่น-รางวัล');
    }

    // ประวัติการลาออก / เกษียณอายุ / เสียชีวิต
    if(isCheckedResignationRetireDeath){
      DataForExcel = [];

      DataForExcel.push(["คำสั่ง อตก. ที่","สั่ง ณ วันที่","สถานะ"]);
      data.resignation_retirement_death_histories?.map((data: any) => {
        DataForExcel.push([
          data.order_no ? data.order_no : '-',
          data.date ? Moment(data.date).add(543, 'year').format('DD/MM/YYYY') : '-',
          data.status ? data.status : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติลาออก-เกษียณ-เสียชีวิต');
    }

    // ผู้รับรองการเข้าทำงาน
    if(isCheckedCertifier){
      DataForExcel = [];

      DataForExcel.push(["ชื่อ-สกุล","ความสัมพันธ์","ตำแหน่ง"]);
      data.employee_certifiers?.map((data: any) => {
        DataForExcel.push([
          data.name ? data.name : '-',
          data.relationship ? data.relationship : '-',
          data.position ? data.position : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ผู้รับรองการเข้าทำงาน');
    }

    // ประวัติการทำงาน ของ อตก.
    if(isCheckedWorkOrtokor){
      DataForExcel = [];

      DataForExcel.push(["ประเภทบุคคลากร","ระดับตำแหน่ง","ตำแหน่ง","ลักษณะตำแหน่ง","วันที่เริ่มงาน","วันที่สิ้นสุด"]);
      data.mof_work_histories?.toReversed().map((data: any) => {
        DataForExcel.push([
          data.employee_type ? data.employee_type : '-',
          data.position_level ? data.position_level : '-',
          data.position_name ? data.position_name : '-',
          data.position_type ? data.position_type : '-',
          data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : '-',
          data.end_date ? Moment(data.end_date).add(543, 'year').format('DD/MM/YYYY') : data.is_currently === 1 ? 'ปัจจุบัน' : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติการทำงาน ของ อตก');
    }

    // ประวัติการทำงานที่ผ่านมา
    if(isCheckedWorkHistory){
      DataForExcel = [];

      DataForExcel.push(["สถานที่ทำงาน","วันที่เริ่มต้น-สิ้นสุด","อายุงาน","ตำแหน่ง","เงินเดือน","เหตุผลที่ลาออก"]);
      data.work_histories?.map((data: any) => {
        DataForExcel.push([
          data.location ? data.location : '-',
          data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : '-' + " - " + data.end_date ? Moment(data.end_date).add(543, 'year').format('DD/MM/YYYY') : '-',
          data.start_date && data.end_date ? getDateDiff(data.start_date,data.end_date) : '-',
          data.position ? data.position : '-',
          data.salary ? addCommas(data.salary) : '-',
          data.reason ? data.reason : '-',
        ])
      })

      const ws = XLSX.utils.aoa_to_sheet(DataForExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'ประวัติการทำงานที่ผ่านมา');
    }

    if(Object.keys(wb.Sheets).length > 0){
      XLSX.writeFile(wb, 'ทะเบียนประวัติบุคลากร.xlsx');
    }
    
  };

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function displayPhoto(){
    if(data.photo === "" || data.photo === null){
      return <img src="/static/no-image.png" alt="not photo image"/>
    }else{
      return <img className="employee-photo object-fit-cover" src={getMediaPath(data.photo)} alt="photo image"/>
    }  
  }

  const PDF = () => (
    <Document>
      {/* Page1 */}
      <Page size="A4" style={{padding: 10}}>
        <View>
          <View style={{fontFamily: 'kanit-regular',borderBottomWidth: 1,marginBottom: 10}} fixed>
            <View style={{flexDirection: 'row'}}>
              <Text style={{fontSize: 15,textAlign: 'left',width: '50%',}}>รายงานประวัติบุคลากร <Text></Text></Text>
              <Text style={{fontSize: 10,textAlign: 'right',width: '50%',alignSelf: 'flex-end'}} render={({ pageNumber, totalPages}) => (`หน้าที่ ${pageNumber} / ${totalPages-1}`)}/>
            </View>
          </View>

          <View style={{flexDirection: 'row',alignItems: 'center',marginBottom: 10}}>
            <View style={{width: '87%'}}>
              <View style={{flexDirection: 'row',marginBottom: 3}}>
                <Text style={{fontFamily: 'kanit-regular',width: '35%',fontSize: 10,textAlign: 'left'}}>
                  รหัสพนักงาน <Text style={{fontFamily: 'kanit-200'}}>{data.code ? data.code : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left'}}>
                  เพศ <Text style={{fontFamily: 'kanit-200'}}>{data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : ''} <Text></Text></Text>
                </Text>
              </View>
              <View style={{flexDirection: 'row',marginBottom: 3}}>
                <Text style={{fontFamily: 'kanit-regular',width: '35%',fontSize: 10,textAlign: 'left'}}>
                  ชื่อต้น <Text style={{fontFamily: 'kanit-200'}}>{data.prefix_name_th ? data.prefix_name_th : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',width: '32.5%',fontSize: 10,textAlign: 'left'}}>
                  ชื่อ <Text style={{fontFamily: 'kanit-200'}}>{data.firstname_th ? data.firstname_th : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',width: '32.5%',fontSize: 10,textAlign: 'left'}}>
                  ชื่อสกุล <Text style={{fontFamily: 'kanit-200'}}>{data.lastname_th ? data.lastname_th : ''} <Text></Text></Text>
                </Text>
              </View>
              <View style={{flexDirection: 'row',marginBottom: 3}}>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left'}}>
                  ชื่อภาษาอังกฤษ <Text style={{fontFamily: 'kanit-200'}}>{data.prefix_name_en ? data.prefix_name_en : ''}{data.firstname_en ? data.firstname_en : ''} {data.lastname_en ? data.lastname_en : ''} <Text></Text></Text>
                </Text>
              </View>
              <View style={{flexDirection: 'row',marginBottom: 3}}>
                <Text style={{fontFamily: 'kanit-regular',width: '35%',fontSize: 10,textAlign: 'left'}}>
                  เลขประจำตัวผู้เสียภาษี <Text style={{fontFamily: 'kanit-200'}}>{data.id_card ? data.id_card : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',width: '32.5%',fontSize: 10,textAlign: 'left'}}>
                  วันเดือนปีเกิด <Text style={{fontFamily: 'kanit-200'}}>{data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',width: '32.5%',fontSize: 10,textAlign: 'left'}}>
                  อายุ <Text style={{fontFamily: 'kanit-200'}}>{data.birthday ? getAge(data.birthday) : ''} <Text></Text></Text>
                </Text>
              </View>
            </View>
            <View style={{width: '13%'}}>
              <Image src={data.photo ? getMediaPath(data.photo) : '/static/no-image.png'} style={{width: 72,height: 82,objectFit: 'cover',alignSelf: 'flex-end',borderRadius: 5}}/>
            </View>
          </View>

          <View style={{borderLeftWidth: 1,borderTopWidth: 1,borderRightWidth: 1,borderBottomWidth: 1,marginBottom: 10}}>
            <View style={{flexDirection: 'row'}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                เลขที่บัตรประชาชน <Text style={{fontFamily: 'kanit-200'}}>{data.id_card ? data.id_card : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                วันหมดอายุ <Text style={{fontFamily: 'kanit-200'}}>{data.id_card_date_expire ? Moment(data.id_card_date_expire).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ออกให้ ณ <Text style={{fontFamily: 'kanit-200'}}>{data.id_card_date_generate ? Moment(data.id_card_date_generate).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',borderTopWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                ภูมิลำเนา <Text style={{fontFamily: 'kanit-200'}}>{data.address_contactable ? data.address_contactable : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                เชื้อชาติ <Text style={{fontFamily: 'kanit-200'}}>{data.ethnicity ? data.ethnicity : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                สัญชาติ <Text style={{fontFamily: 'kanit-200'}}>{data.nationality ? data.nationality : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',borderTopWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                ศาสนา <Text style={{fontFamily: 'kanit-200'}}>{data.religion ? data.religion : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}></Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                หมู่เลือด <Text style={{fontFamily: 'kanit-200'}}>{data.blood_type ? data.blood_type : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',borderTopWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                เบอร์โทรศัพท์ <Text style={{fontFamily: 'kanit-200'}}>{data.phone ? data.phone : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5,borderRightWidth: 1}}>
                อีเมล <Text style={{fontFamily: 'kanit-200'}}>{data.user?.email ? data.user?.email : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}></Text>
            </View>
            <View style={{flexDirection: 'row',borderTopWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ชื่อบิดา <Text style={{fontFamily: 'kanit-200'}}>{data.contact_father_prefix_name ? data.contact_father_prefix_name : ''}{data.contact_father_firstname ? data.contact_father_firstname : ''} {data.contact_father_lastname ? data.contact_father_lastname : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ชื่อมารดา <Text style={{fontFamily: 'kanit-200'}}>{data.contact_mother_prefix_name ? data.contact_mother_prefix_name : ''}{data.contact_mother_firstname ? data.contact_mother_firstname : ''} {data.contact_mother_lastname ? data.contact_mother_lastname : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',borderTopWidth: 1}}>
              <View style={{flexDirection: 'column',width: '50%',borderRightWidth: 1}}>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  ที่อยู่ <Text style={{fontFamily: 'kanit-200'}}>{data.address_contactable ? data.address_contactable : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  ไปรษณีย์ <Text style={{fontFamily: 'kanit-200'}}>{data.postcode_contactable ? data.postcode_contactable : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  โทรศัพท์ <Text style={{fontFamily: 'kanit-200'}}>{data.phone ? data.phone : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  อีเมล <Text style={{fontFamily: 'kanit-200'}}>{data.user?.email ? data.user?.email : ''} <Text></Text></Text>
                </Text>
              </View>
              <View style={{flexDirection: 'column',width: '50%'}}>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  กรณีฉุกเฉินติดต่อ <Text style={{fontFamily: 'kanit-200'}}>{data.emergency_contact_full_name ? data.emergency_contact_full_name : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  ความสัมพันธ์ <Text style={{fontFamily: 'kanit-200'}}>{data.emergency_contact_relationship ? data.emergency_contact_relationship : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  ที่อยู่ <Text style={{fontFamily: 'kanit-200'}}>{data.emergency_contact_address ? data.emergency_contact_address : ''} <Text></Text></Text>
                </Text>
                <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                  โทรศัพท์ <Text style={{fontFamily: 'kanit-200'}}>{data.emergency_contact_tel ? data.emergency_contact_tel : ''} <Text></Text></Text>
                </Text>
              </View>
            </View>
            <View style={{flexDirection: 'column',borderTopWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ประวัติการศึกษา <Text></Text>
              </Text>
              <>
                {
                  data.education_histories?.toReversed().map((data: any,index:any) => {
                    return <>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={{fontFamily: 'kanit-regular',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                          {index+1}. <Text style={{fontFamily: 'kanit-200'}}>{data.graduation_level ? data.graduation_level : '-'} <Text></Text></Text>
                        </Text>
                        <Text style={{fontFamily: 'kanit-regular',width: '60%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                          จาก <Text style={{fontFamily: 'kanit-200'}}>{data.school_name ? data.school_name : '-'} <Text></Text></Text>
                        </Text>
                        <Text style={{fontFamily: 'kanit-regular',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                          ปี <Text style={{fontFamily: 'kanit-200'}}>{data.graduation_year ? data.graduation_year : '-'} <Text></Text></Text>
                        </Text>
                      </View>
                    </>
                  })
                }
              </>
            </View>
            <View style={{flexDirection: 'row'}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ตำแหน่งงานปัจจุบัน <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.position_name ? data.current_job_position?.position_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                กอง/กลุ่ม/สำนักงาน <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.division_group_office_name ? data.current_job_position?.division_group_office_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ฝ่าย/สำนัก <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.department_offices_name ? data.current_job_position?.department_offices_name : ''} <Text></Text></Text>
              </Text>
            </View>
          </View>

          <Text style={{fontFamily: 'kanit-regular',fontSize: 10,borderBottomWidth: 1,marginBottom: 5}}>รายละเอียดเงินเดือน <Text></Text></Text>

          <View style={{marginBottom: 10}}>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                ฝ่าย <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.department_offices_name ? data.current_job_position?.department_offices_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                กอง <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.division_group_office_name ? data.current_job_position?.division_group_office_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                แผนก <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                ตำแหน่ง <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.position_name ? data.current_job_position?.position_name : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{ fontFamily: 'kanit-regular', width: '25%', fontSize: 10, textAlign: 'left' }}>
                {
                  data.mof_work_histories?.map((data: any, index: any) => {
                    if (index === 0) {
                      return <>ครองระดับ(ล่าสุด) <Text style={{fontFamily: 'kanit-200'}}>{data.tenure_position_date ? Moment(data.tenure_position_date).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.map((data: any,index:any) => {
                    if(index === 0){
                      return <>วันที่เริ่มงาน <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.toReversed().map((data: any,index:any) => {
                    if(index === 0){
                      return <>วันที่บรรจุ <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '25%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.toReversed().map((data: any,index:any) => {
                    if(index === 0){
                      return <>อายุงาน <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? getDateDiff(data.start_date,new Date().toISOString()) : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left'}}>
                ประเภทพนักงาน <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.employee_type ? data.current_job_position?.employee_type : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',fontSize: 10,textAlign: 'left'}}>
                อัตราเงินเดือน <Text style={{fontFamily: 'kanit-200'}}>{data.salary ? addCommas(data.salary) : '-'} <Text></Text></Text> บาทต่อเดือน
              </Text>
            </View>
          </View>

        </View>
      </Page>

      {/* Page2 */}
      <Page size="A4" style={{padding: 10}}>
        <View>
          <Text style={{fontFamily: 'kanit-regular',fontSize: 15,textAlign: 'center',marginBottom: 10}}>
            ข้อมูลประวัติการทำงาน <Text></Text>
          </Text>
          
          <Image src={data.photo ? getMediaPath(data.photo) : '/static/no-image.png'} style={{textAlign: 'center',width: 85,height: 95,objectFit: 'cover',alignSelf: 'center',marginBottom: 10,borderRadius: 5}}/>

          <View style={{marginBottom: 10}}>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                ตำแหน่งปัจจุบัน <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.position_name ? data.current_job_position?.position_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                กอง <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.division_group_office_name ? data.current_job_position?.division_group_office_name : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                ฝ่าย <Text style={{fontFamily: 'kanit-200'}}>{data.current_job_position?.department_offices_name ? data.current_job_position?.department_offices_name : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                อัตราเงินเดือนปัจจุบัน <Text style={{fontFamily: 'kanit-200'}}>{data.salary ? addCommas(data.salary) : '-'} <Text></Text></Text> บาท
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                วันเดือนปีเกิด <Text style={{fontFamily: 'kanit-200'}}>{data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                อายุ <Text style={{fontFamily: 'kanit-200'}}>{data.birthday ? getAge(data.birthday) : ''} <Text></Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left'}}>
                เกษียณอายุในปี <Text style={{fontFamily: 'kanit-200'}}>{data.birthday ? parseInt(Moment(data.birthday).add(543, 'year').format('YYYY'))+60 : ''} <Text></Text></Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '50%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.toReversed().map((data: any,index:any) => {
                    if(index === 0){
                      return <>บรรจุเข้าทำงานเมื่อวันที่ <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '50%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.toReversed().map((data: any,index:any) => {
                    if(index === 0){
                      return <>อายุงาน <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? getDateDiff(data.start_date,new Date().toISOString()) : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
            </View>
            <View style={{flexDirection: 'row',marginBottom: 3}}>
              <Text style={{fontFamily: 'kanit-regular',width: '50%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.map((data: any,index:any) => {
                    if(index === 0){
                      return <>ครองระดับในตำแหน่งปัจจุบันเมื่อวันที่ <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '50%',fontSize: 10,textAlign: 'left'}}>
                {
                  data.mof_work_histories?.map((data: any,index:any) => {
                    if(index === 0){
                      return <>อายุงาน <Text style={{fontFamily: 'kanit-200'}}>{data.start_date ? getDateDiff(data.start_date,new Date().toISOString()) : ''} <Text></Text></Text></>
                    }
                  })
                }
              </Text>
            </View>
          </View>

          <View style={{borderLeftWidth: 1,borderTopWidth: 1,borderRightWidth: 1,borderBottomWidth: 1,marginBottom: 10}}>
            <View style={{flexDirection: 'row',borderBottomWidth: 1}}>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ระดับการศึกษา <Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                วุฒิการศึกษา <Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                สถานศึกษา <Text></Text>
              </Text>
            </View>
            {
              data.education_histories?.toReversed().map((data: any,index:any) => {
                return <>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 'kanit-200',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.graduation_level ? data.graduation_level : ''} <Text></Text>
                    </Text>
                    <Text style={{fontFamily: 'kanit-200',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.graduation_major ? data.graduation_major : ''} <Text></Text>
                    </Text>
                    <Text style={{fontFamily: 'kanit-200',width: '33.3%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.school_name ? data.school_name : ''} <Text></Text>
                    </Text>
                  </View>
                </>
              })
            }
          </View>

          <View style={{marginBottom: 10}}>
            <View style={{flexDirection: 'row'}}>
              <Text style={{fontFamily: 'kanit-regular',width: '30%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ประวัติการทำงาน <Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                ระดับตำแหน่ง <Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                สังกัด <Text></Text>
              </Text>
              <Text style={{fontFamily: 'kanit-regular',width: '30%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                เริ่ม - สิ้นสุด <Text></Text>
              </Text>
            </View>
            {
              data.mof_work_histories?.toReversed().map((data: any,index:any) => {
                return <>
                  <View style={{flexDirection: 'row'}}>
                    <Text style={{fontFamily: 'kanit-200',width: '30%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.position_name ? data.position_name : ''} <Text></Text>
                    </Text>
                    <Text style={{fontFamily: 'kanit-200',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.position_level ? data.position_level : ''} <Text></Text>
                    </Text>
                    <Text style={{fontFamily: 'kanit-200',width: '20%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.current_job_position?.division_group_office_name ? data.current_job_position?.division_group_office_name : ''}/{data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : ''} <Text></Text>
                    </Text>
                    <Text style={{fontFamily: 'kanit-200',width: '30%',fontSize: 10,textAlign: 'left',marginLeft: 5}}>
                      {data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : ''} - {data.end_date ? Moment(data.end_date).add(543, 'year').format('DD/MM/YYYY') : data.is_currently === 1 ? 'ปัจจุบัน' : ''} <Text></Text>
                    </Text>
                  </View>
                </>
              })
            }
          </View>
        </View>
      </Page>
      
    </Document>
  );

  return (
    <>
      {/* <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <PDF />
      </PDFViewer> */}
      {loaderComponent}
      <div className="d-flex flex-column flex-sm-row">
        <div className="employee-image-card d-flex justify-content-center">
            {displayPhoto()}
        </div>
        <div className="w-100 d-flex justify-content-between flex-column flex-sm-row">
          <div className="">
            <p className="m-0 mb-1 mx-2">
              <b>{data.prefix_name_th ? data.prefix_name_th : ''}{data.firstname_th ? data.firstname_th : '-'} {data.lastname_th ? data.lastname_th : '-'}</b>
            </p>
            <p className="m-0 my-1 mx-2">
              <b>รหัสพนักงาน</b> : {data.code ? data.code : '-'}
            </p>
            <p className="m-0 my-1 mx-2">
              <b>ตำแหน่ง</b> : {data.current_job_position?.position_name ? data.current_job_position?.position_name : '-'}
            </p>
            <p className="m-0 my-1 mx-2">
              <b>แผนก</b> : {data.current_job_position?.structure_departments_name ? data.current_job_position?.structure_departments_name : '-'}
            </p>
            <p className="m-0 my-1 mx-2">
              <b>กอง / กลุ่ม</b> : {data.current_job_position?.division_group_office_name ? data.current_job_position?.division_group_office_name : '-'}
            </p>
          </div>
          <div>
            <a href="/report/registration-personnel-history/report-personnel-history">
              <Button variant="" className="btn-action me-2 btn-outline-secondary" onClick={()=>{}}>
                <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
                ย้อนกลับ
              </Button>
            </a>
            <Link to="#">
              <Button variant="primary" className="btn-action btn-mof-primary me-2" onClick={exportToExcel} disabled={isDisableExport}>
                <FontAwesomeIcon icon={faFileExport} className="me-2"/>
                Export Excel
              </Button>
            </Link>
            <PDFDownloadLink document={<PDF />} fileName="รายงานประวัติบุคลากร.pdf">
              {({ blob, url, loading, error }) => (
                <Button variant="primary" className="btn-action btn-mof-primary" onClick={()=>{}}>
                  <FontAwesomeIcon icon={faFileExport} className="me-2"/>
                  Export PDF
                </Button>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonnelHeader;
// context/authContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getToken, removeToken, setToken } from './auth';
import { ChildrenProps } from '../models/commons.model';
import jwtDecode from 'jwt-decode';
import { AuthJWT } from '../models/authentication.model';
import { PermissionModel } from '../models/permission.model';
import { initialPermission } from '../utils/constants';

interface AuthContextProps {
    isAuthenticated: boolean;
    authJwt: AuthJWT | null;
    permission: PermissionModel;
    login: (token: string, role: string, permission: PermissionModel, user_id: string) => void;
    logout: () => void;
    onSetPermission: (permission: PermissionModel) => void;
}


const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const getAuthJwt = (): AuthJWT | null => {
    const token = getToken();
    if (token) {
        return jwtDecode<AuthJWT>(token!);
    }
    return null;
};

export const AuthProvider: React.FC<ChildrenProps> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!getToken());
    const [authJwt, setAuthJwt] = useState(getAuthJwt());
    const [permission, setPermission] = useState(initialPermission);
	const [user_id, setUserId] = useState<string>('');

    const login = (token: string, userRoles: string, permission: PermissionModel, user_id: string) => {
        setToken(token);
        setAuthJwt(jwtDecode<AuthJWT>(token!));
        setIsAuthenticated(true);
        setPermission(permission);
		// setUserId(user_id)
    };

    const logout = () => {
        removeToken();
        setAuthJwt(null);
        setIsAuthenticated(false);
    };

    const onSetPermission = (permission: PermissionModel) => {
        setPermission(permission);
    }

    useEffect(() => {
        // Handle token expiration, etc.
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{isAuthenticated, authJwt, permission, login, logout, onSetPermission}}>
            {children}
        </AuthContext.Provider>
    );
};

import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../shared/services/notification-service';
import {useNavigate} from 'react-router-dom';
import {
  initialResponseData, LeaveRequestStatusOptions,
  LeaveRequestTypeOptions,
} from "../../shared/utils/constants";
import InputSelect from "../../shared/components/atoms/input-select";
import {LeaveRequestService} from "../../shared/services/leave-request";
import TableHeaderOrdering, {Header} from "../../shared/components/table-header-ordering";
import PaginationForm from "../../shared/components/molecules/commons/pagination-form";
import {ApiResponse} from "../../shared/models/common.model";
import TableRowNoData from "../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {LeaveRequest, LeaveRequestQueryParams, LeaveTypeNames} from "../../shared/models/leave-request.model";
import Moment from "moment";
import {SystemLogService} from "../../shared/services/system-log-service";

const headers: Header[] = [
  {
    label: 'User',
    field: 'user',
    order: null,
    isOrder: true,
  },
  {
    label: 'ระบบ',
    field: 'module',
    order: null,
    isOrder: true,
  },
  {
    label: 'การใช้งาน',
    field: 'action',
    order: null,
    isOrder: true,
  },
  {
    label: 'IP Address',
    field: 'ip_address',
    order: null,
    isOrder: true,
  },
  {
    label: 'Time stamp',
    field: 'created_at',
    order: null,
    isOrder: false,
  },
];

const SystemLogListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    module: '',
    search: '',
    page: 1,
    page_size: 10,
  });
  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const navigate = useNavigate();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await SystemLogService.getList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  type ModuleType = {
    [key: string]: string;
  };

  const moduleType: ModuleType = {
    "leave-request-history": "ประวัติการลา",
    "approver-request-list": "อนุมัติการลาของผู้ใต้บังคับบัญชา",
    "leave-request": "ลาออนไลน์",
    "shift-request": "กำหนดตารางกะ",
    "shift-request-personel": "กำหนดตารางกะรายบุคคล",
    "user-shift-request": "ตารางกะ",
    "employees": "ทะเบียนประวัติบุคลากร",
    "employee-info": "ข้อมูลส่วนตัว",
    "organization-structures": "บริหารโครงสร้างองค์กร",
    "permission-request": "คำร้องขอสิทธ์การเข้าถึง",
    "permission-approve": "อนุมัติการกำหนดสิทธ์",
    "register-history": "ทะเบียนพนักงาน",
    "reset-password": "เเก้ไขรหัสผ่าน",
    "employee-checkin-report": "รายงานการเข้าออกงาน",
  }

  const moduleOptions = Object.entries(moduleType).map(([key, label]) => ({
    value: key,
    label: label,
  }));

  type actionType = {
    [key: string]: string;
  }

  const actionType: actionType = {
    "view": "ดูข้อมูล",
    "create": "สร้าง",
    "update": "แก้ไข",
    "delete": "ลบ",
    "export": "export",
    "approve": "อนุมัติ",
  }

  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
    });
  }, []);

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานผู้ใช้งานระบบ (System Log)</h2>
    </div>

    {loaderComponent}
    <div className="row mt-2">
      <div className="flex-row col-sm-12 col-md-4">
        <label className="form-label">ระบบ</label>
        <InputSelect value={queryParams.module} options={moduleOptions} placeholder="" name="is_active"
                     onChange={(e) => {
                       setQueryParams(prevState => {
                         return {
                           ...prevState,
                           module: e.value.value || ""
                         }
                       })
                     }} required={true}
                     isValidateError={false}/>
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
               placeholder="ค้นหาจากชื่อ..." name="search"
               onChange={(e) => {
                 setQueryParams(prevState => {
                   return {
                     ...prevState,
                     search: e.target.value || ""
                   }
                 })
               }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={()=>{
          fetchData();
          setQueryParams(prevState => {
            return {
              ...prevState,
              page: 1
            }
          })
        }}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={() => {
        }} isShow={true}/>
        </thead>
        <tbody>
        {
          data.rows?.map((data: any) => {
            return <tr key={data.id}>
              <td>{data?.user}</td>
              <td>{moduleType[data?.module] || '-'}</td>
              <td>{actionType[data?.action]}</td>
              <td>{data.ip_address}</td>
              <td>{Moment(data?.created_at).utcOffset('+07:00').format('DD/MM/YYYY HH:mm:ss')}</td>
            </tr>
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
    <div className="">
      <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                      onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
    </div>

  </div>;
}

export default SystemLogListPage;
import axios from 'axios';


const BASE_API = process.env.REACT_APP_API_URI + '/auth';

export const apiAuthentication = {
    onLogin,
    onFindAccount,
    onRegisterAccount,
    onResetPassword,
    onTokenResetPasswordVerify,
    onResetPasswordSubmit,
};

async function onLogin(data: any) {
    const response = await axios.post(`${BASE_API}/login/`, data);
    return response.data;
}

async function onFindAccount(idCard: any) {
    const response = await axios.get(`${BASE_API}/find-account/`, {params: {id_card: idCard}});
    return response.data;
}

async function onRegisterAccount(data: any) {
    const response = await axios.post(`${BASE_API}/register/`, data);
    return response.data;
}

async function onResetPassword(data: any) {
    const response = await axios.post(`${BASE_API}/reset-password/`, data);
    return response.data;
}

async function onTokenResetPasswordVerify(token: any) {
    const response = await axios.post(`${BASE_API}/reset-password/${token}/verify/`, {});
    return response.data;
}


async function onResetPasswordSubmit(token: any, data: any) {
    const response = await axios.post(`${BASE_API}/reset-password/${token}/submit/`, data);
    return response.data;
}

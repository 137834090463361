import React from 'react';

type PageProps = {
  componentWidth: number;
  date: string;
  description: string;
  onDeleteClick?: () => void;
  isDeleteButtonVisible?: boolean;
}

const HolidayDetailSection = ({componentWidth , date , description , onDeleteClick , isDeleteButtonVisible}:PageProps) => {
  return (
    <div className="col">
      <div className="row p-3">
        <div style={{width: `${componentWidth}`, margin: '0 0 0 0', borderBottom: '1px dashed black'}}>
          <div className="col-12 d-flex flex-row align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16" opacity="0.7" style={{pointerEvents:"none"}}>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
            <div style={{marginLeft:"12px" , fontSize:"15px" , lineHeight:"19.5px", color:"#002047" ,fontWeight:"500"}}>{date}</div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div style={{margin: "6px 0 8px 12px" , color:"#6C6C6C", fontSize:"15px"}}>{description}</div>
            {
              isDeleteButtonVisible &&
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  className="bi bi-x-square" viewBox="0 0 16 16" style={{cursor: "pointer"}} onClick={onDeleteClick}>
              <path
                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidayDetailSection;
import EmployeeLeaveHistory from '../components/employee-leave-history';
import EmployeeLeaveQuota from '../components/employee-leave-quota';

const EmployeeLeaveHistoryPage = () => {
    return (
        <div className="h-100">
            <EmployeeLeaveQuota defaultKey={null}/>
            <EmployeeLeaveHistory defaultKey={null}/>
        </div>
    );
};

export default EmployeeLeaveHistoryPage;
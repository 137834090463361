import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeModel } from '../models/employee.model';
import { initialStateEmployee } from './employee-reducer';

export const employeeEditRequestReducer = createSlice({
  name: 'employee',
  initialState: initialStateEmployee,
  reducers: {
    setEmployeeEditRequest: (state, action: PayloadAction<EmployeeModel>) => {
      Object.assign(state, action.payload);
    },
  }
});

export const {
  setEmployeeEditRequest
} = employeeEditRequestReducer.actions;

export default employeeEditRequestReducer.reducer;
import React from 'react';

export const getMessageContents = (messageText: any[]) => {
  return (
    <div>
      {
        messageText.map((message, index) => {
          return <p className="mb-0" key={index}>- {message}</p>;
        })
      }
      <p className="mt-2">ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่</p>
    </div>
  );
};
import React, {FC, useEffect} from 'react';
import Select from 'react-select';

interface InputSelectProps {
  value?: number | string | null;
  name: string;
  options: any[];
  placeholder: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisable?: boolean;
  required?: boolean;
  onChange: (data: any) => void;
  isValidateError?: boolean;
  isLeaveRequestPage?: boolean;
  width?: string;
}

const InputSelect: FC<InputSelectProps> = ({
                                             value = null,
                                             name,
                                             options,
                                             placeholder,
                                             onChange,
                                             isClearable = true,
                                             isSearchable = true,
                                             isDisable = false,
                                             required = false,
                                             isValidateError = false,
                                             isLeaveRequestPage = false,
                                             width
                                           }) => {
  const [selectedValue, setSelectedValue] = React.useState(null);

  useEffect(() => {
    setSelectedValue(options.find(option => option.value === value) || null);
  }, [options, value]);

  function handleChange(e: any) {
    if (e !== null) {
      setSelectedValue(e);
      onChange({value: e, name: name});
    } else {
      setSelectedValue(e);
      onChange({value: '', name: ""});
    }
  }

  return (
    <Select className="dropdown"
            classNamePrefix="select"
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={isDisable}
            placeholder={placeholder}
            options={options}
            value={selectedValue}
            required={required}
            isOptionDisabled={(option) => option?.disabled}
            name={name}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({...base, zIndex: 9999}),
              control: base => ({
                ...base,
                border: isLeaveRequestPage && isValidateError ? '1px solid red' : "",
                borderRadius: isLeaveRequestPage && isValidateError ? '6px' : "",
                width: width,
              }),
            }}
            onChange={handleChange}></Select>
  );
};

export default InputSelect;
import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ReportInOutLeaveService} from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {faCaretLeft} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import {useParams} from 'react-router-dom';

const headers: Header[] = [
  {
    label: 'สำนัก/ฝ่าย',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'กอง/กลุ่ม',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ชื่อบุคลากร',
    field: '',
    order: null,
    isOrder: false,
  }
];

var ShiftName = "";
var TimeInOutWork = "";
var count_employee = "";

const ReportPersonnelWorkEachShiftDetailPage: FC = () => { 
  let {id} = useParams();
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    shift_request: id,
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();
  
  const fetchData = async () => {
    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportPersonnelWorkEachShiftDetail(queryParams);
      setData(result);

      ShiftName = result.detail?.shift_name;
      TimeInOutWork = result.detail?.work_time_start + " - " + result.detail?.work_time_end;
      count_employee = result.count;
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page]);

  return <>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานจำนวนบุคลากรที่ปฏิบัติงานในแต่ละกะ</h2>
      <Link to="/report/report-in-out-leave/report-personnel-work-each-shift">
        <Button variant="" className="btn-action me-2 btn-outline-secondary" onClick={() => {}}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
      </Link>
    </div>
    
    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">ชื่อกะ</label>
          <input className="form-control" type="text" value={ShiftName} disabled/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">เวลาเข้างาน - ออกงาน</label>
          <input className="form-control" type="text" value={TimeInOutWork} disabled/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">จำนวนบุคลากร</label>
          <input className="form-control" type="text" value={count_employee} disabled/>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data:any,index) => {
              return <tr key={index}>
                <td>{data.department_offices_name ? data.department_offices_name : '-'}</td>
                <td>{data.division_group_office_name ? data.division_group_office_name : '-'}</td>
                <td>{data.structure_departments_name ? data.structure_departments_name : '-'}</td>
                <td>{data.position_name ? data.position_name : '-'}</td>
                <td>{data.name ? data.name : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </>
}

export default ReportPersonnelWorkEachShiftDetailPage;
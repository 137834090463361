import {
  DepartmentOfficeModel,
  DeputyDirectorModel,
  DirectorModel,
  DivisionGroupOfficeModel,
  OrganizationalStructureModel,
  PersonnelModel,
  StructureDepartmentModel,
  StructurePositionModel
} from '../models/organizational-structure.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialStateOrganizationalStructure: OrganizationalStructureModel = {
  id: null,
  name: '',
  budget_year: '',
  status: 'DRAFT',
  active: null,
  created_by: null,
  directors: [
    {
      id: null,
      organizational_structure_id: null,
      name: '',
      user_id: null,
      is_acting_in_position: false,
      tenure_start_date: null,
      tenure_end_date: null,
      is_empty: false,
      level: '',
    }
  ],
  deputy_directors: [
    {
      id: null,
      organizational_structure_id: null,
      name: '',
      pay_account_number: null,
      is_empty: false,
      user_id: null,
      is_acting_in_position: false,
      level: '',
    },
    {
      id: null,
      organizational_structure_id: null,
      name: '',
      pay_account_number: null,
      is_empty: false,
      user_id: null,
      is_acting_in_position: false,
      level: '',
    },
    {
      id: null,
      organizational_structure_id: null,
      name: '',
      pay_account_number: null,
      is_empty: false,
      user_id: null,
      is_acting_in_position: false,
      level: '',
    }
  ],
  department_offices: [
    {
      id: null,
      director_id: null,
      director_index: null,
      deputy_director_id: null,
      deputy_director_index: null,
      code: null,
      name: null,
      power_rate: null,
      type: null,
      user_id: null,
      is_acting_in_position: false,
      is_empty: false,
      pay_account_number: null,
      level: '',
    }
  ],
  division_group_offices: [
    {
      id: null,
      department_office_id: null,
      department_office_index: null,
      director_id: null,
      director_index: null,
      deputy_director_id: null,
      deputy_director_index: null,
      type: 'normal',
      code: null,
      name: null,
      power_rate: null,
      user_id: null,
      is_acting_in_position: false,
      is_empty: false,
      pay_account_number: null,
      level: '',
    }
  ],
  structure_departments: [
    {
      id: null,
      department_office_id: null,
      department_office_index: null,
      division_group_office_id: null,
      division_group_office_index: null,
      director_id: null,
      director_index: null,
      deputy_director_id: null,
      deputy_director_index: null,
      type: 'normal',
      name: null,
      power_rate: null,
      user_id: null,
      is_acting_in_position: false,
      is_empty: false,
      pay_account_number: null,
      level: '',
    }
  ],
  structure_positions: [
    {
      id: null,
      structure_department_id: null,
      structure_department_index: null,
      pay_account_number: null,
      name: null,
      power_rate: null,
      level: '',
    }
  ],
  personnels: [],
};

export interface UpdateOrganizationalStructureFieldAction {
  field: keyof OrganizationalStructureModel;
  value: string | number | null | boolean;
}

export interface DepartmentOfficeFieldAction {
  index: number;
}

export interface DivisionGroupOfficeFieldAction {
  index: number;
}

export interface StructureDepartmentFieldAction {
  index: number;
}

export interface StructurePositionFieldAction {
  index: number;
}

export interface PersonnelFieldAction {
  index: number;
}

export interface UpdateDirectorFieldAction {
  index: number;
  field: keyof DirectorModel;
  value: string | number | null | boolean;
}

export interface UpdateDeputyDirectorFieldAction {
  index: number;
  field: keyof DeputyDirectorModel;
  value: string | number | null | boolean;
}

export interface UpdateDepartmentOfficeFieldAction extends DepartmentOfficeFieldAction {
  field: keyof DepartmentOfficeModel;
  value: string | number | null | boolean;
}

export interface NewDepartmentOfficeFieldAction {
  value: DepartmentOfficeModel;
}

export interface UpdateDivisionGroupOfficeFieldAction extends DivisionGroupOfficeFieldAction {
  field: keyof DivisionGroupOfficeModel;
  value: string | number | null | boolean;
}

export interface NewDivisionGroupOfficeFieldAction {
  value: DivisionGroupOfficeModel;
}

export interface UpdateStructureDepartmentFieldAction extends StructureDepartmentFieldAction {
  field: keyof StructureDepartmentModel;
  value: string | number | null | boolean;
}

export interface NewStructureDepartmentFieldAction {
  value: StructureDepartmentModel;
}

export interface UpdateStructurePositionFieldAction extends StructurePositionFieldAction {
  field: keyof StructurePositionModel;
  value: string | number | null | boolean;
}

export interface NewStructurePositionFieldAction {
  value: StructurePositionModel;
}

export interface UpdatePersonnelFieldAction extends PersonnelFieldAction {
  field: keyof PersonnelModel;
  value: string | number | null | boolean;
}

export interface NewPersonnelFieldAction {
  value: PersonnelModel;
}

export const organizationalStructureReducer = createSlice({
  name: 'organizational_structure',
  initialState: initialStateOrganizationalStructure,
  reducers: {
    setOrganizationalStructureId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setOrganizationalStructure: (state, action: PayloadAction<OrganizationalStructureModel>) => {
      Object.assign(state, action.payload);
    },
    updateOrganizationalStructureRootField: (state, action: PayloadAction<UpdateOrganizationalStructureFieldAction>) => {
      const {field, value} = action.payload;
      state[field as keyof OrganizationalStructureModel] = value as never;
    },
    // from root related
    updateDirectorField: (state, action: PayloadAction<UpdateDirectorFieldAction>) => {
      const {index, field, value} = action.payload;
      state.directors[index][field as keyof DirectorModel] = value as never;
    },
    updateDeputyDirectorField: (state, action: PayloadAction<UpdateDeputyDirectorFieldAction>) => {
      const {index, field, value} = action.payload;
      state.deputy_directors[index][field as keyof DeputyDirectorModel] = value as never;
    },
    // specific update related
    updateDepartmentOfficeField: (state, action: PayloadAction<UpdateDepartmentOfficeFieldAction>) => {
      const {
        index,
        field,
        value
      } = action.payload;
      state.department_offices
        [index]
        [field as keyof DepartmentOfficeModel] = value as never;
    },
    updateDivisionGroupOfficeField: (state, action: PayloadAction<UpdateDivisionGroupOfficeFieldAction>) => {
      const {
        index,
        field,
        value
      } = action.payload;
      state.division_group_offices
        [index]
        [field as keyof DivisionGroupOfficeModel] = value as never;
    },
    updateStructureDepartmentField: (state, action: PayloadAction<UpdateStructureDepartmentFieldAction>) => {
      const {
        index,
        field,
        value
      } = action.payload;
      state.structure_departments
        [index]
        [field as keyof StructureDepartmentModel] = value as never;
    },
    updateStructurePositionField: (state, action: PayloadAction<UpdateStructurePositionFieldAction>) => {
      const {
        index,
        field,
        value
      } = action.payload;
      state.structure_positions
        [index]
        [field as keyof StructurePositionModel] = value as never;
    },
    updatePersonnelField: (state, action: PayloadAction<UpdatePersonnelFieldAction>) => {
      const {
        index,
        field,
        value
      } = action.payload;
      state.personnels
        [index]
        [field as keyof PersonnelModel] = value as never;
    },
    // specific new data related
    addNewDirectorField: (state, action: PayloadAction<DirectorModel>) => {
      state.directors.push(action.payload);
    },
    addNewDeputyDirectorField: (state, action: PayloadAction<DeputyDirectorModel>) => {
      state.deputy_directors.push(action.payload);
    },
    addNewDepartmentOfficeField: (state, action: PayloadAction<NewDepartmentOfficeFieldAction>) => {
      const {
        value,
      } = action.payload;
      state.department_offices.push(value);
    },
    addNewDivisionGroupOfficeField: (state, action: PayloadAction<NewDivisionGroupOfficeFieldAction>) => {
      const {
        value
      } = action.payload;
      state.division_group_offices.push(value);
    },
    addNewStructureDepartmentField: (state, action: PayloadAction<NewStructureDepartmentFieldAction>) => {
      const {
        value
      } = action.payload;
      state.structure_departments.push(value);
    },
    addNewStructurePositionField: (state, action: PayloadAction<NewStructurePositionFieldAction>) => {
      const {
        value
      } = action.payload;
      state.structure_positions.push(value);
    },
    addNewPersonnelField: (state, action: PayloadAction<NewPersonnelFieldAction>) => {
      const {
        value
      } = action.payload;
      state.personnels.push(value);
    },
    // specific delete related
    deleteDirectorField: (state, action: PayloadAction<number>) => {
      const array = state.directors as [];
      state.directors = array.filter((item, i) => i !== action.payload);
    },
    deleteDeputyDirectorField: (state, action: PayloadAction<number>) => {
      const array = state.deputy_directors as [];
      state.deputy_directors = array.filter((item, i) => i !== action.payload);
    },
    deleteDepartmentOfficeField: (state, action: PayloadAction<DepartmentOfficeFieldAction>) => {
      const {
        index
      } = action.payload;
      const array = state.department_offices as [];
      state.department_offices = array.filter((item, i) => i !== index);
    },
    deleteDivisionGroupOfficeField: (state, action: PayloadAction<DivisionGroupOfficeFieldAction>) => {
      const {
        index
      } = action.payload;
      const array = state.division_group_offices as [];
      state.division_group_offices = array.filter((item, i) => i !== index);
    },
    deleteStructureDepartmentField: (state, action: PayloadAction<StructureDepartmentFieldAction>) => {
      const {
        index,
      } = action.payload;
      const array = state.structure_departments as [];
      state.structure_departments = array.filter((item, i) => i !== index);
    },
    deleteStructurePositionField: (state, action: PayloadAction<StructurePositionFieldAction>) => {
      const {
        index
      } = action.payload;
      const array = state.structure_positions as [];
      state.structure_positions = array.filter((item, i) => i !== index);
    },
    deletePersonnelField: (state, action: PayloadAction<PersonnelFieldAction>) => {
      const {
        index
      } = action.payload;
      const array = state.personnels as [];
      state.personnels = array.filter((item, i) => i !== index);
    },
  },
});

export const {
  setOrganizationalStructureId,
  setOrganizationalStructure,
  updateOrganizationalStructureRootField,
  updateDirectorField,
  updateDeputyDirectorField,
  updateDepartmentOfficeField,
  updateDivisionGroupOfficeField,
  updateStructureDepartmentField,
  updateStructurePositionField,
  updatePersonnelField,
  addNewDirectorField,
  addNewDeputyDirectorField,
  addNewDepartmentOfficeField,
  addNewDivisionGroupOfficeField,
  addNewStructureDepartmentField,
  addNewStructurePositionField,
  addNewPersonnelField,
  deleteDirectorField,
  deleteDeputyDirectorField,
  deleteDepartmentOfficeField,
  deleteDivisionGroupOfficeField,
  deleteStructureDepartmentField,
  deleteStructurePositionField,
  deletePersonnelField,
} = organizationalStructureReducer.actions;

export default organizationalStructureReducer.reducer;
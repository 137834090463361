import React, { ChangeEvent, FC, useState } from 'react';
import {
  addNewDeputyDirector,
  deleteDeputyDirector,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateDepartmentOffice,
  updateDeputyDirector,
  updateDivisionGroupOffice,
  updateStructureDepartment
} from '../../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import { DeputyDirectorModel } from '../../../../shared/models/organizational-structure.model';
import { faMinus, faPlus, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleNumericInput } from '../../../../shared/utils/function-validators';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { Dropdown } from '../../../../shared/models/common.model';
import HeaderEmployeeSearch from '../inputs/header-employee-search';

interface DeputyDirectorFormProps extends DeputyDirectorModel {
  index: number;
  lastButton: boolean;
  hideDelete: boolean;
}

const DeputyDirectorForm: FC<DeputyDirectorFormProps> = ({
                                                           index,
                                                           id,
                                                           organizational_structure_id,
                                                           name,
                                                           pay_account_number,
                                                           lastButton,
                                                           is_empty,
                                                           hideDelete,
                                                           user_id,
                                                           is_acting_in_position,
                                                           level,
                                                         }) => {
  const dispatch = useDispatch();
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const [showModal, setShowModal] = useState(false);

  const addNewData = () => {
    addNewDeputyDirector(
      {
        id: null,
        organizational_structure_id: null,
        name: null,
        pay_account_number: null,
        is_empty: false,
        user_id: null,
        is_acting_in_position: false,
        level: '',
      },
      dispatch
    );
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    organizationalStructure.department_offices.forEach((data, dataIndex) => {
      if (data.deputy_director_index === index && data.type === 'department') {
        const resetId = {
          target: {
            name: 'deputy_director_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: null,
          }
        };
        updateDepartmentOffice(dataIndex, resetId, dispatch);
        updateDepartmentOffice(dataIndex, resetIndex, dispatch);
      } else if ((data.deputy_director_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: (data.deputy_director_index as number) - 1,
          }
        };
        updateDepartmentOffice(dataIndex, resetIndex, dispatch);
      }
    });
    organizationalStructure.division_group_offices.forEach((data, dataIndex) => {
      if (data.deputy_director_index === index && data.type === 'deputy_director') {
        const resetId = {
          target: {
            name: 'deputy_director_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: null,
          }
        };
        updateDivisionGroupOffice(dataIndex, resetId, dispatch);
        updateDivisionGroupOffice(dataIndex, resetIndex, dispatch);
      } else if ((data.deputy_director_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: (data.deputy_director_index as number) - 1,
          }
        };
        updateDivisionGroupOffice(dataIndex, resetIndex, dispatch);
      }
    });
    organizationalStructure.structure_departments.forEach((data, dataIndex) => {
      if (data.deputy_director_index === index && data.type === 'deputy_director') {
        const resetId = {
          target: {
            name: 'deputy_director_id',
            value: null,
          }
        };
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: null,
          }
        };
        updateStructureDepartment(dataIndex, resetId, dispatch);
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      } else if ((data.deputy_director_index || -1) > index) {
        const resetIndex = {
          target: {
            name: 'deputy_director_index',
            value: (data.deputy_director_index as number) - 1,
          }
        };
        updateStructureDepartment(dataIndex, resetIndex, dispatch);
      }
    });
    deleteDeputyDirector(index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const displayModal = () => {
    if (showModal) {
      const child = organizationalStructure.department_offices
        .filter(data => data.deputy_director_index === index && data.type === 'department');
      const child2 = organizationalStructure.division_group_offices
        .filter(data => data.deputy_director_index === index && data.type === 'deputy_director');
      const child3 = organizationalStructure.structure_departments
        .filter(data => data.deputy_director_index === index && data.type === 'deputy_director');
      const message = <>
        <p>คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้</p>
        {
          child.length > 0 ?
            <p className="m-0">มีฝ่ายภายใต้รองผู้อำนวยการ <b>{name}</b> ทั้งหมด <b>{child.length}</b> ฝ่าย
            </p> : ''
        }
        {
          child.map((data, index) => {
            return <p key={index} className="m-0">- ฝ่าย {data.name} (อัตรากำลัง: {data.power_rate})</p>;
          })
        }
        {
          child2.length > 0 ?
            <p className="m-0">มี กอง / กลุ่ม / สำนักงาน
              ภายใต้รองผู้อำนวยการ <b>{name}</b> ทั้งหมด <b>{child2.length}</b> กอง / กลุ่ม / สำนักงาน
            </p> : ''
        }
        {
          child2.map((data, index) => {
            return <p key={index} className="m-0">- กอง / กลุ่ม /
              สำนักงาน {data.name} (อัตรากำลัง: {data.power_rate})</p>;
          })
        }
        {
          child3.length > 0 ?
            <p className="m-0">มี แผนก ภายใต้รองผู้อำนวยการ <b>{name}</b> ทั้งหมด <b>{child3.length}</b> แผนก
            </p> : ''
        }
        {
          child3.map((data, index) => {
            return <p key={index} className="m-0">- แผนก {data.name} (อัตรากำลัง: {data.power_rate})</p>;
          })
        }
        {
          child.length > 0 || child2.length > 0 || child3.length > 0 ?
            <div className="alert alert-warning mb-0 mt-2" role="alert">
              <FontAwesomeIcon icon={faWarning} className="me-2"/>
              เมื่อกดลบแล้วฝ่ายภายใต้ทั้งหมดจะต้องเลือก รองผู้อำนวยการ ใหม่
            </div> : ''
        }
      </>;
      return <DeleteConfirmationModal
        show={showModal}
        size={'lg'}
        title={'ยืนยันการลบข้อมูล'}
        description={message}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />;

    }
    return;
  };

  const buttonDisplay = () => {
    if (hideDelete) {
      return;
    }
    return <button className="btn btn-danger" type="button" onClick={handleDelete} hidden={disable}>
      <FontAwesomeIcon icon={faMinus}/>
    </button>;
  };

  const addButton = () => {
    if (lastButton && !disable) {
      return <div className="row">
        <div className="col-12">
          <button className="btn btn-primary d-block px-4 btn-action-height btn-mof-primary" onClick={addNewData}
                  hidden={disable} type="button">
            <FontAwesomeIcon icon={faPlus} className="me-2"/>
            เพิ่มข้อมูล
          </button>
        </div>
      </div>;
    }
    return;
  };

  const handleOnCheckEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    const setIsEmpty = {
      target: {
        name: 'is_empty',
        value: e.target.checked
      }
    };
    const resetName = {
      target: {
        name: 'name',
        value: e.target.checked ? 'ตำแหน่งว่างอยู่' : null
      }
    };
    // const resetPayAccountNumber = {
    //   target: {
    //     name: 'pay_account_number',
    //     value: e.target.checked ? '-' : null
    //   }
    // };
    updateDeputyDirector(index, resetName, dispatch);
    // updateDeputyDirector(index, resetPayAccountNumber, dispatch);
    updateDeputyDirector(index, setIsEmpty, dispatch);

    if (e.target.checked) {
      const setUserId = {
        target: {
          name: 'user_id',
          value: null
        }
      };
      updateDeputyDirector(index, setUserId, dispatch);
    }
  };

  const handleOnCheckActingInPosition = (e: ChangeEvent<HTMLInputElement>) => {
    const check = {
      target: {
        name: 'is_acting_in_position',
        value: e.target.checked
      }
    };
    updateDeputyDirector(index, check, dispatch);
  };

  const handleOnSelectEmployee = (value: Dropdown) => {
    const updateData = {
      target: {
        name: 'user_id',
        value: +value.value
      }
    };
    const updateName = {
      target: {
        name: 'name',
        value: value.label
      }
    };
    updateDeputyDirector(index, updateName, dispatch);
    updateDeputyDirector(index, updateData, dispatch);
  };

  const validateNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleNumericInput(e);
    updateDeputyDirector(index, e, dispatch);
  };

  return <>
    <div className="row mb-3">
      <div className="col-sm-12 col-md-4">
        <label className="form-label required">เลขบัญชีถือจ่าย</label>
        <input type="text" className="form-control" name="pay_account_number" disabled={disable}
               placeholder="" value={pay_account_number || ''} required={true}
               onChange={validateNumber}/>
        <div className="invalid-feedback">
          กรุณากรอกเลขบัญชีถือจ่าย
        </div>
      </div>
      <div className="col-sm-12 col-md-4">
        <label className="form-label required">ชื่อ รองผู้อำนวยการ</label>
        <div className="input-group">
          {
            is_empty ?
              <input type="text" className="form-control" name="name" disabled={true}
                     placeholder="" value={name || ''} required={true}
                     onChange={(e) => updateDeputyDirector(index, e, dispatch)}/>
              :
              <div className="w-100">
                <HeaderEmployeeSearch onChange={(value) => handleOnSelectEmployee(value)} value={user_id}
                                      changeValue={organizationalStructure.deputy_directors.map(data => data.user_id).join(',')}/>
              </div>
          }
          <input type="text" className="form-control" name="name" hidden={true} required={true}
                 value={name || ''} onChange={() => {
          }}/>
          <div className="invalid-feedback">
            กรุณากรอกชื่อ รองผู้อำนวยการ
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-4">
        <label className="form-label required">ระดับ</label>
        <div className="input-group">
          <input type="text" className="form-control" name="level" disabled={disable}
                 onChange={(e) => updateDeputyDirector(index, e, dispatch)}
                 placeholder="" value={level || ''} required={true}/>
          <div className="input-group-append ps-2">
            {buttonDisplay()}
          </div>
          <div className="invalid-feedback">
            กรุณากรอกระดับ
          </div>
        </div>
      </div>
    </div>
    <div className="row mb-3">
      <div className="col-sm-12 col-md-3">
        <div className="form-check">
          <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_empty}
                 name={`is_empty_${index}`}
                 disabled={disable}
                 onChange={handleOnCheckEmpty}
                 id={`is_empty_${index}`}/>
          <label className="form-check-label pointer" htmlFor={`is_empty_${index}`}>
            ตำแหน่งว่างอยู่ตอนนี้
          </label>
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="form-check">
          <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_acting_in_position}
                 name={`is_acting_in_position${index}`}
                 disabled={disable}
                 onChange={handleOnCheckActingInPosition}
                 id={`is_acting_in_position${index}`}/>
          <label className="form-check-label pointer" htmlFor={`is_acting_in_position${index}`}>
            รักษาการในตำแหน่ง
          </label>
        </div>
      </div>
    </div>
    {displayModal()}
    {addButton()}
    {index === organizationalStructure.deputy_directors.length - 1 ? null : <hr/>}
  </>;
};

export default DeputyDirectorForm;
import React, { FC } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { EmployeeGetModel, JobPosition } from '../../../shared/models/employee.model';
import { updateEmployeeState } from '../../../shared/services/employee-service';
import { initialStateEmployee } from '../../../shared/states/employee-reducer';
import { useDispatch } from 'react-redux';
import TableRowNoData from '../../../shared/components/molecules/commons/table-row-no-data';
import TableHeaderOrdering, { Header, OrderData, } from '../../../shared/components/table-header-ordering';

interface EmployeeTableRowsProps {
    rows: EmployeeGetModel[];
    onChangeOrder: (orderData: OrderData) => void;
}

interface EmployeeRowProps {
    id: number | null;
    prefix_name_th: string | null;
    full_name_th: string | null;
    department: string | null;
    branch: string | null;
    email: string | null;
    current_job_position: JobPosition | null;
    user?: any;
    job_positions?: any[];
}

const EmployeeTable: FC<EmployeeTableRowsProps> = ({rows, onChangeOrder}) => {
    const tableRowNoData = () => {
        if (rows.length === 0) {
            return <TableRowNoData colspan={6}/>;
        }
        return null;
    };

    const headers: Header[] = [
        {
            label: 'ประเภทบุคลากร',
            field: 'employee_type',
            order: null,
            isOrder: true,
        },
        {
            label: 'ชื่อ - นามสกุล',
            field: 'firstname_th',
            order: null,
            isOrder: true,
        },
        {
            label: 'ตำแหน่ง',
            field: 'position_name',
            order: null,
            isOrder: true,
        },
        {
            label: 'แผนก',
            field: 'department',
            order: null,
            isOrder: false,
        },
        {
            label: 'กอง / ฝ่าย',
            field: 'branch',
            order: null,
            isOrder: false,
        },
        {
            label: 'อีเมล',
            field: 'email',
            order: null,
            isOrder: true,
        }
    ];

    return (
        <div className="">
            <Table striped bordered hover>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
                </thead>
                <tbody>
                {
                    rows.map((data, index) => {
                        return <EmployeeRow key={index}
                                            id={data.id}
                                            prefix_name_th={data.prefix_name_th}
                                            full_name_th={data.full_name_th}
                                            current_job_position={data.current_job_position}
                                            department={'-'}
                                            branch={'-'}
                                            email={data.user?.email}/>;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
    );
};

const EmployeeRow: FC<EmployeeRowProps> = ({
                                               id,
                                               prefix_name_th,
                                               full_name_th,
                                               current_job_position,
                                               department,
                                               branch,
                                               email
                                           }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateToDetail = () => {
        updateEmployeeState(initialStateEmployee, dispatch);
        navigate(`/employees/${id}/information`);
    };

    return (
        <tr className="pointer" onClick={navigateToDetail}>
            <td>{current_job_position?.employee_type || '-'}</td>
            <td>{prefix_name_th} {full_name_th}</td>
            <td>{current_job_position?.position_name || '-'}</td>
            <td>{department}</td>
            <td>{branch || '-'}</td>
            <td>{email}</td>
        </tr>
    );
};

export default EmployeeTable;
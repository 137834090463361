import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {RegistrationPersonnelHistoryService} from "../../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import {useParams} from 'react-router-dom';
import {Table} from 'react-bootstrap';
import TableHeaderOrdering, {Header} from "../../../../../shared/components/table-header-ordering";
import TableRowNoData from "../../../../../shared/components/molecules/commons/table-row-no-data";
import Moment from "moment";
import { dateDiff } from '../../../../../shared/utils/functions-date-time';

const headers: Header[] = [
  {
    label: 'สถานที่ทำงาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันที่เริ่มต้น-สิ้นสุด',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุงาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เงินเดือน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เหตุผลที่ลาออก',
    field: '',
    order: null,
    isOrder: false,
  }
];

const PersonnelWorkHistory: FC = () => {

  let {id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelHistoryDetail({id: id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.work_histories?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
  }, []);

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getDateDiff = (start_date:any,end_date:any) => {
    if (start_date && end_date) {
      const data = dateDiff(start_date, end_date);
      const result = [];
      if (data.years)
        result.push(`${Math.abs(data.years)} ปี`);
      if (data.months)
        result.push(`${Math.abs(data.months)} เดือน`);
      if (data.days)
        result.push(`${Math.abs(data.days)} วัน`);
      return result.length > 0 ? result.join(' ') : '1 วัน';
    }
    return '-';
  };

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">ประวัติการทำงานที่ผ่านมา</h5>
          </Accordion.Header>
          <Accordion.Body>
            <div className="overflow-auto mt-3">
              <Table bordered>
                <thead>
                  <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                </thead>
                <tbody>
                  {data.work_histories?.map((data:any,index:any) => {
                    return <tr key={index}>
                      <td>{data.location ? data.location : '-'}</td>
                      <td>{(data.start_date ? Moment(data.start_date).add(543, 'year').format('DD/MM/YYYY') : '-')+" - "+(data.end_date ? Moment(data.end_date).add(543, 'year').format('DD/MM/YYYY') : '-')}</td>
                      <td>{data.start_date && data.end_date ? getDateDiff(data.start_date,data.end_date) : '-'}</td>
                      <td>{data.position ? data.position : '-'}</td>
                      <td>{data.salary ? addCommas(data.salary) : '-'}</td>
                      <td>{data.reason ? data.reason : '-'}</td>
                    </tr>
                  })}
                  {tableRowNoData()}
                </tbody>
              </Table>
            </div>
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelWorkHistory;
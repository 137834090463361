import React, { ChangeEvent, FC } from 'react';
import {
  addNewDirector,
  deleteDirector,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateDirector
} from '../../../../shared/services/organizational-structure-service';
import { useDispatch, useSelector } from 'react-redux';
import { DirectorModel } from '../../../../shared/models/organizational-structure.model';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import { convertDateToString, dateDiff } from '../../../../shared/utils/functions-date-time';
import { Dropdown } from '../../../../shared/models/common.model';
import HeaderEmployeeSearch from '../inputs/header-employee-search';

interface DirectorFormProps extends DirectorModel {
  index: number;
  lastButton: boolean;
}

const DirectorForm: FC<DirectorFormProps> = ({
                                               index,
                                               id,
                                               organizational_structure_id,
                                               name,
                                               tenure_start_date,
                                               tenure_end_date,
                                               is_empty,
                                               lastButton,
                                               user_id,
                                               is_acting_in_position,
                                               level,
                                             }) => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';

  const addNewData = () => {
    addNewDirector(
      {
        id: null,
        organizational_structure_id: null,
        name: null,
        tenure_start_date: null,
        tenure_end_date: null,
        is_empty: false,
        user_id: null,
        is_acting_in_position: false,
        level: '',
      },
      dispatch
    );
  };

  const deleteData = () => {
    deleteDirector(index, dispatch);
  };

  const buttonDisplay = () => {
    if (lastButton) {
      return <button className="btn btn-primary" onClick={addNewData} hidden={disable}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>;
    }
    return <button className="btn btn-danger" onClick={deleteData} hidden={disable}>
      <FontAwesomeIcon icon={faMinus}/>
    </button>;
  };

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    if (value.startDate === null)
      return;

    const startTime = {
      target: {
        name: 'tenure_start_date',
        value: value.startDate ? convertDateToString(value.startDate) : null
      }
    };
    const endTime = {
      target: {
        name: 'tenure_end_date',
        value: value.endDate ? convertDateToString(value.endDate) : null
      }
    };
    updateDirector(index, startTime, dispatch);
    updateDirector(index, endTime, dispatch);
  };

  const handleOnCheckEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    const setIsEmpty = {
      target: {
        name: 'is_empty',
        value: e.target.checked
      }
    };
    const resetName = {
      target: {
        name: 'name',
        value: e.target.checked ? 'ตำแหน่งว่างอยู่' : null
      }
    };
    updateDirector(index, resetName, dispatch);
    updateDirector(index, setIsEmpty, dispatch);

    if (e.target.checked) {
      const setUserId = {
        target: {
          name: 'user_id',
          value: null
        }
      };
      updateDirector(index, setUserId, dispatch);
    }
  };

  const handleOnCheckActingInPosition = (e: ChangeEvent<HTMLInputElement>) => {
    const check = {
      target: {
        name: 'is_acting_in_position',
        value: e.target.checked
      }
    };
    updateDirector(index, check, dispatch);
  };

  const getDateDiff = () => {
    if (tenure_start_date && tenure_end_date) {
      const data = dateDiff(tenure_start_date, tenure_end_date);
      const result = [];
      if (data.years)
        result.push(`${Math.abs(data.years)} ปี`);
      if (data.months)
        result.push(`${Math.abs(data.months)} เดือน`);
      if (data.days)
        result.push(`${Math.abs(data.days)} วัน`);
      return result.length > 0 ? result.join(' ') : '1 วัน';
    }
    return '';
  };

  const handleOnSelectEmployee = (value: Dropdown) => {
    const updateData = {
      target: {
        name: 'user_id',
        value: +value.value
      }
    };
    const updateName = {
      target: {
        name: 'name',
        value: value.label
      }
    };
    updateDirector(index, updateName, dispatch);
    updateDirector(index, updateData, dispatch);
  };

  return <>
    <div className="row mt-3">
      <div className="col-sm-12 col-md-3">
        <label className="form-label required">ชื่อ ผู้อำนวยการ</label>
        {
          is_empty ?
            <input type="text" className="form-control" name="name" disabled={true}
                   placeholder="" value={name || ''}
                   onChange={(e) => updateDirector(index, e, dispatch)}/>
            :
            <HeaderEmployeeSearch onChange={(value) => handleOnSelectEmployee(value)} value={user_id}
                                  required={true}
                                  changeValue={organizationalStructure.directors.map(data => data.user_id).join(',')}/>
        }
        <input type="text" className="form-control" name="name" hidden={true} required={true}
               value={name || ''} onChange={() => {
        }}/>
        <div className="invalid-feedback">
          กรุณาเลือก ผู้อำนวยการ
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <label className="form-label required">ระดับ</label>
        <input type="text" className="form-control" name="level" disabled={disable}
               onChange={(e) => updateDirector(index, e, dispatch)}
               placeholder="" value={level || ''} required={true}/>
        <div className="invalid-feedback">
          กรุณากรอกระดับ
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <label className="form-label required">วาระการดำรงตำแหน่ง</label>
        <DateRangeInput
          startDate={tenure_start_date ? new Date(tenure_start_date) : null}
          endDate={tenure_end_date ? new Date(tenure_end_date) : null}
          onChange={handleDateRangeChange}
          disable={disable}/>
        <input type="text" className="form-control" name="tenure_start_date" hidden={true}
               value={tenure_start_date || ''} required={true} onChange={() => {
        }}/>
        <div className="invalid-feedback">
          กรุณาเลือกวาระการดำรงตำแหน่ง
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <label className="form-label">ระยะเวลา (ปี เดือน วัน)</label>
        <input type="text" className="form-control" disabled={true}
               value={getDateDiff()}/>
      </div>
    </div>
    <div className="row mt-3">
      <div className="col-sm-12 col-md-3">
        <div className="form-check">
          <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_empty}
                 name="is_empty"
                 disabled={disable}
                 onChange={handleOnCheckEmpty}
                 id="is_empty"/>
          <label className="form-check-label pointer" htmlFor="is_empty">
            ตำแหน่งว่างอยู่ตอนนี้
          </label>
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="form-check">
          <input className="form-check-input pointer no-validate" type="checkbox" defaultChecked={is_acting_in_position}
                 name="is_acting_in_position"
                 disabled={disable}
                 onChange={handleOnCheckActingInPosition}
                 id="is_acting_in_position"/>
          <label className="form-check-label pointer" htmlFor="is_acting_in_position">
            รักษาการแทนในตำแหน่งผู้อำนวยการ
          </label>
        </div>
      </div>
    </div>
  </>;
};

export default DirectorForm;
import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ReportInOutLeaveService} from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import {useNavigate} from 'react-router-dom';

const headers: Header[] = [
  {
    label: 'ชื่อกะ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เวลาเข้างาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เวลาออกงาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'จำนวนบุคลากร',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportPersonnelWorkEachShiftListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const navigate = useNavigate();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportPersonnelWorkEachShiftList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.page_size, 
    queryParams.page
  ]);

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานจำนวนบุคลากรที่ปฏิบัติงานในแต่ละกะ</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
          <input className="form-control" type="text" value={queryParams.search as string}
              placeholder="ค้นหาจากชื่อกะ..." name="search"
              onChange={(e) => {
                setQueryParams(prevState => {
                  return {
                    ...prevState,
                    search: e.target.value || ""
                  }
                })
              }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered hover>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index} style={{cursor: "pointer"}} onClick={() => {
                navigate(`${data.id}/details`)
              }}>
                <td>{data.shift_name ? data.shift_name : '-'}</td>
                <td>{data.work_time_start ? data.work_time_start : '-'}</td>
                <td>{data.work_time_end ? data.work_time_end : '-'}</td>
                <td>{data.count_employee ? data.count_employee : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportPersonnelWorkEachShiftListPage;
import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {RegistrationPersonnelHistoryService} from "../../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import {useParams} from 'react-router-dom';
import {Table} from 'react-bootstrap';
import TableHeaderOrdering, {Header} from "../../../../../shared/components/table-header-ordering";
import TableRowNoData from "../../../../../shared/components/molecules/commons/table-row-no-data";
import {calculateAge} from '../../../../../shared/utils/function-employees';
import Moment from "moment";

const headers: Header[] = [
  {
    label: 'ชื่อ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'นามสกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เพศ',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันเดือนปีเกิด',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อายุ',
    field: '',
    order: null,
    isOrder: false,
  }
];

const PersonnelFamilyDetail: FC = () => {

  let {id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelHistoryDetail({id: id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.childern?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAge = (data:any) => {
		if (data) {
			return calculateAge(data)+" ปี";
		}
		return '-';
	};

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">รายละเอียดครอบครัว</h5>
          </Accordion.Header>
          <Accordion.Body>
            {/* บิดา */}
            <h5 className="text-mof-primary m-0 pt-1 pb-2">บิดา</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_father_prefix_name ? data.contact_father_prefix_name : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_father_firstname ? data.contact_father_firstname : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_father_lastname ? data.contact_father_lastname : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_father_status ? data.contact_father_status : '-'}/>
              </div>
            </div>

            {/* มารดา */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">มารดา</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_mother_prefix_name ? data.contact_mother_prefix_name : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_mother_firstname ? data.contact_mother_firstname : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_mother_lastname ? data.contact_mother_lastname : '-'}/>
              </div>
            </div>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_father_status ? data.contact_father_status : '-'}/>
              </div>
            </div>

            {/* คู่สมรส */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">คู่สมรส</h5>
            <div className="row">
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">สถานภาพ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_spouse_status ? data.contact_spouse_status : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_spouse_firstname ? data.contact_spouse_firstname : '-'}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data.contact_spouse_lastname ? data.contact_spouse_lastname : '-'}/>
              </div>
            </div>

            {/* จำนวนบุตร */}
            <h5 className="text-mof-primary m-0 pt-4 pb-2">จำนวนบุตร</h5>
            <div className="overflow-auto mt-3">
              <Table bordered>
                <thead>
                  <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                </thead>
                <tbody>
                  {data.childern?.map((data:any,index:any) => {
                    return <tr key={index}>
                      <td>{data.first_name ? data.first_name : '-'}</td>
                      <td>{data.last_name ? data.last_name : '-'}</td>
                      <td>{data.gender ? data.gender==='MALE' ? 'ชาย':'หญิง' : '-'}</td>
                      <td>{data.birthday ? Moment(data.birthday).add(543, 'year').format('DD/MM/YYYY') : ''}</td>
                      <td>{data.birthday ? getAge(data.birthday) : '-'}</td>
                    </tr>
                  })}
                  {tableRowNoData()}
                </tbody>
              </Table>
            </div>
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelFamilyDetail;
import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../shared/services/employee-service';
import { NameChangeHistoryModel } from '../../../../shared/models/employee.model';
import {
    getFixValue,
    handleEmployeeSectionInputChange,
    handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import DateInput from '../../../../shared/components/atoms/date-input';
import { convertDateToString } from '../../../../shared/utils/functions-date-time';
import SectionFileForm from '../section-file-form';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';

interface NameChangeHistoryModelProp extends NameChangeHistoryModel {
    index: number;
}

interface SortingField {
    field: keyof NameChangeHistoryModel | null;
    order: null | 'DESC' | 'ASC';
}

const NameChangeHistoryForm: FC = () => {
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const [sorting, setSorting] = useState<SortingField>({
        field: null,
        order: null
    });

    const tableRowNoData = () => {
        if (employee.name_change_histories.length === 0) {
            return <TableRowNoData colspan={6}/>;
        }
        return null;
    };

    const headers: Header[] = [
        {
            label: 'ชื่อ',
            field: 'first_name',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'นามสกุล',
            field: 'last_name',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'วันที่เปลี่ยนแปลง',
            field: 'date',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'เหตุผล',
            field: 'date',
            order: null,
            isOrder: showOrder,
        },
        {
            label: 'แนบไฟล์',
            field: 'file',
            order: null,
            isOrder: false,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const onChangeOrder = (orderData: OrderData) => {
        setSorting({
            field: orderData.field as keyof NameChangeHistoryModel,
            order: orderData.order
        });
    };

    const dataOrder = () => {
        if (showOrder)
            return employee.name_change_histories.map(data => data).sort((a, b) => {
                return SortingByField(a, b, sorting);
            });
        return employee.name_change_histories;
    };


    return (
        <div className="h-100 overflow-x">
            <Table bordered>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
                </thead>
                <tbody>
                {
                    dataOrder().map((data, index) => {
                        return <NameChangeHistoryRow
                            key={index}
                            index={index}
                            {...data}
                        />;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
    );
};

const NameChangeHistoryRow: FC<NameChangeHistoryModelProp> = ({
                                                                  index,
                                                                  first_name,
                                                                  last_name,
                                                                  reason,
                                                                  date,
                                                                  file,
                                                                  from_id,
                                                              }) => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
    const type = 'name_change_histories';
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);
    let fromEmployee: any | null = null;

    if (from_id) {
        const found = oldEmployee[type].find(data => data.id === from_id);
        if (found)
            fromEmployee = found;
    }

    const validateEditRequest = (value: any, key: keyof NameChangeHistoryModel) => {
        if (employeeAction.type === 'view-edit-request') {
            if (fromEmployee && from_id) {
                if (value !== fromEmployee[key])
                    return 'field-warning';
            }
            if (!from_id) {
                return 'field-new';
            }
        }
        return '';
    }

    const [showModal, setShowModal] = useState(false);

    const deleteButton = () => {
        if (!disable) {
            return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
        }
        return null;
    };

    const handleDelete = () => {
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        handleEmployeeSectionRemoveByIndex(type, index, dispatch);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <tr className="bg-white">
            <td className={`${validateEditRequest(first_name, 'first_name')}`}>
                <input type="text" className="form-control" name="first_name" disabled={disable}
                       placeholder="" value={first_name || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(last_name, 'last_name')}`}>
                <input type="text" className="form-control" name="last_name" disabled={disable}
                       placeholder="" value={last_name || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(date, 'date')}`}>
                <DateInput date={date ? new Date(date) : null} required={true}
                           positionAbsolute={false}
                           onChange={
                               (value: Date | null) => handleEmployeeSectionInputChange(
                                   type,
                                   index,
                                   getFixValue('date', value ? convertDateToString(value) : null),
                                   dispatch
                               )
                           }
                           disable={disable}/>
            </td>
            <td className={`${validateEditRequest(reason, 'reason')}`}>
                <input type="text" className="form-control" name="reason" disabled={disable}
                       placeholder="" value={reason || ''} required={true}
                       onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
            </td>
            <td className={`${validateEditRequest(file, 'file')}`}>
                <SectionFileForm section_key={'name_change_histories'} index={
                    disable ? employee.name_change_histories.findIndex(data =>
                        data.first_name === first_name &&
                        data.last_name === last_name &&
                        data.reason === reason &&
                        data.date === date
                    ) : index
                }/>
            </td>
            <td className="text-center">
                {deleteButton()}
            </td>
            <DeleteConfirmationModal
                show={showModal}
                title={'ยืนยันการลบข้อมูล'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
                onHide={handleCloseModal}
                onConfirm={handleConfirmDelete}
            />
        </tr>
    );
};

export default NameChangeHistoryForm;
import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { TrainingHistoryModel } from '../../../../shared/models/employee.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import {
  handleEmployeeSectionInputChange,
  handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import { convertDateToString } from '../../../../shared/utils/functions-date-time';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';
import { handleNumericInput } from '../../../../shared/utils/function-validators';

interface TrainingHistoryRowProps extends TrainingHistoryModel {
  index: number;
}

const TrainingHistoryForm: FC = () => {
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const [sorting, setSorting] = useState<SortingField>({
    field: null,
    order: null
  });

  const tableRowNoData = () => {
    if (employee.training_histories.length === 0) {
      return <TableRowNoData colspan={7}/>;
    }
    return null;
  };

  const headers: Header[] = [
    {
      label: 'หลักสูตร',
      field: 'course',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ประเภทการฝึกอบรม',
      field: 'course_type',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ปี',
      field: 'year',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'สถาบัน / ประเทศ',
      field: 'location',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'แนบไฟล์',
      field: 'file',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  const onChangeOrder = (orderData: OrderData) => {
    setSorting({
      field: orderData.field,
      order: orderData.order
    });
  };

  const dataOrder = () => {
    if (showOrder)
      return employee.training_histories.map(data => data).sort((a, b) => {
        return SortingByField(a, b, sorting);
      });
    return employee.training_histories;
  };


  return (
    <div className="overflow-auto">
      <Table bordered>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
        </thead>
        <tbody>
        {
          dataOrder().map((data, index) => {
            return <TrainingHistoryRow
              key={index}
              index={index}
              {...data}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
  );
};

const TrainingHistoryRow: FC<TrainingHistoryRowProps> = ({
                                                           index,
                                                           course,
                                                           course_type,
                                                           location,
                                                           year,
                                                           file,
                                                           from_id,
                                                         }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const type = 'training_histories';
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  let fromEmployee: any | null = null;

  if (from_id) {
    const found = oldEmployee[type].find(data => data.id === from_id);
    if (found)
      fromEmployee = found;
  }

  const validateEditRequest = (value: any, key: keyof TrainingHistoryModel) => {
    if (employeeAction.type === 'view-edit-request') {
      if (fromEmployee && from_id) {
        if (value !== fromEmployee[key])
          return 'field-warning';
      }
      if (!from_id) {
        return 'field-new';
      }
    }
    return '';
  }

  const [showModal, setShowModal] = useState(false);

  const deleteButton = () => {
    if (!disable) {
      return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
    }
    return null;
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleEmployeeSectionRemoveByIndex(type, index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    const startTime = {
      target: {
        name: 'start_time',
        value: value.startDate ? convertDateToString(value.startDate) : null
      }
    };
    const endTime = {
      target: {
        name: 'end_time',
        value: value.endDate ? convertDateToString(value.endDate) : null
      }
    };
    handleEmployeeSectionInputChange(type, index, startTime, dispatch);
    handleEmployeeSectionInputChange(type, index, endTime, dispatch);
  };

  // const getDateDiff = () => {
  //     if (start_time && end_time) {
  //         const data = dateDiff(start_time, end_time);
  //         const result = [];
  //         if (data.years)
  //             result.push(`${Math.abs(data.years)} ปี`);
  //         if (data.months)
  //             result.push(`${Math.abs(data.months)} เดือน`);
  //         if (data.days)
  //             result.push(`${Math.abs(data.days)} วัน`);
  //         return result.length > 0 ? result.join(' ') : '1 วัน';
  //     }
  //     return 'กรุณาเลือกวันที่เริ่มต้น - สิ้นสุด';
  // };

  return (
    <tr className="bg-white">
      <td className={`${validateEditRequest(course, 'course')}`}>
        <input type="text" className="form-control" name="course" disabled={disable}
               placeholder="" value={course || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(course_type, 'course_type')}`}>
        <input type="text" className="form-control" name="course_type" disabled={disable}
               placeholder="" value={course_type || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(year, 'year')}`}>
        <input type="text" className="form-control" name="year" disabled={disable}
               placeholder="" value={year || ''} required={true} maxLength={4}
               pattern=".{4,4}"
               onChange={(e) => {
                 handleNumericInput(e);
                 handleEmployeeSectionInputChange(type, index, e, dispatch);
               }}/>
      </td>
      {/*<td className={`${validateEditRequest(start_time, 'start_time')} ${validateEditRequest(end_time, 'end_time')}`}>*/}
      {/*    <DateRangeInput*/}
      {/*        startDate={start_time ? new Date(start_time) : null}*/}
      {/*        endDate={end_time ? new Date(end_time) : null}*/}
      {/*        onChange={handleDateRangeChange}*/}
      {/*        disable={disable}/>*/}
      {/*</td>*/}
      <td className={`${validateEditRequest(location, 'location')}`}>
        <input type="text" className="form-control" name="location" disabled={disable}
               placeholder="" value={location || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(file, 'file')}`}>
        <SectionFileForm section_key={'training_histories'}
                         index={
                           disable ? employee.training_histories.findIndex(data => data.course === course &&
                             data.course_type === course_type &&
                             data.location === location &&
                             data.year === year
                           ) : index
                         }/>
      </td>
      <td className="text-center">
        {deleteButton()}
      </td>
      <DeleteConfirmationModal
        show={showModal}
        title={'ยืนยันการลบข้อมูล'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </tr>
  );
};

export default TrainingHistoryForm;
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import InsigniaHistoryForm from '../forms/insignia-history-form';
import { useDispatch, useSelector } from 'react-redux';
import { EducationHistoryModel, InsigniaHistoryModel } from '../../../../shared/models/employee.model';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import SectionAddRow from '../section-add-row';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeInsigniaHistoryProps {
    defaultKey?: AccordionEventKey;
}

const EmployeeInsigniaHistory: FC<EmployeeInsigniaHistoryProps> = ({defaultKey = '0'}) => {
    const dispatch = useDispatch();
    const employee = useSelector(getCurrentEmployee);
    const employeeAction = useSelector(getEmployeeAction);

    const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
    const haveSomeEditField = () => {
        if (employeeAction.type === 'view-edit-request') {
            const lengthNotSame = employee.insignia_histories.length !== employeeEditProfile.insignia_histories.length;

            if (lengthNotSame)
                return true;

            return employee.insignia_histories.some((data, index) => {
                return Object.keys(data).some(key => {
                    const kk = key as keyof InsigniaHistoryModel;
                    if (key === 'id' || key === 'employee_id' || key === 'created_at'  || key === 'updated_at' || key === 'from_id')
                        return false;
                    return employeeEditProfile.insignia_histories[index][kk] !== data[kk];
                });
            });
        }
        return false;
    };

    const onAddNewData = () => {
        const initData: InsigniaHistoryModel = {
            id: null,
            employee_id: null,
            year: null,
            date_of_award: null,
            type: null,
            name: null,
            file: null,
            new_file: true,
            file_name: '',
            from_id: null,
        };
        handleEmployeeSectionAddNewData('insignia_histories', initData, dispatch);
    };


    return (
        <Accordion defaultActiveKey={defaultKey} className="mt-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
                    <h5 className="text-mof-primary m-0">ประวัติเครื่องราชอิสริยาภรณ์</h5>
                </Accordion.Header>
                <Accordion.Body>
                    <InsigniaHistoryForm/>
                    <SectionAddRow onAddNewData={onAddNewData}/>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default EmployeeInsigniaHistory;
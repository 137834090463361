import {
  addNewDataToSection,
  removeDataFromSection,
  updateDataInSection,
  updateField,
  updateUserField
} from '../states/employee-reducer';
import {EmployeeAttachment, EmployeeModel, EmployeeSectionKey, UserModel} from '../models/employee.model';
import {SelectDropdown} from '../models/common.model';
import {AppDispatch} from '../states/store';

export interface ValueChange {
  name: string;
  value: string | number | null | boolean;
}

export interface fixValue {
  target: ValueChange;
}

export const handleEmployeeInputChange = (event: React.ChangeEvent<HTMLInputElement> | {
  target: ValueChange
}, dispatch: AppDispatch) => {
  const {name, value} = event.target;
  dispatch(updateField({field: name as keyof EmployeeModel, value}));
};

export const handleEmployeeUserInputChange = (event: React.ChangeEvent<HTMLInputElement> | fixValue, dispatch: AppDispatch) => {
  const {name, value} = event.target;
  if (value === '') {
    dispatch(updateUserField({field: name as keyof UserModel, value: null}));
    return;
  }
  dispatch(updateUserField({field: name as keyof UserModel, value}));
};

export const handleEmployeeInputSelectChange = (select: SelectDropdown, dispatch: AppDispatch, name?: keyof EmployeeModel) => {
  if (select.value) {
    const name = select.name;
    const value = select.value!.value;
    dispatch(updateField({field: name as keyof EmployeeModel, value}));
  } else {
    if (name) {
      const value = null;
      dispatch(updateField({field: name as keyof EmployeeModel, value}));
    }
  }
};

export const handleEmployeeSectionInputChange = (type: EmployeeSectionKey, index: number, event: React.ChangeEvent<HTMLInputElement> | {
  target: ValueChange
} | any, dispatch: AppDispatch, isSelect?: boolean) => {
  if (isSelect) {
    const value = event.value.value;
    dispatch(updateDataInSection({type: type, index: index, field: event.name, value}));
    return;
  }
  const {name, value} = event.target;
  dispatch(updateDataInSection({type: type, index: index, field: name, value}));
};

export const getFixValue = (name: string, value: string | number | null): fixValue => {
  return {
    target: {
      name: name,
      value: value
    }
  };
};

export const handleEmployeeSectionInputSelectChange = (type: EmployeeSectionKey, index: number, select: SelectDropdown, dispatch: AppDispatch, name?: keyof EmployeeModel) => {
  if (select.value) {
    const name = select.name as string;
    const value = select.value!.value;
    dispatch(updateDataInSection({type: type, index: index, field: name, value}));
  } else {
    if (name) {
      const value = null;
      dispatch(updateDataInSection({type: type, index: index, field: name, value}));
    }
  }
};

export const handleEmployeeSectionAddNewData = (type: EmployeeSectionKey, data: any, dispatch: AppDispatch) => {
  dispatch(addNewDataToSection({type: type, data: data}));
};

export const handleEmployeeSectionRemoveByIndex = (type: EmployeeSectionKey, index: number, dispatch: AppDispatch) => {
  dispatch(removeDataFromSection({type: type, index: index}));
};

export const prepareEmployeeAttachment = (data: EmployeeAttachment[]) => {
  return {
    education_history: data.filter(data => data.file_type === 'education_history'),
    employee_certifier: data.filter(data => data.file_type === 'employee_certifier'),
    insignia_history: data.filter(data => data.file_type === 'insignia_history'),
    name_change_history: data.filter(data => data.file_type === 'name_change_history'),
    outstanding_performance_award: data.filter(data => data.file_type === 'outstanding_performance_award'),
    punishment_history: data.filter(data => data.file_type === 'punishment_history'),
    resignation_retirement_death_history: data.filter(data => data.file_type === 'resignation_retirement_death_history'),
    training_history: data.filter(data => data.file_type === 'training_history'),
    job_position: data.filter(data => data.file_type === 'job_position'),
  };
};
interface SortingField {
    field: any;
    order: null | 'DESC' | 'ASC';
}

export const SortingByField = (a: any, b: any, sorting: SortingField) => {
    if (sorting.field === null)
        return 0;

    if (a[sorting.field] === null && b[sorting.field] === null) {
        return 0; // Both items are null, consider them equal
    }
    if (a[sorting.field] === null) {
        return 1; // Null values go to the end
    }
    if (b[sorting.field] === null) {
        return -1; // Null values go to the end
    }

    if (typeof a[sorting.field] === 'string') {
        const tempA = a[sorting.field] as string;
        const tempB = b[sorting.field] as string;
        if (isDateFormat(tempA) && isDateFormat(tempB)) {
            const DateA = new Date(tempA);
            const DateB: Date = new Date(tempB);
            return sorting.order === 'DESC' ? +DateA - +DateB : +DateB - +DateA;
        }
        if (sorting.order === 'DESC') {
            return tempA.localeCompare(tempB);
        }
        if (sorting.order === 'ASC') {
            return tempB.localeCompare(tempA);
        }
        return 0;
    } else {
        return 0;
    }
};

function isDateFormat(str: string) {
    // Define a regular expression for a common date format (YYYY-MM-DD)
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

    // Use the test method to check if the string matches the pattern
    return dateFormatRegex.test(str);
}

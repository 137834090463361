import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table, Form } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';
import {SystemLogService} from "../../shared/services/system-log-service";
import { apiEmployeeService } from '../../shared/services/employee-service';

const EmployeeCheckInsListPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        date: '',
        date2: '',
        ordering_field: 'date',
        ordering: 'DESC',
        page: 1,
        page_size: 10,
        employee_full_name: '',
        is_view_subordinate: false,
        sub_code: '',
        sub_full_name: ''
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();

    const isEdit = searchParams.get('isEdit');
    const id = searchParams.get('id');
    const userId = !isEdit && !id ? authJwt?.id : id && parseInt(id) || null;
    const currentUserId = authJwt?.id;
    let employeeTmp: any = null;
    const [ employee, setEmployee ] = useState<any>(null);

    const headers: Header[] = [
        {
            label: 'พนักงาน',
            field: 'code',
            order: null,
            isOrder: true,
        },
        {
            label: 'วันที่',
            field: 'date',
            order: null,
            isOrder: true,
        },
        {
            label: 'เข้างาน',
            field: 'scan_min_time',
            order: null,
            isOrder: true,
        },
        {
            label: 'ออกงาน',
            field: 'scan_max_time',
            order: null,
            isOrder: true,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const fetchEmployeeMe = async () => {
        startLoading();
        try {
            const employee2 = await apiEmployeeService.getEmployeeMe();
            setEmployee(employee2);
            employeeTmp = employee2;
            if(!isAdmin) {
                queryParams.code = employee2.code;
            }
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const createSystemLog = async (data: any) => {
        try {
            await SystemLogService.create(data);
        } catch (error) {
            const err = error as any;
            notificationService.dangerNotification('ระบบขัดข้อง', err.message);
        }
    }

    useEffect(() => {
        createSystemLog({
            employee_id: authJwt?.employee_id,
            module: 'employee-checkin-report',
            action: 'view',
        });
    }, []);

    useEffect(() => {
        let f = async () => {
            await fetchEmployeeMe();
            await fetchData();
        }
        f();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    const fetchData = async () => {
        startLoading();
        try {
            let code = queryParams.code;
            let name = queryParams.employee_full_name;
            let is_view_subordinate = queryParams.is_view_subordinate;
            if(!isAdmin) {
                if(is_view_subordinate) {
                    code = queryParams.sub_code;
                    name = queryParams.sub_full_name;
                }
            }
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/v2/get-all-users-for-everyday`, {
                params: {
                    code: code,
                    date: Moment(queryParams.date).isValid() ? Moment(queryParams.date).format('YYYY-MM-DD') : '',
                    date2: Moment(queryParams.date2).isValid() ? Moment(queryParams.date2).format('YYYY-MM-DD') : '',
                    page: queryParams.page,
                    limit: queryParams.page_size,
                    ordering_field: queryParams.ordering_field,
                    ordering: queryParams.ordering,
                    employee_full_name: name,
                    is_view_subordinate: is_view_subordinate ? 1:0
                }
            });
            // for(let i=0; i<response.data.rows.length; ++i) {
            //     let obj = response.data.rows[i];
            //     obj.key = obj.user_id + '-' + obj.date;
            // }
            setData(response.data);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };
    const tableRowNoData = () => {
        if (data.rows.length === 0) {
            return <TableRowNoData colspan={4} />;
        }
        return null;
    };

    const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, code: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            code: e.target.value
        });
    };

    const onChangeSubCode = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            sub_code: e.target.value
        });
    };

    const onChangeFullname = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, code: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            employee_full_name: e.target.value
        });
    };

    const onChangeSubFullname = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            sub_full_name: e.target.value
        });
    };

    const onChangeIsViewSubOrdinate = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            is_view_subordinate: e.target.checked
        });
    };

    const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, date: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            date: e.target.value
        });
    };

    const onChangeDate2 = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            date2: e.target.value
        });
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">รายงานการเข้าออกงาน</h2>
        </div>
        <div className="row">
            {
                (() => {
                    if(isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <label className="form-label">รหัสผู้ใช้</label>
                            <input className="form-control" type="text" value={queryParams.code}
                                onChange={onChangeCode} name="user_id"/>
                        </div>
                    }
                    return '';
                })()
            }
          {
            (() => {
              if(isAdmin) {
                return <div className="col-sm-12 col-md-2">
                  <label className="form-label">ชื่อ-นามสกุล</label>
                  <input className="form-control" type="text" value={queryParams.employee_full_name}
                         onChange={onChangeFullname} name="employee_full_name"/>
                </div>
              }
              return '';
            })()
          }
            <div className="col-sm-12 col-md-2">
                <label className="form-label">วันที่เริ่ม</label>
                <input className="form-control" type="date" value={queryParams.date}
                       onChange={onChangeDate} name="search"/>
            </div>
            <div className="col-sm-12 col-md-2">
                <label className="form-label">ถึง</label>
                <input className="form-control" type="date" value={queryParams.date2}
                       onChange={onChangeDate2} name="search"/>
            </div>
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <div style={{ height: '38px' }}></div>
                            <Form.Check
                                inline={ true }
                                type="checkbox"
                                label="ดูเวลาเข้า-ออก ผู้ใต้บังคับบัญชา"
                                id="test-1"
                                value="1"
                                checked={queryParams.is_view_subordinate == '1'}
                                onChange={onChangeIsViewSubOrdinate}
                            />
                        </div>
                    }
                })()
            }
            
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                            <label className="form-label">รหัสผู้ใต้บังคับบัญชา</label>
                            <input className="form-control" type="text" value={queryParams.sub_code}
                                onChange={onChangeSubCode}/>
                        </div>
                    }
                    return '';
                })()
            }
            {
                (() => {
                    if(!isAdmin) {
                        return <div className="col-sm-12 col-md-2">
                        <label className="form-label">ชื่อ-นามสกุล ผู้ใต้บังคับบัญชา</label>
                        <input className="form-control" type="text" value={queryParams.sub_full_name}
                                onChange={onChangeSubFullname}/>
                        </div>
                    }
                    return '';
                })()
            }
            <div className="col-sm-12 col-md-2">
                <div style={{ height: '31px' }}></div>
                <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
            </div>
        </div>
        <div className="overflow-auto mt-3">
            <Table striped bordered hover>
                <thead>
                    <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true} />
                </thead>
                <tbody>
                    {
                        data.rows.map((data, index) => {
                            let name = '(' + data.code + ') ' + data.firstname_th + ' ' + data.lastname_th;
                            if(!data.code && !data.firstname_th && !data.lastname_th) { name = `ไม่พบผู้ใช้งาน`; }
                            return <tr>
                                <td>{name}</td>
                                <td>{data.date && Moment(data.date).isValid() ? Moment(data.date).format('DD/MM/YYYY') : '-'}</td>
                                <td>{data.scan_in_time ? data.scan_in_time : '-'}</td>
                                <td>{data.scan_out_time ? data.scan_out_time : '-'}</td>
                                <td></td>
                            </tr>;
                        })
                    }
                    {tableRowNoData()} 
                </tbody>
            </Table>
        </div>
        <div className="">
            <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                onChangePageSize={onChangePageSize} onChangePage={onChangePage} />
        </div>
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsListPage;
import React, { FC } from 'react';
import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../shared/authentications/auth-context';
import { hasPermission } from '../../shared/utils/roles';

const EmployeeRegistrationsPage: FC = () => {
    const pathList = useLocation().pathname.split('/');
    const currentPath = pathList[pathList.length - 1];
    const navigate = useNavigate();
    const {permission} = useAuth();
    const [searchParams] = useSearchParams();

    const isSeperated = searchParams.get('seperated')

    const navigateToSubPage = (path: string) => {
        navigate(path);
    };

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">{isSeperated ? "คำขอรีเซ็ตรหัสผ่าน" : "ประวัติส่วนตัวพนักงาน"}</h2>
        </div>

      {
        !isSeperated &&
        <div className="row row-gap-3">
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/all-users')}
                  disabled={!hasPermission(permission, ['employee_actives'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'all-users' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              ผู้ใช้งานทั้งหมด
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/register-user-requests')}
                  disabled={!hasPermission(permission, ['employee_register_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'register-user-requests' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              ผู้ใช้งานใหม่
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/profile-edit-requests')}
                  disabled={!hasPermission(permission, ['employee_edit_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'profile-edit-requests' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              รายการผู้ใช้งานที่แก้ไขข้อมูล
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/normal-users')}
                  disabled={!hasPermission(permission, ['employee_edit_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'normal-users' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              ปกติ
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/retire-users')}
                  disabled={!hasPermission(permission, ['employee_edit_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'retire-users' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              เกษียณ
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/resign-users')}
                  disabled={!hasPermission(permission, ['employee_edit_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'resign-users' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              ลาออก
            </div>
          </Button>
        </div>
        <div className="col-sm-12 col-md-3">
          <Button variant="info"
                  onClick={() => navigateToSubPage('/employee-registrations/fired-users')}
                  disabled={!hasPermission(permission, ['employee_edit_requests'], 'read')}
                  className={`w-100 btn-action ${currentPath === 'fired-users' ? 'btn-mof-info-secondary' : 'btn-mof-info'}`}>
            <div className="">
              ไล่ออก
            </div>
          </Button>
        </div>
      </div>}

        <div className="mt-4">
            <Outlet/>
        </div>

    </div>;
};

export default EmployeeRegistrationsPage;
import React from 'react';

type PageProps = {
  day: string | undefined;
  value?: string;
  isSelected: boolean;
  onClick?: () => void;
  isAprover?: boolean;
}

const DaySelectBox = ({day , value , isSelected, onClick, isAprover}: PageProps) => {
  const convertDaytoThai = (day: string | undefined) => {
    if (day === "SUN") {
      return "อาทิตย์";
    } else if (day === "MON") {
      return "จันทร์";
    } else if (day === "TUE") {
      return "อังคาร";
    } else if (day === "WED") {
      return "พุธ";
    } else if (day === "THU") {
      return "พฤหัสบดี";
    } else if (day === "FRI") {
      return "ศุกร์";
    } else if (day === "SAT") {
      return "เสาร์";
    } else {
      return "";
    }
  }

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      onClick={onClick}
      style={{
        border: `${isSelected ? "" : "1px solid black"}` , width:"100px" , height:"30px" , borderRadius:"6px" ,
        backgroundColor: `${isSelected ? "#00BA34" : ""}` , fontSize:"14px" ,textAlign:"center" , cursor: isAprover ? "" : "pointer",
        marginRight: "6px" , marginTop: "6px", opacity: `${isAprover ? "0.7" : "1"}`, pointerEvents: `${isAprover ? "none" : "auto"}`
      }}>
      <div style={{fontWeight:"bold",userSelect:"none"}}>
        {convertDaytoThai(day)}
      </div>
    </div>
  );
};

export default DaySelectBox;

import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction
} from '../../../shared/services/organizational-structure-service';
import {EmployeeDropdownParams} from '../../../shared/models/employee.model';
import {GroupBase, OptionsOrGroups} from 'react-select';
import {apiDropdownService} from '../../../shared/services/dropdown-service';
import {AsyncPaginate} from 'react-select-async-paginate';
import {Dropdown} from '../../../shared/models/common.model';
import {InputActionMeta} from 'react-select/dist/declarations/src/types';
import {apiEmployeeService} from "../../../shared/services/employee-service";

interface EmployeeSearchProps {
  onChange: (data: Dropdown) => void;
  value: any,
  required?: boolean;
  submitted?: boolean;
  changeValue: string;
  disable?: boolean;
}

const EmployeeSearch: FC<EmployeeSearchProps> = ({
                                                         onChange,
                                                         value,
                                                         required = false,
                                                         submitted = false,
                                                         changeValue,
                                                         disable = false
                                                       }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  // const disable = organizationalStructureAction.status === 'view';
  const [localValue, setLocalValue] = useState({value: value, label: value});
  const [query, setQuery] = useState<EmployeeDropdownParams>({
    search: '',
    excludes: null,
  })
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    loadOptions();
  }, [query]);

  type OptionType = {
    value: number;
    label: string;
  };

  // const getUserId = async (value: any) => {
  //   try {
  //    if (value) {
  //      const user = await apiEmployeeService.getEmployeeDetail(value);
  //      if (user.user_id) {
  //        setUserId(user.user_id)
  //      }
  //    }
  //     // return userId;
  //   } catch (error) {
  //     console.error('Error fetching options:', error);
  //   }
  // }

  const loadOptions = async (
  ) => {
    setIsLoading(true);

    let options = [];
    try {
      options = await apiDropdownService.getEmployeeDropdown(query);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching options:', error);
      setIsLoading(false);
    }
    return {
      options: options,
    };
  };

  const handleOnChange = async (newValue: any) => {
    if (newValue === null) {
      onChange({value: '', label: ''});
      setLocalValue({
        label: '',
        value: '',
      });
      setQuery({
        ...query,
        search: '',
      })
      setUserId(null);
    }
    if (newValue) {
      onChange(newValue);
      setLocalValue({
        label: newValue.label,
        value: newValue.value,
      });
      // setUserId(getUserId(newValue.value));
      // getUserId(newValue.value);
    }
  };

  const onInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setLocalValue({
        label: newValue,
        value: newValue,
      });
    }
    setQuery({
      ...query,
      search: newValue,
    });
  };

  return (
    <div>
      <AsyncPaginate
        isDisabled={disable}
        key={JSON.stringify(changeValue)}
        debounceTimeout={300}
        loadOptions={loadOptions}
        isLoading={isLoading}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        value={localValue}
        inputValue={localValue.value}
        placeholder=""
        isClearable={true}
      />
    </div>
  );
};

export default EmployeeSearch;
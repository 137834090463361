import React, { FC } from 'react';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import { useDispatch, useSelector } from 'react-redux';
import { EducationHistoryModel, WorkHistoryModel } from '../../../../shared/models/employee.model';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import { Accordion } from 'react-bootstrap';
import SectionAddRow from '../section-add-row';
import WorkHistoryPassForm from '../forms/work-history-pass-form';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeWorkHistoryProps {
    defaultKey?: AccordionEventKey;
}

const EmployeeWorkHistoryPass: FC<EmployeeWorkHistoryProps> = ({defaultKey = '0'}) => {
    const dispatch = useDispatch();
    const employee = useSelector(getCurrentEmployee);
    const employeeAction = useSelector(getEmployeeAction);

    const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
    const haveSomeEditField = () => {
        if (employeeAction.type === 'view-edit-request') {
            const lengthNotSame = employee.work_histories.length !== employeeEditProfile.work_histories.length;

            if (lengthNotSame)
                return true;

            return employee.work_histories.some((data, index) => {
                return Object.keys(data).some(key => {
                    const kk = key as keyof WorkHistoryModel;
                    if (key === 'id' || key === 'employee_id' || key === 'created_at'  || key === 'updated_at' || key === 'from_id')
                        return false;
                    return employeeEditProfile.work_histories[index][kk] !== data[kk];
                });
            });
        }
        return false;
    };

    const onAddNewData = () => {
        const initData: WorkHistoryModel = {
            id: null,
            employee_id: null,
            location: null,
            start_date: null,
            end_date: null,
            position: null,
            salary: null,
            reason: null,
            file: null,
            new_file: true,
            file_name: '',
            from_id: null,
        };
        handleEmployeeSectionAddNewData('work_histories', initData, dispatch);
    };

    return <Accordion defaultActiveKey={defaultKey} className="mt-3">
        <Accordion.Item eventKey="0">
            <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
                <h5 className="text-mof-primary m-0">ประวัติการทำงานที่ผ่านมา</h5>
            </Accordion.Header>
            <Accordion.Body>
                <WorkHistoryPassForm/>
                <SectionAddRow onAddNewData={onAddNewData}/>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>;
};

export default EmployeeWorkHistoryPass;
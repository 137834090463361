import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { faCaretLeft, faCheckCircle, faPenToSquare, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiEmployeeService,
  employeeActionAfterSave,
  getCurrentEmployee,
  getEmployeeAction,
  updateEmployeeActionStatus,
  updateEmployeeActionSubmitted,
  updateEmployeeActionType,
  updateEmployeeId
} from '../../../shared/services/employee-service';
import { AxiosError } from 'axios';
import { notificationService } from '../../../shared/services/notification-service';
import { handleEmployeeSectionRemoveByIndex, } from '../../../shared/utils/function-state';
import { EmployeeSection } from '../../../shared/models/employee.model';
import EmployeePhotoForm from './employee-photo-form';
import { getMessageContents } from '../../../shared/utils/error-messages';
import { useAuth } from '../../../shared/authentications/auth-context';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import InputReasonModal from '../../../shared/components/molecules/modals/input-reason-modal';
import { RequestService } from '../../../shared/services/request-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { hasPermission } from '../../../shared/utils/roles';

interface EmployeeCardProps {
  editRequestStatus?: string;
}

const EmployeeCard: FC<EmployeeCardProps> = ({editRequestStatus = ''}) => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const navigate = useNavigate();

  const pathList = useLocation().pathname.split('/');
  const isMePath = pathList.includes('me');

  const location = useLocation();
  const employeeId = employee.id ? Number(employee.id) : null;

  const {authJwt, permission} = useAuth();
  const isSelfEdit = employee.id === authJwt?.employee_id;

  const onValidateEmployee = async () => {
    try {
      startLoading();
      const result = await apiEmployeeService.validateEmployee(employee);
      if (result.status === 200) {
        return true;
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        const messageList: any[] = error.response.data.details;
        const messageText = Array.from(
          new Set(
            messageList.map(data => {
              return data.message;
            })
          )
        );
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', getMessageContents(messageText));
      } else {
        notificationService.dangerNotification(
          'เกิดข้อผิดพลาด',
          'ไม่สามารถติดต่อกับเซิร์ฟเวอร์ได้'
        );
      }
      return false;
    } finally {
      stopLoading();
    }
  };

  const getEmployeeId = () => {
    return id ? parseInt(id) : authJwt!.employee_id;
  };

  const onClickEdit = () => {
    // กดแก้ไข
    updateEmployeeActionSubmitted(false, dispatch);
    updateEmployeeActionType('edit', dispatch);
    updateEmployeeActionStatus('input', dispatch);
  };

  const prepareAttachmentData = (data: any, id?: number) => {
    const formData = new FormData();
    const employeeId = id ? id.toString() : (employee.id as number).toString();
    formData.append('file_type', data.file_type);
    formData.append('file_name', data.file_name);
    formData.append('file', data.file);
    formData.append('employee_id', employeeId);
    return formData;
  };

  const afterSaveSections = async (id?: number) => {
    // mean complete all request apps for update/create
    updateEmployeeActionStatus('after_process', dispatch);
  };

  const prepareSelfEditData = () => {
    const raw: any = {...employee};
    // delete raw.id;
    // delete raw.user_id;
    // delete raw.user;
    return raw;
  };

  const onClickSave = async () => {
    // กดบันทึก
    updateEmployeeActionSubmitted(true, dispatch);

    const isValid = await onValidateEmployee();

    if (isValid) {
      startLoading();
      try {
        if (isSelfEdit) {
          const result = await apiEmployeeService.selfEditEmployee(employeeId as number, prepareSelfEditData());
          if (result.status === 201) {
            const id = result.data.id;
            updateEmployeeId(getEmployeeId(), dispatch);
            await updateAllSectionSelfEdit(id);
            await afterSaveSections(id);
            employeeActionAfterSave(true, dispatch);
            notificationService.successNotification('สร้างคำขอแก้ไขข้อมูลส่วนตัวสำเร็จ', '');
          }
        } else {
          const result = await apiEmployeeService.updateEmployee(employeeId as number, employee);
          if (result.status === 200) {
            await updateAllSection(employeeId as number, true);
            await afterSaveSections();
            employeeActionAfterSave(true, dispatch);
            notificationService.successNotification('บันทึกข้อมูลสำเร็จ', '');
          }
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 400) {
          notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
        } else {
          notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
        }
      } finally {
        stopLoading();
      }
    }
  };

  const onClickCreate = async () => {
    updateEmployeeActionSubmitted(true, dispatch);
    const isValid = await onValidateEmployee();

    if (isValid) {
      startLoading();
      try {
        const result = await apiEmployeeService.createEmployee(employee);
        if (result.status === 201) {
          const id = result.data.id;
          updateEmployeeId(id, dispatch);
          await updateAllSection(id, true);
          await afterSaveSections(id);
          employeeActionAfterSave(true, dispatch);
          notificationService.successNotification('สร้างข้อมูลสำเร็จ', '');
        } else {
          console.log('Create failed');
        }
      } catch (error: any) {
        if (error.response?.status === 400) {
          const errorMessage = error.response.data?.message === 'User already exists' ? 'อีเมลถูกใช้งานแล้ว' : 'กรุณากรอกข้อมูลให้ครบ';
          notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', errorMessage);
        } else {
          notificationService.dangerNotification('สร้างข้อมูลไม่สำเร็จ', error.message);
        }
      } finally {
        stopLoading();
      }
    }
  };

  const updateAllSection = async (employeeId: number, includeJobPosition = false) => {
    try {
      startLoading();
      const data = prepareSectionData(includeJobPosition);
      const result = await apiEmployeeService.updateEmployeeSection(employeeId, data);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
      } else {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  const updateAllSectionSelfEdit = async (employeeId: number) => {
    try {
      startLoading();
      const data = prepareSectionDataForSelfEdit(employeeId);
      const result = await apiEmployeeService.updateEmployeeSection(employeeId, data);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
      } else {
        notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
      }
    } finally {
      stopLoading();
    }
  };

  const resetIdAndEmployeeId = (section: any, employeeId: any) => {
    return section.map((data: any) => {
      const id = data.id;
      return {...data, id: null, employee_id: employeeId, from_id: id};
    });
  };

  const prepareSectionDataForSelfEdit = (employeeId: any): EmployeeSection => {
    return {
      education_histories: resetIdAndEmployeeId(employee.education_histories, employeeId),
      employee_certifiers: resetIdAndEmployeeId(employee.employee_certifiers, employeeId),
      insignia_histories: resetIdAndEmployeeId(employee.insignia_histories, employeeId),
      name_change_histories: resetIdAndEmployeeId(employee.name_change_histories, employeeId),
      outstanding_performance_awards: resetIdAndEmployeeId(employee.outstanding_performance_awards, employeeId),
      punishment_histories: resetIdAndEmployeeId(employee.punishment_histories, employeeId),
      resignation_retirement_death_histories: resetIdAndEmployeeId(employee.resignation_retirement_death_histories, employeeId),
      training_histories: resetIdAndEmployeeId(employee.training_histories, employeeId),
      children: resetIdAndEmployeeId(employee.children, employeeId),
      work_histories: resetIdAndEmployeeId(employee.work_histories, employeeId),
      job_positions: resetIdAndEmployeeId(employee.job_positions, employeeId),
    };
  };

  const prepareSectionData = (includeJobPosition = false) => {
    const data: EmployeeSection = {
      education_histories: employee.education_histories,
      employee_certifiers: employee.employee_certifiers,
      insignia_histories: employee.insignia_histories,
      name_change_histories: employee.name_change_histories,
      outstanding_performance_awards: employee.outstanding_performance_awards,
      punishment_histories: employee.punishment_histories,
      resignation_retirement_death_histories: employee.resignation_retirement_death_histories,
      training_histories: employee.training_histories,
      children: employee.children,
      work_histories: employee.work_histories
    };
    if (includeJobPosition) {
      data.job_positions = employee.job_positions;
    }
    return data;
  };

  const onClickAddJobPosition = () => {
    updateEmployeeActionType('job_position', dispatch);
  };

  const onClickSaveJobPosition = async () => {
    updateEmployeeActionSubmitted(true, dispatch);
    const isValid = await onValidateEmployee();

    if (isValid) {
      try {
        startLoading();
        const data = {
          job_positions: employee.job_positions,
        };
        const result = await apiEmployeeService.updateEmployeeSection(employeeId as number, data);
        if (result.status === 200) {
          await afterSaveSections();
          employeeActionAfterSave(true, dispatch);
          notificationService.successNotification('สร้างข้อมูลสำเร็จ', '');
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 400) {
          notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', 'กรุณากรอกข้อมูลให้ครบ');
        } else {
          notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
        }
      } finally {
        stopLoading();
      }
    }
  };

  const onClickEditJobPosition = () => {
    // กดแก้ไขตำแหน่ง
    updateEmployeeActionSubmitted(false, dispatch);
    updateEmployeeActionType('job_position_edit', dispatch);
    updateEmployeeActionStatus('input', dispatch);
  };

  const onApproveRequest = () => {
    setConfirmModal(true);
  };

  const onRejectRequest = () => {
    setRejectModal(true);
  };

  const onApprove = async () => {
    handleCloseModal();
    startLoading();
    try {
      await RequestService.onApproveEditProfileRequest(getEmployeeId(), {});
      notificationService.successNotification('อนุมัติสำเร็จ', '');
      navigate('/employee-registrations/profile-edit-requests');
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onReject = async (reason: any) => {
    handleCloseModal();
    startLoading();
    const data = {
      reason: reason
    };
    try {
      await RequestService.onRejectEditProfileRequest(getEmployeeId(), data);
      notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
      navigate('/employee-registrations/profile-edit-requests');
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(false);
    setRejectModal(false);
  };

  const displayModal = () => {
    if (showConfirmModal) {
      return <ConfirmModal
        show={showConfirmModal}
        title={'ยืนยันการอนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการอนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onApprove}
      />;
    }
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติคำขอ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติคำขอนี้'}
        onHide={handleCloseModal}
        onConfirm={onReject}
      />;
    }
    return;
  };

  const buttonAction = () => {
    const currentTab = location.pathname.split('/')[3];
    const type = location.pathname.split('/')[2];
    if (isMePath && employeeAction.type === 'view' && currentTab === 'information') {
      return <Button variant="warning" className="btn-action" onClick={onClickEdit}>
        <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
        แก้ไข
      </Button>;
    } else if (isMePath && employeeAction.type === 'edit') {
      return <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickSave}>
        <FontAwesomeIcon icon={faSave} className="me-2"/>
        บันทึก
      </Button>;
    }

    if (hasPermission(permission, ['employees'], 'update')) {
      if (employeeAction.type === 'view' && currentTab === 'information') {
        return <Button variant="warning" className="btn-action" onClick={onClickEdit}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>;
      } else if (employeeAction.type === 'edit') {
        return <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickSave}>
          <FontAwesomeIcon icon={faSave} className="me-2"/>
          บันทึก
        </Button>;
      } else if (employeeAction.type === 'create' || type === 'create') {
        return <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickCreate}>
          <FontAwesomeIcon icon={faSave} className="me-2"/>
          บันทึก
        </Button>;
      } else if (employeeAction.type === 'view' && currentTab === 'work-history') {
        return <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickAddJobPosition}>
          <FontAwesomeIcon icon={faPlus} className="me-2"/>
          เพิ่ม
        </Button>;
      } else if (['job_position', 'job_position_edit'].includes(employeeAction.type) && currentTab === 'work-history' && type !== 'create') {
        return <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickSaveJobPosition}>
          บันทึก
        </Button>;
      } else if (employeeAction.type === 'job_position_view' && currentTab === 'work-history' && type !== 'create') {
        return <Button variant="warning" className="btn-action" onClick={onClickEditJobPosition}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>;
      }
    }
    if (hasPermission(permission, ['employees'], 'approve')) {
      if (employeeAction.type === 'view-edit-request' && editRequestStatus === 'WAITING_FOR_ACTIONS') {
        return <>
          <Button variant="danger" type="button" className="btn-action me-2 text-white"
                  onClick={onRejectRequest}>
            <FontAwesomeIcon icon={faCircleXmark} className="me-2"/>
            ไม่อนุมัติ
          </Button>
          <Button variant="success" type="button" className="btn-action me-2 text-white"
                  onClick={onApproveRequest}>
            <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
            อนุมัติ
          </Button>
        </>;
      }
    }
    return null;
  };

  const backToJobPositionHistoryView = () => {
    clearJobPositionNullData();
    updateEmployeeActionType('view', dispatch);
  };

  const clearJobPositionNullData = () => {
    const indexNullData = employee.job_positions.findIndex(data => data.id === null);
    if (indexNullData !== -1) {
      handleEmployeeSectionRemoveByIndex('job_positions', indexNullData, dispatch);
    }
  };

  const backButton = () => {
    const currentTab = location.pathname.split('/')[3];
    const type = location.pathname.split('/')[2];
    if (['job_position', 'job_position_view', 'job_position_edit'].includes(employeeAction.type) && currentTab === 'work-history' && type != 'create') {
      return <Button variant="primary" className="btn-action me-2 btn-mof-primary"
                     onClick={backToJobPositionHistoryView}>
        <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
        ย้อนกลับ
      </Button>;
    }
    if (employeeAction.type === 'view-edit-request') {
      return <Link to={'/employee-registrations/profile-edit-requests'}>
        <Button variant="primary" className="btn-action me-2 btn-mof-primary" type={'button'}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
      </Link>;
    }
    return <Link to="/employees">
      <Button variant="primary" className="btn-action me-2 btn-mof-primary">
        <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
        ย้อนกลับ
      </Button>
    </Link>;
  };

  return (
    <>
      <div className='d-flex flex-row justify-content-end mb-3'>
        <Link to={"/reset-password"} >
        <Button variant="warning" className="btn-action">
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไขรหัสผ่าน
        </Button>
        </Link>
      </div>
    <div className="d-flex flex-column flex-sm-row">
      {displayModal()}
      {loaderComponent}
      <EmployeePhotoForm/>
      <div className="w-100 d-flex justify-content-between flex-column flex-sm-row">
        <div className="">
          <p className="m-0 mb-1 mx-2">
            <b>{employee.prefix_name_th} {employee.firstname_th} {employee.lastname_th}</b>
          </p>
          <p className="m-0 my-1 mx-2">
            <b>รหัสพนักงาน</b> : {employee.code}
          </p>
          <p className="m-0 my-1 mx-2">
            <b>ตำแหน่ง</b> : {employee.current_job_position?.position_name || '-'}
          </p>
          <p className="m-0 my-1 mx-2">
            <b>แผนก</b> : (WIP)
          </p>
          <p className="m-0 my-1 mx-2">
            <b>กอง / ฝ่าย</b> : (WIP)
          </p>
        </div>
        <div className="d-flex justify-content-between">
          {backButton()}
          {buttonAction()}
        </div>
      </div>
    </div>
      </>
  );
};

export default EmployeeCard;
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {ApiResponse} from '../../shared/models/common.model';
import {initialResponseData} from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, {Header, OrderData} from '../../shared/components/table-header-ordering';
import {Button, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import {AxiosError} from 'axios';
import {notificationService} from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import {PermissionRequest, PermissionRequestQueryParams} from '../../shared/models/permission.model';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import {PermissionService} from '../../shared/services/permission-service';
import Moment from 'moment/moment';
import {useAuth} from '../../shared/authentications/auth-context';
import PermissionApproveModal from "../permissions/components/permission-approve-modal";
import {hasPermission} from "../../shared/utils/roles";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";
import {ShiftRequestPayloadType, ShiftRequestType} from "../../shared/models/employee-shifts.model";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import HolidayDetailSection from "./pages/holiday-details-section";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import {convertDateToString} from "../../shared/utils/functions-date-time";

interface EmployeeShiftRowProps extends ShiftRequestType {
  onAction: (status: 'approve' | 'reject', id: any) => void;
}

const EmployeeShiftPersonelPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<PermissionRequestQueryParams>({
      search: '',
      ordering_field: '',
      ordering: '',
      page: 1,
      page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<ShiftRequestType>>(initialResponseData);
    const {authJwt} = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [showRejectModal, setRejectModal] = useState(false);
    const [idTarget, setIdTarget] = useState<any>(null);
    const [submitted, setSubmitted] = useState(false);
    const [currentStatus, setCurrentStatus] = useState('');

    const isEdit = searchParams.get('isEdit') || false;
    const id = searchParams.get('id');
    const userId = !isEdit && !id ? authJwt?.id : id && parseInt(id) || null;
    const currentUserId = authJwt?.id;

    const headers: Header[] = [
      {
        label: 'ตารางกะ',
        field: 'shift_name',
        order: null,
        isOrder: true,
      },
      {
        label: 'เวลาเข้า - ออกงาน',
        field: 'start_time',
        order: null,
        isOrder: true,
      },
      {
        label: 'วันที่เริ่ม - วันที่สิ้นสุด',
        field: 'start_date',
        order: null,
        isOrder: true,
      },
      {
        label: 'สถานะ',
        field: 'is_active',
        order: null,
        isOrder: true,
      },
      {
        label: '',
        field: '',
        order: null,
        isOrder: false,
      }
    ];

    useEffect(() => {
      fetchData();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    const fetchData = async () => {
      startLoading();
      try {
        if (userId) {
          const result = await EmployeeShiftService.getEmployeeShiftByUserId(userId);
          setData(result);
          setCurrentStatus(result.rows[0]?.status);
        }
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
      } finally {
        stopLoading();
      }
    };

    const handleSubmit = async (isReject?: boolean, reason?: string) => {
      setSubmitted(true);
      const formData: ShiftRequestPayloadType = {
        shift_request_payload: {
          shift_name: data.rows[0]?.shift_name,
          start_date: data.rows[0]?.start_date,
          end_date: data.rows[0]?.end_date,
          work_time_start: data.rows[0]?.work_time_start,
          work_time_end: data.rows[0]?.work_time_end,
          break_time_start: data.rows[0]?.break_time_start,
          break_time_end: data.rows[0]?.break_time_end,
          status: '',
          reason: null
        },
        shift_repeat_details: data.rows[0]?.shift_repeat_details,
        shift_holiday_details: data.rows[0]?.shift_holiday_details?.map(item => {
          return {
            holiday_date: convertDateToString(new Date(item.holiday_date)),
            holiday_name: item.holiday_name
          }
        }),
        shift_employee_assignment_details: data.rows[0]?.shift_employee_assignment_details?.map(item => {
          return {
            user_id: item.user_id
          }
        }),
      }
      try {
        startLoading();
        let response = null
        {
          formData.isPersonelEdit = true;
          if (isReject) {
            formData.isReject = true;
            formData.shift_request_payload.reason = reason;
          }
          response = await EmployeeShiftService.createEmployeeShift(formData);
        }
        if (response) {
          notificationService.successNotification(!isReject ? 'อนุมัติคำขอเรียบร้อย' : 'ไม่อนุมัติคำขอเรียบร้อย', '');
          navigate('/employee-shift/employee-shift-list');
        }
      } catch
        (error) {
        const err = error as any;
        if (err.response?.status === 400) {
          notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
        } else {
          notificationService.dangerNotification('ระบบขัดข้อง', err.message);
        }
      } finally {
        stopLoading();
      }
    }

    const onChangePageSize = (page_size: number) => {
      setQueryParams({
        ...queryParams,
        page_size: page_size
      });
    };

    const onChangePage = (page: number) => {
      setQueryParams({
        ...queryParams,
        page: page
      });
    };
    const tableRowNoData = () => {
      if (data.rows.length === 0) {
        return <TableRowNoData colspan={7}/>;
      }
      return null;
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
      setQueryParams(prevState => {
        return {...prevState, search: e.target.value};
      });
    };

    const onChangeOrder = (orderData: OrderData) => {
      setQueryParams({
        ...queryParams,
        ordering_field: orderData.field,
        ordering: orderData.order,
      });
    };

    const onAction = (status: 'approve' | 'reject', id: any) => {
      setIdTarget(id);
      if (status === 'approve') {
        setConfirmModal(true);
      } else {
        setRejectModal(true);
      }
    };

    const onReject = async (reason: any) => {
      handleCloseModal();
      startLoading();
      const data = {
        reason: reason
      };
      try {
        await PermissionService.onPermissionRequestReject(idTarget, data);
        notificationService.successNotification('ไม่อนุมัติสำเร็จ', '');
        await fetchData();
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification('ไม่อนุมัติไม่สำเร็จ', err.message);
      } finally {
        stopLoading();
      }
    };

    const handleCloseModal = () => {
      setConfirmModal(false);
      setRejectModal(false);
    };

    const handleOnAction = () => {
      handleCloseModal();
      fetchData();
    };

    const shiftDetails = data.rows as any;
    const shiftRepeatDetails = data.rows[0]?.shift_repeat_details as any;

    interface ShiftRepeatDetail {
      shift_repeat_type: 'work_at_company' | 'work_from_home';
      shift_repeat_every: 'every_week' | 'every_1_week' | 'every_2_weeks' | 'every_3_weeks';
      sunday: boolean;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
    }

    interface HolidayDetail {
      holiday_date: string;
      holiday_name: string;
    }

    interface ShiftDetail {
      start_date: string;
      shift_repeat_details: ShiftRepeatDetail[];
      shift_holiday_details: HolidayDetail[];
    }

    interface EmployeeShiftCalendarProps {
      shiftDetails: ShiftDetail[];
    }

    const [events, setEvents] = useState<Array<{ title: string; start: string }>>([]);

    useEffect(() => {
      generateEvents();
    }, [shiftDetails]);

    const isHoliday = (date: Date, holidays: HolidayDetail[]): HolidayDetail | null => {
      const dateString = date.toISOString().split('T')[0];
      for (let holiday of holidays) {
        if (dateString === new Date(holiday.holiday_date).toISOString().split('T')[0]) {
          return holiday; // Return the holiday object if the date matches
        }
      }
      return null; // Return null if no matching holiday
    };

    const generateEvents = () => {
      let tempEvents: Array<{ title: string; start: string; backgroundColor?: string; textColor?: string;}> = [];
      let processedHolidaysAndDaysOff: { [key: string]: boolean } = {}; // Track processed holidays and weekends

      shiftDetails?.forEach((detail: any) => {
        const startDate = new Date(detail.start_date);
        const endDate = detail.end_date ? new Date(detail.end_date) : new Date(new Date().setFullYear(new Date().getFullYear() + 5));

        detail.shift_repeat_details?.forEach((repeatDetail: any) => {
          let currentDate = new Date(startDate);
          while (currentDate <= endDate) {
            const dateString = currentDate.toISOString().split('T')[0];
            const dayOfWeek = currentDate.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

            const holidayMatch = isHoliday(currentDate, detail.shift_holiday_details);
            if (holidayMatch) {
              // Prioritize holidays over "Day Off"
              if (!processedHolidaysAndDaysOff[dateString]) {
                tempEvents.push({
                  //#C80C7D
                  backgroundColor: 'rgb(200, 12, 125, 0.1)',
                  textColor: 'rgb(200, 12, 125)',
                  title: `${holidayMatch.holiday_name}`,
                  start: dateString,
                });
                processedHolidaysAndDaysOff[dateString] = true;
              }
            } else if (dayOfWeek === 0 || dayOfWeek === 6) {
              // For weekends, add "Day Off" if not a holiday and not already processed
              if (!processedHolidaysAndDaysOff[dateString]) {
                tempEvents.push({
                  backgroundColor: 'rgb(255, 150, 27, 0.1)',
                  textColor: 'rgb(255, 150, 27)',
                  title: "Day Off",
                  start: dateString,
                });
                processedHolidaysAndDaysOff[dateString] = true;
              }
            } else if (shouldAddEvent(repeatDetail, currentDate, startDate)) {
              // On weekdays that are not holidays, add work time and type (WFH/WFO) as separate events
              if (!processedHolidaysAndDaysOff[dateString]) { // Check again to avoid duplicate entries on holidays
                const workTimeTitle = `${detail.work_time_start.substring(0, 5)}-${detail.work_time_end.substring(0, 5)}`;
                const workTypeTitle = repeatDetail.shift_repeat_type === 'work_from_home' ? "WFH" : "WFO";

                //#00BA34

                tempEvents.push({title: workTimeTitle, start: dateString, backgroundColor: 'rgb(0, 186, 52, 0.1)', textColor: 'rgb(0, 186, 52)'});
                tempEvents.push({title: workTypeTitle, start: dateString, backgroundColor: 'rgb(0, 186, 52, 0.1)', textColor: 'rgb(0, 186, 52)'});

                processedHolidaysAndDaysOff[dateString] = true; // Mark this date as processed
              }
            }
            currentDate.setDate(currentDate.getDate() + 1);
          }
        });
      });

      setEvents(tempEvents);
    };
    const shouldAddEvent = (detail: ShiftRepeatDetail, date: Date, startDate: Date): boolean => {
      const dayOfWeek = date.getDay();
      const mapping = [detail.sunday, detail.monday, detail.tuesday, detail.wednesday, detail.thursday, detail.friday, detail.saturday];


      if (!mapping[dayOfWeek]) {
        return false;
      }


      const start = startDate.getTime();
      const current = date.getTime();
      const diffInTime = current - start;
      const diffInWeeks = Math.floor(diffInTime / (1000 * 3600 * 24 * 7));

      switch (detail.shift_repeat_every) {
        case 'every_week':
          return true;
        case 'every_1_week':
          return diffInWeeks % 2 === 0;
        case 'every_2_weeks':
          return diffInWeeks % 3 === 0;
        case 'every_3_weeks':
          return diffInWeeks % 4 === 0;
        default:
          return false;
      }
    };

    const onClickEdit = (assignedEmployee: any) => {
       if (data.rows[0]?.shift_name.includes('(รายบุคคล)')) {
         navigate(`/employee-shift/edit/${data.rows[0]?.id}?isPersonelEdit=true&isAlredyPersonelShift=true&userId=${data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.id)}`);
       } else {
         navigate(`/employee-shift/edit/${data.rows[0]?.id}?isPersonelEdit=true&userId=${data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.id)}`);
       }
    }

  const displayModal = () => {
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติขอนี้'}
        onHide={handleCloseModal}
        onConfirm={(reason: any)=>{handleSubmit(true, reason)}}
      />;
    }
    return;
  };

    return <div>
      {displayModal()}
      <div className="d-flex justify-content-between py-4">
        <h2 className="text-mof-primary m-0">{!isEdit ? "ตารางกะ" : "ตารางกะรายบุคคล"}</h2>
      </div>
      <div className="d-flex flex-row column-gap-5">
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.code)}</div>
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.prefix_name_th + " " + item?.user?.employee?.firstname_th + " " + item?.user?.employee?.lastname_th)}</div>
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.current_job_position?.pay_account_number)}</div>
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.current_job_position?.position_name)}</div>
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.current_job_position?.structure_departments_name)}</div>
        <div>{data.rows[0]?.shift_employee_assignment_details?.map((item: any) => item?.user?.employee?.current_job_position?.division_group_office_name)}</div>
      </div>
      <hr/>
      {isEdit && data.rows[0]?.status !== 'waiting_for_approver' &&
          <Button variant="warning" className="btn-action" onClick={()=> {onClickEdit(data.rows[0]?.shift_employee_assignment_details)}} style={{width: "250px"}}>
              <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
              แก้ไขตารางกะรายบุคคล
          </Button>}
      {loaderComponent}
      <div className="col">
        <div className="col">

            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-lg-4 mb-3">
                <Button variant={null === 'information' ? 'outline-info' : 'info'}
                        onClick={() => {
                          // setCurrentTab('holiday')
                        }}
                        className={`w-100 btn-action ${null === 'information' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
                  <div>
                    วันหยุดประจำปี
                  </div>
                </Button>
                <div className='mt-3'>
                  {data.rows[0]?.shift_holiday_details.map((item, index) => {
                    return <HolidayDetailSection
                      key={index}
                      date={new Date(item?.holiday_date)?.toISOString().slice(0, 10) || ''}
                      description={item?.holiday_name}
                      componentWidth={100}
                    />
                  })}
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-8">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  // events={[
                  //   { title: 'event 1', date: new Date() },
                  //   { title: 'event 2', date: new Date() }
                  // ]}
                  eventContent={renderEventContent}
                  events={events}
                  eventColor={'rgba(255, 255, 255, 0)'}
                />
              </div>

            {<div className="row mt-5 mb-5">
              <div className="col-3 col-xs-1 col-md-0 col-sm-0 col-xl-6"></div>
              <div
                className="d-flex flex-row col-6 col-xs-12 col-sm-12 col-md-12 col-xl-6 justify-content-evenly column-gap-3">
                <div className="" style={{marginLeft: "164px"}}>
                  <Button variant="secondary" type="button"
                          onClick={() => {
                            navigate(-1)
                          }}
                          className="d-block btn-action"
                  >
                    ย้อนกลับ
                  </Button>
                </div>
                {data.rows[0]?.status === "waiting_for_approver" && isEdit && data.rows[0]?.approver_action_user_id === currentUserId || data.rows[0]?.status === "waiting_for_approver" && isEdit && isAdmin ? <>
                  <div className="">
                    <Button type="button"
                            onClick={() => {
                              setRejectModal(true)
                            }}
                            className="d-block btn-action"
                            style={{backgroundColor: "red", border: "1px solid red"}}
                    >
                      ไม่อนุมัติ
                    </Button>
                  </div>
                  <div className="" style={{marginRight: "164px"}}>
                    <Button type="button"
                            onClick={() => {
                              handleSubmit(false)
                            }}
                            className="d-block btn-action"
                            style={{backgroundColor: "green", border: "1px solid green"}}
                    >
                      อนุมัติ
                    </Button>
                  </div>
                </> : null}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>;
  }
;

function renderEventContent(eventInfo: any) {
  return (
    // <div style={{border:"1px solid red", width:"100%", margin:"0 8px 0 8px", borderRadius:"8px"}}>
    <div style={{backgroundColor:eventInfo.event.backgroundColor, textAlign:"center"}}>
      <i style={{color:eventInfo.event.type === "dayoff" ? "rgb(255, 150, 27)" : "" , fontWeight:"600", whiteSpace: "pre-line"}}>{eventInfo.event.title}</i>
    </div>
  )
}

export default EmployeeShiftPersonelPage;
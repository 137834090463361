import React, { FC, useState } from 'react';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import { GetPersonnelModel } from '../../../../shared/models/organizational-structure.model';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    deletePersonnel,
    getCurrentOrganizationalStructure,
    getCurrentOrganizationalStructureAction
} from '../../../../shared/services/organizational-structure-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { PersonnelFieldAction } from '../../../../shared/states/organizational-structure-reducer';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';

interface PersonnelFormProps {
    rows: GetPersonnelModel[];
    allowDelete: boolean;
}

interface PersonnelRowProps extends GetPersonnelModel {
    disable: boolean;
    allowDelete: boolean;
}

const PersonnelForm: FC<PersonnelFormProps> = ({rows, allowDelete = false}) => {
    const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
    const disable = organizationalStructureAction.status === 'view';

    const tableRowNoData = () => {
        if (rows.length === 0) {
            return <TableRowNoData colspan={8}/>;
        }
        return null;
    };


    return (
        <div className="overflow-auto">
            <Table bordered>
                <thead>
                <tr className="border-0">
                    <th>รหัสพนักงาน</th>
                    <th>ชื่อ - นานสกุล</th>
                    <th>เลขบัญชีถือจ่าย</th>
                    <th>ตำแหน่ง</th>
                    <th>แผนก</th>
                    <th>กอง / กลุ่ม / สำนักงาน</th>
                    <th>ฝ่าย / สำนัก</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((data, index) => {
                        return <PersonnelRow key={index}
                                             {...data}
                                             disable={disable}
                                             allowDelete={allowDelete}/>;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
    );
};

const PersonnelRow: FC<PersonnelRowProps> = ({
                                                 id,
                                                 structure_position_id,
                                                 structure_position_index,
                                                 user_id,
                                                 employee,
                                                 disable = false,
                                                 allowDelete
                                             }) => {
    const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const getStructurePosition = () => {
        if (structure_position_index === null) {
            return;
        }

        const structurePosition = organizationalStructure.structure_positions[structure_position_index as number];
        const structureDepartment = organizationalStructure.structure_departments[structurePosition?.structure_department_index as number];
        const divisionGroupOffice = organizationalStructure.division_group_offices[structureDepartment?.division_group_office_index as number];
        const departmentOffice = organizationalStructure.department_offices[divisionGroupOffice?.department_office_index as number];

        return {
            structure_position: structurePosition,
            structure_department: structureDepartment,
            division_group_office: divisionGroupOffice,
            department_office: departmentOffice,
        };
    };

    const mappingData = getStructurePosition();

    const handleDelete = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmDelete = async () => {
        const findPersonnel = organizationalStructure.personnels.findIndex(data => data.user_id === user_id);
        if (findPersonnel === -1) {
            return;
        }

        const deleteIndex: PersonnelFieldAction = {
            index: findPersonnel
        };
        setShowModal(false);
        deletePersonnel(deleteIndex, dispatch);
    };

    const deleteButton = () => {
        if (!disable && allowDelete) {
            return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
        }
        return null;
    };

    const loadModal = () => {
        if (!disable && allowDelete) {
            return <DeleteConfirmationModal
                show={showModal}
                title={'ยืนยันการลบบุคลากร'}
                description={'คุณแน่ใจหรือไม่ว่าต้องการลบบุคลากรนี้'}
                onHide={handleCloseModal}
                onConfirm={handleConfirmDelete}
            />;
        }
        return null;
    };

    return (
        <tr className="bg-white">
            <td>{employee.code} {user_id}</td>
            <td>{employee.prefix_name_th} {employee.firstname_th} {employee.lastname_th}</td>
            <td>{mappingData?.structure_position?.pay_account_number || '-'}</td>
            <td>{mappingData?.structure_position?.name || '-'}</td>
            <td>{mappingData?.structure_department?.name || '-'}</td>
            <td>{mappingData?.division_group_office?.name || '-'}</td>
            <td>{mappingData?.department_office?.name || '-'}</td>
            <td>{deleteButton()}</td>
            {loadModal()}
        </tr>
    );
};

export default PersonnelForm;
import React, { FC, useEffect, useState } from 'react';
import { formatToThaiDate } from '../../utils/functions-date-time';
import Calendar from 'react-calendar';
import { LooseValue, Range } from 'react-calendar/dist/cjs/shared/types';
import ClickOutsideDetector from '../click-outside-detector';

export interface DateRangeInputChange {
    startDate: Date | null;
    endDate: Date | null;
}

interface DateRangeInputProps {
    startDate: Date | null;
    endDate: Date | null;
    disable: boolean;
    onChange: (value: DateRangeInputChange) => void;
    isNoEndDate?: boolean;
    isError?: boolean;
}

const DateRangeInput: FC<DateRangeInputProps> = ({startDate, endDate, disable, onChange, isNoEndDate = false, isError}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateRangeThaiFormat, setDateRangeThaiFormat] = useState<LooseValue>([startDate, endDate]);

    useEffect(() => {
        const date = dateRangeThaiFormat as Range<Date>;
        if (startDate !== date[0] && endDate !== date[1]) {
            setDateRangeThaiFormat([startDate, endDate]);
        }
    }, [onChange]);

    const handleThaiDateInputClick = () => {
        if (disable) {
            return;
        }
        // const date = dateRangeThaiFormat as Range<Date>;
        // if (date[0] === null) {
        //     setDateRangeThaiFormat([new Date(), new Date()]);
        // }
        setShowCalendar(true);
    };

    const handleDateRangeSelection = (value: LooseValue) => {
      console.log('value', value)
        if (Array.isArray(value)) {
            setDateRangeThaiFormat(value);
            const date = value as Range<Date>;
            if (date.length === 2) {
                onChange({
                    startDate: date[0] || null,
                    endDate: date[1] || null,
                });
            }
        } else {
          setDateRangeThaiFormat(value);
          const date = value as any;
          if (date) {
            onChange({
              startDate: date,
              endDate: null,
            });
          }
        }
        setShowCalendar(false);
    };

    const getValue = (isNoEndDate:boolean) => {
        const date = dateRangeThaiFormat as Range<Date>;
        const emptyDate = date.length === 2 && date[0] === null && date[1] === null;
        return !emptyDate
            ? `${date[0] && formatToThaiDate(date[0])} - ${isNoEndDate ? 'ไม่สิ้นสุด' : date[1] ? formatToThaiDate(date[1]) : ''}`
            : '';
    };

    const onClickOutsideCalendar = () => {
        setShowCalendar(false);
    };

    return (
        <>
            <div
                className={`form-control text-wrap pointer ${disable ? 'disable' : ''} ${isError ? "border-danger" : ''}`}
                onClick={handleThaiDateInputClick}
            >
             <div className="d-flex flex-row justify-content-between align-items-center">
               <div>
                 {getValue(isNoEndDate) ? getValue(isNoEndDate) : 'วัน/เดือน/ปี - วัน/เดือน/ปี'}
               </div>
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16" opacity="0.7" style={{pointerEvents:"none"}}>
                 <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
               </svg>
             </div>
            </div>
            {showCalendar && (
                <ClickOutsideDetector onClickOutside={onClickOutsideCalendar}>
                    <div className="position-absolute calendar-index">
                        <Calendar
                            locale={'th'}
                            selectRange={!isNoEndDate}
                            value={dateRangeThaiFormat}
                            onChange={handleDateRangeSelection}
                        />
                    </div>
                </ClickOutsideDetector>
            )}
        </>
    );
};


export default DateRangeInput;

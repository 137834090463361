import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PageNotFound: FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/');
    };

    return <div className="container">
        <div className="row col-12">
            <h1 className="text-center text-secondary">ไม่พบหน้านี้</h1>
        </div>
        <div className="row col-8 m-auto">
            <img className="img-fluid" src="/static/undraw_page_not_found.svg" alt="page not found svg"/>
        </div>
        <div className="d-flex justify-content-center mt-3">
            <Button variant="primary" type="button" className="btn-action-height rounded-5 px-5" onClick={handleBack}>
                กลับไปหน้าหลัก
            </Button>
        </div>
    </div>;
};

export default PageNotFound;
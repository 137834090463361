import React, { FC } from 'react';
import { Table } from 'react-bootstrap';

interface LeaveHistoryRowProps {
    school_name: string | null;
    graduation_level: string | null;
    graduation_major: string | null;
    graduation_year: string | null;

    onEdit: boolean;
}


const LeaveHistoryForm: FC = () => {
    const onEdit = true;

    const LeaveHistoryData = [
        {
            school_name: 'มหาวิทยาลัยรังสิต',
            graduation_level: 'ปริญญาตรี',
            graduation_major: 'วิทยาการคอมพิวเตอร์',
            graduation_year: '2562',
        }
    ];

    return (
        <div className="overflow-auto">
            <Table bordered>
                <thead>
                <tr className="border-0">
                    <th>วันที่ขอ</th>
                    <th>วันที่อนุมัติ</th>
                    <th>ประเภท</th>
                    <th>เริ่ม</th>
                    <th>สิ้นสุด</th>
                    <th>วันลาทั้งหมด</th>
                    <th>วันลาคงเหลือ</th>
                </tr>
                </thead>
                <tbody>
                {
                    LeaveHistoryData.map((data, index) => {
                        return <LeaveHistoryRow
                            key={index}
                            school_name={data.school_name}
                            graduation_level={data.graduation_level}
                            graduation_major={data.graduation_major}
                            graduation_year={data.graduation_year}
                            onEdit={onEdit}
                        />;
                    })
                }
                </tbody>
            </Table>
        </div>
    );
};

const LeaveHistoryRow: FC<LeaveHistoryRowProps> = ({
                                                       school_name,
                                                       graduation_level,
                                                       graduation_major,
                                                       graduation_year,
                                                       onEdit = false
                                                   }) => {
    return (
        <tr className="bg-white">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
    );
};

export default LeaveHistoryForm;
import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import InputSelect from "../../../shared/components/atoms/input-select";
import DateInput from "../../../shared/components/atoms/date-input";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCheckCircle, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Autocomplete, TextField} from "@mui/material";
import {
  allowToUseLeaveOptions, carryOverOptions,
  employeeTypeOptions, genderOptions,
  minimumLeaveOptions,
  organizationLevelOptions,
  workPeriodOptions
} from "../const/leave-setting";
import {LeaveRequestService} from "../../../shared/services/leave-request";
import {notificationService} from "../../../shared/services/notification-service";
import {convertDateToString} from "../../../shared/utils/functions-date-time";
import {AxiosError} from "axios";
import {LeaveRequestQueryParams} from "../../../shared/models/leave-request.model";
import useSpinnerLoader from "../../../shared/components/spinner-loader";

type PagePropTypes = {
  isCreatePage?: boolean
  isViewPage?: boolean
}

const LeaveSettingSections = ({isCreatePage, isViewPage: isView}: PagePropTypes) => {
  const naviagte = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParamId = searchParams.get('id');
  const isEditPage = searchParams.get('isEdit') === 'true';
  const [currentTab, setCurrentTab] = React.useState<"leaveType" | "leaveMain">('leaveType');
  const [submitted, setSubmitted] = useState(false);

  const isViewPage = isView && !isCreatePage && !isEditPage

  const [leaveLimitByTypeDetailId, setLeaveLimitByTypeDetailId] = useState<number | null>(null)

  const [employeeLevel, setEmployeeLevel] = React.useState<any>([]);
  const [leaveMainSettingData, setLeaveMainSettingData] = React.useState<{
    employee_type: string,
    work_period: string,
    total_day_allow: string | number,
    comment: string,
    employee_level: { employee_level: string }[]
  }[]>([
    {
      employee_type: "",
      work_period: "",
      total_day_allow: "",
      comment: "",
      employee_level: []
    },
  ]);

  const [leaveTypeSettingData, setleaveTypeSettingData] = React.useState<any>({
    leave_type_name: "",
    include_dayoff: false,
    minimum_leave: "",
    allow_to_use_leave: "",
    carry_over: "",
    carry_over_period: null,
    gender: "",
    condition: '',
    is_retro_pattern: false,
    is_leave_in_advance: false
  })

  const handleSubmit = async (isViewPage?: boolean) => {
    setSubmitted(true);
    const data: any = {
      leave_limit_by_type: leaveTypeSettingData,
      leave_limit_by_type_detail: leaveMainSettingData,
    }
    if (!isViewPage) {
      data.leave_limit_by_type_id = parseInt(queryParamId ? queryParamId : "")
      data.leave_limit_by_type_detail_id = leaveLimitByTypeDetailId
    }
    try {
      let response: any = null
      if (isCreatePage) {
        response = await LeaveRequestService.createLeaveLimit(data)
      } else {
        response = await LeaveRequestService.updateLeaveLimit(data)
      }
      if (response) {
        notificationService.successNotification("บันทึกข้อมูลสำเร็จ", "")
        naviagte("/employee-leaves/leave-setting")
      }
    } catch (e) {
      notificationService.dangerNotification("บันทึกข้อมูลไม่สำเร็จ", "")
    }
  }

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const [queryParams, setQueryParams] = useState({
    request_id: '',
    leave_type: '',
    status: '',
    search: '',
    page: 1,
    page_size: 10,
    id: queryParamId,
  });

  const fetchData = async () => {
    startLoading();
    try {
      const result = await LeaveRequestService.getLeaveLimitByTypeById(queryParams)
      // setData(result);
      const leaveLimitByTypeData = result.rows?.map((item: any) => {
        return {
          leave_type_name: item.leave_type_name,
          include_dayoff: item.include_dayoff,
          minimum_leave: item.minimum_leave,
          allow_to_use_leave: item.allow_to_use_leave,
          carry_over: item.carry_over,
          carry_over_period: item.carry_over_period,
          gender: item.gender,
          condition: item.condition,
          is_retro_pattern: item.is_retro_pattern,
          is_leave_in_advance: item.is_leave_in_advance
        }
      })
      if (leaveLimitByTypeData.length > 0) {
        setleaveTypeSettingData(leaveLimitByTypeData[0])
      }

      const leaveMainSettingData = result.rows?.flatMap((item:any) =>
        item.leave_limit_by_type_details?.map((detail:any) => ({
          id: detail.id,
          employee_type: detail.employee_type,
          work_period: detail.work_period,
          total_day_allow: parseInt(detail.total_day_allow),
          comment: detail.comment,
          employee_level: detail.leave_limit_employee_level_details?.map((level:any) => ({
            employee_level: level.employee_level,
            id: level.id
          })),
        }))
      );

      if (leaveMainSettingData && leaveMainSettingData.length > 0) {
        setLeaveMainSettingData(leaveMainSettingData);
      } else {
        setLeaveMainSettingData([{
          employee_type: "",
          work_period: "",
          total_day_allow: "",
          comment: "",
          employee_level: []
        }]);
      }

    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onClickAddLeaveMainDetailData = () => {
    setLeaveMainSettingData((prevState) => {
      const newState = [...prevState];
      newState.push({
        employee_type: "",
        work_period: "",
        total_day_allow: "",
        comment: "",
        employee_level: []
      });
      return newState;
    });
  }

  const getEmployeeLevelOptions = (index: number) => {
    const employeeLevelOptions = leaveMainSettingData[index]?.employee_level.map((item: any) => {
      const foundOption = organizationLevelOptions?.find((option: any) => option.value === item.employee_level);
      return {
        value: item.employee_level,
        label: foundOption ? foundOption.label : ''
      }
    })
    return employeeLevelOptions
  }


  useEffect(() => {
    if (isViewPage || isEditPage) {
      fetchData();
    } else {
      setleaveTypeSettingData({
        leave_type_name: "",
        include_dayoff: false,
        minimum_leave: "",
        allow_to_use_leave: "",
        carry_over: "",
        carry_over_period: null,
        gender: "",
        condition: '',
        is_retro_pattern: false,
        is_leave_in_advance: false
      })
      setLeaveMainSettingData([
        {
          employee_type: "",
          work_period: "",
          total_day_allow: "",
          comment: "",
          employee_level: []
        }
      ])
      setEmployeeLevel([])
    }
  }, [isViewPage, isCreatePage]);

useEffect(()=>{
  if (leaveTypeSettingData.carry_over === "ยกวันลาคงเหลือไม่ได้") {
    setleaveTypeSettingData({...leaveTypeSettingData, carry_over_period: null})
  }
},[leaveTypeSettingData.carry_over])

  return (
    <div className='py-4'>
      {loaderComponent}
      <h2 className="text-mof-primary m-0">{isCreatePage ? "เพิ่มวันลา" : "ตั้งค่าวันลา"}</h2>
      <div className="row mt-3">
        <div className="row mt-3">
          <div className="col-4 col-md-4">
            <Button variant={currentTab === 'leaveType' ? 'outline-info' : 'info'}
                    onClick={() => {
                      setCurrentTab('leaveType')
                    }}
                    className={`w-100 btn-action ${currentTab === 'leaveType' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div>
                ข้อมูลประเภทการลา
              </div>
            </Button>
          </div>
          <div className="col-4 col-md-4">
            <Button variant={currentTab === 'leaveMain' ? 'outline-info' : 'info'}
                    onClick={() => {
                      setCurrentTab('leaveMain')
                    }}
                    className={`w-100 btn-action ${currentTab === 'leaveMain' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div>
                ตั้งค่าวันลา
              </div>
            </Button>
          </div>
        </div>
      </div>

      {currentTab === 'leaveMain' ?
       leaveMainSettingData?.map((item, index) => {
         return (
           <div key={index}>
             <div className="row mt-3">
               <div className='d-flex flex-row justify-content-end mb-2'>
                 {!isViewPage && <FontAwesomeIcon icon={faTrashCan} className="me-2 pointer" onClick={() => {
                   setLeaveMainSettingData((prevState) => prevState.filter((_, i) => i !== index));
                 }}/>}
               </div>
               <div className={`col-sm-12 col-md-8`}>
                 <label className="form-label required">ระดับพนักงาน</label>
                 <Autocomplete
                   multiple
                   // limitTags={2}
                   id="multiple-limit-tags"
                   options={organizationLevelOptions || []}
                   getOptionLabel={(option: any) => option.label}
                   value={getEmployeeLevelOptions(index)}
                   renderInput={(params) => (
                     <TextField {...params} label="ระดับพนักงาน" placeholder="ระดับพนักงาน" size="small"/>
                   )}
                   onChange={(event, value) => {
                     const organizationLevel = value.map((item: any) => {
                       return {employee_level: item.value}
                     })
                      setLeaveMainSettingData((prevState) => {
                        const newState = [...prevState];
                        newState[index].employee_level = organizationLevel;
                        return newState;
                      });
                   }}
                   disabled={isViewPage}
                 />
               </div>
               <div className={`col-sm-12 col-md-4`}>
                 <label className="form-label required">ประเภทพนักงาน</label>
                 <InputSelect options={employeeTypeOptions} placeholder="กรุณาเลือกประเภทพนักงาน" name="is_active"
                              onChange={(e) => {
                                setLeaveMainSettingData((prevState) => {
                                  const newState = [...prevState];
                                  newState[index].employee_type = e.value.value;
                                  return newState;
                                });
                              }} required={true}
                              isValidateError={false} isLeaveRequestPage={true} isDisable={isViewPage}
                              value={item.employee_type}/>
                 {null === undefined || null === "" && submitted ?
                   <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                     กรุณาเลือกประเภทพนักงาน
                   </div> : null}
               </div>
             </div>
             <div className="row mt-3">
               <div className="col-sm-12 col-md-4">
                 <label className="form-label required" style={{fontFamily: "initial"}}>ระยะเวลาการปฎิบัติงาน</label>
                 <InputSelect options={workPeriodOptions} placeholder="ระยะเวลาการปฎิบัติงาน" name="is_active"
                              onChange={(e) => {
                                setLeaveMainSettingData((prevState) => {
                                  const newState = [...prevState];
                                  newState[index].work_period = e.value.value;
                                  return newState;
                                });
                              }} required={true}
                              isValidateError={false} isLeaveRequestPage={true} value={item.work_period} isDisable={isViewPage}/>
                 {null === undefined || null === "" && submitted ?
                   <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                     กรุณาระยะเวลาการปฎิบัติงาน
                   </div> : null}
               </div>
               <div className={`col-sm-12 col-md-4`}>
                 <label className="form-label required">จำนวนวัน/ปี</label>
                 <input type="number" className="form-control" name="shift_name"
                        placeholder="จำนวนวัน/ปี" value={item.total_day_allow} required={true}
                        onChange={(e) => {
                          setLeaveMainSettingData((prevState) => {
                            const newState = [...prevState];
                            newState[index].total_day_allow = parseInt(e.target.value);
                            return newState;
                          });
                        }}
                        disabled={isViewPage}
                 />
                 <div className="invalid-feedback">
                   กรุณากรอกจำนวนวัน/ปี
                 </div>
               </div>
               <div className={`col-sm-12 col-md-4 mb-3`}>
                 <label className="form-label">หมายเหตุ</label>
                 <input type="text" className="form-control" name="shift_name"
                        placeholder="หมายเหตุ" value={item.comment}
                        onChange={(e) => {
                          setLeaveMainSettingData((prevState) => {
                            const newState = [...prevState];
                            newState[index].comment = e.target.value;
                            return newState;
                          });
                        }}
                        disabled={isViewPage}
                 />
                 <div className="invalid-feedback">
                   กรุณากรอกหมายเหตุ
                 </div>
               </div>
             </div>
             <hr className="mt-3" style={{ color:"black", backgroundColor:"black", opacity:"0.3"}}/>
           </div>
         )
       })


        :
        <>
          <div className="row mt-3">
            <div className={`col-sm-12 col-md-8 pb-3`}>
              <label className="form-label required">ประเภทการลา</label>
              <input type="text" className="form-control" name="shift_name"
                     placeholder="ชื่อประเภทการลา" value={leaveTypeSettingData.leave_type_name} required={true}
                     onChange={(e) => {
                       setleaveTypeSettingData({...leaveTypeSettingData, leave_type_name: e.target.value})
                     }}
                      disabled={isViewPage}
              />
              <div className="invalid-feedback">
                กรุณากรอกประเภทการลา
              </div>
            </div>
           <div className="row">
             <div className="col-sm-12 col-md-4">
               <div className="form-check mt-3" style={{marginLeft: "12px"}}>
                 <input className="form-check-input" type="checkbox"
                        value={leaveTypeSettingData.include_dayoff ? 1 : 0}
                        defaultChecked={leaveTypeSettingData.include_dayoff}
                        checked={leaveTypeSettingData.include_dayoff}
                        name="flexCheckDefault"
                        onChange={(e) => {
                          setleaveTypeSettingData({...leaveTypeSettingData, include_dayoff: e.target.checked})
                        }}
                        disabled={isViewPage}
                        id="include"/>
                 <label className="form-check-label pointer" htmlFor="include">
                   นับรวมวันหยุดและนักขัตฤกษ์
                 </label>
               </div>
             </div>
             <div className="col-sm-12 col-md-4">
               <div className="form-check mt-3" style={{marginLeft: "12px"}}>
                 <input className="form-check-input" type="checkbox"
                        value={leaveTypeSettingData.is_retro_pattern ? 1 : 0}
                        defaultChecked={leaveTypeSettingData.is_retro_pattern}
                        checked={leaveTypeSettingData.is_retro_pattern}
                        name="flexCheckDefault"
                        onChange={(e) => {
                          setleaveTypeSettingData({...leaveTypeSettingData, is_retro_pattern: e.target.checked})
                        }}
                        disabled={isViewPage}
                        id="is_retro_pattern"/>
                 <label className="form-check-label pointer" htmlFor="is_retro_pattern">
                   ลาย้อนหลังได้ 7 วัน
                 </label>
               </div>
             </div>
             <div className="col-sm-12 col-md-4"></div>
             <div className="col-sm-12 col-md-4">
               <div className="form-check mt-3" style={{marginLeft: "12px"}}>
                 <input className="form-check-input" type="checkbox"
                        value={leaveTypeSettingData.is_leave_in_advance ? 1 : 0}
                        defaultChecked={leaveTypeSettingData.is_leave_in_advance}
                        checked={leaveTypeSettingData.is_leave_in_advance}
                        name="flexCheckDefault"
                        onChange={(e) => {
                          setleaveTypeSettingData({...leaveTypeSettingData, is_leave_in_advance: e.target.checked})
                        }}
                        disabled={isViewPage}
                        id="is_leave_in_advance"/>
                 <label className="form-check-label pointer" htmlFor="is_leave_in_advance">
                   อนุญาติให้ลาล่วงหน้า
                 </label>
               </div>
             </div>
           </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-md-4 mt-3">
              <label className="form-label required" style={{fontFamily: "initial"}}>อนุญาติให้ลาขั้นต่ำ</label>
              <InputSelect options={minimumLeaveOptions} placeholder="กรุณาเลือกอนุญาติให้ลาขั้นตำ" name="is_active"
                           onChange={(e) => {
                             setleaveTypeSettingData({...leaveTypeSettingData, minimum_leave: e.value.value})
                           }} required={true}
                           isValidateError={false} isLeaveRequestPage={true}
                           value={leaveTypeSettingData.minimum_leave}
                           isDisable={isViewPage}
              />
              {null === undefined || null === "" && submitted ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกอนุญาติให้ลาขั้นตำ
                </div> : null}
            </div>
            <div className="col-sm-12 col-md-4 mt-3">
              <label className="form-label required">เริ่มใช้วันลา</label>
              <InputSelect options={allowToUseLeaveOptions} placeholder="กรุณาเลือกเริ่มใช้วันลา" name="is_active"
                           onChange={(e) => {
                             setleaveTypeSettingData({...leaveTypeSettingData, allow_to_use_leave: e.value.value})
                           }} required={true}
                           isValidateError={false} isLeaveRequestPage={true}
                           value={leaveTypeSettingData.allow_to_use_leave}
                           isDisable={isViewPage}
              />
              {null === undefined || null === "" && submitted ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกเริ่มใช้วันลา
                </div> : null}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-4 mt-3">
              <label className="form-label required">ยกวันคงเหลือ</label>
              <InputSelect options={carryOverOptions} placeholder="กรุณาเลือกยกวันคงเหลือ" name="is_active"
                           onChange={(e) => {
                             const value = e.value.value
                             if (value) {
                               setleaveTypeSettingData({...leaveTypeSettingData, carry_over: value})
                             }
                           }} required={true} isDisable={isViewPage}
                           isValidateError={false} isLeaveRequestPage={true} value={leaveTypeSettingData.carry_over}/>
              {null === undefined || null === "" && submitted ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกยกวันคงเหลือ
                </div> : null}
            </div>
            <div className="col-sm-12 col-md-4 mt-3">
              <label className="form-label">วันคงเหลือยกมาใช้ได้ถึงวันที่</label>
              <DateInput date={leaveTypeSettingData?.carry_over_period && new Date(leaveTypeSettingData?.carry_over_period)}
                         onChange={(e) => {
                           if (e !== null) {
                             setleaveTypeSettingData({
                               ...leaveTypeSettingData,
                               carry_over_period: e ? convertDateToString(new Date(e)) : ''
                             })
                           }
                         }}
                         required={true}
                         minDate={new Date()}
                         placeHolder={leaveTypeSettingData ? "วันคงเหลือยกมาใช้ได้ถึงวันที่" : ""}
                // maxDate={}
                         disable={isViewPage as boolean || leaveTypeSettingData.carry_over === "ยกวันลาคงเหลือไม่ได้"}
              />
              <input type="date" className="form-control" name="birthday"
                     placeholder="" disabled={false} hidden={true}
                     value={(new Date().toISOString().slice(0, 10)) || ''}
                     required={true}
                     onChange={() => {
                     }}/>
              <div className="invalid-feedback">
                กรุณาเลือกวันคงเหลือยกมาใช้ได้ถึงวันที่
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-md-4">
              <label className="form-label required" style={{fontFamily: "initial"}}>เพศ</label>
              <InputSelect options={genderOptions} placeholder="เพศ" name="is_active"
                           onChange={(e) => {
                             setleaveTypeSettingData({...leaveTypeSettingData, gender: e.value.value})
                           }} required={true}
                           isValidateError={false} isLeaveRequestPage={true} value={leaveTypeSettingData.gender} isDisable={isViewPage}/>
              {null === undefined || null === "" && submitted ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกเพศ
                </div> : null}
            </div>
            <div className={`col-sm-12 col-md-4`}>
              <label className="form-label">เงื่อนไข</label>
              <textarea className="form-control" name="shift_name"
                     placeholder="เงื่อนไข" value={leaveTypeSettingData.condition} required={true}
                     onChange={(e) => {
                       setleaveTypeSettingData({...leaveTypeSettingData, condition: e.target.value})
                     }}
                     disabled={isViewPage}
              />
              <div className="invalid-feedback">
                กรุณากรอกเงื่อนไข
              </div>
            </div>
          </div>
        </>
      }
      {
        currentTab === 'leaveMain' && !isViewPage &&
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4 mt-3"
                                               onClick={()=>{onClickAddLeaveMainDetailData()}}>
              <FontAwesomeIcon icon={faPlus} className="me-2"/>
              เพิ่มข้อมูล
          </Button>
      }
      <div className="d-flex justify-content-end mt-5">
        <div className="d-flex flex-row">
          <Link to="/employee-leaves/leave-setting">
            <Button variant="primary" className="btn-action me-2 btn-mof-primary"
                    onClick={() => {
                    }}>
              <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
              ย้อนกลับ
            </Button>
          </Link>
          {!isViewPage && <Button variant="primary" type="button"
                   onClick={() => {
                     handleSubmit(isViewPage)
                   }}
                   className="d-block btn-action"
                   style={{background: "background: #007BFF"}}
                   disabled={false}
          >
            บันทึก
          </Button>}
        </div>
      </div>
    </div>
  );
};

export default LeaveSettingSections;

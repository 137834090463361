import React, { FC, FormEvent, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import TableRowNoData from '../../../shared/components/molecules/commons/table-row-no-data';
import { OrganizationalStructureModelGet } from '../../../shared/models/organizational-structure.model';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCopy, faPenToSquare, faSquareUpRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import {
  apiOrganizationalStructureService,
  updateOrganizationalStructureStatus,
  updateOrganizationalStructureType
} from '../../../shared/services/organizational-structure-service';
import { useDispatch } from 'react-redux';
import DeleteConfirmationModal from '../../../shared/components/molecules/modals/delete-confirmation-modal';
import { AxiosError } from 'axios';
import { notificationService } from '../../../shared/services/notification-service';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { getStatusName, OrganizationalStructureStatusType } from '../functions/utils';
import { useAuth } from '../../../shared/authentications/auth-context';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import TableHeaderOrdering, { Header, OrderData } from '../../../shared/components/table-header-ordering';
import InputReasonModal from '../../../shared/components/molecules/modals/input-reason-modal';
import { hasPermission } from '../../../shared/utils/roles';

interface OrganizationalStructuresTableProps {
  rows: any[];
  onRefresh: () => void;
  onChangeOrder: (orderData: OrderData) => void;
}

interface OrganizationalStructuresRowProps extends OrganizationalStructureModelGet {
  isAdmin: boolean;
  owner: boolean;
  onDelete: (id: number) => void;
  onDuplicate: (id: number) => void;
  onSendApprove: (id: number) => void;
  onReject: (id: number) => void;
  onApprove: (id: number) => void;
  onTerminate: (id: number) => void;
}

interface OrganizationalStructuresAction {
  showConfirmModal: boolean,
  type: null | 'duplicate' | 'publish' | 'reject' | 'approve' | 'terminate'
}

const OrganizationalStructuresTable: FC<OrganizationalStructuresTableProps> = ({rows, onRefresh, onChangeOrder}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [action, setAction] = useState<OrganizationalStructuresAction>({
    showConfirmModal: false,
    type: null
  });
  const [currentId, setCurrentId] = useState<number>();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();

  const {authJwt, permission} = useAuth();
  const isAdmin = authJwt?.role === 'ADMIN';

  const tableRowNoData = () => {
    if (rows.length === 0) {
      return <TableRowNoData colspan={5}/>;
    }
    return null;
  };

  const handleOnDelete = (id: number) => {
    setCurrentId(id);
    setShowDeleteModal(true);
  };

  const handleOnDuplicate = (id: number) => {
    setCurrentId(id);
    setAction({
      showConfirmModal: true,
      type: 'duplicate'
    });
  };

  const handleOnSendApprove = (id: number) => {
    setCurrentId(id);
    setAction({
      showConfirmModal: true,
      type: 'publish'
    });
  };

  const handleOnReject = (id: number) => {
    setCurrentId(id);
    setAction({
      showConfirmModal: true,
      type: 'reject'
    });
  };

  const handleOnApprove = (id: number) => {
    setCurrentId(id);
    setAction({
      showConfirmModal: true,
      type: 'approve'
    });
  };

  const handleOnTerminate = (id: number) => {
    setCurrentId(id);
    setAction({
      showConfirmModal: true,
      type: 'terminate'
    });
  };

  const handleConfirmDelete = async () => {
    if (currentId) {
      startLoading();
      try {
        const result = await apiOrganizationalStructureService.deleteOrganizationalStructure(currentId);
        notificationService.successNotification('ลบข้อมูลสำเร็จ', '');
        onRefresh();
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification('ลบข้อมูลไม่สำเร็จ', err.message);
      } finally {
        stopLoading();
        setShowDeleteModal(false);
      }
    }
  };

  const handleConfirmActionModal = async (reason: string = '-') => {
    if (currentId) {
      let messageSuccess = '';
      let messageError = '';
      let service;

      if (action.type === 'duplicate') {
        messageSuccess = 'ทำสำเนาสำเร็จ';
        messageError = 'ทำสำเนาข้อมูลไม่สำเร็จ';
        service = apiOrganizationalStructureService.duplicateOrganizationalStructure(currentId);
      } else if (action.type === 'publish') {
        messageSuccess = 'ส่งอนุมัติสำเร็จ';
        messageError = 'ส่งอนุมัติไม่สำเร็จ';
        service = apiOrganizationalStructureService.publishOrganizationalStructure(currentId);
      } else if (action.type === 'reject') {
        messageSuccess = 'ส่งกลับแก้ไขสำเร็จ';
        messageError = 'ส่งกลับแก้ไขไม่สำเร็จ';
        service = apiOrganizationalStructureService.rejectOrganizationalStructure(currentId, reason);
      } else if (action.type === 'approve') {
        messageSuccess = 'อนุมัติสำเร็จ';
        messageError = 'อนุมัติไม่สำเร็จ';
        service = apiOrganizationalStructureService.approveOrganizationalStructure(currentId);
      } else if (action.type === 'terminate') {
        messageSuccess = 'ไม่อนุมัติสำเร็จ';
        messageError = 'ไม่อนุมัติไม่สำเร็จ';
        service = apiOrganizationalStructureService.terminateOrganizationalStructure(currentId, reason);
      }

      startLoading();
      try {
        const result = await service;
        notificationService.successNotification(messageSuccess, '');
        onRefresh();
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification(messageError, err.message);
      } finally {
        stopLoading();
        setAction({
          showConfirmModal: false,
          type: null
        });
      }
    }
  };

  const displayAllModal = () => {
    if (showDeleteModal) {
      return <DeleteConfirmationModal
        show={showDeleteModal}
        title={'ยืนยันการลบโครงสร้างองค์กร'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบโครงสร้างองค์กรนี้'}
        onHide={handleCloseAllModal}
        onConfirm={handleConfirmDelete}
      />;
    }
    if (action.showConfirmModal) {
      let confirmTitle = '';
      let confirmDescription = '';

      if (action.type === 'duplicate') {
        confirmTitle = 'ทำสำเนาโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการทำสำเนาโครงสร้างองค์กรนี้';
      } else if (action.type === 'publish') {
        confirmTitle = 'ส่งอนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการส่งอนุมัติโครงสร้างองค์กรนี้';
      } else if (action.type === 'reject') {
        confirmTitle = 'ส่งกลับแก้ไขโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการส่งกลับแก้ไขโครงสร้างองค์กรนี้';
      } else if (action.type === 'approve') {
        confirmTitle = 'อนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการอนุมัติโครงสร้างองค์กรนี้';
      } else if (action.type === 'terminate') {
        confirmTitle = 'ไม่อนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติโครงสร้างองค์กรนี้';
      }

      if (['reject', 'terminate'].includes(action.type as string)) {
        return <InputReasonModal
          show={action.showConfirmModal}
          title={confirmTitle}
          description={confirmDescription}
          onHide={handleCloseAllModal}
          onConfirm={handleConfirmActionModal}
        />;
      }

      return <ConfirmModal
        show={action.showConfirmModal}
        title={confirmTitle}
        description={confirmDescription}
        onHide={handleCloseAllModal}
        onConfirm={handleConfirmActionModal}
      />;
    }
    return null;
  };

  const handleCloseAllModal = () => {
    setAction({
      showConfirmModal: false,
      type: null
    });
    setShowDeleteModal(false);
  };

  const headers: Header[] = [
    {
      label: 'ชื่อโครงสร้างองค์กร',
      field: 'name',
      order: null,
      isOrder: true,
    },
    {
      label: 'ปีงบประมาณ',
      field: 'budget_year',
      order: null,
      isOrder: true,
    },
    {
      label: 'สถานะ',
      field: 'status',
      order: null,
      isOrder: true,
    },
    {
      label: 'สร้างโดย',
      field: 'full_name_th',
      order: null,
      isOrder: true,
    },
    {
      label: '',
      field: '-',
      order: null,
      isOrder: false,
    }
  ];

  return (
    <div className="overflow-auto">
      {loaderComponent}
      <Table striped bordered hover>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
        </thead>
        <tbody>
        {
          rows.map((data, index) => {
            return <OrganizationalStructuresRow
              key={index}
              isAdmin={isAdmin}
              id={data.id}
              name={data.name}
              budget_year={data.budget_year}
              status={data.status}
              active={data.active}
              created_by={data.created_by}
              created_by_employee={data.created_by_employee}
              owner={data.created_by === authJwt?.employee_id}
              onDelete={handleOnDelete}
              onDuplicate={handleOnDuplicate}
              onSendApprove={handleOnSendApprove}
              onReject={handleOnReject}
              onApprove={handleOnApprove}
              onTerminate={handleOnTerminate}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
      {displayAllModal()}
    </div>
  );
};

const OrganizationalStructuresRow: FC<OrganizationalStructuresRowProps> = ({
                                                                             isAdmin,
                                                                             id,
                                                                             name,
                                                                             budget_year,
                                                                             created_by,
                                                                             status,
                                                                             created_by_employee,
                                                                             active,
                                                                             owner,
                                                                             onDelete,
                                                                             onDuplicate,
                                                                             onSendApprove,
                                                                             onReject,
                                                                             onApprove,
                                                                             onTerminate
                                                                           }) => {
  const {permission} = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleOnClick = () => {
    if (owner) {
      updateOrganizationalStructureType('view', dispatch);
      updateOrganizationalStructureStatus('view', dispatch);
      navigate(`/organizational-structures/${id}/director`);
    } else if (isAdmin) {
      updateOrganizationalStructureType('view', dispatch);
      updateOrganizationalStructureStatus('view', dispatch);
      navigate(`/organizational-structures/${id}/summary`);
    }
  };

  const handleOnEdit = (e: FormEvent) => {
    e.stopPropagation();
    updateOrganizationalStructureType('edit', dispatch);
    updateOrganizationalStructureStatus('input', dispatch);
    navigate(`/organizational-structures/${id}/director`);
  };

  const handleOnDuplicate = (e: FormEvent) => {
    e.stopPropagation();
    onDuplicate(id as number);
  };

  const handleOnSendApprove = (e: FormEvent) => {
    e.stopPropagation();
    onSendApprove(id as number);
  };

  const handleOnDelete = (e: FormEvent) => {
    e.stopPropagation();
    onDelete(id as number);
  };

  const handleOnReject = (e: FormEvent) => {
    e.stopPropagation();
    onReject(id as number);
  };

  const handleOnApprove = (e: FormEvent) => {
    e.stopPropagation();
    onApprove(id as number);
  };

  const handleOnTerminate = (e: FormEvent) => {
    e.stopPropagation();
    onTerminate(id as number);
  };

  const displayButtonAction = () => {
    if (status === 'WAITING_FOR_APPROVE') {
      return <>
        <Button variant="secondary" type="button" className="me-2 px-4" onClick={handleOnReject}
                disabled={!hasPermission(permission, ['organizational_structures'], 'approve')}>
          <FontAwesomeIcon icon={faSquareUpRight} className="me-2"/>
          ส่งกลับแก้ไข
        </Button>
        <Button variant="outline-success" type="button" className="me-2 px-4 btn-mof-primary" onClick={handleOnApprove}
                disabled={!hasPermission(permission, ['organizational_structures'], 'approve')}>
          <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
          อนุมัติ
        </Button>
        {/*<Button variant="outline-danger" type="button" className="me-2 px-4" onClick={handleOnTerminate}>*/}
        {/*    <FontAwesomeIcon icon={faCircleXmark} className="me-2"/>*/}
        {/*    ไม่อนุมัติ*/}
        {/*</Button>*/}
      </>;
    } else if (owner && ['WAITING_FOR_EDIT', 'DRAFT'].includes(status as OrganizationalStructureStatusType)) {
      return <>
        <Button variant="warning" type="button" className="me-2 px-4" onClick={handleOnEdit}
                disabled={!hasPermission(permission, ['organizational_structures'], 'update')}>
          <FontAwesomeIcon icon={faPenToSquare} className="me-2"/>
          แก้ไข
        </Button>
        <Button variant="secondary" type="button" className="me-2 px-4" onClick={handleOnDuplicate}
                disabled={!hasPermission(permission, ['organizational_structures'], 'create')}>
          <FontAwesomeIcon icon={faCopy} className="me-2"/>
          ทำสำเนา
        </Button>
        <Button variant="primary" type="button" className="me-2 px-4 btn-mof-primary"
                onClick={handleOnSendApprove}>
          <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
          ส่งอนุมัติ
        </Button>
        <Button variant="danger" type="button" className="me-2" onClick={handleOnDelete}
                disabled={!hasPermission(permission, ['organizational_structures'], 'delete')}>
          <FontAwesomeIcon icon={faCircleXmark}/>
        </Button>
      </>;
    } else if (['APPROVE'].includes(status as OrganizationalStructureStatusType)) {
      return <>
        <Button variant="secondary" type="button" className="me-2 px-4" onClick={handleOnDuplicate}
              disabled={!hasPermission(permission, ['organizational_structures'], 'create')}>
        <FontAwesomeIcon icon={faCopy} className="me-2"/>
        ทำสำเนา
        </Button>
        <Button variant="danger" type="button" className="me-2" onClick={handleOnDelete}
                disabled={!hasPermission(permission, ['organizational_structures'], 'delete')}>
          <FontAwesomeIcon icon={faCircleXmark}/>
        </Button>
      </>;
    } else if (owner && !isAdmin) {
      return <>
        <Button variant="secondary" type="button" className="me-2 px-4" onClick={handleOnDuplicate}
                disabled={!hasPermission(permission, ['organizational_structures'], 'create')}>
          <FontAwesomeIcon icon={faCopy} className="me-2"/>
          ทำสำเนา
        </Button>
      </>;
    } else {
      return null;
    }
  };

  return <tr className={owner || isAdmin ? 'pointer' : ''} onClick={handleOnClick}>
    <td>{name}</td>
    <td>{budget_year}</td>
    <td>{getStatusName(status)}</td>
    <td>{created_by_employee.full_name_th}</td>
    <td>
      <div className="justify-content-end d-flex">
        {displayButtonAction()}
      </div>
    </td>
  </tr>;
};

export default OrganizationalStructuresTable;
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import EmployeeFilterForm from './components/employee-filter-form';
import EmployeeTable from './components/employee-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { EmployeeFilterData, EmployeeGetModel, EmployeeListQueryParams, } from '../../shared/models/employee.model';
import {
    apiEmployeeService,
    employeeActionAfterSave,
    updateEmployeeActionStatus,
    updateEmployeeActionSubmitted,
    updateEmployeeActionType,
    updateEmployeeState
} from '../../shared/services/employee-service';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { ApiResponse } from '../../shared/models/common.model';
import { notificationService } from '../../shared/services/notification-service';
import { AxiosError } from 'axios';
import { initialStateEmployee } from '../../shared/states/employee-reducer';
import { useDispatch } from 'react-redux';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import { OrderData } from '../../shared/components/table-header-ordering';


const EmployeeListPage = () => {
    const [data, setData] = useState<ApiResponse<EmployeeGetModel>>(
        {
            count: 0,
            total_pages: 0,
            current_page: 0,
            rows: [],
        }
    );
    const [queryParams, setQueryParams] = useState<EmployeeListQueryParams>({
        page: 1,
        page_size: 10,
        employee_type: null,
        position_name: '',
        search: '',
        ordering_field: null,
        ordering: null,
    });
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();

    const handleFilter = (data: EmployeeFilterData) => {
        setQueryParams({
            ...queryParams,
            employee_type: data.employee_type,
            position_name: data.position_name,
            search: data.search,
        });
    };

    useEffect(() => {
        fetchData();
    }, [queryParams]);

    const fetchData = async () => {
        startLoading();
        try {
            const result = await apiEmployeeService.getEmployeeList(queryParams);
            setData(result);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };

    const dispatch = useDispatch();
    const onClickAddEmployee = () => {
        updateEmployeeActionType('create', dispatch);
        updateEmployeeActionStatus('input', dispatch);
        updateEmployeeActionSubmitted(false, dispatch);
        updateEmployeeState(initialStateEmployee, dispatch);
        employeeActionAfterSave(false, dispatch);
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    return (
        <div>
            {loaderComponent}
            <div className="d-flex justify-content-between py-4">
                <h2 className="text-mof-primary m-0">ประวัติส่วนตัว</h2>
                <Link to="/employees/create/information">
                    <Button variant="primary" className="btn-action btn-mof-primary" onClick={onClickAddEmployee}>
                        <FontAwesomeIcon icon={faPlus} className="me-2"/>
                        เพิ่มบุคลากร
                    </Button>
                </Link>
            </div>
            <div className="py-2">
                <EmployeeFilterForm onFilter={handleFilter}/>
            </div>
            <div className="py-2 overflow-auto">
                <EmployeeTable rows={data.rows} onChangeOrder={onChangeOrder}/>
            </div>
            <div className="">
                <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                                onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
            </div>
        </div>
    );
};

export default EmployeeListPage;

import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {ManageUserService} from "../../../../../shared/services/Report/ManageUser/manage-user";
import {useParams} from 'react-router-dom';
import {Table} from 'react-bootstrap';
import TableHeaderOrdering, {Header} from "../../../../../shared/components/table-header-ordering";
import TableRowNoData from "../../../../../shared/components/molecules/commons/table-row-no-data";

const headers: Header[] = [
  {
    label: 'ประจำปี',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ผลงานดีเด่น',
    field: '',
    order: null,
    isOrder: false,
  }
];

const PersonnelOutstandingAwards: FC = () => {

  let {employee_id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await ManageUserService.getReportEditEmployeeDetailDetail({employee_id: employee_id});
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData0 = () => {
    if (data[0]?.outstanding_performance_awards?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const tableRowNoData1 = () => {
    if (data[1]?.outstanding_performance_awards?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">ผลงานดีเด่น/รางวัล</h5>
          </Accordion.Header>
          <Accordion.Body>
            <div className="overflow-auto mt-3">
              {data[0]?.outstanding_performance_awards?.length === 0 && data[1]?.outstanding_performance_awards?.length === 0 ? '' : <h6 className="text-mof-primary m-0 pt-2 pb-2">ข้อมูลก่อนหน้า</h6>}
              <Table bordered>
                <thead>
                  <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                </thead>
                <tbody>
                  {data[1]?.outstanding_performance_awards?.map((data:any,index:any) => {
                    return <tr key={index}>
                      <td>{data.year ? data.year : '-'}</td>
                      <td>{data.name ? data.name : '-'}</td>
                    </tr>
                  })}
                  {tableRowNoData1()}
                </tbody>
              </Table>
            </div>

            {
              data[0]?.outstanding_performance_awards?.length === 0 && data[1]?.outstanding_performance_awards?.length === 0 ? '' :
              <div className="overflow-auto mt-3">
                <h6 className="text-mof-primary m-0 pt-2 pb-2">ข้อมูลล่าสุด</h6>
                <Table bordered>
                  <thead>
                    <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
                  </thead>
                  <tbody>
                    {data[0]?.outstanding_performance_awards?.map((data:any,index:any) => {
                      return <tr key={index}>
                        <td>{data.year ? data.year : '-'}</td>
                        <td>{data.name ? data.name : '-'}</td>
                      </tr>
                    })}
                    {tableRowNoData0()}
                  </tbody>
                </Table>
              </div>
            }
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelOutstandingAwards;
import React from 'react';
import { handleEmployeeInputChange } from './function-state';
import { AppDispatch } from '../states/store';

export const handleNumericInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/\D/g, '');
};

export const handleInputNumberChange = (event: React.ChangeEvent<HTMLInputElement>, dispatch: AppDispatch) => {
    handleNumericInput(event);
    const updateValue = {
        target: {
            name: event.target.name,
            value: event.target.value || null
        }
    };
    handleEmployeeInputChange(updateValue, dispatch);
};

export const convertToPositiveNumber = (input: string) => {
    const parsedValue = parseInt(input);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
        return parsedValue.toString();
    } else {
        return '';
    }
};


export const areListEqual = (array1: any, array2: any) => {
    if (array1.length === array2.length) {
        return array1.every((element: any) => {
            if (array2.includes(element)) {
                return true;
            }

            return false;
        });
    }

    return false;
}
export const formatToThaiDate = (date: Date): string => {
    const thaiYear = date.getFullYear() + 543;
    return `${date.getDate()}/${date.getMonth() + 1}/${thaiYear}`;
};

export const convertDateToString = (date: Date): string => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

export const dateDiff = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearsDiff = end.getFullYear() - start.getFullYear();
    const monthsDiff = end.getMonth() - start.getMonth() + 12 * yearsDiff;

    const years = Math.floor(monthsDiff / 12);
    const remainingMonths = monthsDiff % 12;

    // Get the maximum days in the start month and end month
    const maxDaysInStartMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();
    const maxDaysInEndMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate();

    const days = Math.min(end.getDate(), maxDaysInEndMonth) - Math.min(start.getDate(), maxDaysInStartMonth);
    const months = remainingMonths - (days < 0 ? 1 : 0);

    return {
        days: days,
        months: months,
        years: years,
    };
};
import React, {useEffect} from 'react';
import {Button} from "react-bootstrap";
import {Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import InputSelect from "../../../shared/components/atoms/input-select";
import AssignedEmployeeShiftListSection, {initialHeaders} from "../pages/assigned-employee-shift-list-section";
import EmployeeSearch from "./employee-search";
import {EmployeeListQueryParams} from "../../../shared/models/employee.model";
import {apiDropdownService} from "../../../shared/services/dropdown-service";

type PageProps = {
  open: boolean;
  handleClose: () => void;
  onRejectDialogValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitClick: () => void;
  onAssignedEmployeeShift?: (userId: number | null , isChecked: boolean) => void;
  currentCheckedUsers?: number[];
  handleSelectAll?: (data: any) => void;
  isCreatePage?: boolean;
  createPageState?: any;
};
const AddEmployeeShiftDetail = ({ open,
                                  handleClose,
                                  onRejectDialogValueChange,
                                  handleSubmitClick,
                                  onAssignedEmployeeShift,
                                  currentCheckedUsers,
                                  handleSelectAll,
                                  createPageState,
                                  isCreatePage }: PageProps) => {

  const [searchValue , setSearchValue] = React.useState<{label: string , value: string | number}>({
    label: '',
    value: ''
  })

  const [positionSearchValue , setPositionSearchValue] = React.useState('')
  const [ structureDepartmentSearchValue, setStructureDepartmentSearchValue] = React.useState('')

  const [positionOptions, setPositionOptions] = React.useState<any[]>([])
  const [structureDepartmentsOptions, setStructureDepartmentsOptions] = React.useState<any[]>([])

  const handleSetQueryParamState = (setData?: React.Dispatch<React.SetStateAction<EmployeeListQueryParams>>) => {
   if (setData) {
     setData((prevState: any) => {
       return {
         ...prevState,
         search: searchValue.label.split(' ')[1]?.concat(' ', searchValue.label.split(' ')[2]) || '',
         position_name: positionSearchValue || '',
         structure_departments_name: structureDepartmentSearchValue || ''
       }
     })
   }
  }

  const onAssignEmployeeShift = (data: any , isChecked: boolean) => {
    if (onAssignedEmployeeShift) {
      onAssignedEmployeeShift(data , isChecked)
    }
  }

  const isCheckAll = (data:any) => {

    if (!currentCheckedUsers || currentCheckedUsers.length === 0) {
      return false;
    }

    if (!data || data.length === 0) {
      return false;
    }

    const allChecked = data.every((item:any) => currentCheckedUsers.includes(item.user.id));

    return allChecked;
  };

  const handleCurrentSelectAll = (data: any) => {
    if (handleSelectAll) {
      handleSelectAll(data)
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const position = await apiDropdownService.getPositionDropdown({search: ''});
        const structureDepartments = await apiDropdownService.getStructureDepartmentDropdown({search: ''});
        const positionOptions = position
          .filter((item: any) => item.position_name !== null)
          .map((item: any, idx: number) => ({
            value: idx,
            label: item.position_name,
          }));


        const structureDepartmentsOptions = structureDepartments
          .filter((item: any) => item.structure_departments_name !== null)
          .map((item: any, idx: number) => ({
            value: idx,
            label: item.structure_departments_name,
          }));
        setPositionOptions(positionOptions);
        setStructureDepartmentsOptions(structureDepartmentsOptions);
      } catch (e) {
        console.error('Error fetching options:', e);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    handleSetQueryParamState()
  }, [searchValue , positionSearchValue, structureDepartmentSearchValue]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={()=>{
          handleClose()
          setSearchValue({
            label: '',
            value: ''
          })
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            height: "auto",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center" sx={{fontWeight: "Medium", color: "#056310"}}
                      fontFamily={`"Anuphan", sans-serif`}>เพิ่มบุคลากร</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="col-sm-12 col-md-12 mt-sm-3 mt-md-0 mt-xl-3" style={{padding: "0 32px 0 32px"}}>
            <label className="form-label required">รหัสพนักงาน หรือ ชื่อ - นามสกุล</label>
            <EmployeeSearch onChange={(value)=>{
              if (value !== null) {
                setSearchValue({
                  label: value.label,
                  value: value.value
                })
              }
            }} value={null} changeValue={''} />
            {/*<InputSelect options={[]} placeholder="รหัสพนักงาน หรือ ชื่อ - นามสกุล" name="is_active"*/}
            {/*             onChange={()=>{}} required={true}*/}
            {/*             isValidateError={false} value={null}/>*/}
            {/*{null === undefined || null === "" && submitted ?*/}
            {null === undefined || null === "" && null ?
              <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                กรุณาเลือกประเภทการลา
              </div> : null}
          </div>
          <div className="row mt-3" style={{padding: "0 32px 0 32px"}}>
            <div className="col-sm-12 col-md-6">
              <label className="form-label required">แผนก</label>
              <InputSelect options={structureDepartmentsOptions} placeholder="กรุณาเลือกแผนก" name="is_active"
                           onChange={(e)=>{
                              setStructureDepartmentSearchValue(e.value?.label)
                           }} required={true}
                           isValidateError={false} value={null}/>
              {/*{null === undefined || null === "" && submitted ?*/}
              {null === undefined || null === "" && null ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกแผนก
                </div> : null}
            </div>
            <div className="col-sm-12 col-md-6">
              <label className="form-label required">ตำเเหน่ง</label>
              <InputSelect options={positionOptions} placeholder="กรุณาเลือกตำเเหน่ง" name="is_active"
                           onChange={(e)=>{
                             setPositionSearchValue(e.value?.label)
                           }} required={true}
                           isValidateError={false} value={null}/>
              {/*{null === undefined || null === "" && submitted ?*/}
              {null === undefined || null === "" && null ?
                <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>
                  กรุณาเลือกตำเเหน่ง
                </div> : null}
            </div>
          </div>
          <div className="row mt-3" style={{padding: "0 32px 0 32px"}}>
            <AssignedEmployeeShiftListSection setQueryParamState={handleSetQueryParamState}
                                              onAssignEmployeeShift={(data , isChecked)=>{onAssignEmployeeShift(data , isChecked)}}
                                              currentHeaders={initialHeaders}
                                              isChecked={currentCheckedUsers}
                                              isCheckAll={isCheckAll}
                                              setIsCheckAll={handleCurrentSelectAll}
                                              isCreatePage={false}
            />
          </div>
        </DialogContent>
        <div
          className="d-flex flex-row col-12 justify-content-center column-gap-3 mb-4">
          <div className="">
            <Button type="button"
                    variant="primary"
                    onClick={handleSubmitClick}
                    className="d-block btn-action"
                    style={{background: "background: #007BFF"}}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddEmployeeShiftDetail;


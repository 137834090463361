import apiService from './api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/requests';

export class RequestService {

    static async getRegisterRequest(params: any) {
        const response = await apiService().get(`${REQUEST_BASE_API}/register`, {params});
        return response.data;
    }

    static async onApproveRegister(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/register-approve`, data);
        return response.data;
    }

    static async onRejectRegister(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/register-reject`, data);
        return response.data;
    }

    static async getAllUser(params: any) {
        const response = await apiService().get(`${REQUEST_BASE_API}/all-users`, {params});
        return response.data;
    }

    static async getResetPasswordRequest(params: any) {
        const response = await apiService().get(`${REQUEST_BASE_API}/reset-password`, {params});
        return response.data;
    }

    static async onApproveResetPassword(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/reset-password-approve`, data);
        return response.data;
    }

    static async onRejectResetPassword(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/reset-password-reject`, data);
        return response.data;
    }

    static async getEditProfileRequest(params: any) {
        const response = await apiService().get(`${REQUEST_BASE_API}/edit-profile-requests`, {params});
        return response.data;
    }

    static async getEditProfileRequestDetail(id: any) {
        const response = await apiService().get(`${REQUEST_BASE_API}/edit-profile-requests/${id}`, {});
        return response.data;
    }

    static async onApproveEditProfileRequest(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/edit-profile-request-approve`, data);
        return response.data;
    }

    static async onRejectEditProfileRequest(id: any, data: any) {
        const response = await apiService().post(`${REQUEST_BASE_API}/${id}/edit-profile-request-reject`, data);
        return response.data;
    }
}
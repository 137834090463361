import React, { FC, useEffect, useState } from 'react';
import { Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import theme from '../../../styles/theme';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationalStructureAction } from '../../../shared/services/organizational-structure-service';
import { useNavigate } from 'react-router-dom';

interface StepperResponsiveProps {
    isCreate: boolean;
    id?: string;
}

const StepperResponsive: FC<StepperResponsiveProps> = ({id, isCreate = false}) => {
    const [activeStep, setActiveStep] = useState(0);

    const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
    const navigate = useNavigate();
    const canSkip = organizationalStructureAction.status === 'view';

    useEffect(() => {
        if (organizationalStructureAction.page === 'director') {
            setActiveStep(0);
        } else if (organizationalStructureAction.page === 'deputy-director') {
            setActiveStep(1);
        } else if (organizationalStructureAction.page === 'office-department') {
            setActiveStep(2);
        } else if (organizationalStructureAction.page === 'division-group-office') {
            setActiveStep(3);
        } else if (organizationalStructureAction.page === 'structure-department') {
            setActiveStep(4);
        } else if (organizationalStructureAction.page === 'structure-position') {
            setActiveStep(5);
        } else if (organizationalStructureAction.page === 'personnel') {
            setActiveStep(6);
        } else if (organizationalStructureAction.page === 'summary') {
            setActiveStep(7);
        }
    }, [organizationalStructureAction.page]);

    const steps = [
        {
            label: 'ผู้อำนวยการ',
            link: isCreate ? '/organizational-structures/create/director' : `/organizational-structures/${id}/director`,
        },
        {
            label: 'รองผู้อำนวยการ',
            link: isCreate ? '/organizational-structures/create/deputy-director' : `/organizational-structures/${id}/deputy-director`,
        },
        {
            label: 'สำนัก / ฝ่าย',
            link: isCreate ? '/organizational-structures/create/office-department' : `/organizational-structures/${id}/office-department`,
        },
        // {
        //     label: '',
        //     link: isCreate ? '/organizational-structures/create/office' : `/organizational-structures/${id}/office`,
        // },
        {
            label: 'กอง / กลุ่ม / สำนักงาน',
            link: isCreate ? '/organizational-structures/create/division-group-office' : `/organizational-structures/${id}/division-group-office`,
        },
        {
            label: 'แผนก',
            link: isCreate ? '/organizational-structures/create/structure-department' : `/organizational-structures/${id}/structure-department`,
        },
        {
            label: 'ตำแหน่ง',
            link: isCreate ? '/organizational-structures/create/structure-position' : `/organizational-structures/${id}/structure-position`,
        },
        {
            label: 'บุคลากร',
            link: isCreate ? '/organizational-structures/create/personnel' : `/organizational-structures/${id}/personnel`,
        },
        {
            label: 'โครงสร้างองค์กร',
            link: isCreate ? '/organizational-structures/create/summary' : `/organizational-structures/${id}/summary`,
        },
    ];

    const navigateLink = (link: string) => {
        if (canSkip) {
            navigate(link);
        }
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <Stepper activeStep={activeStep} alternativeLabel className="overflow-auto">
                    {steps.map((data) => (
                        <Step key={data.label}>
                            <StepLabel onClick={() => navigateLink(data.link)}>
                                {data.label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </ThemeProvider>
        </>
    );
};

export default StepperResponsive;
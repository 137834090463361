import React, { FC } from 'react';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { JobPosition } from '../../../../shared/models/employee.model';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import SectionAddRow from '../section-add-row';
import JobPositionNewForm from '../forms/job-position-new-form';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeJobPositionProps {
  defaultKey?: AccordionEventKey;
}

const EmployeeJobPosition: FC<EmployeeJobPositionProps> = ({defaultKey = '0'}) => {
  const dispatch = useDispatch();
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);

  const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
  const haveSomeEditField = () => {
    if (employeeAction.type === 'view-edit-request') {
      const lengthNotSame = employee.job_positions.length !== employeeEditProfile.job_positions.length;

      if (lengthNotSame)
        return true;

      return employee.job_positions.some((data, index) => {
        return Object.keys(data).some(key => {
          const kk = key as keyof JobPosition;
          if (key === 'id' || key === 'employee_id' || key === 'created_at' || key === 'updated_at' || key === 'from_id')
            return false;
          return employeeEditProfile.job_positions[index][kk] !== data[kk];
        });
      });
    }
    return false;
  };

  const onAddNewData = () => {
    const initData: JobPosition = {
      id: null,
      employee_id: null,
      employee_type: null,
      position_level: null,
      position_name: null,
      position_type: null,
      start_date: null,
      end_date: null,
      is_currently: false,
      reason: null,
      order_no: null,
      title: null,
      file: null,
      new_file: true,
      file_name: '',
      from_id: null,
      tenure_position_date: null,
    };
    handleEmployeeSectionAddNewData('job_positions', initData, dispatch);
  };
  return (
    <Accordion defaultActiveKey={defaultKey} className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
          <h5 className="text-mof-primary m-0">ประวัติการทำงานของ อตก.</h5>
        </Accordion.Header>
        <Accordion.Body>
          <JobPositionNewForm/>
          <SectionAddRow onAddNewData={onAddNewData}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EmployeeJobPosition;
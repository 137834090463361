import axios from 'axios';
import { getMediaPath } from '../utils/function-media';


export const apiEmployeeAttachmentService = {
    downloadFile
};

async function downloadFile(url: string, fileName: string) {
    axios
        .get(getMediaPath(url), {
            responseType: 'blob',
        })
        .then((response) => {
            const blobURL = URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(blobURL);
        })
        .catch((error) => {
            console.error('Error downloading the file:', error);
        });
}
import { EmployeeAction, EmployeeActionStatus, EmployeeActionType } from '../models/employee.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export const initialStateEmployeeAction: EmployeeAction = {
    type: 'view',
    isSubmitted: false,
    after_save: false,
    status: 'view'
};

export const employeeActionReducer = createSlice({
    name: 'employee_action',
    initialState: initialStateEmployeeAction,
    reducers: {
        changeType: (state, action: PayloadAction<EmployeeActionType>) => {
            state.type = action.payload;
        },
        changeStatus: (state, action: PayloadAction<EmployeeActionStatus>) => {
            state.status = action.payload;
        },
        changeSubmitted: (state, action: PayloadAction<boolean>) => {
            state.isSubmitted = action.payload;
        },
        afterSave: (state, action: PayloadAction<boolean>) => {
            state.after_save = action.payload;
        }
    },
});

// Export the action creators
export const {
    changeType,
    changeSubmitted,
    changeStatus,
    afterSave
} = employeeActionReducer.actions;

// Export the reducer
export default employeeActionReducer.reducer;

import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ReportInOutLeaveService} from "../../../../shared/services/Report/ReportInOutLeave/report-in-out-leave";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import Moment from "moment";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";
import {
  fetchDepartmentsOfficeNameOptions,
  fetchDivisionGroupOfficeNameOptions,
  fetchDepartmentsNameOptions,
  fetchPositionNameOptions,
  
  department_offices_name_Options,
  division_group_office_name_Options, 
  departments_name_Options, 
  position_name_Options, 
} from '../../OptionsDropDown';

const headers: Header[] = [
  {
    label: 'วันที่',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'จำนวนผู้ไม่สแกนเข้างาน',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'จำนวนผู้ไม่สแกนออกงาน',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportNotScanInOutListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    date_start: '',
    date_end: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await ReportInOutLeaveService.getReportNotScanInOutList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.date_start, 
    queryParams.date_end,
    queryParams.page_size, 
    queryParams.page,
  ]);

  useEffect(() => {
    fetchDepartmentsOfficeNameOptions();
    fetchDivisionGroupOfficeNameOptions();
    fetchDepartmentsNameOptions();
    fetchPositionNameOptions();
  }, []);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      date_start: value.startDate ? convertDateToString(value.startDate) : '',
      date_end: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานสถิติจำนวนผู้ไม่สแกนเข้า - ออก</h2>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={departments_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={position_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
          <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
          <DateRangeInput
            startDate={queryParams.date_start ? new Date(queryParams.date_start) : null}
            endDate={queryParams.date_end ? new Date(queryParams.date_end) : null}
            onChange={handleDateRangeChange}
            disable={false}
          />
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.date ? Moment(data.date).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY') : '-'}</td>
                <td>{data.departments_name ? data.departments_name : '-'}</td>
                <td>{data.non_check_in ? data.non_check_in : '-'}</td>
                <td>{data.non_check_out ? data.non_check_out : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportNotScanInOutListPage;
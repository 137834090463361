import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {Button, Form} from 'react-bootstrap';
import {notificationService} from '../../shared/services/notification-service';
import {useAuth} from '../../shared/authentications/auth-context';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import InputSelect from "../../shared/components/atoms/input-select";
import {Divider, FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import DateInput from "../../shared/components/atoms/date-input";
import {AxiosError} from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase, faCalendarPlus, faCaretLeft, faPlus, faUserPen} from "@fortawesome/free-solid-svg-icons";
import {handleEmployeeInputChange} from "../../shared/utils/function-state";
import DateRangeInput, {DateRangeInputChange} from "../../shared/components/atoms/date-range-input";
import {convertDateToString} from "../../shared/utils/functions-date-time";
import {updateDirector} from "../../shared/services/organizational-structure-service";
import RHFTimePicker from "../../shared/components/mui/TimePicker";
import DaySelectBox from "./components/day-select-box";
import HolidayDetailSection from "./pages/holiday-details-section";
import AssignedEmployeeShiftListPage, {initialHeaders} from "./pages/assigned-employee-shift-list-section";
import AddHolidayDetail from "./components/add-holiday-detail";
import AddEmployeeShiftDetail from "./components/add-employee-shift-detail";
import {DayOfWeek, HolidayType, ShiftRepeatDetailType} from "./types/type";
import {days, shiftRepeatEvery} from "./const";
import {LeaveRequestService} from "../../shared/services/leave-request";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";
import {ShiftRequestPayloadType, ShiftRequestType} from "../../shared/models/employee-shifts.model";
import {apiEmployeeService} from "../../shared/services/employee-service";
import {EmployeeListQueryParams} from "../../shared/models/employee.model";
import InputReasonModal from "../../shared/components/molecules/modals/input-reason-modal";

type pagePropTypes = {
  isApprover?: boolean;
  isEdit?: boolean;
  approverData?: any;
}

const ShiftCreatePage = ({isApprover, isEdit, approverData}: pagePropTypes) => {
  const auth = useAuth();
  const userId = auth?.authJwt?.id;
  const isAdmin = auth?.authJwt?.role === "ADMIN";
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();



  const [submitted, setSubmitted] = useState(false);

  const [dialog, setDialog] = useState({
    addHoliday: false,
    addEmployee: false
  });

  const isPersonelEdit = searchParams.get('isPersonelEdit') === 'true';
  const isAlredyPersonelShift = searchParams.get('isAlredyPersonelShift') === 'true';
  const personelEditUserId = searchParams.get('userId');

  const [shiftRepeatDetail, setShiftRepeatDetail] = useState<ShiftRepeatDetailType[]>([
    {
      "shift_repeat_type": "work_at_company",
      "shift_repeat_every": "every_week",
      "sunday": false,
      "monday": false,
      "tuesday": false,
      "wednesday": false,
      "thursday": false,
      "friday": false,
      "saturday": false,
      "repeat": false
    },
    {
      "shift_repeat_type": "work_from_home",
      "shift_repeat_every": "every_week",
      "sunday": false,
      "monday": false,
      "tuesday": false,
      "wednesday": false,
      "thursday": false,
      "friday": false,
      "saturday": false,
      "repeat": false
    }
  ])

  const [currentTab, setCurrentTab] = useState('holiday');

  const [shiftName, setShiftName] = useState('');
  const [previouseShiftName, setPreviouseShiftName] = useState('');
  const [shiftDate, setShiftDate] = useState<{
    start_date: any,
    end_date: any,
    isNoEndDate: boolean
  }>({
    start_date: null,
    end_date: null,
    isNoEndDate: false
  });

  const [shiftTime, setShiftTime] = useState({
    work_start_time: '',
    work_end_time: '',
    break_time_start: '',
    break_time_end: '',
  });

  const [holiday, setHoliday] = useState<HolidayType[]>([])

  const [rawAssignedEmployeeShift, setRawAssignedEmployeeShift] = useState([])
  const [assignedEmployeeShift, setAssignedEmployeeShift] = useState<{ user_id: number }[]>([])

  const [showRejectModal, setRejectModal] = useState(false);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    if (value.startDate !== null && value.endDate !== null && !shiftDate.isNoEndDate) {
      setShiftDate((prev: any) => {
        return {
          ...prev,
          start_date: value.startDate ? convertDateToString(value.startDate) : null,
          end_date: value.endDate ? convertDateToString(value.endDate) : null
        }
      });
    }
    if (shiftDate.isNoEndDate && value.startDate !== null) {
      setShiftDate((prev: any) => {
        return {
          ...prev,
          start_date: value.startDate ? convertDateToString(value.startDate) : null,
          end_date: null
        }
      });
    }
  };

  const isDaySelectBoxSelected = (type: string, day: DayOfWeek): boolean => {
    // Find the object in the array that matches the specified shift_repeat_type.
    const detail = shiftRepeatDetail?.find(item => item.shift_repeat_type === type);

    // If found, return the boolean value for the specified day, otherwise return false.
    return detail ? detail[day] : false;
  };

  const onSelectDayBoxClick = (type: string | undefined, day: string) => {
    setShiftRepeatDetail(prevState => {
      // Determine the opposite type to update it accordingly
      const oppositeType = type === 'work_at_company' ? 'work_from_home' : 'work_at_company';

      return prevState?.map((item: any) => {
        // Toggle the boolean value for the specified day if the type matches
        if (item.shift_repeat_type === type) {
          return {...item, [day]: !item[day]};
        }
        // For the opposite type, set the same day to false
        else if (item.shift_repeat_type === oppositeType) {
          return {...item, [day]: false};
        }
        // For all other items, return them unchanged
        return item;
      });
    });
  };

  const handleAssignEmployeeShift = (rawData: any | null, isChecked: boolean) => {
    setAssignedEmployeeShift(prevState => {
      // If the checkbox is checked, add the employee ID to the list if it's not already present
      if (isChecked) {
        // Check if the employee ID is already in the list
        const isAlreadyAssigned = prevState.some(item => item.user_id === rawData.user?.id);
        if (!isAlreadyAssigned && rawData.user?.id !== null) {
          // If it's not already assigned, and e is not null, add it to the list
          return [...prevState, {user_id: rawData.user?.id}];
        }
        // If it's already assigned, or e is null, return the previous state without changes
        return prevState;
      } else {
        // If the checkbox is unchecked, remove the employee ID from the list
        return prevState.filter(item => item.user_id !== rawData.user?.id);
      }
    });
    setRawAssignedEmployeeShift((prevState: any) => {
      // If the checkbox is checked, add the employee ID to the list if it's not already present
      if (isChecked) {
        // Check if the employee ID is already in the list
        const isAlreadyAssigned = prevState.some((item: any) => (isEdit ? item.user_id : item.user?.id) === rawData.user?.id);
        if (!isAlreadyAssigned && rawData.user?.id !== null) {
          // If it's not already assigned, and e is not null, add it to the list
          return [...prevState, rawData];
        }
        // If it's already assigned, or e is null, return the previous state without changes
        return prevState;
      } else {
        // If the checkbox is unchecked, remove the employee ID from the list
        return prevState.filter((item: any) => (isEdit ? item.user_id : item.user?.id) !== rawData.user?.id);
      }
    })
  };

  const handleSelectAll = (rawData: any) => {
    setAssignedEmployeeShift(prevState => {
      // Determine if all users on the current page are already selected
      const allSelectedOnPage = rawData.every((item: any) => prevState?.find(({user_id}) => user_id === item.user.id));

      if (allSelectedOnPage) {
        // If all users on the page are selected, remove them from the state
        return prevState.filter(({user_id}) => !rawData.some((item: any) => item.user.id === user_id));
      } else {
        // Otherwise, add missing users from the page to the state
        const newState = [...prevState];
        rawData.forEach((item: any) => {
          if (!prevState?.find(({user_id}) => user_id === item.user.id)) {
            newState.push({user_id: item.user.id});
          }
        });
        return newState;
      }
    });
    setRawAssignedEmployeeShift((prevState: any) => {
      // Determine if all users on the current page are already selected
      const allSelectedOnPage = rawData.every((item: any) => prevState?.find((data: any) => (isEdit ? data?.user_id : data.user?.id) === item.user.id));

      if (allSelectedOnPage) {
        // If all users on the page are selected, remove them from the state
        return prevState.filter((item: any) => !rawData.some((data: any) => data.user.id === (isEdit ? item.user_id : item.user?.id)));
      } else {
        // Otherwise, add missing users from the page to the state
        const newState = [...prevState];
        rawData.forEach((item: any) => {
          if (!prevState?.find((data: any) => (isEdit ? data?.user_id : data.user?.id) === item.user.id)) {
            newState.push(item);
          }
        });
        return newState;
      }
    })
  };

  const isValidForm = () => {
    let validationResult = {
      valid: true,
      isSameName: false,
    };

    if (shiftName === '') {
      validationResult.valid = false;
    }
    if (isPersonelEdit && shiftName === previouseShiftName) {
      validationResult.isSameName = true;
    }
    // if (shiftDate.start_date === null || shiftTime.work_start_time === '' || shiftTime.work_end_time === '' || shiftTime.break_time_start === '' || shiftTime.break_time_end === '') {
    //   validationResult.valid = false;
    // }
    if (shiftDate.start_date === null || shiftTime.work_start_time === '' || shiftTime.work_end_time === '') {
      validationResult.valid = false;
    }

    return validationResult;
  }

  const handleSubmit = async () => {
    setSubmitted(true);
    const validationResult = isValidForm();
    if (!validationResult.valid) {
      notificationService.dangerNotification('กรุณากรอกข้อมูลให้ครบถ้วน', '');
      return
    }
    const formData: ShiftRequestPayloadType = {
      shift_request_payload: {
        shift_name: shiftName,
        start_date: shiftDate.start_date,
        end_date: shiftDate.end_date,
        work_time_start: shiftTime.work_start_time,
        work_time_end: shiftTime.work_end_time,
        break_time_start: shiftTime.break_time_start,
        break_time_end: shiftTime.break_time_end,
        status: 'waiting_for_approver',
        reason: null
      },
      shift_repeat_details: shiftRepeatDetail,
      shift_holiday_details: holiday?.map(item => {
        return {
          holiday_date: convertDateToString(item.holiday_date),
          holiday_name: item.holiday_name
        }
      }),
      shift_employee_assignment_details: assignedEmployeeShift
    }
    try {
      startLoading();
      let response = null
      {
        if (isEdit && !isPersonelEdit) {
          response = await EmployeeShiftService.updateEmployeeShift(approverData.id, formData)
        } else if (isPersonelEdit && isAlredyPersonelShift) {
          response = await EmployeeShiftService.updateEmployeeShift(approverData.id, formData)
        } else if (isPersonelEdit && !isAlredyPersonelShift) {
          formData.isPersonelEdit = true;
          formData.oldShiftRequestId = approverData.id;
          response = await EmployeeShiftService.createEmployeeShift(formData)
        } else {
          response = await EmployeeShiftService.createEmployeeShift(formData)
        }
      }
      if (response) {
        notificationService.successNotification('ส่งคำขอเรียบร้อยเเล้ว', '');
        navigate('/employee-shift/employee-shift-list');
      }
    } catch (error) {
      const err = error as any;
      if (err.response?.status === 400) {
        notificationService.dangerNotification('ไม่พบข้อมูล', err.response.data.message);
      } else if (err.response.data?.message?.name === "SequelizeUniqueConstraintError") {
        const erroruserId = err.response.data?.message?.fields?.user_id;
        if (erroruserId) {
          const employeeErrorDetail = rawAssignedEmployeeShift?.filter((item: any) => item.user_id === parseInt(erroruserId));
          const employeeErrorName = employeeErrorDetail?.map((item: any) => item.prefix_name_th + ' ' + item.firstname_th + ' ' + item.lastname_th)
          notificationService.dangerNotification('มีบุคคลกรที่อยู่ในตารางกะอื่นเเล้ว', employeeErrorName);
        }
      } else {
        notificationService.dangerNotification('ระบบขัดข้อง', err.message);
      }
    } finally {
      stopLoading();
    }
  }

  useEffect(() => {
    if (shiftDate.isNoEndDate) {
      setShiftDate(prevState => {
        return {...prevState, end_date: null}
      })
    }
    if (!shiftDate.isNoEndDate) {
      setShiftDate(prevState => {
        return {...prevState, end_date: shiftDate.start_date}
      })
    }
  }, [shiftDate.isNoEndDate]);

  useEffect(() => {
    if (isApprover || isEdit) {
      fetchData();
      if (approverData) {
        const shiftRepeatDetails = approverData?.shift_repeat_details?.length > 0 ? approverData?.shift_repeat_details : [];
        const transformedShiftRepeatDetails = shiftRepeatDetails.map((detail: any) => ({
          shift_repeat_type: detail.shift_repeat_type,
          shift_repeat_every: detail.shift_repeat_every,
          sunday: detail.sunday,
          monday: detail.monday,
          tuesday: detail.tuesday,
          wednesday: detail.wednesday,
          thursday: detail.thursday,
          friday: detail.friday,
          saturday: detail.saturday,
          repeat: detail.repeat,
        }));

        const holidayDetails = approverData?.shift_holiday_details?.length > 0 ? approverData?.shift_holiday_details : [];
        const transformedHolidayDetails = holidayDetails.map((detail: any) => ({
          holiday_date: new Date(detail.holiday_date),
          holiday_name: detail.holiday_name
        }));

        const employeeDetails = approverData.shift_employee_assignment_details.map((detail: any) => ({
          shift_request_id: approverData.id,
          ...detail.user.employee,
          status: approverData.status
        }));

        setShiftName(approverData?.shift_name)
        setPreviouseShiftName(approverData?.shift_name)
        setShiftDate({
          start_date: convertDateToString(new Date(approverData?.start_date)) || null,
          end_date: convertDateToString(new Date(approverData?.end_date)) || null,
          isNoEndDate: approverData?.end_date === null
        })
        setShiftTime({
          work_start_time: approverData?.work_time_start?.slice(0, -3),
          work_end_time: approverData?.work_time_end?.slice(0, -3),
          break_time_start: approverData?.break_time_start?.slice(0, -3),
          break_time_end: approverData?.break_time_end?.slice(0, -3)
        })
        setShiftRepeatDetail(transformedShiftRepeatDetails);
        setHoliday(transformedHolidayDetails);
        if (!isPersonelEdit) {
          setRawAssignedEmployeeShift(employeeDetails)
        } else {
          setRawAssignedEmployeeShift(employeeDetails.filter((detail: any) => detail.user_id === (personelEditUserId ? parseInt(personelEditUserId) : null)))
        }
        if (isEdit && !isPersonelEdit) {
          setAssignedEmployeeShift(employeeDetails.map((detail: any) => ({user_id: detail.user_id})));
        } else if (isPersonelEdit) {
          setAssignedEmployeeShift(employeeDetails.filter((detail: any) => detail.user_id === (personelEditUserId ? parseInt(personelEditUserId) : null)).map((detail: any) => ({user_id: detail.user_id})));
        }
      }

    }
  }, [isApprover, approverData]);

  const [employeeListData, setEmployeeListData] = useState(null);

  const [queryParams, setQueryParams] = useState<EmployeeListQueryParams>({
    page: 1,
    page_size: 10,
    employee_type: null,
    position_name: '',
    search: '',
    structure_departments_name: '',
    ordering_field: null,
    ordering: null,
  });

  const fetchData = async () => {
    startLoading();
    try {
      const result = await apiEmployeeService.getEmployeeList(queryParams);
      setEmployeeListData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const status = approverData?.status;

  const updateShiftStatus = async (data: any, action: "rejected" | "approved") => {
    try {
      startLoading();
      const result = await EmployeeShiftService.updateEmployeeShiftStatus(data);
      if (result) {
        notificationService.successNotification(action === "approved" ? 'อนุมัติคำขอเรียบร้อย' : 'ไม่อนุมัติคำขอเรียบร้อย', '');
        navigate('/employee-shift/employee-shift-list');
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('อนุมัติคำขอไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  }

  const handleCloseModal = () => {
    setRejectModal(false);
  };

  const displayModal = () => {
    if (showRejectModal) {
      return <InputReasonModal
        show={showRejectModal}
        title={'ยืนยันการไม่อนุมัติ'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติขอนี้'}
        onHide={handleCloseModal}
        onConfirm={(reason: any)=>{updateShiftStatus({id: approverData?.id, status: "rejected", reason: reason }, "rejected")}}
      />;
    }
    return;
  };

  return <>
    {loaderComponent}
    {displayModal()}
    <AddHolidayDetail open={dialog.addHoliday}
                      handleClose={() => {
                        setDialog(prev => {
                          return {...prev, addHoliday: false}
                        })
                      }}
                      onHolidayDetailDeleteClick={(index: number) => () => {
                        setHoliday(prevState => {
                          return prevState.filter((item, i) => i !== index)
                        })
                      }}
                      handleSubmitClick={(value: HolidayType[]) => {
                        setHoliday(prevState => {
                          return [...prevState, ...value]
                        })
                        setDialog({
                          addHoliday: false,
                          addEmployee: false
                        })
                      }}
    />
    <AddEmployeeShiftDetail open={dialog.addEmployee}
                            handleClose={() => setDialog(prev => {
                              return {...prev, addEmployee: false}
                            })}
                            onRejectDialogValueChange={() => {
                            }}
                            handleSubmitClick={() => {
                              setDialog({
                                addHoliday: false,
                                addEmployee: false
                              })

                            }}
                            onAssignedEmployeeShift={(data: any, isChecked: boolean) => {
                              handleAssignEmployeeShift(data, isChecked)
                            }}
                            currentCheckedUsers={assignedEmployeeShift?.map(item => item.user_id)}
                            handleSelectAll={handleSelectAll}
    />
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">
        {isEdit && !isPersonelEdit ? "เเก้ไข ช่วงเวลาเข้า - ออกงาน" : isPersonelEdit ? "เเก้ไข ช่วงเวลาเข้า - ออกงานรายบุคคล" : "กำหนดช่วงเวลาเข้า - ออกงาน"}
      </h2>
      {!isApprover && <div className="d-flex flex-row column-gap-3">
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                  onClick={() => {
                    setDialog({addHoliday: true, addEmployee: false})
                  }}>
              <FontAwesomeIcon icon={faPlus} className="me-2"/>
              เพิ่มวันหยุดประจำปี
          </Button>
          <Button variant="primary" className="btn-action-height btn-mof-primary px-4"
                  onClick={() => {
                    setDialog({addHoliday: false, addEmployee: true})
                  }}
                  disabled={isEdit && isPersonelEdit}
          >
              <FontAwesomeIcon icon={faPlus} className="me-2"/>
            {!isEdit ? "เพิ่มบุคลากร" : "เเก้ไข / เพิ่มบุคลากร"}
          </Button>
      </div>}
    </div>

    <Form noValidate validated={submitted} className="">
      <div className="row">
        <div className="row mt-3">
          <div className={`col-sm-12 col-md-8 pb-3`}>
            <label className="form-label required">ชื่อตารางกะ</label>
            <input type="text" className="form-control" name="shift_name" disabled={isApprover}
                   placeholder="ชื่อตารางกะ" value={shiftName} required={true}
                   onChange={(e) => {
                     setShiftName(e.target.value)
                   }}/>
            <div className="invalid-feedback">
              กรุณากรอกตารางกะ
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <label className="form-label required" style={{whiteSpace: "nowrap"}}>วันที่เริ่มต้น-สิ้นสุด</label>
            <DateRangeInput
              startDate={shiftDate.start_date ? new Date(shiftDate.start_date) : null}
              endDate={shiftDate.end_date ? new Date(shiftDate.end_date) : null}
              onChange={handleDateRangeChange}
              isNoEndDate={shiftDate.isNoEndDate}
              isError={shiftDate.start_date === null && submitted}
              disable={isApprover || false}
            />
            <input type="date" className="form-control" name="shift_date" hidden={true}
                   value={isApprover && shiftDate.start_date ? new Date(shiftDate.start_date) : shiftDate.start_date}
                   required={true} onChange={(e) => {
            }}/>
            <div className="invalid-feedback">
              กรุณาเลือกวันที่เริ่มต้น-สิ้นสุด
            </div>
            <div className="form-check mt-3">
              <input className="form-check-input" type="checkbox" value={shiftDate.isNoEndDate ? 1 : 0}
                     name="flexCheckDefault" checked={shiftDate.isNoEndDate || false}
                     disabled={isApprover || false} onChange={() => {
                setShiftDate(prevState => {
                  return {...prevState, isNoEndDate: !prevState.isNoEndDate}
                })
              }} id="same-address"/>
              <label className="form-check-label pointer" htmlFor="same-address">
                ไม่สิ้นสุด
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-2"  style={{width: "180px"}}>
            <label className="form-label required" style={{whiteSpace: "nowrap"}}>เวลาเข้างาน</label>
            <RHFTimePicker className="form-control" name="work_start_time" timeType="startTime"
                           currentTime={shiftTime.work_start_time}
                           value={shiftTime.work_start_time}
                           handleClearTime={() => {
                             setShiftTime(prevState => {
                               return {...prevState, work_start_time: ""}
                             })
                           }}
                           onTimeChange={(e) => {
                             setShiftTime(prevState => {
                               return {...prevState, work_start_time: e}
                             });
                           }}
                           isSubmited={submitted}
                           disabled={isApprover || false}
            />
            <input type="text" className="form-control" name="work_start_time" hidden={true}
                   value={shiftTime.work_start_time} required={true} onChange={() => {
            }}/>
            <div className="invalid-feedback">
              กรุณากรอกเวลาเข้างาน
            </div>
          </div>
          <div className="col-sm-12 col-md-2" style={{width: "180px"}}>
            <label className="form-label required" style={{whiteSpace: "nowrap"}}>เวลาออกงาน</label>
            <RHFTimePicker className="form-control" name="work_end_time" timeType="endTime"
                           currentTime={shiftTime.work_end_time}
                           value={shiftTime.work_end_time}
                           handleClearTime={() => {
                             setShiftTime(prevState => {
                               return {...prevState, work_end_time: ""}
                             })
                           }}
                           onTimeChange={(e) => {
                             setShiftTime(prevState => {
                               return {...prevState, work_end_time: e}
                             });
                           }}
                           isSubmited={submitted}
                           disabled={isApprover || false}
            />
            <input type="text" className="form-control" name="work_end_time" hidden={true}
                   value={shiftTime.work_end_time} required={true} onChange={() => {
            }}/>
            <div className="invalid-feedback">
              กรุณากรอกเวลาออกงาน
            </div>
          </div>
        </div>
        {/*<div className="row mt-3">*/}
        {/*  <div className="col-sm-12 col-md-4">*/}
        {/*    <label className="form-label required" style={{whiteSpace: "nowrap"}}>เวลาพักเริ่มต้น</label>*/}
        {/*    <RHFTimePicker className="form-control" name="break_time_start" timeType="breakTimeStart"*/}
        {/*                   currentTime={shiftTime.break_time_start}*/}
        {/*                   value={shiftTime.break_time_start}*/}
        {/*                   handleClearTime={() => {*/}
        {/*                     setShiftTime(prevState => {*/}
        {/*                       return {...prevState, break_time_start: ""}*/}
        {/*                     })*/}
        {/*                   }}*/}
        {/*                   onTimeChange={(e) => {*/}
        {/*                     setShiftTime(prevState => {*/}
        {/*                       return {...prevState, break_time_start: e}*/}
        {/*                     });*/}
        {/*                   }}*/}
        {/*                   isSubmited={submitted}*/}
        {/*                   disabled={isApprover || false}*/}
        {/*    />*/}
        {/*    <input type="text" className="form-control" name="break_time_start" hidden={true}*/}
        {/*           value={shiftTime.break_time_start} required={true} onChange={() => {*/}
        {/*    }}/>*/}
        {/*    <div className="invalid-feedback">*/}
        {/*      กรุณากรอกเวลาพักเริ่มต้น*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col-sm-12 col-md-4">*/}
        {/*    <label className="form-label required" style={{whiteSpace: "nowrap"}}>เวลาพักสิ้นสุด</label>*/}
        {/*    <RHFTimePicker className="form-control" name="break_time_end" timeType="breakTimeEnd"*/}
        {/*                   currentTime={shiftTime.break_time_end}*/}
        {/*                   value={shiftTime.break_time_end}*/}
        {/*                   handleClearTime={() => {*/}
        {/*                     setShiftTime(prevState => {*/}
        {/*                       return {...prevState, break_time_end: ""}*/}
        {/*                     })*/}
        {/*                   }}*/}
        {/*                   onTimeChange={(e) => {*/}
        {/*                     setShiftTime(prevState => {*/}
        {/*                       return {...prevState, break_time_end: e}*/}
        {/*                     });*/}
        {/*                   }}*/}
        {/*                   isSubmited={submitted}*/}
        {/*                   disabled={isApprover || false}*/}
        {/*    />*/}
        {/*    <input type="text" className="form-control" name="break_time_end" hidden={true}*/}
        {/*           value={shiftTime.break_time_end} required={true} onChange={() => {*/}
        {/*    }}/>*/}
        {/*    <div className="invalid-feedback">*/}
        {/*      กรุณากรอกเวลาพักสิ้นสุด*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div className="row mt-3">
        <div className="row mt-3">
          <div className="col-sm-12 col-md-5">
            {/*<div className="form-check mt-3">*/}
            {/*  <input className="form-check-input" type="checkbox"*/}
            {/*         value={shiftRepeatDetail?.find(item => item.shift_repeat_type === "work_at_company")?.repeat ? 1 : 0}*/}
            {/*         name="flexCheckDefault"*/}
            {/*         defaultChecked={approverData?.shift_repeat_details?.filter((item: any) => item.shift_repeat_type === "work_at_company")[0]?.repeat}*/}
            {/*         disabled={isApprover || false} onChange={() => {*/}
            {/*    setShiftRepeatDetail(prevState => {*/}
            {/*      return prevState?.map(item => {*/}
            {/*        // Check if it's the "work_at_company" item*/}
            {/*        if (item.shift_repeat_type === "work_at_company") {*/}
            {/*          // Toggle the "repeat" property*/}
            {/*          return {...item, repeat: !item.repeat};*/}
            {/*        }*/}
            {/*        // Return all other items unchanged*/}
            {/*        return item;*/}
            {/*      });*/}
            {/*    });*/}
            {/*  }} id="same-address1"/>*/}
            {/*  <label className="form-check-label pointer" htmlFor="same-address1">*/}
            {/*    ทำซ้ำ*/}
            {/*  </label>*/}
            {/*</div>*/}
            {/*<div className="row mt-3">*/}
            {/*  <label className="form-label required">วันทำงาน (ที่ อตก)</label>*/}
            {/*  <InputSelect options={shiftRepeatEvery} placeholder="กรุณาเลือกประเภทการวนซ้ำ" name="is_active"*/}
            {/*               onChange={(e) => {*/}
            {/*                 setShiftRepeatDetail(prevState => {*/}
            {/*                   return prevState?.map(item => {*/}
            {/*                     // Check if it's the "work_at_company" item*/}
            {/*                     if (item.shift_repeat_type === "work_at_company") {*/}
            {/*                       // Toggle the "repeat" property*/}
            {/*                       return {...item, shift_repeat_every: e.value.value || ''};*/}
            {/*                     }*/}
            {/*                     // Return all other items unchanged*/}
            {/*                     return item;*/}
            {/*                   });*/}
            {/*                 });*/}
            {/*               }} required={true}*/}
            {/*               isValidateError={false} isLeaveRequestPage={true}*/}
            {/*               value={shiftRepeatDetail?.find(item => item.shift_repeat_type === 'work_at_company')?.shift_repeat_every}*/}
            {/*               isDisable={isApprover || false}/>*/}
            {/*  {true === undefined || true === "" && submitted ?*/}
            {/*    <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>*/}
            {/*      กรุณาเลือกประเภทการลา*/}
            {/*    </div> : null}*/}
            {/*</div>*/}
              <label className="form-label" style={{fontWeight:"600",marginBottom:"0px"}}>วันทำงาน (ที่ อตก)</label>
            <div className="row p-3">
              {days.map((item, index) => (
                <DaySelectBox day={item.day}
                              onClick={() => {
                                onSelectDayBoxClick('work_at_company', item.value)
                              }}
                              isSelected={isDaySelectBoxSelected('work_at_company', item.value)}
                              isAprover={isApprover}
                />))}
            </div>
          </div>
          <div className="d-none col-sm-12 col-md-1 d-sm-none d-md-block">
            <Divider orientation="vertical" variant="middle"
                     style={{opacity: "0.2", borderColor: "black", transform: "translateX(-50%)"}}></Divider>
          </div>
          <div className="col-sm-12 col-md-5">
            {/*<div className="form-check mt-3">*/}
            {/*  <input className="form-check-input" type="checkbox"*/}
            {/*         value={shiftRepeatDetail?.find(item => item.shift_repeat_type === "work_from_home")?.repeat ? 1 : 0}*/}
            {/*         name="flexCheckDefault"*/}
            {/*         defaultChecked={approverData?.shift_repeat_details?.filter((item: any) => item.shift_repeat_type === "work_from_home")[0]?.repeat}*/}
            {/*         disabled={isApprover || false} onChange={() => {*/}
            {/*    setShiftRepeatDetail(prevState => {*/}
            {/*      return prevState?.map(item => {*/}
            {/*        // Check if it's the "work_at_company" item*/}
            {/*        if (item.shift_repeat_type === "work_from_home") {*/}
            {/*          // Toggle the "repeat" property*/}
            {/*          return {...item, repeat: !item.repeat};*/}
            {/*        }*/}
            {/*        // Return all other items unchanged*/}
            {/*        return item;*/}
            {/*      });*/}
            {/*    });*/}
            {/*  }} id="same-address2"/>*/}
            {/*  <label className="form-check-label pointer" htmlFor="same-address2">*/}
            {/*    ทำซ้ำ*/}
            {/*  </label>*/}
            {/*</div>*/}
            {/*<div className="row mt-3">*/}
            {/*  <label className="form-label required">วันทำงาน (ที่บ้าน)</label>*/}
            {/*  <InputSelect options={shiftRepeatEvery} placeholder="กรุณาเลือกประเภทการวนซ้ำ" name="is_active"*/}
            {/*               onChange={(e) => {*/}
            {/*                 setShiftRepeatDetail(prevState => {*/}
            {/*                   return prevState?.map(item => {*/}
            {/*                     // Check if it's the "work_at_company" item*/}
            {/*                     if (item.shift_repeat_type === "work_from_home") {*/}
            {/*                       // Toggle the "repeat" property*/}
            {/*                       return {...item, shift_repeat_every: e.value.value || ''};*/}
            {/*                     }*/}
            {/*                     // Return all other items unchanged*/}
            {/*                     return item;*/}
            {/*                   });*/}
            {/*                 });*/}
            {/*               }} required={true}*/}
            {/*               isValidateError={false} isLeaveRequestPage={true}*/}
            {/*               value={shiftRepeatDetail?.find(item => item.shift_repeat_type === 'work_from_home')?.shift_repeat_every}*/}
            {/*               isDisable={isApprover || false}/>*/}
            {/*  {true === undefined || true === "" && submitted ?*/}
            {/*    <div style={{color: "#ED5245", fontSize: "0.875em", marginTop: "0.25rem"}}>*/}
            {/*      กรุณาเลือกประเภทการลา*/}
            {/*    </div> : null}*/}
            {/*</div>*/}
              <label className="form-label" style={{fontWeight:"600", marginBottom:"0px"}}>วันทำงาน (ที่บ้าน)</label>
            <div className="row p-3">
              {days?.map((item, index) => (
                <DaySelectBox day={item.day}
                              onClick={() => {
                                onSelectDayBoxClick('work_from_home', item.value)
                              }}
                              isSelected={isDaySelectBoxSelected('work_from_home', item.value)}
                              isAprover={isApprover}
                />))}
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="row mt-3">
          <div className="col-4 col-md-4">
            <Button variant={null === 'information' ? 'outline-info' : 'info'}
                    onClick={() => {
                      setCurrentTab('holiday')
                    }}
                    className={`w-100 btn-action ${null === 'information' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div>
                วันหยุดประจำปี
              </div>
            </Button>
          </div>
          <div className="col-4 col-md-4">
            <Button variant={null === 'leave-history' ? 'outline-info' : 'info'}
                    onClick={() => {
                      setCurrentTab('employee')
                    }}
                    className={`w-100 btn-action ${null === 'leave-history' ? 'btn-mof-outline-info' : 'btn-mof-info'}`}>
              <div>
                บุคลากร
              </div>
            </Button>
          </div>
        </div>
      </div>

      <div className="col mt-3">
        {currentTab === 'holiday' ? holiday?.map((item, index) => {
            return <>
              <HolidayDetailSection key={index} componentWidth={80}
                                    date={convertDateToString(item?.holiday_date) || ''}
                                    description={item?.holiday_name}
                                    isDeleteButtonVisible={!isApprover}
                                    onDeleteClick={() => {
                                      setHoliday(prevState => {
                                        return prevState.filter((item, i) => i !== index)
                                      })
                                    }}
              />
            </>
          }) :
          <AssignedEmployeeShiftListPage isCheckboxDisplay={false} currentHeaders={initialHeaders.slice(1)}
                                         isCreatePage={true} createPageState={rawAssignedEmployeeShift}/>}
      </div>

      {!isApprover && <div className="row mt-4 mb-5">
          <div className="col-sm-8 col-md-8 col-lg-10">
          </div>
          <div className="d-flex justify-content-end">
              <div className="d-flex flex-row">
                  <Link to="/employee-shift/employee-shift-list">
                      <Button variant="primary" className="btn-action me-2 btn-mof-primary"
                              onClick={() => {
                              }}>
                          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
                          ย้อนกลับ
                      </Button>
                  </Link>
                  <Button variant="primary" type="button"
                          onClick={handleSubmit}
                          className="d-block btn-action"
                          style={{background: "background: #007BFF"}}
                          disabled={false}
                  >
                      บันทึก
                  </Button>
              </div>
          </div>
      </div>}
      {isApprover && <div className="row mt-4 mb-5">
          <div className="col-3 col-xs-1 col-md-0 col-sm-0 col-xl-6"></div>
          <div
              className="d-flex flex-row col-6 col-xs-12 col-sm-12 col-md-12 col-xl-6 justify-content-evenly column-gap-3">
              <div className="" style={{marginLeft: "164px"}}>
                  <Button variant="secondary" type="button"
                          onClick={() => {
                            navigate(-1)
                          }}
                          className="d-block btn-action"
                  >
                      ย้อนกลับ
                  </Button>
              </div>
            {status === "waiting_for_approver" && approverData?.approver_action_user_id === userId || status === "waiting_for_approver" && isAdmin ? <>
              <div className="">
                <Button type="button"
                        onClick={() => {
                          setRejectModal(true)
                        }}
                        className="d-block btn-action"
                        style={{backgroundColor: "red", border: "1px solid red"}}
                >
                  ไม่อนุมัติ
                </Button>
              </div>
              <div className="" style={{marginRight: "164px"}}>
                <Button type="button"
                        onClick={() => {
                          updateShiftStatus({id: approverData?.id, status: "approved"}, "approved")
                        }}
                        className="d-block btn-action"
                        style={{backgroundColor: "green", border: "1px solid green"}}
                >
                  อนุมัติ
                </Button>
              </div>
            </> : null}
          </div>
      </div>}
    </Form>

  </>
}

export default ShiftCreatePage;
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { faCloudUpload, faDownload, faEye, faFile, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../../shared/services/notification-service';
import { EmployeeSectionKey } from '../../../shared/models/employee.model';
import { apiEmployeeAttachmentService } from '../../../shared/services/employee-attachment-service';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../shared/services/employee-service';
import { fileToBase64 } from '../../../shared/utils/function-attachment';
import LoaderSpinner from '../../../shared/components/molecules/commons/loader-spinner';
import FilePreviewer from '../../../shared/components/molecules/previews/file-previewer';
import { handleEmployeeSectionInputChange } from '../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../shared/components/molecules/modals/delete-confirmation-modal';

interface SectionFileFormFormProp {
  section_key: EmployeeSectionKey;
  index: number;
}

interface File {
  file: string | File | null;
  file_name: string;
  new_file?: boolean;
}

const SectionFileForm: FC<SectionFileFormFormProp> = ({
                                                        section_key,
                                                        index
                                                      }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'job_position_view' || employeeAction.type === 'view-edit-request';
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const loaderComponent = isLoading ? <LoaderSpinner/> : null;
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(index);
  const [file, setFile] = useState<File>(employee[section_key][index] as File);

  useEffect(() => {
    if (currentIndex !== index) {
      setCurrentIndex(index);
      setFile(employee[section_key][index] as File);
    }
  }, [index]);

  const handleButtonClickUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      fileToBase64(fileList[0]).then(base64String => {
        const value = {
          target: {
            name: 'file',
            value: base64String as string
          }
        };
        const newFile = {
          target: {
            name: 'new_file',
            value: true,
          }
        };
        const fileName = {
          target: {
            name: 'file_name',
            value: fileList[0].name,
          }
        };
        setFile({
          file: base64String as string,
          file_name: fileList[0].name,
          new_file: true
        });
        handleEmployeeSectionInputChange(section_key, currentIndex, value, dispatch);
        handleEmployeeSectionInputChange(section_key, currentIndex, newFile, dispatch);
        handleEmployeeSectionInputChange(section_key, currentIndex, fileName, dispatch);
      });
    }
  };

  const handleShowPreviewModel = () => {
    setShowPreviewModal(true);
  };

  const handleClosePreviewModel = () => {
    setShowPreviewModal(false);
  };

  const handleDownload = () => {
    setIsLoading(true);
    try {
      const result = apiEmployeeAttachmentService.downloadFile(file.file as string, getFileName());
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getFileName = () => {
    if (file.new_file === true) {
      return file.file_name;
    }
    const file_extension = (file.file as string).split('.')[1];
    return `แนบไฟล์ครั้งที่ ${currentIndex + 1}.${file_extension}`;
  };

  const handleConfirmDelete = async () => {
    const deleteFile = {
      target: {
        name: 'file',
        value: null,
      }
    };
    handleEmployeeSectionInputChange(section_key, currentIndex, deleteFile, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteFile = () => {
    // Show the confirmation modal when the delete button is clicked
    setShowModal(true);
  };

  const uploadButton = () => {
    if (file.file === null) {
      return <>
        <button className="btn btn-light" type="button" disabled={disable}
                onClick={handleButtonClickUploadFile}>
          <FontAwesomeIcon icon={faCloudUpload}/>
        </button>
      </>;
    } else {
      return <>
        {/*<button className="btn btn-light" type="button" disabled={disable}*/}
        {/*        onClick={handleButtonClickUploadFile}>*/}
        <FontAwesomeIcon icon={faFile}/>
        {/*</button>*/}
        <Button variant="outline-success" type="button" className="ms-2 btn-mof-primary" onClick={handleShowPreviewModel}>
          <FontAwesomeIcon icon={faEye}/>
        </Button>
        <Button variant="outline-info" className="ms-2" type="button" onClick={handleDownload}
                hidden={file.new_file === true}>
          <FontAwesomeIcon icon={faDownload}/>
        </Button>
        {
          !disable ? <Button variant="danger" className="ms-2" onClick={handleDeleteFile}
                             hidden={disable}>
            <FontAwesomeIcon icon={faTrashCan}/>
          </Button> : <></>
        }
        <FilePreviewer
          fileUrl={file.file.toString()}
          fileName={getFileName()}
          show={showPreviewModal}
          base64String={file.new_file === true}
          onHide={handleClosePreviewModel}/>
        <DeleteConfirmationModal
          show={showModal}
          title={'ยืนยันการลบไฟล์'}
          description={'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้'}
          onHide={handleCloseModal}
          onConfirm={handleConfirmDelete}
        />
      </>;
    }

  };

  return (
    <div>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{display: 'none'}}/>
      {loaderComponent}
      {uploadButton()}
    </div>
  );
};

export default SectionFileForm;
import Cookies from 'js-cookie';

export const getToken = (): string | undefined => {
    return Cookies.get('token');
};

export const setToken = (token: string): void => {
    const isSecure = window.location.protocol === 'https:'; // Check if protocol is HTTPS

    Cookies.set('token', token, {secure: isSecure});
};

export const removeToken = (): void => {
    Cookies.remove('token');
};

import React, { useEffect, useState } from 'react';

interface ImagePreviewerProps {
    imageUrl: string | File;
    fileName: string;
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({imageUrl, fileName}) => {
    const [imageUrlString, setImageUrlString] = useState<string | null>(null);

    useEffect(() => {
        if (typeof imageUrl === 'string') {
            setImageUrlString(imageUrl);
            return;
        } else {
            const objectUrl = URL.createObjectURL(imageUrl);
            setImageUrlString(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, []);

    const readImageAsDataUrl = (file: File) => {
    };

    const renderImagePreview = () => {
        if (typeof imageUrl === 'string') {
            return <img src={imageUrlString as string}
                        className="img-fluid img-thumbnail rounded mx-auto d-block"
                        alt={`Image ${fileName}`}/>;
        } else {
            readImageAsDataUrl(imageUrl);
            return <p>Loading...</p>;
        }
    };

    return (
        <div>
            <img src={imageUrlString as string}
                 className="img-fluid img-thumbnail rounded mx-auto d-block"
                 alt={`Image ${fileName}`}/>
        </div>
    );
};

export default ImagePreviewer;

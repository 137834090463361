import apiService from './api-service';
import {ShiftRequestPayloadType, ShiftRequestType} from "../models/employee-shifts.model";

const PERMISSION_BASE_API = process.env.REACT_APP_API_URI + '/employees-shifts';

export class EmployeeShiftService {
  static async getEmployeeShiftList(data: any) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/list`, {params: data});
    return response.data;
  }

  static async getEmployeeShiftPersonelList(data: any) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/personel-list`, {params: data});
    return response.data;
  }

  static async getEmployeeShiftApproverList(data: any) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/approver-list`, {params: data});
    return response.data;
  }

  static async getEmployeeShiftById(id: number | null) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/${id}`);
    return response.data;
  }

  static async getEmployeeShiftByUserId(id: number | null) {
    const response = await apiService().get(`${PERMISSION_BASE_API}/user/${id}`);
    return response.data;
  }

  static async updateEmployeeShiftStatus(data: any) {
    const response = await apiService().patch(`${PERMISSION_BASE_API}/status`, data);
    return response.data;
  }

  static async createEmployeeShift(data: ShiftRequestPayloadType) {
    const response = await apiService().post(`${PERMISSION_BASE_API}`, data);
    return response.data;
  }

  static async updateEmployeeShift(id: number | null, data: ShiftRequestPayloadType) {
    const response = await apiService().patch(`${PERMISSION_BASE_API}/${id}`, data);
    return response.data;
  }
  static async deleteEmployeeShift(id: number) {
    const response = await apiService().delete(`${PERMISSION_BASE_API}/${id}`);
    return response.data;
  }
}


import EmployeePersonalRecord from '../components/sections/employee-personal-record';
import EmployeeNameChangeHistory from '../components/sections/employee-name-change-history';
import EmployeeEducationHistory from '../components/sections/employee-education-history';
import EmployeeTrainingHistory from '../components/sections/employee-training-history';
import EmployeeInsigniaHistory from '../components/sections/employee-insignia-history';
import EmployeePunishmentHistory from '../components/sections/employee-punishment-history';
import EmployeeOutstandingPerformanceAward from '../components/sections/employee-outstanding-performance-award';
import EmployeeResignationRetirementDeathHistory
    from '../components/sections/employee-resignation-retirement-death-history';
import EmployeeCertifier from '../components/sections/employee-certifier';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getEmployeeAction } from '../../../shared/services/employee-service';
import EmployeeFamilyDetail from '../components/sections/employee-family-detail';
import EmployeeWorkHistoryPass from '../components/sections/employee-work-history-pass';
import EmployeeJobPosition from '../components/sections/employee-job-position';
import {useEffect} from "react";

const EmployeeInformationPage = () => {
    const employeeAction = useSelector(getEmployeeAction);

    return (
        <div className="h-100">
            <Form noValidate validated={employeeAction.isSubmitted}>
                <EmployeePersonalRecord defaultKey={null}/>
                <EmployeeFamilyDetail defaultKey={null}/>
                <EmployeeNameChangeHistory defaultKey={null}/>
                <EmployeeEducationHistory defaultKey={null}/>
                <EmployeeTrainingHistory defaultKey={null}/>
                <EmployeeInsigniaHistory defaultKey={null}/>
                <EmployeePunishmentHistory defaultKey={null}/>
                <EmployeeOutstandingPerformanceAward defaultKey={null}/>
                <EmployeeResignationRetirementDeathHistory defaultKey={null}/>
                <EmployeeCertifier defaultKey={null}/>
                <EmployeeJobPosition defaultKey={null}/>
                <EmployeeWorkHistoryPass defaultKey={null}/>
            </Form>
        </div>
    );
};

export default EmployeeInformationPage;
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModuleType, PermissionModel, PermissionTable } from '../../../shared/models/permission.model';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { PermissionService } from '../../../shared/services/permission-service';
import { AxiosError } from 'axios';
import { notificationService } from '../../../shared/services/notification-service';
import { initialPermission } from '../../../shared/utils/constants';
import {useAuth} from "../../../shared/authentications/auth-context";

interface PermissionApproveModalProps {
  permissionRequestId: number;
  show: boolean;
  edited: boolean;
  onHide: () => void;
  onAction: () => void;
}

const PermissionApproveModal: FC<PermissionApproveModalProps> = ({
                                                                   permissionRequestId,
                                                                   show,
                                                                   edited,
                                                                   onHide,
                                                                   onAction,
                                                                 }) => {
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const [data, setData] = useState<PermissionModel>(initialPermission);
  const [requestData, setRequestData] = useState<PermissionModel>(initialPermission);
  const permissionModule: PermissionTable[] = [
    {
      name: 'ทะเบียนประวัติบุคลากร',
      module: 'employees',
    },
    {
      name: 'บริหารโครงสร้างองค์กร',
      module: 'organizational_structures',
    },
    {
      name: 'จัดการผู้ใช้งานทั้งหมด',
      module: 'employee_actives',
    },
    {
      name: 'คำขอผู้ใช้งานใหม่',
      module: 'employee_register_requests',
    },
    {
      name: 'คำขอรีเซ็ตรหัสผ่าน',
      module: 'employee_reset_password_requests',
    },
    {
      name: 'คำขอผู้ใช้งานแก้ไขข้อมูล',
      module: 'employee_edit_requests',
    },
    {
      name: 'อนุมัติคำขอสิทธิ์การเข้าถึง',
      module: 'permission_approve',
    },
    // {
    //   name: 'ลาออนไลน์ / ประวัติการลา',
    //   module: 'employee_leaves',
    // },
    {
      name: 'อนุมัติการลา',
      module: 'employee_leave_approve',
    },
    {
      name: 'ตั้งค่าการลา',
      module: 'employee_leave_setting',
    },
    {
      name: 'ตารางกะ',
      module: 'employee_shift_personel_view',
    },
    {
      name: 'กำหนดตารางกะ',
      module: 'employee_shift',
    },
    {
      name: 'ตารางกะรายบุคคล',
      module: 'employee_shift_personel',
    },
    {
      name: 'เข้า-ออกงานสำรอง',
      module: 'employee_reserve_checkin',
    },
    {
      name: 'จัดการเข้า-ออกงานสำรอง',
      module: 'employee_reserve_checkin_manage',
    },
    // {
    //   name: 'รายงานการเข้า-ออกงาน',
    //   module: 'employee_checkin_report',
    // },
    {
      name: 'ตรวจสอบสถานะเครื่อง',
      module: 'device_status',
    },
    {
      name: 'รายงาน',
      module: 'report',
    },
    {
      name: 'รายงานผู้ใช้งานระบบ',
      module: 'system_logs',
    },
  ];

  const {logout, permission, onSetPermission, authJwt} = useAuth();

  const userId = authJwt?.id;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await PermissionService.getPermissionRequestDetail(permissionRequestId);
      setData(result.permission_base);
      setRequestData(result.permission_request_detail);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async () => {
    startLoading();
    const data: any = {};

    if (edited) {
      data.permission_request_details = Object.keys(requestData).map(key => {
        const keyname = key as ModuleType;
        return {
          module: key,
          ...requestData[keyname],
        };
      });
    }

    try {
      const response = await PermissionService.onPermissionRequestApprove(permissionRequestId, data);
      notificationService.successNotification('อนุมัติสำเร็จ', '');
      onAction();
      // logout();
      if (response?.data?.user_id === userId) {
        logout();
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('อนุมัติไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, module: ModuleType) => {
    setRequestData(prevState => ({
      ...prevState,
      [module]: {
        ...prevState[module],
        [e.target.name]: e.target.checked,
      },
    }));
  };

  const highlightColor = (baseData: boolean, newData: boolean) => {
    if (!baseData && newData)
      return 'field-new';
    if (baseData && !newData)
      return 'field-remove';
    return '';
  };

  const displayTable = () => {
    if (isLoading) {
      return <></>;
    }
    return permissionModule.map((module, index) => {
      return <tr key={index}>
        <td className="text-center py-3">
          {module.name}
        </td>
        <td className={`align-middle ${highlightColor(data[module.module].read, requestData[module.module].read)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="read"
                 defaultChecked={requestData[module.module].read}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className={`align-middle ${highlightColor(data[module.module].create, requestData[module.module].create)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="create"
                 defaultChecked={requestData[module.module].create}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className={`align-middle ${highlightColor(data[module.module].update, requestData[module.module].update)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="update"
                 defaultChecked={requestData[module.module].update}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className={`align-middle ${highlightColor(data[module.module].delete, requestData[module.module].delete)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="delete"
                 defaultChecked={requestData[module.module].delete}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td className={`align-middle ${highlightColor(data[module.module].export, requestData[module.module].export)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="export"
                 defaultChecked={requestData[module.module].export}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
        <td
          className={`align-middle ${highlightColor(data[module.module].approve, requestData[module.module].approve)}`}>
          <input className="pointer mof-form-check-input d-block m-auto" type="checkbox" name="approve"
                 defaultChecked={requestData[module.module].approve}
                 disabled={!edited} onChange={(e) => handleOnChange(e, module.module)} id="same-address"/>
        </td>
      </tr>;
    });
  };

  return (
    <Modal show={show} onHide={onHide} centered size={'xl'}>
      <Modal.Header closeButton>
        <Modal.Title className="text-mof-primary text-center">กำหนดสิทธิ์</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaderComponent}

        <table className="table">
          <thead>
          <tr>
            <th></th>
            <th className="text-center">ดูข้อมูล</th>
            <th className="text-center">สร้าง</th>
            <th className="text-center">แก้ไข</th>
            <th className="text-center">ลบ</th>
            <th className="text-center">export</th>
            <th className="text-center">อนุมัติ</th>
          </tr>
          </thead>
          <tbody>
          {
            displayTable()
          }
          </tbody>
        </table>

        <div className="mt-2">
          <h6>คำอธิบาย</h6>
          <div className="d-flex">
            <div className="small-box field-new"></div>
            <label className="ms-2">เพิ่มใหม่</label>
          </div>
          <div className="d-flex">
            <div className="small-box field-remove"></div>
            <label className="ms-2">นำออก</label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" className="btn-action me-2" onClick={onHide}>
          <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
          ย้อนกลับ
        </Button>
        <Button variant="primary" type="button" className="btn-action btn-mof-primary me-2" onClick={onSubmit}>
          อนุมัติ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionApproveModal;
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  handleInputNumberChange,
  updateOrganizationalStructurePage,
  updateOrganizationalStructureRoot
} from '../../../shared/services/organizational-structure-service';
import DirectorForm from '../components/forms/director-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { notificationService } from '../../../shared/services/notification-service';
import SaveDraft from '../functions/save-draft';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';

const DirectorPage: FC = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [showSkipModal, setShowSkipModal] = useState(false);

  useEffect(() => {
    updateOrganizationalStructurePage('director', dispatch);
  }, []);

  const getNextLink = () => {
    if (id) {
      navigate(`/organizational-structures/${id}/deputy-director`);
    } else {
      navigate('/organizational-structures/create/deputy-director');
    }
  };

  const validateForm = () => {
    if (organizationalStructureAction.status === 'view') {
      getNextLink();
      return;
    }
    setSubmitted(true);
    const inValid = organizationalStructure.directors.some(data => {
      const isUserNotSelect = !data.is_empty && !data.user_id;
      return isUserNotSelect || !data.level || !data.tenure_start_date || !data.tenure_end_date || !organizationalStructure.name || !organizationalStructure.budget_year;
    });
    if (inValid) {
      setShowSkipModal(true);
      return;
    }
    getNextLink();
  };

  const handleCloseSkipModal = () => {
    setShowSkipModal(false);
  }

  const handleConfirmSkipModal = () => {
    getNextLink();
  }

  return (
    <Form noValidate validated={organizationalStructureAction.isSubmitted || submitted}
          className="custom-no-validation">
      <ConfirmModal
        show={showSkipModal}
        title={'กรุณากรอกข้อมูลให้ครบถ้วน'}
        description={'ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่'}
        confirmLabel={'ใช่'}
        onHide={handleCloseSkipModal}
        onConfirm={handleConfirmSkipModal}
      />
      <div className="h-100 content-background-color rounded-4 px-4 py-4">
        <div className="row">
          <div className="col-sm-12 col-md-7">
            <label className="form-label required">ชื่อโครงสร้างองค์กร</label>
            <input type="text" className="form-control" name="name" disabled={disable}
                   placeholder="" value={organizationalStructure.name || ''} required={true}
                   onChange={(e) => updateOrganizationalStructureRoot(e, dispatch)}/>
            <div className="invalid-feedback">
              กรุณากรอกชื่อโครงสร้างองค์กร
            </div>
          </div>
          <div className="col-sm-12 col-md-5">
            <label className="form-label required">ปีงบประมาณ</label>
            <input type="text" className="form-control" name="budget_year" disabled={disable}
                   maxLength={4}
                   pattern=".{4,4}"
                   placeholder="" value={organizationalStructure.budget_year || ''}
                   required={true}
                   onChange={(e) => handleInputNumberChange(e, dispatch, updateOrganizationalStructureRoot)}/>
            <div className="invalid-feedback">
              กรุณากรอกปีงบประมาณ
            </div>
          </div>
        </div>
        {
          organizationalStructure.directors.map((data, index) => {
            return <DirectorForm key={index}
                                 index={index}
                                 lastButton={index === organizationalStructure.directors.length - 1}
                                 {...data}/>;
          })
        }
      </div>

      <div className="d-flex justify-content-end my-4">
        <Link to="/organizational-structures">
          <Button variant="secondary" type="button" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        <SaveDraft/>
        <Button variant="primary" type="button" className="btn-action me-2" onClick={validateForm}>
          ถัดไป
          <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
        </Button>
      </div>
    </Form>
  );
};

export default DirectorPage;
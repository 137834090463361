import React, { ChangeEvent, FC, useEffect, useState } from 'react';

interface InputCurrency {
    value: string | number;
    name: string;
    disable: boolean;
    required: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputCurrency: FC<InputCurrency> = ({
                                              value,
                                              name,
                                              disable,
                                              required,
                                              onChange
                                          }) => {
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        setCurrency(commaCurrency(value));
    }, [value]);

    const commaCurrency = (newValue: string | number) => {
        return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = parseFloat(event.target.value.replace(/,/g, '')).toString();
        onChange(event);
    };

    return <input type="text" className="form-control" name={name}
                  placeholder="" disabled={disable} value={currency || ''} required={required}
                  onChange={handleOnChange}/>;
};

export default InputCurrency;
import {FC, useState,useEffect} from 'react';
import { Accordion } from 'react-bootstrap';
import ScrollToTopButton from '../../../../../shared/components/molecules/commons/scroll-to-top-button';
import {notificationService} from '../../../../../shared/services/notification-service';
import {AxiosError} from "axios/index";
import {ManageUserService} from "../../../../../shared/services/Report/ManageUser/manage-user";
import {useParams} from 'react-router-dom';
import {calculateAge, calculateRetirement} from '../../../../../shared/utils/function-employees';
import Moment from "moment";

const PersonnelHistory: FC = () => {

  let {employee_id} = useParams();
  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      const result = await ManageUserService.getReportEditEmployeeDetailDetail({employee_id: employee_id});
      setData(result);
      console.log(result)
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getAge = (birthday:any) => {
		if (birthday) {
			return `${calculateAge(birthday)} ปี`;
		}
		return '';
	};

  const getRetireIn = (birthday:any) => {
		if (birthday) {
			const result = calculateRetirement(birthday);
			const retire = [];
			if (result.years < 0 || result.months < 0 || result.days < 0) {
				return 'เกษียณอายุแล้ว';
			}
			if (result.years)
				retire.push(`${result.years} ปี`);
			if (result.months)
				retire.push(`${result.months} เดือน`);
			if (result.days)
				retire.push(`${result.days} วัน`);

			return retire.join(' ');
		}
		return '';
	};

  function addCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const styleNewEdit_div = "bg-success-subtle";
  const styleNewEdit_input = "form-control mt-2 border-success border-3";

  return (
    <>
      <Accordion className="mt-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={`text-mof-primary`}>
            <h5 className="text-mof-primary m-0">ประวัติส่วนตัว</h5>
          </Accordion.Header>
          <Accordion.Body>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.code === data[1]?.code ? '' : styleNewEdit_div}`}>
                <label className="form-label">รหัสพนักงาน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.code ? data[1]?.code : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.code === data[1]?.code ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.code ? data[0]?.code : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${getRetireIn(data[0]?.birthday) === getRetireIn(data[1]?.birthday) ? '' : styleNewEdit_div}`}>
                <label className="form-label">เกษียณอายุในอีก</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={getRetireIn(data[1]?.birthday)}/>
                <input type="text" className={`${styleNewEdit_input} ${getRetireIn(data[0]?.birthday) === getRetireIn(data[1]?.birthday) ? 'd-none' : ''}`} readOnly={true} disabled={true} value={getRetireIn(data[0]?.birthday) ? getRetireIn(data[0]?.birthday) : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.id_card === data[1]?.id_card ? '' : styleNewEdit_div}`}>
                <label className="form-label">เลขบัตรประจำตัวประชาชน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.id_card ? data[1]?.id_card : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.id_card === data[1]?.id_card ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.id_card ? data[0]?.id_card : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.prefix_name_th === data[1]?.prefix_name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">คำนำหน้า (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.prefix_name_th ? data[1]?.prefix_name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.prefix_name_th === data[1]?.prefix_name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.prefix_name_th ? data[0]?.prefix_name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.firstname_th === data[1]?.firstname_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.firstname_th ? data[1]?.firstname_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.firstname_th === data[1]?.firstname_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.firstname_th ? data[0]?.firstname_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.lastname_th === data[1]?.lastname_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">นามสกุล (TH)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.lastname_th ? data[1]?.lastname_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.lastname_th === data[1]?.lastname_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.lastname_th ? data[0]?.lastname_th : ''}/>
              </div>
            </div>
            <div className="row mb-1">
            <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.prefix_name_en === data[1]?.prefix_name_en ? '' : styleNewEdit_div}`}>
                <label className="form-label">คำนำหน้า (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.prefix_name_en ? data[1]?.prefix_name_en : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.prefix_name_en === data[1]?.prefix_name_en ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.prefix_name_en ? data[0]?.prefix_name_en : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.firstname_en === data[1]?.firstname_en ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.firstname_en ? data[1]?.firstname_en : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.firstname_en === data[1]?.firstname_en ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.firstname_en ? data[0]?.firstname_en : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.lastname_en === data[1]?.lastname_en ? '' : styleNewEdit_div}`}>
                <label className="form-label">นามสกุล (EN)</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.lastname_en ? data[1]?.lastname_en : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.lastname_en === data[1]?.lastname_en ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.lastname_en ? data[0]?.lastname_en : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.birthday === data[1]?.birthday ? '' : styleNewEdit_div}`}>
                <label className="form-label">วันเดือนปีเกิด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.birthday ? Moment(data[1]?.birthday).add(543, 'year').format('DD/MM/YYYY') : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.birthday === data[1]?.birthday ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[1]?.birthday ? Moment(data[0]?.birthday).add(543, 'year').format('DD/MM/YYYY') : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${getAge(data[0]?.birthday) === getAge(data[1]?.birthday) ? '' : styleNewEdit_div}`}>
                <label className="form-label">อายุ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={getAge(data[1]?.birthday)}/>
                <input type="text" className={`${styleNewEdit_input} ${getAge(data[0]?.birthday) === getAge(data[1]?.birthday) ? 'd-none' : ''}`} readOnly={true} disabled={true} value={getAge(data[0]?.birthday)}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.gender === data[1]?.gender ? '' : styleNewEdit_div}`}>
                <label className="form-label">เพศ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.gender ? data[1]?.gender==='MALE' ? 'ชาย':'หญิง' : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.gender === data[1]?.gender ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[1]?.gender ? data[0]?.gender==='MALE' ? 'ชาย':'หญิง' : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.ethnicity === data[1]?.ethnicity ? '' : styleNewEdit_div}`}>
                <label className="form-label">เชื้อชาติ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.ethnicity ? data[1]?.ethnicity : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.ethnicity === data[1]?.ethnicity ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.ethnicity ? data[0]?.ethnicity : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.nationality === data[1]?.nationality ? '' : styleNewEdit_div}`}>
                <label className="form-label">สัญชาติ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.nationality ? data[1]?.nationality : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.nationality === data[1]?.nationality ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.nationality ? data[0]?.nationality : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.religion === data[1]?.religion ? '' : styleNewEdit_div}`}>
                <label className="form-label">ศาสนา</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.religion ? data[1]?.religion : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.religion === data[1]?.religion ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.religion ? data[0]?.religion : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.phone === data[1]?.phone ? '' : styleNewEdit_div}`}>
                <label className="form-label">เบอร์โทรศัพท์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.phone ? data[1]?.phone : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.phone === data[1]?.phone ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.phone ? data[0]?.phone : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.new_email === data[1]?.new_email ? '' : styleNewEdit_div}`}>
                <label className="form-label">อีเมล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.new_email ? data[1]?.new_email : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.new_email === data[1]?.new_email ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.new_email ? data[0]?.new_email : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.blood_type === data[1]?.blood_type ? '' : styleNewEdit_div}`}>
                <label className="form-label">กรุ๊ปเลือด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.blood_type ? data[1]?.blood_type : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.blood_type === data[1]?.blood_type ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.blood_type ? data[0]?.blood_type : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.salary === data[1]?.salary ? '' : styleNewEdit_div}`}>
                <label className="form-label">เงินเดือน</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.salary ? addCommas(data[1]?.salary) : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.salary === data[1]?.salary ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.salary ? addCommas(data[0]?.salary) : ''}/>
              </div>
            </div>

            {/* กรณีเร่งด่วนติดต่อ */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">กรณีเร่งด่วนติดต่อ</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_full_name === data[1]?.emergency_contact_full_name ? '' : styleNewEdit_div}`}>
                <label className="form-label">ชื่อ - นามสกุล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_full_name ? data[1]?.emergency_contact_full_name : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_full_name === data[1]?.emergency_contact_full_name ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_full_name ? data[0]?.emergency_contact_full_name : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_tel === data[1]?.emergency_contact_tel ? '' : styleNewEdit_div}`}>
                <label className="form-label">เบอร์โทรศัพท์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_tel ? data[1]?.emergency_contact_tel : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_tel === data[1]?.emergency_contact_tel ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_tel ? data[0]?.emergency_contact_tel : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_relationship === data[1]?.emergency_contact_relationship ? '' : styleNewEdit_div}`}>
                <label className="form-label">ความสัมพันธ์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_relationship ? data[1]?.emergency_contact_relationship : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_relationship === data[1]?.emergency_contact_relationship ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_relationship ? data[0]?.emergency_contact_relationship : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_address === data[1]?.emergency_contact_address ? '' : styleNewEdit_div}`}>
                <label className="form-label">สถานที่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_address ? data[1]?.emergency_contact_address : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_address === data[1]?.emergency_contact_address ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_address ? data[0]?.emergency_contact_address : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_postcode === data[1]?.emergency_contact_postcode ? '' : styleNewEdit_div}`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_postcode ? data[1]?.emergency_contact_postcode : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_postcode === data[1]?.emergency_contact_postcode ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_postcode ? data[0]?.emergency_contact_postcode : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_province?.name_th === data[1]?.emergency_contact_province?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_province?.name_th ? data[1]?.emergency_contact_province?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_province?.name_th === data[1]?.emergency_contact_province?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_province?.name_th ? data[0]?.emergency_contact_province?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_amphure?.name_th === data[1]?.emergency_contact_amphure?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_amphure?.name_th ? data[1]?.emergency_contact_amphure?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_amphure?.name_th === data[1]?.emergency_contact_amphure?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_amphure?.name_th ? data[0]?.emergency_contact_amphure?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.emergency_contact_tambon?.name_th === data[1]?.emergency_contact_tambon?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.emergency_contact_tambon?.name_th ? data[1]?.emergency_contact_tambon?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.emergency_contact_tambon?.name_th === data[1]?.emergency_contact_tambon?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.emergency_contact_tambon?.name_th ? data[0]?.emergency_contact_tambon?.name_th : ''}/>
              </div>
            </div>

            {/* ที่อยู่ตามบัตรประชาชน */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ตามบัตรประชาชน</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.address_official === data[1]?.address_official ? '' : styleNewEdit_div}`}>
                <label className="form-label">ที่อยู่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.address_official ? data[1]?.address_official : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.address_official === data[1]?.address_official ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.address_official ? data[0]?.address_official : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.postcode_official === data[1]?.postcode_official ? '' : styleNewEdit_div}`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.postcode_official ? data[1]?.postcode_official : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.postcode_official === data[1]?.postcode_official ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.postcode_official ? data[0]?.postcode_official : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.official_province?.name_th === data[1]?.official_province?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.official_province?.name_th ? data[1]?.official_province?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.official_province?.name_th === data[1]?.official_province?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.official_province?.name_th ? data[0]?.official_province?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.official_amphure?.name_th === data[1]?.official_amphure?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.official_amphure?.name_th ? data[1]?.official_amphure?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.official_amphure?.name_th === data[1]?.official_amphure?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.official_amphure?.name_th ? data[0]?.official_amphure?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.official_tambon?.name_th === data[1]?.official_tambon?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.official_tambon?.name_th ? data[1]?.official_tambon?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.official_tambon?.name_th === data[1]?.official_tambon?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.official_tambon?.name_th ? data[0]?.official_tambon?.name_th : ''}/>
              </div>
            </div>

            {/* ที่อยู่ปัจจุบัน */}
            <h5 className="text-mof-primary m-0 my-4 pt-2">ที่อยู่ปัจจุบัน</h5>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.address_contactable === data[1]?.address_contactable ? '' : styleNewEdit_div}`}>
                <label className="form-label">ที่อยู่</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.address_contactable ? data[1]?.address_contactable : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.address_contactable === data[1]?.address_contactable ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.address_contactable ? data[0]?.address_contactable : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.postcode_contactable === data[1]?.postcode_contactable ? '' : styleNewEdit_div}`}>
                <label className="form-label">รหัสไปรษณีย์</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.postcode_contactable ? data[1]?.postcode_contactable : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.postcode_contactable === data[1]?.postcode_contactable ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.postcode_contactable ? data[0]?.postcode_contactable : ''}/>
              </div>
            </div>
            <div className="row mb-1">
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contactable_province?.name_th === data[1]?.contactable_province?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">จังหวัด</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contactable_province?.name_th ? data[1]?.contactable_province?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contactable_province?.name_th === data[1]?.contactable_province?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contactable_province?.name_th ? data[0]?.contactable_province?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contactable_amphure?.name_th === data[1]?.contactable_amphure?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">อำเภอ</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contactable_amphure?.name_th ? data[1]?.contactable_amphure?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contactable_amphure?.name_th === data[1]?.contactable_amphure?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contactable_amphure?.name_th ? data[0]?.contactable_amphure?.name_th : ''}/>
              </div>
              <div className={`col-sm-12 col-md-4 pb-3 ${data[0]?.contactable_tambon?.name_th === data[1]?.contactable_tambon?.name_th ? '' : styleNewEdit_div}`}>
                <label className="form-label">ตำบล</label>
                <input type="text" className="form-control" readOnly={true} disabled={true} value={data[1]?.contactable_tambon?.name_th ? data[1]?.contactable_tambon?.name_th : ''}/>
                <input type="text" className={`${styleNewEdit_input} ${data[0]?.contactable_tambon?.name_th === data[1]?.contactable_tambon?.name_th ? 'd-none' : ''}`} readOnly={true} disabled={true} value={data[0]?.contactable_tambon?.name_th ? data[0]?.contactable_tambon?.name_th : ''}/>
              </div>
            </div>
            
            <div className="mt-3"><ScrollToTopButton/></div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default PersonnelHistory;
import {useState, useEffect} from 'react';
import PersonnelHeader from './components/personnel-header';
import PersonnelHistory from './components/personnel-history';
import PersonnelFamilyDetail from './components/personnel-family-detail';
import PersonnelRename from './components/personnel-rename';
import PersonnelEducation from './components/personnel-education';
import PersonnelTraining from './components/personnel-training';
import PersonnelInsignia from './components/personnel-insignia';
import PersonnelPunishment from './components/personnel-punishment';
import PersonnelOutstandingAwards from './components/personnel-outstanding-awards';
import PersonnelResignationRetireDeath from './components/personnel-resignation-retire-death';
import PersonnelCertifier from './components/personnel-certifier';
import PersonnelWorkOrtokor from './components/personnel-work-ortorkor';
import PersonnelWorkHistory from './components/personnel-work-history';

const ReportPersonnelHistoryDetailPage = () => {
    const [isCheckedAll, setCheckedAll] = useState(true);
    const [isCheckedHistory, setCheckedHistory] = useState(false);
    const [isCheckedFamilyDetail, setCheckedFamilyDetail] = useState(false);
    const [isCheckedRename, setCheckedRename] = useState(false);
    const [isCheckedEducation, setCheckedEducation] = useState(false);
    const [isCheckedTraining, setCheckedTraining] = useState(false);
    const [isCheckedInsignia, setCheckedInsignia] = useState(false);
    const [isCheckedPunishment, setCheckedPunishment] = useState(false);
    const [isCheckedOutstandingAwards, setCheckedOutstandingAwards] = useState(false);
    const [isCheckedResignationRetireDeath, setCheckedResignationRetireDeath] = useState(false);
    const [isCheckedCertifier, setCheckedCertifier] = useState(false);
    const [isCheckedWorkOrtokor, setCheckedWorkOrtokor] = useState(false);
    const [isCheckedWorkHistory, setCheckedWorkHistory] = useState(false);

    useEffect(() => {
      if(isCheckedAll){
        setCheckedHistory(false);
        setCheckedFamilyDetail(false);
        setCheckedRename(false);
        setCheckedEducation(false);
        setCheckedTraining(false);
        setCheckedInsignia(false);
        setCheckedPunishment(false);
        setCheckedOutstandingAwards(false);
        setCheckedResignationRetireDeath(false);
        setCheckedCertifier(false);
        setCheckedWorkOrtokor(false);
        setCheckedWorkHistory(false);
      }
    },[isCheckedAll]);

    return (
      <>
        <div className="pt-4 pb-2">
          <PersonnelHeader 
            isCheckedAll={isCheckedAll}
            isCheckedHistory={isCheckedHistory}
            isCheckedFamilyDetail={isCheckedFamilyDetail}
            isCheckedRename={isCheckedRename}
            isCheckedEducation={isCheckedEducation}
            isCheckedTraining={isCheckedTraining}
            isCheckedInsignia={isCheckedInsignia}
            isCheckedPunishment={isCheckedPunishment}
            isCheckedOutstandingAwards={isCheckedOutstandingAwards}
            isCheckedResignationRetireDeath={isCheckedResignationRetireDeath}
            isCheckedCertifier={isCheckedCertifier}
            isCheckedWorkOrtokor={isCheckedWorkOrtokor}
            isCheckedWorkHistory={isCheckedWorkHistory}
          />
        </div>

        <hr />

        <h5 className="text-mof-primary m-0">เลือกส่วนที่ต้องการให้แสดงประวัติ</h5>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="All" checked={isCheckedAll} onChange={()=>setCheckedAll(!isCheckedAll)}/>
              <label className="form-check-label pointer" htmlFor="All">ทั้งหมด</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="History" checked={isCheckedHistory} onChange={()=>setCheckedHistory(!isCheckedHistory)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="History">ประวัติส่วนตัว</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="FamilyDetail" checked={isCheckedFamilyDetail} onChange={()=>setCheckedFamilyDetail(!isCheckedFamilyDetail)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="FamilyDetail">รายละเอียดครอบครัว</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Rename" checked={isCheckedRename} onChange={()=>setCheckedRename(!isCheckedRename)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Rename">การเปลี่ยนชื่อ</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Education" checked={isCheckedEducation} onChange={()=>setCheckedEducation(!isCheckedEducation)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Education">การศึกษา</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Training" checked={isCheckedTraining} onChange={()=>setCheckedTraining(!isCheckedTraining)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Training">การฝึกอบรม</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Insignia" checked={isCheckedInsignia} onChange={()=>setCheckedInsignia(!isCheckedInsignia)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Insignia">เครื่องราชอิสริยาภรณ์</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Punishment" checked={isCheckedPunishment} onChange={()=>setCheckedPunishment(!isCheckedPunishment)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Punishment">การลงโทษ</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="OutstandingAwards" checked={isCheckedOutstandingAwards} onChange={()=>setCheckedOutstandingAwards(!isCheckedOutstandingAwards)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="OutstandingAwards">ผลงานดีเด่น/รางวัล</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="ResignationRetireDeath" checked={isCheckedResignationRetireDeath} onChange={()=>setCheckedResignationRetireDeath(!isCheckedResignationRetireDeath)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="ResignationRetireDeath">ลาออก/เกษียณอายุ/เสียชีวิต</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="Certifier" checked={isCheckedCertifier} onChange={()=>setCheckedCertifier(!isCheckedCertifier)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="Certifier">ผู้รับรองการเข้าทำงาน</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="WorkOrtokor" checked={isCheckedWorkOrtokor} onChange={()=>setCheckedWorkOrtokor(!isCheckedWorkOrtokor)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="WorkOrtokor">ประวัติการทำงาน ของ อตก.</label>
            </div>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="WorkHistory" checked={isCheckedWorkHistory} onChange={()=>setCheckedWorkHistory(!isCheckedWorkHistory)} disabled={isCheckedAll}/>
              <label className="form-check-label pointer" htmlFor="WorkHistory">ประวัติการทำงานที่ผ่านมา</label>
            </div>
          </div>
        </div>

        <div className="h-100">
          <div className={isCheckedAll || isCheckedHistory ? 'd-block' : 'd-none'}><PersonnelHistory/></div>
          <div className={isCheckedAll || isCheckedFamilyDetail ? 'd-block' : 'd-none'}><PersonnelFamilyDetail/></div>
          <div className={isCheckedAll || isCheckedRename ? 'd-block' : 'd-none'}><PersonnelRename/></div>
          <div className={isCheckedAll || isCheckedEducation ? 'd-block' : 'd-none'}><PersonnelEducation/></div>
          <div className={isCheckedAll || isCheckedTraining ? 'd-block' : 'd-none'}><PersonnelTraining/></div>
          <div className={isCheckedAll || isCheckedInsignia ? 'd-block' : 'd-none'}><PersonnelInsignia/></div>
          <div className={isCheckedAll || isCheckedPunishment ? 'd-block' : 'd-none'}><PersonnelPunishment/></div>
          <div className={isCheckedAll || isCheckedOutstandingAwards ? 'd-block' : 'd-none'}><PersonnelOutstandingAwards/></div>
          <div className={isCheckedAll || isCheckedResignationRetireDeath ? 'd-block' : 'd-none'}><PersonnelResignationRetireDeath/></div>
          <div className={isCheckedAll || isCheckedCertifier ? 'd-block' : 'd-none'}><PersonnelCertifier/></div>
          <div className={isCheckedAll || isCheckedWorkOrtokor ? 'd-block' : 'd-none'}><PersonnelWorkOrtokor/></div>
          <div className={isCheckedAll || isCheckedWorkHistory ? 'd-block' : 'd-none'}><PersonnelWorkHistory/></div>
        </div>
      </>
    );
};

export default ReportPersonnelHistoryDetailPage;
import apiService from './api-service';

const REQUEST_BASE_API = process.env.REACT_APP_API_URI + '/employees-attendance';


export class LeaveRequestService {
  static async sendLeaveRequest(data: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/leave-request`, data);
    return response.data;
  }

  static async sendLeaveRequestStatus(data: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/leave-request-status-update`, data);
    return response.data;
  }

  static async getLeaveRequest(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave-request`, {params: data});
    return response.data;
  }

  static async getApproverLeaveRequestList(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/approver-leave-request-list`, {params: data});
    return response.data;
  }

  static async getLeaveHistoryTypeTotal(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave-history-count`, {params: data});
    return response.data;
  }

  static async getLeaveLimitByType(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave-limit-by-type`, {params: data});
    return response.data;
  }

  static async getLeaveLimitByTypeById(data: any) {
    const response = await apiService().get(`${REQUEST_BASE_API}/leave-limit-by-type-by-id`, {params: data});
    return response.data;
  }

  static async createLeaveLimit(data: any) {
    const response = await apiService().post(`${REQUEST_BASE_API}/leave-limit-by-type`, data);
    return response.data;
  }

  static async updateLeaveLimit(data: any) {
    const response = await apiService().patch(`${REQUEST_BASE_API}/leave-limit-by-type`, data);
    return response.data;
  }

  static async deleteLeaveLimit(data: any) {
    const response = await apiService().delete(`${REQUEST_BASE_API}/leave-limit-by-type`, {data});
    return response.data;
  }

  static async getApprovedLeaveList() {
    const response = await apiService().get(`${REQUEST_BASE_API}/employee-approved-leave-history`);
    return response.data;
  }

}

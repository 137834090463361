import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { RegisterForm } from '../../../shared/models/authentication.model';
import { Button, Form, Image } from 'react-bootstrap';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faDeleteLeft, faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { notificationService } from '../../../shared/services/notification-service';
import { apiAuthentication } from '../../../shared/services/authentication-service';
import { useNavigate } from 'react-router-dom';
import { convertToPositiveNumber } from '../../../shared/utils/function-validators';
import InfoModal from '../../../shared/components/molecules/modals/info-modal';


const RegisterPage: FC = () => {
    const [submitted, setSubmitted] = useState(false);
    const [form, setForm] = useState<RegisterForm>({
        id: null,
        id_card: '',
        password: '',
        confirm_password: '',
        username: '',
        firstname_en: '',
        lastname_en: '',
        current_job_position: null,
        department_name: '',
        email: '',
    });
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
    const [passwordValidator, setPasswordValidator] = useState({
        isConfirmValid: false,
        isLengthValid: false,
        isUppercaseValid: false,
        isLowercaseValid: false,
        isNumberValid: false,
        isSpecialCharValid: false,
    });
    const [hidePassword, setHidePassword] = useState(true);
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
    const [lockIdCard, setLockIdCard] = useState(false);
    const [showModal, setShowModal] = useState(false);
	const [modalError, setModalError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        validatePassword();
    }, [form.password, form.confirm_password]);

    useEffect(() => {
        if (form.id_card.length === 13) {
            onSearchByIdCard();
        }
    }, [form.id_card]);

    const onRegister = async () => {
        setSubmitted(true);
        if (
            !passwordValidator.isConfirmValid ||
            !passwordValidator.isLengthValid ||
            !passwordValidator.isUppercaseValid ||
            !passwordValidator.isLowercaseValid ||
            !passwordValidator.isNumberValid ||
            !passwordValidator.isSpecialCharValid
        ) {
            notificationService.warningNotification('กรุณากรอกรหัสผ่านตามเงื่อนไขที่ระบุ', '');
            return;
        }

        if (
            !form.id
        ) {
            notificationService.warningNotification('กรุณากรอกหมายเลขบัตรประชาชนในระบบ', '');
            return;
        }

        try {
            await apiAuthentication.onRegisterAccount(form);
            notificationService.successNotification('ลงทะเบียนเรียบร้อย', 'กรุณารอผู้ดูแลระบบตรวจสอบข้อมูล');
            navigate('/login');
        } catch (error) {
            const err = error as any;
            notificationService.dangerNotification('ระบบขัดข้อง', err.message);
        } finally {
            stopLoading();
        }
    };

    const onClickHidePassword = () => {
        setHidePassword(prevState => !prevState);
    };

    const onClickConfirmHidePassword = () => {
        setHideConfirmPassword(prevState => !prevState);
    };
    const handleOnChangeIdCard = (event: ChangeEvent<HTMLInputElement>) => {
        event.target.value = convertToPositiveNumber(event.target.value);
        setForm(data => {
            return {...data, id_card: event.target.value};
        });
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setForm(prevState => ({
            ...prevState,
            password: password
        }));
    };

    const handlePasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setForm(prevState => ({
            ...prevState,
            confirm_password: password
        }));
    };

    const validatePassword = () => {
        const regexPassword = /[!@#$%^&*()_+|~\-=`{}\[\]:";'<>?,./]/;
        // Validate the password against individual criteria
        setPasswordValidator(prevState => ({
            isConfirmValid: form.password === form.confirm_password,
            isLengthValid: form.password.length >= 8,
            isUppercaseValid: /[A-Z]/.test(form.password),
            isLowercaseValid: /[a-z]/.test(form.password),
            isNumberValid: /\d/.test(form.password),
            isSpecialCharValid: regexPassword.test(form.password),
        }));
    };

    const onSearchByIdCard = async () => {
        if (form.id_card.length !== 13) {
            notificationService.warningNotification('กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก', '');
            return;
        }
        startLoading();
        try {
            const result = await apiAuthentication.onFindAccount(form.id_card);
            setForm(prevState => ({
                ...result,
                firstname_en: result.employee.firstname_en,
                lastname_en: result.employee.lastname_en,
                current_job_position: result.employee.current_job_position,
                id_card: prevState.id_card,
                password: prevState.password,
                confirm_password: prevState.confirm_password,
            }));
            setLockIdCard(true);
        } catch (error) {
            const err = error as any;
            if (err?.response?.status === 400) {
                setShowModal(true);
				setModalError(err.response?.data?.message);
                // notificationService.warningNotification('ไม่พบข้อมูล', err.response?.data?.message, 6000);
            } else {
                notificationService.dangerNotification('ระบบขัดข้อง', err.message);
            }
            setForm(prevState => ({
                id: null,
                id_card: '',
                password: '',
                confirm_password: '',
                username: '',
                firstname_en: '',
                lastname_en: '',
                current_job_position: null,
                department_name: '',
                email: '',
            }));
        } finally {
            stopLoading();
        }
    };

    const onClearIdCard = () => {
        setForm(prevState => ({
            id: null,
            id_card: '',
            password: prevState.password,
            confirm_password: prevState.confirm_password,
            username: '',
            firstname_en: '',
            lastname_en: '',
            current_job_position: null,
            department_name: '',
            email: '',
        }));
        setLockIdCard(false);
    };

    const displayButtonIdCard = () => {
        if (lockIdCard)
            return <button className="btn btn-danger" type="button" id="id_card_search"
                           onClick={onClearIdCard}>
                <FontAwesomeIcon icon={faDeleteLeft} color={'#FFFFFF'}/>
            </button>;

        return <button className="btn btn-mof-primary" type="button" id="id_card_search"
                       onClick={onSearchByIdCard}>
            <FontAwesomeIcon icon={faSearch}/>
        </button>;
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const displayModal = () => {
        if (showModal) {
            return <InfoModal
              show={showModal}
              title={'ไม่พบข้อมูล'}
              description={modalError}
              onHide={handleCloseModal}
            />
        }
        return null;
    };

    return <Form noValidate validated={submitted} className="mb-5">
        {loaderComponent}
        {displayModal()}
        <div className="container pt-5">
            <div className="m-auto login-logo">
                <Image src="static/ORTORKOR-LOGO.png" alt="logo" className="w-100"/>
            </div>
            <div className="bg-white shadow rounded px-sm-5 px-3 py-5 register-page m-auto mt-4">
                <h4 className="text-mof-primary">
                    ลงทะเบียน
                </h4>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">หมายเลขบัตรประชาชน</label>
                        <div className="input-group">
                            <input type="text" className="form-control" id="id_card" name="id_card"
                                   required maxLength={13}
                                   pattern=".{13,13}"
                                   autoComplete="off"
                                   disabled={lockIdCard}
                                   value={form.id_card} onChange={handleOnChangeIdCard}/>
                            {displayButtonIdCard()}
                            <div className="invalid-feedback">
                                กรุณากรอกหมายเลขบัตรประชาชน
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="username">Username (สร้างชื่ออัตโนมัติ)</label>
                        <input type="text" className="form-control" id="username" name="username"
                               required disabled={true}
                               value={form.username}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">ชื่อจริง</label>
                        <input type="text" className="form-control" id="firstname_en" name="firstname_en"
                               required disabled={true}
                               value={form.firstname_en}/>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="username">นามสกุล</label>
                        <input type="text" className="form-control" id="lastname_en" name="lastname_en"
                               required disabled={true}
                               value={form.lastname_en}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">ตำแหน่ง</label>
                        <input type="text" className="form-control" id="current_job_position_name"
                               name="current_job_position_name"
                               required disabled={true}
                               value={form.current_job_position?.position_name || ''}/>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="username">แผนก</label>
                        <input type="text" className="form-control" id="department_name" name="department_name"
                               required disabled={true}
                               value={form.department_name}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">อีเมล</label>
                        <input type="text" className="form-control" id="email" name="email"
                               required disabled={true}
                               value={form.email}/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="id_card">รหัสผ่าน</label>
                        <div className="input-group ">
                            <input type={hidePassword ? 'password' : 'text'} className="form-control" id="password"
                                   name="password"
                                   required onChange={handlePasswordChange}
                                   value={form.password}/>
                            <span className="input-group-text pointer" onClick={onClickHidePassword}>
                                <FontAwesomeIcon icon={hidePassword ? faEyeSlash : faEye}/>
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <label className="form-label" htmlFor="username">ยืนยันรหัสผ่าน</label>
                        <div className="input-group ">
                            <input type={hideConfirmPassword ? 'password' : 'text'} className="form-control"
                                   id="confirm_password"
                                   name="confirm_password"
                                   required onChange={handlePasswordConfirmChange}
                                   value={form.confirm_password}/>
                            <span className="input-group-text pointer" onClick={onClickConfirmHidePassword}>
                                <FontAwesomeIcon icon={hideConfirmPassword ? faEyeSlash : faEye}/>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column mt-3">
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isConfirmValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">ยืนยันรหัสผ่านเหมือนกัน</label>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isLengthValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">อักขระอย่างน้อย 8 ตัว</label>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isUppercaseValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">ตัวอักษรพิมพ์ใหญ่ (A-Z)</label>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isLowercaseValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">ตัวอักษรพิมพ์เล็ก (a-z)</label>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isNumberValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">ตัวเลข (0-9)</label>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={passwordValidator.isSpecialCharValid ? faCircleCheck : faCircle}
                                         color={'#056310'}/>
                        <label className="ms-2">{`อักขระพิเศษ(!@#$ % ^ & * ()_+|~-=\`{}[]:";'<>?,./)`}</label>
                    </div>


                </div>

                <div className="row mt-3">
                    <div className="col-sm-12 col-md-4">
                        <Button variant="primary" type="button"
                                onClick={onRegister}
                                className="d-block w-100 btn-action-height btn-mof-primary">
                            ลงทะเบียน
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    </Form>;
};

export default RegisterPage;
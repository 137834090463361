import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare, faPlus, faSearch} from '@fortawesome/free-solid-svg-icons';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import { PermissionRequest, PermissionRequestQueryParams } from '../../shared/models/permission.model';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import { PermissionService } from '../../shared/services/permission-service';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import PermissionApproveModal from "../permissions/components/permission-approve-modal";
import {hasPermission} from "../../shared/utils/roles";
import {Link, useParams} from "react-router-dom";
import {EmployeeShiftService} from "../../shared/services/employee-shift-service";
import { ShiftRequestType} from "../../shared/models/employee-shifts.model";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import ShiftCreatePage from "./employee-shift-create-page";

interface EmployeeShiftRowProps extends ShiftRequestType {
  onAction: (status: 'approve' | 'reject', id: any) => void;
}

const EmployeeShiftApproverPage = () => {
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const { id } = useParams();

  const [data , setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    startLoading();
    try {
      const result = await EmployeeShiftService.getEmployeeShiftById(id ? parseInt(id) : null);
      if (result) {
        setData(result.rows[0]);
      }
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  return (<>
      {loaderComponent}
      <ShiftCreatePage isApprover={true} approverData={data}/>
    </>
  )
};

export default EmployeeShiftApproverPage;
import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

interface ReportModalProps {
    show: boolean;
    onHide: () => void;
    onConfirm: (report_type: 'report_1' | 'report_2' | 'report_3' | 'report_4', file_type: 'excel' | 'csv' | 'pdf') => void;
}


const EmployeeReportModal: FC<ReportModalProps> = ({
                                                       show,
                                                       onHide,
                                                       onConfirm
                                                   }) => {
    const [reportType, setReportType] = useState({
        report_1: true,
        report_2: false,
        report_3: false,
        report_4: false,
    });
    const [fileType, setFileType] = useState({
        excel: true,
        csv: false,
        pdf: false,
    });

    const handleOnHide = () => {
        onHide();
    };

    const getFileType = (): 'excel' | 'csv' | 'pdf' => {
        if (fileType.excel)
            return 'excel';
        else if (fileType.csv)
            return 'csv';
        else if (fileType.pdf)
            return 'pdf';
        else
            return 'excel';
    };
    const handleOnCLick = () => {
        if (reportType.report_1)
            onConfirm('report_1', getFileType());
        else if (reportType.report_2)
            onConfirm('report_2', getFileType());
        else if (reportType.report_3)
            onConfirm('report_3', getFileType());
        else if (reportType.report_4)
            onConfirm('report_4', getFileType());
        else
            onConfirm('report_1', getFileType());
    };

    const handleChangeReportType = (e: any) => {
        const update = {
            report_1: false,
            report_2: false,
            report_3: false,
            report_4: false,
        };
        update[e.target.id as 'report_1' | 'report_2' | 'report_3' | 'report_4'] = e.target.checked;
        setReportType(update);
    };

    const handleChangeFileType = (e: any) => {
        const update = {
            excel: false,
            csv: false,
            pdf: false,
        };
        update[e.target.id as 'excel' | 'csv' | 'pdf'] = e.target.checked;
        setFileType(update);
    };

    return <Modal show={show} onHide={handleOnHide} centered size={'lg'}>
        <Modal.Header style={{
            display: 'flex',
            justifyContent: 'left',
        }}>
            <Modal.Title>
                <h4 className="text-center text-mof-primary m-0">
                    ดาวน์โหลดรายงาน
                </h4>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-12">
                    <p className="mb-2 required">กรุณาเลือกประเภทของไฟล์</p>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={fileType.excel}
                               onChange={handleChangeFileType}
                               name="fileType"
                               id="excel"/>
                        <label className="form-check-label pointer" htmlFor="excel">
                            Excel
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={fileType.csv}
                               onChange={handleChangeFileType}
                               name="fileType"
                               id="csv"/>
                        <label className="form-check-label pointer" htmlFor="csv">
                            Csv
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={fileType.pdf}
                               onChange={handleChangeFileType}
                               name="fileType"
                               id="pdf"/>
                        <label className="form-check-label pointer" htmlFor="pdf">
                            Pdf
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <p className="mb-2 required">กรุณาเลือกประเภทของรายงานการ</p>
                </div>
                <div className="col-12">
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={reportType.report_1}
                               onChange={handleChangeReportType}
                               name="reportType"
                               id="report_1"/>
                        <label className="form-check-label pointer" htmlFor="report_1">
                            รายงานประวัติบุคลากร
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={reportType.report_2}
                               onChange={handleChangeReportType}
                               name="reportType"
                               id="report_2"/>
                        <label className="form-check-label pointer" htmlFor="report_2">
                            รายงานแสดงข้อมูลบุคลากรที่มีคุณสมบัติตามที่ต้องการ
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={reportType.report_3}
                               onChange={handleChangeReportType}
                               name="reportType"
                               id="report_3"/>
                        <label className="form-check-label pointer" htmlFor="report_3">
                            รายงานบุคลากรที่จะเกษียณอายุ
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input pointer no-validate" type="radio"
                               defaultChecked={reportType.report_4}
                               onChange={handleChangeReportType}
                               name="reportType"
                               id="report_4"/>
                        <label className="form-check-label pointer" htmlFor="report_4">
                            รายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง
                        </label>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer style={{
            display: 'flex',
            justifyContent: 'right',
        }}>
            <Button variant="secondary" className="btn-action" onClick={handleOnHide}>
                <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
                ย้อนกลับ
            </Button>
            <Button variant="primary" className="btn-action" onClick={handleOnCLick}>
                ดาวน์โหลด
            </Button>
        </Modal.Footer>
    </Modal>;
};

export default EmployeeReportModal;
import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch,faTasks} from '@fortawesome/free-solid-svg-icons';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {useNavigate} from 'react-router-dom';
import {initialResponseData} from "../../../../shared/utils/constants";
import {ManageUserService} from "../../../../shared/services/Report/ManageUser/manage-user";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import Moment from "moment";
import DateRangeInput, { DateRangeInputChange } from '../../../../shared/components/atoms/date-range-input';
import {convertDateToString} from "../../../../shared/utils/functions-date-time";

const headers: Header[] = [
  {
    label: 'Username',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ชื่อ - สกุล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อีเมล',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'เบอร์โทรศัพท์',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'วันที่แก้ไข',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'รายการแก้ไข',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportEditEmployeeDetailListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    start_date: '',
    end_date: '',
    search: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const navigate = useNavigate();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await ManageUserService.getReportEditEmployeeDetailList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  }, [queryParams.page_size, queryParams.page, queryParams.start_date, queryParams.end_date]);

  const handleDateRangeChange = (value: DateRangeInputChange) => {
    setQueryParams({
      ...queryParams,
      start_date: value.startDate ? convertDateToString(value.startDate) : '',
      end_date: value.endDate ? convertDateToString(value.endDate) : ''
    });
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานการแก้ไขข้อมูลพนักงาน</h2>
    </div>

    {loaderComponent}

    <div className="row mt-2">
      <div className="flex-row col-sm-12 col-md-4">
          <label className="form-label">ช่วงวันเริ่มต้น-สิ้นสุด</label>
          <DateRangeInput
            startDate={queryParams.start_date ? new Date(queryParams.start_date) : null}
            endDate={queryParams.end_date ? new Date(queryParams.end_date) : null}
            onChange={handleDateRangeChange}
            disable={false}
          />
      </div>
      <div className="col-sm-12 col-md-3 d-flex align-items-end mt-4">
        <input className="form-control" type="text" value={queryParams.search as string}
            placeholder="ค้นหาจากชื่อ..." name="search"
            onChange={(e) => {
              setQueryParams(prevState => {
                return {
                  ...prevState,
                  search: e.target.value || ""
                }
              })
            }}/>
      </div>
      <div className="col-sm-12 col-md-1 d-flex align-items-end mt-3">
        <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.username ? data.username : '-'}</td>
                <td>{data.prefix_name_th ? data.prefix_name_th : ''}{data.full_name_th ? data.full_name_th : '-'}</td>
                <td>{data.email ? data.email : '-'}</td>
                <td>{data.phone ? data.phone : '-'}</td>
                <td>{data.action_at ? Moment(data.action_at).utcOffset('+07:00').add(543,'year').format('DD/MM/YYYY HH:mm:ss') : '-'}</td>
                <td>
                  <Button variant="primary" type="button" className="d-block mx-4 px-3" onClick={() => {navigate(`${data.employee_id}/details`)}}>
                    <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                  </Button>
                </td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportEditEmployeeDetailListPage;
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { AllUserQueryParams, EmployeeRequestPage } from '../../../shared/models/request.model';
import { ApiResponse, SelectDropdown } from '../../../shared/models/common.model';
import {initialResponseData, UserActiveStatus, UserActiveStatusOptions} from '../../../shared/utils/constants';
import useSpinnerLoader from '../../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../../shared/components/table-header-ordering';
import TableRowNoData from '../../../shared/components/molecules/commons/table-row-no-data';
import { RequestService } from '../../../shared/services/request-service';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../../shared/services/notification-service';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../../shared/components/molecules/commons/pagination-form';
import InputSelect from '../../../shared/components/atoms/input-select';
import Moment from 'moment/moment';
import Toggle from 'react-toggle';
import { UserService } from '../../../shared/services/user-service';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';
import { hasPermission } from '../../../shared/utils/roles';
import { useAuth } from '../../../shared/authentications/auth-context';

interface EmployeeRequestPageProps extends EmployeeRequestPage {
    onChangeStatus: (status: boolean, id: any) => void;
}

const AllUsersPage: FC = () => {
    const [queryParams, setQueryParams] = useState<AllUserQueryParams>({
        search: '',
        is_active: null,
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<EmployeeRequestPage>>(initialResponseData);
    const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();

    const headers: Header[] = [
        {
            label: 'Username',
            field: 'username',
            order: null,
            isOrder: true,
        },
        {
            label: 'ชื่อ - นามสกุล',
            field: 'full_name_th',
            order: null,
            isOrder: true,
        },
        {
            label: 'อีเมล',
            field: 'email',
            order: null,
            isOrder: true,
        },
        {
            label: 'เบอร์โทรศัพท์',
            field: 'phone',
            order: null,
            isOrder: true,
        },
        {
            label: 'สถานะ',
            field: 'is_active',
            order: null,
            isOrder: true,
        },
        {
            label: 'ไม่เข้าใช้งาน',
            field: '',
            order: null,
            isOrder: false,
        },
        {
            label: 'ใช้งานครั้งล่าสุด',
            field: 'login_last_date',
            order: null,
            isOrder: true,
        }
    ];

    useEffect(() => {
        fetchData();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    const fetchData = async () => {
        startLoading();
        try {
            const result = await RequestService.getAllUser(queryParams);
            setData(result);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };
    const tableRowNoData = () => {
        if (data.rows.length === 0) {
            return <TableRowNoData colspan={7}/>;
        }
        return null;
    };

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setQueryParams(prevState => {
            return {...prevState, search: e.target.value};
        });
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    const handleSelectChange = (e: SelectDropdown) => {
        setQueryParams(prevState => ({
            ...prevState,
            is_active: e.value?.value || null,
        }));
    };

    const onGetService = (status: boolean) => {
        if (status)
            return UserService.onActive;
        return UserService.onInActive;
    };

    const onChangeStatus = async (status: boolean, id: any) => {
        startLoading();
        try {
            const data = await onGetService(status)(id);
            notificationService.successNotification('เปลี่ยนสถานะเรียบร้อย', '');
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

  useEffect(() => {
    return () => {
      setQueryParams({
        search: '',
        is_active: null,
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
      })
    };
  }, []);


    return <>
        {loaderComponent}
        <div className="row">
            {/*<div className="col-sm-12 col-md-3">*/}
            {/*    <InputSelect options={UserActiveStatus} placeholder="สถานะ" name="is_active"*/}
            {/*                 onChange={handleSelectChange}/>*/}
            {/*</div>*/}
            <div className="col-sm-12 col-md-3">
                <input className="form-control" type="text" value={queryParams.search}
                       onChange={onChangeSearch}
                       placeholder="ค้นหา..." name="search"/>
            </div>
            <div className="col-sm-12 col-md-2">
                <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
            </div>
        </div>

        <div className="overflow-auto mt-3">
            <Table striped bordered hover>
                <thead>
                <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true}/>
                </thead>
                <tbody>
                {
                    data.rows.map((data, index) => {
                        return <AllUserRow key={data.id} {...data} onChangeStatus={onChangeStatus}/>;
                    })
                }
                {tableRowNoData()}
                </tbody>
            </Table>
        </div>
        <div className="">
            <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                            onChangePageSize={onChangePageSize} onChangePage={onChangePage}/>
        </div>
    </>;
};

const AllUserRow: FC<EmployeeRequestPageProps> = ({id, user, full_name_th, phone, onChangeStatus}) => {
    const { permission} = useAuth();
    const [showConfirmModal, setConfirmModal] = useState(false);
    const [targetStatus, setTargetStatus] = useState(user.is_active);

    const getLastLoginFormat = () => {
        return user.last_login ?
            Moment(user.last_login).add(543, 'year').format('DD/MM/YYYY HH:mm:ss')
            : '';
    };

    const getLastLoginDiffDate = () => {
        return user.last_login ?
            `${Moment().diff(Moment(user.last_login), 'days')} วัน`
            : '';
    };

    const onChange = async (e: any) => {
        setConfirmModal(true);
    };

    const handleCloseModal = () => {
        setConfirmModal(false);

    };

    const onConfirm = () => {
        setConfirmModal(false);
        onChangeStatus(!targetStatus, user.id);
        setTargetStatus(!targetStatus);
    };

    const displayModal = () => {
        if (showConfirmModal) {
            return <ConfirmModal
                show={showConfirmModal}
                title={'ยืนยันการเปลี่ยนสถานะ'}
                description={'คุณแน่ใจหรือไม่ว่าต้องเปลี่ยนสถานะ'}
                onHide={handleCloseModal}
                onConfirm={onConfirm}
            />;
        }
        return;
    };

    return <tr>
        {displayModal()}
        <td>{user.username}</td>
        <td>{full_name_th}</td>
        <td>{user.email}</td>
        <td>{phone}</td>
        <td>
            <Toggle
                id="is_active"
                name="is_active"
                disabled={!hasPermission(permission, ['employee_actives'], 'approve')}
                checked={targetStatus}
                icons={false}
                onChange={onChange}/>
        </td>
        <td>
            {getLastLoginDiffDate()}
        </td>
        <td>
            {getLastLoginFormat()}
        </td>
    </tr>;
};

export default AllUsersPage;

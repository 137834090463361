import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScrollToTopButton: React.FC = () => {
    const handleScrollToTop = () => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="text-center">
            <button className="btn btn-secondary" type="button" onClick={handleScrollToTop}>
                <FontAwesomeIcon icon={faChevronUp}/>
            </button>
            <p className="text-secondary pointer mb-0" onClick={handleScrollToTop}>TOP</p>
        </div>
    );
};

export default ScrollToTopButton;
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewDepartmentOffice,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  updateOrganizationalStructurePage
} from '../../../shared/services/organizational-structure-service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import OfficeDepartmentForm from '../components/forms/office-department-form';
import SaveDraft from '../functions/save-draft';
import ConfirmModal from '../../../shared/components/molecules/modals/confirm-modal';

const OfficeDepartmentPage: FC = () => {
  const dispatch = useDispatch();
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  let {id} = useParams();
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);
  const disable = organizationalStructureAction.status === 'view';
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [showSkipModal, setShowSkipModal] = useState(false);

  useEffect(() => {
    updateOrganizationalStructurePage('office-department', dispatch);
  }, []);

  const getPreviousLink = () => {
    if (id) {
      return `/organizational-structures/${id}/deputy-director`;
    }
    return '/organizational-structures/create/deputy-director';
  };

  const getNextLink = () => {
    if (id) {
      navigate(`/organizational-structures/${id}/division-group-office`);
    } else {
      navigate('/organizational-structures/create/division-group-office');
    }
  };

  const addNewData = () => {
    addNewDepartmentOffice(
      {
        value: {
          id: null,
          director_id: null,
          director_index: null,
          deputy_director_id: null,
          deputy_director_index: null,
          code: null,
          name: null,
          power_rate: null,
          type: null,
          user_id: null,
          is_acting_in_position: false,
          is_empty: false,
          pay_account_number: null,
          level: '',
        }
      },
      dispatch
    );
  };

  const validateForm = () => {
    if (organizationalStructureAction.status === 'view') {
      getNextLink();
      return;
    }
    setSubmitted(true);
    const inValid = organizationalStructure.department_offices
      .some(data => {
        let parentNull = true;
        if (data.type === 'office') {
          parentNull = typeof data.director_index !== 'number'
        }
        if (data.type === 'department') {
          parentNull = typeof data.deputy_director_index !== 'number'
        }

        const isUserNotSelect = !data.is_empty && !data.user_id;
        return !data.name ||
          isUserNotSelect ||
          !data.code ||
          !data.type ||
          !data.level ||
          parentNull ||
          !data.pay_account_number ||
          (data.power_rate || -1) < 0;
      });
    if (inValid) {
      setShowSkipModal(true);
      return;
    }
    getNextLink();
  };

  const DisplayButton = () => {
    if (!disable) {
      return <button className="btn btn-primary d-block px-4 btn-action-height btn-mof-primary"
                     onClick={addNewData}
                     hidden={disable} type="button">
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        เพิ่มข้อมูล
      </button>;
    }
    return;
  };

  const handleCloseSkipModal = () => {
    setShowSkipModal(false);
  }

  const handleConfirmSkipModal = () => {
    getNextLink();
  }

  return (
    <Form noValidate validated={organizationalStructureAction.isSubmitted || submitted}
          className="custom-no-validation">
      <ConfirmModal
        show={showSkipModal}
        title={'กรุณากรอกข้อมูลให้ครบถ้วน'}
        description={'ต้องการข้ามกรอกข้อมูลส่วนนี้หรือไม่'}
        confirmLabel={'ใช่'}
        onHide={handleCloseSkipModal}
        onConfirm={handleConfirmSkipModal}
      />
      <div className="h-100 content-background-color rounded-4 px-4 py-4">
        {
          organizationalStructure.department_offices
            .map((data, index) => {
              return <OfficeDepartmentForm key={index}
                                           index={index}
                                           {...data}/>;
            })
        }
        <div className="row">
          <div className="col-12">
            {DisplayButton()}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end my-4">
        <Link to={getPreviousLink()}>
          <Button variant="secondary" type="button" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        <SaveDraft/>
        <Button variant="primary" type="button" className="btn-action me-2" onClick={validateForm}>
          ถัดไป
          <FontAwesomeIcon icon={faCaretRight} className="ms-2"/>
        </Button>
      </div>
    </Form>
  );
};

export default OfficeDepartmentPage;
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/AccordionContext';
import NameChangeHistoryForm from '../forms/name-change-history-form';
import { handleEmployeeSectionAddNewData } from '../../../../shared/utils/function-state';
import { useDispatch, useSelector } from 'react-redux';
import { ChildModel, NameChangeHistoryModel } from '../../../../shared/models/employee.model';
import SectionAddRow from '../section-add-row';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';

interface EmployeeNameChangeHistoryProps {
  defaultKey?: AccordionEventKey;
}

const EmployeeNameChangeHistory: FC<EmployeeNameChangeHistoryProps> = ({defaultKey = '0'}) => {
  const dispatch = useDispatch();
  const employee = useSelector(getCurrentEmployee);
  const employeeAction = useSelector(getEmployeeAction);

  const employeeEditProfile = useSelector(getCurrentEmployeeEditProfile);
  const haveSomeEditField = () => {
    if (employeeAction.type === 'view-edit-request') {
      const lengthNotSame = employee.name_change_histories.length !== employeeEditProfile.name_change_histories.length;

      if (lengthNotSame)
        return true;

      return employee.name_change_histories.some((data, index) => {
        return Object.keys(data).some(key => {
          const kk = key as keyof NameChangeHistoryModel;
          if (key === 'id' || key === 'employee_id' || key === 'created_at'  || key === 'updated_at' || key === 'from_id')
            return false;
          return employeeEditProfile.name_change_histories[index][kk] !== data[kk];
        });
      });
    }
    return false;
  };

  const onAddNewData = () => {
    const initData: NameChangeHistoryModel = {
      id: null,
      employee_id: null,
      first_name: null,
      last_name: null,
      reason: null,
      date: null,
      file: null,
      new_file: true,
      file_name: '',
      from_id: null,
    };
    handleEmployeeSectionAddNewData('name_change_histories', initData, dispatch);
  };

  return (
    <Accordion defaultActiveKey={defaultKey} className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header className={`text-mof-primary ${haveSomeEditField() ? 'accordion-warning' : ''}`}>
          <h5 className="text-mof-primary m-0">ประวัติการเปลี่ยนชื่อ</h5>
        </Accordion.Header>
        <Accordion.Body>
          <NameChangeHistoryForm/>
          <SectionAddRow onAddNewData={onAddNewData}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default EmployeeNameChangeHistory;
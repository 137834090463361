export const OrganizationalStructureStatus = {
    DRAFT: 'ร่าง',
    WAITING_FOR_APPROVE: 'รออนุมัติ',
    WAITING_FOR_EDIT: 'รอแก้ไข',
    APPROVE: 'อนุมัติ',
    CANCEL: 'ยกเลิก',
};

export type OrganizationalStructureStatusType =
    'DRAFT'
    | 'WAITING_FOR_APPROVE'
    | 'WAITING_FOR_EDIT'
    | 'APPROVE'
    | 'CANCEL';

export const getStatusName = (status: OrganizationalStructureStatusType | null) => {
    if (status === null)
        return '-';
    return OrganizationalStructureStatus[status];
};
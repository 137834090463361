import React, { FC, useEffect } from 'react';
import StepperResponsive from './components/stepper-responsive';
import { useDispatch } from 'react-redux';
import {
    organizationalStructureAfterSave,
    updateOrganizationalStructureActionSubmitted,
    updateOrganizationalStructureState,
    updateOrganizationalStructureStatus,
    updateOrganizationalStructureType
} from '../../shared/services/organizational-structure-service';
import { Outlet } from 'react-router-dom';
import { initialStateOrganizationalStructure } from '../../shared/states/organizational-structure-reducer';

const OrganizationalStructuresWritePage: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        updateOrganizationalStructureState(initialStateOrganizationalStructure, dispatch);
        updateOrganizationalStructureType('create', dispatch);
        updateOrganizationalStructureStatus('input', dispatch);
        organizationalStructureAfterSave(false, dispatch);
        updateOrganizationalStructureActionSubmitted(false, dispatch);
    }, []);

    return (
        <div>
            <div className="d-flex justify-content-between py-4">
                <h2 className="text-mof-primary m-0">โครงสร้างองค์กร</h2>
            </div>

            <StepperResponsive isCreate={true}/>

            <div className="mt-3">
                <Outlet/>
            </div>
        </div>
    );
};

export default OrganizationalStructuresWritePage;
import React, { FC } from 'react';
import { Table } from 'react-bootstrap';

interface LeaveQuotaRowProps {
    type_name: string | null;
    total_leave: string | null;
    total_use: string | null;
    total_remain: string | null;

    onEdit: boolean;
}


const LeaveQuotaForm: FC = () => {
    const onEdit = true;

    const LeaveQuotaData = [
        {
            type_name: 'การลาป่วย',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาคลอดบุตรและการลาไปช่วยเหลือภรรยาที่คลอดบุตร',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลากิจ',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาพักผ่อนประจำปี',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'มาสาย',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'ขาดงาน',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาทำหมัน',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาอุปสมบทหรือการลาไปประกอบพิธีฮัจญ์',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาเนื่องจากราชการทหาร',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาไปศึกษา ฝึกอบรม ดูงานหรือปฏิบัติการวิจัย',
            total_leave: null,
            total_use: null,
            total_remain: null,
        },
        {
            type_name: 'การลาไปต่างประเทศ',
            total_leave: null,
            total_use: null,
            total_remain: null,
        }
    ];

    return (
        <div className="overflow-auto">
            <Table bordered>
                <thead>
                <tr className="border-0">
                    <th>ประเภท</th>
                    <th>วันลาทั้งหมด</th>
                    <th>ใช้แล้ว</th>
                    <th>วันลาคงเหลือ</th>
                </tr>
                </thead>
                <tbody>
                {
                    LeaveQuotaData.map((data, index) => {
                        return <LeaveQuotaRow
                            key={index}
                            type_name={data.type_name}
                            total_leave={data.total_leave}
                            total_use={data.total_use}
                            total_remain={data.total_remain}
                            onEdit={onEdit}
                        />;
                    })
                }
                </tbody>
            </Table>
        </div>
    );
};

const LeaveQuotaRow: FC<LeaveQuotaRowProps> = ({
                                                   type_name,
                                                   total_leave,
                                                   total_use,
                                                   total_remain,
                                                   onEdit = false
                                               }) => {
    return (
        <tr className="bg-white">
            <td>{type_name}</td>
            <td>{total_leave || '-'}</td>
            <td>{total_use || '-'}</td>
            <td>{total_remain || '-'}</td>
        </tr>
    );
};

export default LeaveQuotaForm;
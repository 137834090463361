import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Alert, Button, Table, Modal } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSignIn } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';
import { apiEmployeeService } from '../../shared/services/employee-service';

const EmployeeCheckInsCheckInPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        date: '',
        date2: '',
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();
    const [showModal, setShowModal] = useState({
        visible: false,
        type: 'danger',
        head: '',
        body: '',
        date: '',
        desc: ''
    });

    const [d_now, set_d_now] = useState<any>('');
    let employeeTmp: any = null;
    const [ employee, setEmployee ] = useState<any>(null);
    const [ checkInTime, setCheckInTime ] = useState<any>(null);
    const [ checkOutTime, setCheckOutTime ] = useState<any>(null);
    const [ checkInOutText, setCheckInOutText ] = useState<any>(null);

    const fetchEmployeeMe = async () => {
        startLoading();
        try {
            const employee2 = await apiEmployeeService.getEmployeeMe();
            setEmployee(employee2);
            employeeTmp = employee2;
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const fetchCheckIn = async (data: any) => {
        startLoading();
        let now = Moment().format('YYYY-MM-DD');
        try {
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/v2/get-all-users-for-everyday`, {
                params: {
                    code: data.first ? employeeTmp.code : employee.code,
                    date: now,
                    date2: now
                }
            });
            let v = response.data.rows.length > 0 ? response.data.rows[0] : null;
            if(v) {
                setCheckInTime(v.scan_in_time);
                setCheckOutTime(v.scan_out_time);
            }
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const apiCheckIn = async () => {
        startLoading();
        let d = {
            user_id: '-1',
            user_name: employee.code,
            date: Moment().utc().add(7, 'hour').format('YYYY-MM-DDTHH:mm:ss.000') + 'Z'
        }
        stopLoading();
        try {
            const response = await apiService().post(`${process.env.REACT_APP_API_URI}/employees-check-ins/check-in-v3`, {
                scan_type_2: 'backup',
                record: d
            });
            let d2: any = Moment(response.data.record.date).utc().format('HH:mm');
            setShowModal({
                visible: true,
                type: 'success',
                head: 'สำเร็จ',
                body: '1',
                date: d2,
                desc: ''
            });
            //notificationService.successNotification('สำเร็จ', 'ลงเวลาสำเร็จ');
            fetchCheckIn({});
        } catch (error: any) {
            //const err = error as AxiosError;
            setShowModal({
                visible: true,
                type: 'danger',
                head: 'ไม่สำเร็จ',
                body: '2', // err.message
                date: '',
                desc: error.response.data.message
            });
            //notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    useEffect(() => {
        let f = async () => {
            await fetchEmployeeMe();
            await fetchCheckIn({ first: true });
            setInterval(() => {
                let m = Moment();
                let a = m.format('HH : mm : ss');
                let b = parseInt(m.format('HHmm'));
                set_d_now(a);
                setCheckInOutText(b < 1300 ? 'เข้างาน' : 'ออกงาน');
            }, 500);
        }
        f();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    //

    const displayModal = () => {
        const handleClose = () => {
            setShowModal({
                ...showModal,
                visible: false
            });
        }
        const body1 = () => {
            if(showModal.body == '1') {
                return <div className="text-center">ลงเวลาสำเร็จ<br/><div className="mt-2" style={{ fontSize: '2.0rem', fontWeight: 700 }}>{ showModal.date } น.</div></div>
            } else if(showModal.body == '2') {
                return <div className="text-center">ลงเวลาไม่สำเร็จ<br/><div className="text-danger mt-2">{ showModal.desc }</div></div>
            }
        }
        return (
            <div
                className="modal show"
                style={{ display: 'block', position: 'initial' }}
            >
                <Modal
                    show={ showModal.visible }
                    onHide={handleClose}
                    >
                <Modal.Header closeButton>
                    <Modal.Title>{ showModal.head }</Modal.Title>
                </Modal.Header>
        
                <Modal.Body>
                    <div>{ body1() }</div>
                </Modal.Body>
        
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>ปิด</Button>
                </Modal.Footer>
                </Modal>
            </div>
            );
    }

    //

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">เข้า-ออกงานสำรอง</h2>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-3">
                <Button variant="primary" type="button" className={'px-4 ' + (employee ? 'd-block' : 'hidden') } style={{ borderRadius: '100%', width: '100px', height: '100px' }} onClick={apiCheckIn}>
                    <FontAwesomeIcon icon={faSignIn}></FontAwesomeIcon>
                    <br></br>
                    <span>{ checkInOutText }</span>
                </Button>
                <div className="mt-1" style={{ width: '100px', textAlign: 'center' }}>{d_now}</div>
            </div>
            <div className="col-sm-12 col-md-9">
                <div>เวลาเข้างาน ณ ปัจจุบัน ( { checkInTime ? checkInTime : '-' } )</div>
                <div>เวลาออกงาน ณ ปัจจุบัน ( { checkOutTime ? checkOutTime : '-' } )</div>
            </div>
        </div>
        <div>
            
        </div>
        { displayModal() }
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsCheckInPage;
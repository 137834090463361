import React, { FC, useEffect, useState } from 'react';
import StepperResponsive from './components/stepper-responsive';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiOrganizationalStructureService,
  getCurrentOrganizationalStructure,
  getCurrentOrganizationalStructureAction,
  organizationalStructureAfterSave,
  updateOrganizationalStructureActionSubmitted,
  updateOrganizationalStructureState,
  updateOrganizationalStructureStatus,
  updateOrganizationalStructureType
} from '../../shared/services/organizational-structure-service';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios/index';
import { notificationService } from '../../shared/services/notification-service';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import {
  OrganizationalStructureModel,
  OrganizationalStructureModelReform
} from '../../shared/models/organizational-structure.model';
import { convertBackendToFrontend } from './functions/convert-data';
import { useAuth } from '../../shared/authentications/auth-context';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCheckCircle, faSquareUpRight } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../../shared/components/molecules/modals/confirm-modal';
import InputReasonModal from '../../shared/components/molecules/modals/input-reason-modal';
import { hasPermission } from '../../shared/utils/roles';

interface OrganizationalStructuresAction {
  showConfirmModal: boolean,
  type: null | 'duplicate' | 'publish' | 'reject' | 'approve' | 'terminate'
}

const OrganizationalStructuresDetailPage: FC = () => {
  let {id} = useParams();
  const dispatch = useDispatch();
  const {loaderComponent, isLoading, startLoading, stopLoading} = useSpinnerLoader();
  const organizationalStructureAction = useSelector(getCurrentOrganizationalStructureAction);

  const [action, setAction] = useState<OrganizationalStructuresAction>({
    showConfirmModal: false,
    type: null
  });
  const organizationalStructure = useSelector(getCurrentOrganizationalStructure);
  const [createdBy, setCreatedBy] = useState<number | null>(null);
  const {authJwt, permission} = useAuth();
  const isAdmin = authJwt?.role === 'ADMIN';
  const navigate = useNavigate();

  const convertDataBackToState = (dataMaster: OrganizationalStructureModelReform): OrganizationalStructureModel => {
    const deputy_directors = dataMaster.deputy_directors.map(data => {
      return {
        ...data
      };
    });

    const {
      department_offices,
      division_group_offices,
      structure_departments,
      structure_positions,
      personnels,
    } = convertBackendToFrontend(dataMaster);

    return {
      id: dataMaster.id,
      name: dataMaster.name,
      budget_year: dataMaster.budget_year,
      status: dataMaster.status,
      active: dataMaster.active,
      created_by: dataMaster.created_by,
      directors: dataMaster.directors,
      deputy_directors: deputy_directors,
      department_offices: department_offices,
      division_group_offices: division_group_offices,
      structure_departments: structure_departments,
      structure_positions: structure_positions,
      personnels: personnels,
    };
  };

  const fetchData = async () => {
    startLoading();
    try {
      const organizationalStructure = await apiOrganizationalStructureService.getOrganizationalStructureDetail(Number(id));

      const data = convertDataBackToState(organizationalStructure.data);
      setCreatedBy(organizationalStructure.data.created_by);

      updateOrganizationalStructureState(data, dispatch);
      if (organizationalStructureAction.type !== 'edit') {
        updateOrganizationalStructureType('view', dispatch);
        updateOrganizationalStructureStatus('view', dispatch);
      }
      organizationalStructureAfterSave(false, dispatch);
      updateOrganizationalStructureActionSubmitted(false, dispatch);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleConfirmActionModal = async (reason: string = '-') => {
    if (id) {
      let messageSuccess = '';
      let messageError = '';
      let service;

      if (action.type === 'reject') {
        messageSuccess = 'ส่งกลับแก้ไขสำเร็จ';
        messageError = 'ส่งกลับแก้ไขไม่สำเร็จ';
        service = apiOrganizationalStructureService.rejectOrganizationalStructure(parseInt(id), reason);
      } else if (action.type === 'approve') {
        messageSuccess = 'อนุมัติสำเร็จ';
        messageError = 'อนุมัติไม่สำเร็จ';
        service = apiOrganizationalStructureService.approveOrganizationalStructure(parseInt(id));
      } else if (action.type === 'terminate') {
        messageSuccess = 'ไม่อนุมัติสำเร็จ';
        messageError = 'ไม่อนุมัติไม่สำเร็จ';
        service = apiOrganizationalStructureService.terminateOrganizationalStructure(parseInt(id), reason);
      }

      startLoading();
      try {
        const result = await service;
        notificationService.successNotification(messageSuccess, '');
        fetchData();
        navigate('/organizational-structures');
      } catch (error) {
        const err = error as AxiosError;
        notificationService.dangerNotification(messageError, err.message);
      } finally {
        stopLoading();
        setAction({
          showConfirmModal: false,
          type: null
        });
      }
    }
  };

  const displayAllModal = () => {
    if (action.showConfirmModal) {
      let confirmTitle = '';
      let confirmDescription = '';

      if (action.type === 'duplicate') {
        confirmTitle = 'ทำสำเนาโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการทำสำเนาโครงสร้างองค์กรนี้';
      } else if (action.type === 'publish') {
        confirmTitle = 'ส่งอนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการส่งอนุมัติโครงสร้างองค์กรนี้';
      } else if (action.type === 'reject') {
        confirmTitle = 'ส่งกลับแก้ไขโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการส่งกลับแก้ไขโครงสร้างองค์กรนี้';
      } else if (action.type === 'approve') {
        confirmTitle = 'อนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการอนุมัติโครงสร้างองค์กรนี้';
      } else if (action.type === 'terminate') {
        confirmTitle = 'ไม่อนุมัติโครงสร้างองค์กร';
        confirmDescription = 'คุณแน่ใจหรือไม่ว่าต้องการไม่อนุมัติโครงสร้างองค์กรนี้';
      }

      if (['reject', 'terminate'].includes(action.type as string)) {
        return <InputReasonModal
          show={action.showConfirmModal}
          title={confirmTitle}
          description={confirmDescription}
          onHide={handleCloseAllModal}
          onConfirm={handleConfirmActionModal}
        />;
      }

      return <ConfirmModal
        show={action.showConfirmModal}
        title={confirmTitle}
        description={confirmDescription}
        onHide={handleCloseAllModal}
        onConfirm={handleConfirmActionModal}
      />;
    }
    return null;
  };

  const handleCloseAllModal = () => {
    setAction({
      showConfirmModal: false,
      type: null
    });
  };

  const handleOnReject = () => {
    setAction({
      showConfirmModal: true,
      type: 'reject'
    });
  };

  const handleOnApprove = () => {
    setAction({
      showConfirmModal: true,
      type: 'approve'
    });
  };

  const handleOnTerminate = () => {
    setAction({
      showConfirmModal: true,
      type: 'terminate'
    });
  };

  const displayButtonAction = () => {
    if (organizationalStructure.status === 'WAITING_FOR_APPROVE') {
      return <div>
        <Link to="/organizational-structures">
          <Button variant="secondary" className="btn-action me-2">
            <FontAwesomeIcon icon={faCaretLeft} className="me-2"/>
            ย้อนกลับ
          </Button>
        </Link>
        {
          hasPermission(permission, ['organizational_structures'], 'approve') ?
            <>
              <Button variant="secondary" type="button" className="btn-action me-2" onClick={handleOnReject}>
                <FontAwesomeIcon icon={faSquareUpRight} className="me-2"/>
                ส่งกลับแก้ไข
              </Button>
              <Button variant="success" type="button" className="btn-action me-2 text-white"
                      onClick={handleOnApprove}>
                <FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
                อนุมัติ
              </Button>
            </> : null
        }
        {/*<Button variant="danger" type="button" className="btn-action me-2 text-white"*/}
        {/*        onClick={handleOnTerminate}>*/}
        {/*    <FontAwesomeIcon icon={faCircleXmark} className="me-2"/>*/}
        {/*    ไม่อนุมัติ*/}
        {/*</Button>*/}
      </div>;
    }
    return null;
  };

  return (
    <div>
      {loaderComponent}
      {displayAllModal()}
      <div className="d-flex justify-content-between py-4">
        <h2 className="text-mof-primary m-0">โครงสร้างองค์กร</h2>
        {displayButtonAction()}
      </div>

      {
        createdBy === authJwt?.employee_id ? <StepperResponsive isCreate={false} id={id}/> : null
      }

      <div className="mt-3">
        <Outlet/>
      </div>
    </div>
  );
};

export default OrganizationalStructuresDetailPage;
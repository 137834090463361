import EmployeeCard from './components/employee-card';
import EmployeeTabMenu from './components/employee-tab-menu';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    employeeActionAfterSave,
    getEmployeeAction,
    updateEmployeeActionStatus,
    updateEmployeeActionSubmitted,
    updateEmployeeActionType,
    updateEmployeeState
} from '../../shared/services/employee-service';
import { initialStateEmployee } from '../../shared/states/employee-reducer';

const EmployeeWritePage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const initSetting = async () => {
            updateEmployeeActionType('create', dispatch);
            updateEmployeeActionStatus('input', dispatch);
            updateEmployeeActionSubmitted(false, dispatch);
            updateEmployeeState(initialStateEmployee, dispatch);
            employeeActionAfterSave(false, dispatch);
        };
        initSetting();
    }, []);


    const employeeAction = useSelector(getEmployeeAction);

    const navigate = useNavigate();

    useEffect(() => {
        if (['create', 'job_position'].includes(employeeAction.type) && employeeAction.status === 'after_process') {
            navigate(`/employees/`);
        }
    }, [employeeAction]);
    const onChangeTab = (tabMenu: number) => {

    };

    return (
        <div className="pb-5">
            <div className="pt-4 pb-2">
                <EmployeeCard/>
            </div>
            <hr/>
            <Outlet/>
        </div>
    );
};

export default EmployeeWritePage;
import React, { FC } from 'react';
import InputSelect from '../../../../../shared/components/atoms/input-select';
import {
    PrefixNameContactSpouseOptions,
    PrefixNameFeMaleOptions, PrefixNameMaleOptions,
    PrefixNameTHOptions
} from '../../../../../shared/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCurrentEmployee,
    getCurrentEmployeeEditProfile,
    getEmployeeAction
} from '../../../../../shared/services/employee-service';
import { handleEmployeeInputChange, handleEmployeeInputSelectChange } from '../../../../../shared/utils/function-state';

const FamilyDetailForm: FC = () => {
    const dispatch = useDispatch();
    const employeeAction = useSelector(getEmployeeAction);
    const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
    const employee = useSelector(employeeStateTarget);
    const oldEmployee = useSelector(getCurrentEmployee);

    const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';

    return (
        <div>
            <h5 className="text-mof-primary m-0">บิดา</h5>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_father_prefix_name !== oldEmployee.contact_father_prefix_name ? 'field-warning' : ''}`}>
                    <label className="form-label required">คำนำหน้า (TH)</label>
                    <InputSelect options={PrefixNameMaleOptions} placeholder="กรุณาเลือก"
                                 name="contact_father_prefix_name"
                                 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
                                 isDisable={disable} isClearable={false} value={employee.contact_father_prefix_name}/>
                    <input type="text" className="form-control" name="contact_father_prefix_name" hidden={true}
                           value={employee.contact_father_prefix_name || ''} required={true} onChange={() => {
                    }}/>
                    <div className="invalid-feedback">
                        กรุณาเลือกคำนำหน้า (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_father_firstname !== oldEmployee.contact_father_firstname ? 'field-warning' : ''}`}>
                    <label className="form-label required">ชื่อ (TH)</label>
                    <input type="text" className="form-control" name="contact_father_firstname"
                           placeholder="" disabled={disable} value={employee.contact_father_firstname || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกชื่อ (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_father_lastname !== oldEmployee.contact_father_lastname ? 'field-warning' : ''}`}>
                    <label className="form-label required">นามสกุล (TH)</label>
                    <input type="text" className="form-control" name="contact_father_lastname"
                           placeholder="" disabled={disable} value={employee.contact_father_lastname || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกนามสกุล (TH)
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_father_status !== oldEmployee.contact_father_status ? 'field-warning' : ''}`}>
                    <label className="form-label required">สถานภาพ</label>
                    <input type="text" className="form-control" name="contact_father_status"
                           placeholder="" disabled={disable} value={employee.contact_father_status || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกสถานภาพ
                    </div>
                </div>
            </div>
            <h5 className="text-mof-primary m-0 my-4 pt-2">มารดา</h5>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_mother_prefix_name !== oldEmployee.contact_mother_prefix_name ? 'field-warning' : ''}`}>
                    <label className="form-label required">คำนำหน้า (TH)</label>
                    <InputSelect options={PrefixNameFeMaleOptions} placeholder="กรุณาเลือก"
                                 name="contact_mother_prefix_name"
                                 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
                                 isDisable={disable} isClearable={false} value={employee.contact_mother_prefix_name}/>
                    <input type="text" className="form-control" name="contact_mother_prefix_name" hidden={true}
                           value={employee.contact_mother_prefix_name || ''} required={true} onChange={() => {
                    }}/>
                    <div className="invalid-feedback">
                        กรุณาเลือกคำนำหน้า (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_mother_firstname !== oldEmployee.contact_mother_firstname ? 'field-warning' : ''}`}>
                    <label className="form-label required">ชื่อ (TH)</label>
                    <input type="text" className="form-control" name="contact_mother_firstname"
                           placeholder="" disabled={disable} value={employee.contact_mother_firstname || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกชื่อ (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_mother_lastname !== oldEmployee.contact_mother_lastname ? 'field-warning' : ''}`}>
                    <label className="form-label required">นามสกุล (TH)</label>
                    <input type="text" className="form-control" name="contact_mother_lastname"
                           placeholder="" disabled={disable} value={employee.contact_mother_lastname || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกนามสกุล (TH)
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_mother_status !== oldEmployee.contact_mother_status ? 'field-warning' : ''}`}>
                    <label className="form-label required">สถานภาพ</label>
                    <input type="text" className="form-control" name="contact_mother_status"
                           placeholder="" disabled={disable} value={employee.contact_mother_status || ''}
                           required={true}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกสถานภาพ
                    </div>
                </div>
            </div>
            <h5 className="text-mof-primary m-0 my-4 pt-2">คู่สมรส</h5>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_spouse_prefix_name !== oldEmployee.contact_spouse_prefix_name ? 'field-warning' : ''}`}>
                    <label className="form-label">คำนำหน้า (TH)</label>
                    <InputSelect options={PrefixNameContactSpouseOptions} placeholder="กรุณาเลือก" name="contact_spouse_prefix_name"
                                 onChange={e => handleEmployeeInputSelectChange(e, dispatch)}
                                 isDisable={disable} isClearable={false} value={employee.contact_spouse_prefix_name}/>
                    <input type="text" className="form-control" name="contact_spouse_prefix_name" hidden={true}
                           value={employee.contact_spouse_prefix_name || ''} onChange={() => {
                    }}/>
                    <div className="invalid-feedback">
                        กรุณาเลือกคำนำหน้า (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_spouse_firstname !== oldEmployee.contact_spouse_firstname ? 'field-warning' : ''}`}>
                    <label className="form-label">ชื่อ (TH)</label>
                    <input type="text" className="form-control" name="contact_spouse_firstname"
                           placeholder="" disabled={disable} value={employee.contact_spouse_firstname || ''}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกชื่อ (TH)
                    </div>
                </div>
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_spouse_lastname !== oldEmployee.contact_spouse_lastname ? 'field-warning' : ''}`}>
                    <label className="form-label">นามสกุล (TH)</label>
                    <input type="text" className="form-control" name="contact_spouse_lastname"
                           placeholder="" disabled={disable} value={employee.contact_spouse_lastname || ''}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกนามสกุล (TH)
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className={`col-sm-12 col-md-4 pb-3 ${employee.contact_spouse_status !== oldEmployee.contact_spouse_status ? 'field-warning' : ''}`}>
                    <label className="form-label">สถานภาพ</label>
                    <input type="text" className="form-control" name="contact_spouse_status"
                           placeholder="" disabled={disable} value={employee.contact_spouse_status || ''}
                           onChange={(e) => handleEmployeeInputChange(e, dispatch)}/>
                    <div className="invalid-feedback">
                        กรุณากรอกสถานภาพ
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FamilyDetailForm;
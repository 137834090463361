import {FC, useEffect, useState} from 'react';
import useSpinnerLoader from '../../../../shared/components/spinner-loader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Table} from 'react-bootstrap';
import {notificationService} from '../../../../shared/services/notification-service';
import {initialResponseData} from "../../../../shared/utils/constants";
import {RegistrationPersonnelHistoryService} from "../../../../shared/services/Report/RegistrationPersonnelHistory/registration-personnel-history";
import TableHeaderOrdering, {Header} from "../../../../shared/components/table-header-ordering";
import PaginationForm from "../../../../shared/components/molecules/commons/pagination-form";
import TableRowNoData from "../../../../shared/components/molecules/commons/table-row-no-data";
import {AxiosError} from "axios/index";
import InputSelect from "../../../../shared/components/atoms/input-select";
import { Link } from 'react-router-dom';
import {faFileExport} from '@fortawesome/free-solid-svg-icons';
import {
  fetchStructureDepartmentsOfficeNameOptions,
  fetchStructureDivisionGroupOfficeNameOptions,
  fetchStructureDepartmentsNameOptions,
  fetchStructurePositionNameOptions,

  structure_department_offices_name_Options,
  structure_division_group_office_name_Options, 
  structure_departments_name_Options, 
  structure_position_name_Options, 
} from '../../OptionsDropDown';

import * as XLSX from 'xlsx';

const headers: Header[] = [
  {
    label: 'สำนัก/ฝ่าย',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'กอง/กลุ่ม',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'แผนก',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'ตำแหน่ง',
    field: '',
    order: null,
    isOrder: false,
  },
  {
    label: 'อัตรากำลังที่ว่าง',
    field: '',
    order: null,
    isOrder: false,
  }
];

const ReportPersonnelJobVacancyListPage: FC = () => {
  const [data, setData] = useState(initialResponseData);
  const [dataExport, setDataExport] = useState(initialResponseData);
  const [queryParams, setQueryParams] = useState({
    department_offices_name: '',
    division_group_office_name: '',
    structure_departments_name: '',
    position_name: '',
    page: 1,
    page_size: 10,
  });

  const {loaderComponent, startLoading, stopLoading} = useSpinnerLoader();

  const fetchData = async () => {
    startLoading();
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelJobVacancyList(queryParams);
      setData(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    } finally {
      stopLoading();
    }
  };

  const fetchDataExport = async () => {
    try {
      const result = await RegistrationPersonnelHistoryService.getReportPersonnelJobVacancyList({page: 1,page_size: 100000,});
      setDataExport(result);
    } catch (error) {
      const err = error as AxiosError;
      notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
    }
  };

  const tableRowNoData = () => {
    if (data.rows?.length === 0) {
      return <TableRowNoData colspan={headers.length}/>;
    }
    return null;
  };

  const onChangePageSize = (page_size: number) => {
    setQueryParams({
      ...queryParams,
      page_size: page_size
    });
  };

  const onChangePage = (page: number) => {
    setQueryParams({
      ...queryParams,
      page: page
    });
  };

  useEffect(() => {
    fetchData();
  },[
    queryParams.department_offices_name,
    queryParams.division_group_office_name,
    queryParams.structure_departments_name,
    queryParams.position_name,
    queryParams.page_size, 
    queryParams.page,
  ]);

  useEffect(() => {
    fetchStructureDepartmentsOfficeNameOptions();
    fetchStructureDivisionGroupOfficeNameOptions();
    fetchStructureDepartmentsNameOptions();
    fetchStructurePositionNameOptions();

    fetchDataExport();
  }, []);

  const exportToExcel = () => {
    const DataForExcel = [];
    DataForExcel.push(headers.map(item => item.label));
    dataExport.rows?.map((data:any)=>{
      DataForExcel.push([
        data.structure_department?.department_office?.name ? data.structure_department?.department_office?.name : '-',
        data.structure_department?.division_group_office?.name ? data.structure_department?.division_group_office?.name : '-',
        data.name ? data.name : '-',
        data.structure_department?.name ? data.structure_department?.name : '-',
        data.power_rate ? data.power_rate : '-',
      ])
    })

    const ws = XLSX.utils.aoa_to_sheet(DataForExcel);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'รายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง.xlsx');
  };

  return <div>
    <div className="d-flex justify-content-between py-4">
      <h2 className="text-mof-primary m-0">รายงานข้อมูลตำแหน่งที่ว่างของบุคลากรในโครงสร้าง</h2>
      <Link to="#">
        <Button variant="primary" className="btn-action btn-mof-primary" onClick={exportToExcel}>
          <FontAwesomeIcon icon={faFileExport} className="me-2"/>
          Export
        </Button>
      </Link>
    </div>

    {loaderComponent}

    <div className="row">
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">สำนัก/ฝ่าย</label>
        <InputSelect value={queryParams.department_offices_name} options={structure_department_offices_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                department_offices_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">กอง/กลุ่ม</label>
        <InputSelect value={queryParams.division_group_office_name} options={structure_division_group_office_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                division_group_office_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">แผนก</label>
        <InputSelect value={queryParams.structure_departments_name} options={structure_departments_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                structure_departments_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
      <div className="flex-row col-sm-12 col-md-3">
        <label className="form-label">ตำแหน่ง</label>
        <InputSelect value={queryParams.position_name} options={structure_position_name_Options} placeholder="" name="is_active"
          onChange={(e:any) => {
            setQueryParams(prevState => {
              return {
                ...prevState,
                position_name: e.value.value || ""
              }
            })
          }} 
          required={true}
          isValidateError={false}/>
      </div>
    </div>

    <div className="overflow-auto mt-3">
      <Table striped bordered>
        <thead>
          <TableHeaderOrdering headers={headers} onChangeOrder={() => {}} isShow={true}/>
        </thead>
        <tbody>
          {
            data.rows?.map((data: any,index) => {
              return <tr key={index}>
                <td>{data.structure_department?.department_office?.name ? data.structure_department?.department_office?.name : '-'}</td>
                <td>{data.structure_department?.division_group_office?.name ? data.structure_department?.division_group_office?.name : '-'}</td>
                <td>{data.structure_department?.name ? data.structure_department?.name : '-'}</td>
                <td>{data.name ? data.name : '-'}</td>
                <td>{data.power_rate ? data.power_rate : '-'}</td>
              </tr>
            })
          }
          {tableRowNoData()}
        </tbody>
      </Table>
    </div>

    <div className="">
      <PaginationForm 
        current_page={queryParams.page} 
        count={data.count} 
        page_size={queryParams.page_size}
        onChangePageSize={onChangePageSize} 
        onChangePage={onChangePage}
      />
    </div>

  </div>;
}

export default ReportPersonnelJobVacancyListPage;
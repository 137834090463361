import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ApiResponse } from '../../shared/models/common.model';
import { initialResponseData } from '../../shared/utils/constants';
import useSpinnerLoader from '../../shared/components/spinner-loader';
import TableHeaderOrdering, { Header, OrderData } from '../../shared/components/table-header-ordering';
import { Button, Table } from 'react-bootstrap';
import { AxiosError } from 'axios';
import { notificationService } from '../../shared/services/notification-service';
import TableRowNoData from '../../shared/components/molecules/commons/table-row-no-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import PaginationForm from '../../shared/components/molecules/commons/pagination-form';
import Moment from 'moment/moment';
import { useAuth } from '../../shared/authentications/auth-context';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { EmployeeShiftService } from "../../shared/services/employee-shift-service";
import { ShiftRequestPayloadType, ShiftRequestType } from "../../shared/models/employee-shifts.model";
import apiService from '../../shared/services/api-service';
import {SystemLogService} from "../../shared/services/system-log-service";

const EmployeeCheckInsManageCheckInPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useState<any>({
        search: '',
        code: '',
        date: '',
        date2: '',
        ordering_field: '',
        ordering: '',
        page: 1,
        page_size: 10,
    });
    const [data, setData] = useState<ApiResponse<any>>(initialResponseData);
    const { authJwt } = useAuth();
    const isAdmin = authJwt?.role === 'ADMIN';
    const { loaderComponent, isLoading, startLoading, stopLoading } = useSpinnerLoader();

    const isEdit = searchParams.get('isEdit');
    const id = searchParams.get('id');
    const userId = !isEdit && !id ? authJwt?.id : id && parseInt(id) || null;
    const currentUserId = authJwt?.id;

    const headers: Header[] = [
        {
            label: 'เหตุผล',
            field: 'reason',
            order: null,
            isOrder: true,
        },
        {
            label: 'ช่วงเวลาเปิดระบบ',
            field: 'day_type',
            order: null,
            isOrder: true,
        },
        {
            label: 'วันที่ขอ',
            field: 'date_from',
            order: null,
            isOrder: true,
        },
        {
            label: 'วันที่ทำรายการ',
            field: 'created_at',
            order: null,
            isOrder: true,
        },
        {
            label: 'สถานะ',
            field: 'status',
            order: null,
            isOrder: true,
        },
        {
            label: 'ผู้อนุมัติ',
            field: 'approver',
            order: null,
            isOrder: true,
        },
        {
            label: '',
            field: '',
            order: null,
            isOrder: false,
        }
    ];

    const fetchData = async () => {
        startLoading();
        try {
            const response = await apiService().get(`${process.env.REACT_APP_API_URI}/employees-check-ins/reqs/list`, {
                params: {
                    search: queryParams.search,
                    status: queryParams.status,
                    page: queryParams.page,
                    limit: queryParams.page_size,
                    ordering_field: queryParams.ordering_field,
                    ordering: queryParams.ordering,
                }
            });
            // for(let i=0; i<response.data.rows.length; ++i) {
            //     let obj = response.data.rows[i];
            //     obj.key = obj.user_id + '-' + obj.date;
            // }
            setData(response.data);
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('โหลดข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const apiSetStatus = async (id: any, status: any) => {
        startLoading();
        try {
            const response = await apiService().post(`${process.env.REACT_APP_API_URI}/employees-check-ins/reqs/set-status`, {
                id: id,
                status: status
            });
            notificationService.successNotification('สำเร็จ', 'อัพเดทข้อมูลสำเร็จ');
            fetchData();
        } catch (error) {
            const err = error as AxiosError;
            notificationService.dangerNotification('บันทึกข้อมูลไม่สำเร็จ', err.message);
        } finally {
            stopLoading();
        }
    };

    const onChangePageSize = (page_size: number) => {
        setQueryParams({
            ...queryParams,
            page_size: page_size
        });
    };

    const onChangePage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page: page
        });
    };
    const tableRowNoData = () => {
        if (data.rows.length === 0) {
            return <TableRowNoData colspan={7} />;
        }
        return null;
    };

    const onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
        // setQueryParams((prevState: any) => {
        //     return {...prevState, code: e.target.value};
        // });
        setQueryParams({
            ...queryParams,
            page: 1,
            search: e.target.value
        });
    };

    const onChangeCode2 = (e: ChangeEvent<HTMLSelectElement>) => {
        setQueryParams({
            ...queryParams,
            page: 1,
            status: e.target.value
        });
    };

    const onChangeOrder = (orderData: OrderData) => {
        setQueryParams({
            ...queryParams,
            ordering_field: orderData.field,
            ordering: orderData.order,
        });
    };

    //

    useEffect(() => {
        fetchData();
    }, [queryParams.ordering, queryParams.page_size, queryParams.page]);

    //

    return <div>
        <div className="d-flex justify-content-between py-4">
            <h2 className="text-mof-primary m-0">ร้องขอเปิดระบบเข้า - ออกงานสำรอง</h2>
            <Link to="/employee-check-ins/manage-check-in/create">
                <Button variant="primary" className="btn-action btn-mof-primary">
                    <FontAwesomeIcon icon={faPlus} className="me-2"/>
                    เปิดระบบ
                </Button>
            </Link>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-3">
                <select className="form-control" value={queryParams.status} onChange={onChangeCode2} name="search">
                    <option value="">-- กรุณาเลือกสถานะ --</option>
                    <option value="1">รออนุมัติ</option>
                    <option value="2">อนุมัติ</option>
                    <option value="3">ไม่อนุมัติ</option>
                    <option value="4,5">ปิดระบบ</option>
                    {/* <option value="5">ยกเลิก</option> */}
                </select>
            </div>
            <div className="col-sm-12 col-md-3">
                <input className="form-control" type="text" value={queryParams.search}
                       onChange={onChangeCode} name="search" placeholder='ค้นหา...'/>
            </div>
            <div className="col-sm-12 col-md-3">
                <Button variant="primary" type="button" className="d-block px-4" onClick={fetchData}>
                    <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </Button>
            </div>
        </div>
        <div className="overflow-auto mt-3">
            <Table striped bordered hover>
                <thead>
                    <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={true} />
                </thead>
                <tbody>
                    {
                        data.rows.map((data, index) => {
                            let name = '(' + data.code + ') ' + data.firstname_th + ' ' + data.lastname_th;
                            if(!data.code && !data.firstname_th && !data.lastname_th) { name = `ไม่พบผู้ใช้งาน`; }
                            return <tr>
                                <td>{data.reason}</td>
                                <td>{(() => {
                                    if(data.day_type == 1) { return 'เต็มวัน'; }
                                    else if(data.day_type == 2) { return 'ครึ่งเช้า'; }
                                    else if(data.day_type == 3) { return 'ครึ่งบ่าย'; }
                                    return '?';
                                })()}</td>
                                <td>{
                                    (Moment(data.date_from).isValid() ? Moment(data.date_from).format('DD/MM/YYYY') : data.date_from) + ' - ' +
                                    (Moment(data.date_to).isValid() ? Moment(data.date_to).format('DD/MM/YYYY') : data.date_to)
                                }</td>
                                <td>{Moment(data.created_at).isValid() ? Moment(data.created_at).format('DD/MM/YYYY HH:mm') : data.created_at}</td>
                                <td>{(() => {
                                    if(data.status == 1) { return 'รออนุมัติ'; }
                                    else if(data.status == 2) { return <div className="text-success">อนุมัติ</div>; }
                                    else if(data.status == 3) { return <div className="text-danger">ไม่อนุมัติ</div>; }
                                    else if(data.status == 4) { return 'ปิดระบบ'; }
                                    else if(data.status == 5) { return 'ปิดระบบ'; }
                                    return '?';
                                })()}</td>
                                <td>{data.approver ? data.approver : '-'}</td>
                                <td>{(() => {
                                    return <span>
                                        <Button variant="warning" type="button" className="d-inline-block px-4 me-2" onClick={ () => {
                                            navigate('/employee-check-ins/manage-check-in/edit/' + data.id);
                                        } }>แก้ไข</Button>
                                        { (() => {
                                            if([4,5].includes(data.status)) {

                                            } else if(isAdmin) {
                                                return <Button variant="danger" type="button" className="d-inline-block px-4" onClick={ () => { apiSetStatus(data.id, 5) } }>
                                                    ปิดระบบ
                                                </Button>;
                                            }
                                        })() }
                                    </span>
                                })() }</td>
                            </tr>;
                        })
                    }
                    {tableRowNoData()}
                </tbody>
            </Table>
        </div>
        <div className="">
            <PaginationForm current_page={queryParams.page} count={data.count} page_size={queryParams.page_size}
                onChangePageSize={onChangePageSize} onChangePage={onChangePage} />
        </div>
        {loaderComponent}
    </div>;
}

export default EmployeeCheckInsManageCheckInPage;
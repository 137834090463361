import React, { FC, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { EducationHistoryModel } from '../../../../shared/models/employee.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEmployee,
  getCurrentEmployeeEditProfile,
  getEmployeeAction
} from '../../../../shared/services/employee-service';
import TableRowNoData from '../../../../shared/components/molecules/commons/table-row-no-data';
import {
  handleEmployeeSectionInputChange,
  handleEmployeeSectionRemoveByIndex
} from '../../../../shared/utils/function-state';
import DeleteConfirmationModal from '../../../../shared/components/molecules/modals/delete-confirmation-modal';
import SectionFileForm from '../section-file-form';
import { SortingField } from '../../../../shared/models/common.model';
import TableHeaderOrdering, { Header, OrderData } from '../../../../shared/components/table-header-ordering';
import { SortingByField } from '../../../../shared/utils/sorting';
import { handleNumericInput } from '../../../../shared/utils/function-validators';

interface EducationHistoryRowProps extends EducationHistoryModel {
  index: number;
}


const EducationHistoryForm: FC = () => {
  const employeeAction = useSelector(getEmployeeAction);
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const showOrder = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const [sorting, setSorting] = useState<SortingField>({
    field: null,
    order: null
  });

  const tableRowNoData = () => {
    if (employee.education_histories.length === 0) {
      return <TableRowNoData colspan={6}/>;
    }
    return null;
  };

  const headers: Header[] = [
    {
      label: 'สถานศึกษา',
      field: 'school_name',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ระดับ',
      field: 'graduation_level',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'สาขาวิชา',
      field: 'graduation_major',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'ปีที่สำเร็จการศึกษา (พ.ศ.)',
      field: 'graduation_year',
      order: null,
      isOrder: showOrder,
    },
    {
      label: 'แนบไฟล์',
      field: 'file',
      order: null,
      isOrder: false,
    },
    {
      label: '',
      field: '',
      order: null,
      isOrder: false,
    }
  ];

  const onChangeOrder = (orderData: OrderData) => {
    setSorting({
      field: orderData.field,
      order: orderData.order
    });
  };

  const dataOrder = () => {
    if (showOrder)
      return employee.education_histories.map(data => data).sort((a, b) => {
        return SortingByField(a, b, sorting);
      });
    return employee.education_histories;
  };

  return (
    <div className="h-100 overflow-x">
      <Table bordered>
        <thead>
        <TableHeaderOrdering headers={headers} onChangeOrder={onChangeOrder} isShow={showOrder}/>
        </thead>
        <tbody>
        {
          dataOrder().map((data, index) => {
            return <EducationHistoryRow
              key={index}
              index={index}
              {...data}
            />;
          })
        }
        {tableRowNoData()}
        </tbody>
      </Table>
    </div>
  );
};

const EducationHistoryRow: FC<EducationHistoryRowProps> = ({
                                                             index,
                                                             school_name,
                                                             graduation_level,
                                                             graduation_major,
                                                             graduation_year,
                                                             file,
                                                             from_id,
                                                           }) => {
  const dispatch = useDispatch();
  const employeeAction = useSelector(getEmployeeAction);
  const disable = employeeAction.type === 'view' || employeeAction.type === 'view-edit-request';
  const type = 'education_histories';
  const employeeStateTarget = employeeAction.type === 'view-edit-request' ? getCurrentEmployeeEditProfile : getCurrentEmployee;
  const employee = useSelector(employeeStateTarget);
  const oldEmployee = useSelector(getCurrentEmployee);
  let fromEmployee: any | null = null;

  if (from_id) {
    const found = oldEmployee[type].find(data => data.id === from_id);
    if (found)
      fromEmployee = found;
  }

  const validateEditRequest = (value: any, key: keyof EducationHistoryModel) => {
    if (employeeAction.type === 'view-edit-request') {
      if (fromEmployee && from_id) {
        if (value !== fromEmployee[key])
          return 'field-warning';
      }
      if (!from_id) {
        return 'field-new';
      }
    }
    return '';
  };

  const [showModal, setShowModal] = useState(false);

  const deleteButton = () => {
    if (!disable) {
      return <FontAwesomeIcon icon={faCircleXmark} className="text-danger pointer" onClick={handleDelete}/>;
    }
    return null;
  };

  const handleDelete = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleEmployeeSectionRemoveByIndex(type, index, dispatch);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <tr className="bg-white">
      <td className={`${validateEditRequest(school_name, 'school_name')}`}>
        <input type="text" className="form-control" name="school_name" disabled={disable}
               placeholder="" value={school_name || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(graduation_level, 'graduation_level')}`}>
        <input type="text" className="form-control" name="graduation_level" disabled={disable}
               placeholder="" value={graduation_level || ''} required={true} maxLength={100}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(graduation_major, 'graduation_major')}`}>
        <input type="text" className="form-control" name="graduation_major" disabled={disable}
               placeholder="" value={graduation_major || ''} required={true}
               onChange={(e) => handleEmployeeSectionInputChange(type, index, e, dispatch)}/>
      </td>
      <td className={`${validateEditRequest(graduation_year, 'graduation_year')}`}>
        <input type="text" className="form-control" name="graduation_year" disabled={disable}
               maxLength={4}
               pattern=".{4,4}"
               placeholder="" value={graduation_year || ''} required={true}
               onChange={(e) => {
                 handleNumericInput(e);
                 handleEmployeeSectionInputChange(type, index, e, dispatch);
               }}/>
      </td>
      <td className={`${validateEditRequest(file, 'file')}`}>
        <SectionFileForm section_key={'education_histories'} index={
          disable ? employee.education_histories.findIndex(data =>
            data.school_name === school_name &&
            data.graduation_level === graduation_level &&
            data.graduation_major === graduation_major &&
            data.graduation_year === graduation_year
          ) : index
        }/>
      </td>
      <td className="text-center">
        {deleteButton()}
      </td>
      <DeleteConfirmationModal
        show={showModal}
        title={'ยืนยันการลบข้อมูล'}
        description={'คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลนี้'}
        onHide={handleCloseModal}
        onConfirm={handleConfirmDelete}
      />
    </tr>
  );
};

export default EducationHistoryForm;